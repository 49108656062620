import { NumbersOutlined, PercentOutlined } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import clsx from 'clsx'

import styles from './DiffTypeSelector.module.scss'

interface DiffTypeSelectorProps {
  value: 'percent' | 'absolute'
  onChange: (type: 'percent' | 'absolute') => void
  disabled: boolean
}

export function DiffTypeSelector({ value, onChange, disabled }: DiffTypeSelectorProps) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_e, value) => onChange(value)}
      aria-label='text alignment'
      disabled={disabled}
    >
      <ToggleButton className={clsx(styles.toggleButton)} value='percent' aria-label='percent'>
        <Tooltip title={'View values as percentages of total number of API calls'} placement={'top'}>
          <PercentOutlined fontSize={'small'} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton className={clsx(styles.toggleButton)} value='absolute' aria-label='absolute'>
        <Tooltip title={'View values as total number of calls for each smart signal'} placement={'top'}>
          <NumbersOutlined fontSize={'small'} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
