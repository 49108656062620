import { Check, Download, ErrorOutline, FileCopyOutlined } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  Link,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import {
  DOCS_INTEGRATIONS,
  DOCS_PUBLIC_MANAGEMENT_API,
  DOCS_SEALED_KEYS,
  DOCS_SERVER_API,
  SUPPORT_PAGE_URL,
  USE_ENCRYPTED_KEYS,
  USE_WORKSPACE_ENVIRONMENT,
} from 'const'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { copyToClipboard } from 'helpers/clipboard'
import { getErrorParams } from 'helpers/data'
import { download } from 'helpers/download'
import { muiRegister } from 'helpers/reactHookForm'
import { MIMEType } from 'helpers/types'
import { useIsApplicationFeatureEnabled, useToast } from 'hooks'
import { useWorkspaceEnvironments } from 'hooks/api/environment'
import { useEncryptionKeyCreateMutation, useTokenCreateMutation } from 'hooks/api/tokens'
import { ApiKey, ApiKeyType, ApiKeyTypeLogMap, ApplicationFeatureName, WorkspaceEnvironment } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { PRIVATE_KEY_TYPES, privateWarning } from '../../content'
import { KeyDescription } from '../KeyDescription/KeyDescription'
import { apiKeyDisplayNameMap } from '../KeySection/KeySection'
import styles from './CreateApiKeyDialog.module.scss'

export interface CreateApiKeyDialogProps extends DialogProps {
  createType: ApiKeyType
  onCloseCreateDialog: (apiKeyType: ApiKeyType, completed: boolean) => void
}

export default function CreateApiKeyDialog(props: CreateApiKeyDialogProps) {
  const { showToast } = useToast()
  const { mutate: sendCreateApiKeyRequest, isLoading } = useTokenCreateMutation()
  const { mutate: sendCreateEncryptionKeyRequest, isLoading: isLoadingEncryptionKeyCreate } =
    useEncryptionKeyCreateMutation()
  const [createdApiKey, setCreatedApiKey] = useState<ApiKey>()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: workspaceEnvironmentsData, isLoading: isLoadingEnvironments } =
    useWorkspaceEnvironments(currentSubscriptionId)
  const isEncryptionEnabled = useIsApplicationFeatureEnabled(
    currentSubscriptionId,
    ApplicationFeatureName.ResultsEncryption
  )

  const isEncryptionEnabledAndFeatureFlagOn = isEncryptionEnabled && USE_ENCRYPTED_KEYS

  const onCreate = useCallback(
    (data: CreateApiKeyFormData) => {
      if (data.type === ApiKeyType.Encryption) {
        sendCreateEncryptionKeyRequest(
          {
            data: {
              ...data,
              description: data.description?.length >= 1 ? data.description : undefined,
            },
            params: { subscriptionId: currentSubscriptionId },
          },
          {
            onSuccess: (responseData) => {
              ampli.apiKeyAdded({ apiKeyType: 'Encryption', environmentChanged: false })
              setCreatedApiKey({ ...responseData, type: ApiKeyType.Encryption })
            },
            onError: (e) => {
              showToast({ message: e.message, severity: 'error' })
            },
          }
        )
        return
      }

      sendCreateApiKeyRequest(
        {
          data: {
            ...data,
            description: data.description?.length >= 1 ? data.description : undefined,
          },
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: (responseData) => {
            ampli.apiKeyAdded({ apiKeyType: apiKeyDisplayNameMap[data.type], environmentChanged: false })
            setCreatedApiKey(responseData)
          },
          onError: (e) => {
            showToast({ message: e.message, severity: 'error' })
          },
        }
      )
    },
    [sendCreateApiKeyRequest, currentSubscriptionId, sendCreateEncryptionKeyRequest, showToast]
  )

  if (createdApiKey) {
    return (
      <ApiKeyCreatedConfirmationDialog
        open
        apiKey={createdApiKey}
        onClose={() => props.onCloseCreateDialog(createdApiKey.type, true)}
      />
    )
  }

  return (
    <>
      {workspaceEnvironmentsData && (
        <CreateApiKeyDialogView
          {...props}
          isEncryptionEnabled={isEncryptionEnabledAndFeatureFlagOn}
          setCreatedApiKey={setCreatedApiKey}
          onCreate={onCreate}
          isLoading={isLoading || isLoadingEncryptionKeyCreate || isLoadingEnvironments}
          workspaceEnvironments={workspaceEnvironmentsData}
        />
      )}
    </>
  )
}

export interface CreateApiKeyDialogViewProps extends CreateApiKeyDialogProps {
  isEncryptionEnabled: boolean
  onCreate: (data: CreateApiKeyFormData) => void
  setCreatedApiKey: (apiKey: ApiKey) => void
  isLoading: boolean
  workspaceEnvironments?: WorkspaceEnvironment[]
}

export type CreateApiKeyFormData = {
  type: ApiKeyType
  name: string
  description: string
  workspaceEnvironmentId?: string
}

// display component for storybook
export function CreateApiKeyDialogView({
  onCreate,
  isEncryptionEnabled,
  onCloseCreateDialog: onCloseCreateModal,
  setCreatedApiKey,
  createType,
  workspaceEnvironments,
  isLoading,
  ...dialogProps
}: CreateApiKeyDialogViewProps) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<CreateApiKeyFormData>({
    defaultValues: {
      type: createType,
      name: '',
      description: '',
      workspaceEnvironmentId: (workspaceEnvironments && workspaceEnvironments[0]?.id) || '',
    },
  })

  const type = watch('type')

  const onClose = useCallback(() => {
    onCloseCreateModal(type, false)
  }, [onCloseCreateModal, type])

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const onEnvironmentSelect = () => {
    ampli.environmentSelectionOpened({ featureContext: 'API Keys' })
  }

  const onEnvironmentChange = () => {
    ampli.environmentSelected({ featureContext: 'API Keys' })
  }

  return (
    <Dialog
      onClose={onClose}
      {...dialogProps}
      fullScreen={smDown}
      isLoading={isLoading}
      classes={{ paper: styles.dialog }}
    >
      <form onSubmit={handleSubmit(onCreate)} noValidate>
        <DialogTitle onClose={onClose} className={styles.dialogTitle}>
          <Typography
            variant='h1'
            component='h1'
            className={styles.heading}
            data-testid={`modal-apikeys-${createType}`}
          >
            Create new {apiKeyDisplayNameMap[createType].toLowerCase()} key
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.content}>
          <Grid container className={styles.formElements}>
            <Grid item xs={12} sx={{ marginTop: '8px', marginBottom: '20px' }}>
              <Stack direction='column' gap={2}>
                <Typography variant='bodyM'>
                  <KeyDescription keyType={createType} />
                </Typography>
                {PRIVATE_KEY_TYPES.includes(createType) && (
                  <Typography variant='bodyMMedium'>{privateWarning(createType)}</Typography>
                )}
              </Stack>
            </Grid>

            {USE_WORKSPACE_ENVIRONMENT && [ApiKeyType.Public, ApiKeyType.Secret].includes(createType) && (
              <Grid item xs={12}>
                <InputLabel htmlFor='workspaceEnvironmentId'>Environment</InputLabel>
                <Select
                  id='workspaceEnvironmentId'
                  native
                  labelId='environment-label-id'
                  inputProps={{
                    'aria-label': 'Environment',
                  }}
                  {...muiRegister(register, 'workspaceEnvironmentId', {
                    required: true,
                  })}
                  fullWidth
                  className={styles.field}
                  onClick={onEnvironmentSelect}
                  onChange={onEnvironmentChange}
                >
                  {workspaceEnvironments?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel htmlFor='name'>Name</InputLabel>
              <TextField
                autoFocus
                autoComplete='off'
                fullWidth
                variant='outlined'
                placeholder='My Production Key'
                id='name'
                data-testid='create-apikey-dialog-name-input'
                title='Name'
                inputProps={{ 'aria-label': 'name' }}
                {...muiRegister(register, 'name', {
                  validate: (value) => {
                    // we don't allow empty strings as names
                    if (value.trim().length > 0) {
                      return true
                    }
                    return 'Please give your API key a name.'
                  },
                })}
                {...getErrorParams('name', errors)}
                className={styles.input}
                FormHelperTextProps={{ classes: { root: styles.helperText } }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor='name'>Description (optional)</InputLabel>
              <TextField
                autoComplete='off'
                fullWidth
                variant='outlined'
                placeholder='A short description of my API key.'
                id='description'
                title='Description'
                inputProps={{ 'aria-label': 'description' }}
                {...muiRegister(register, 'description')}
                {...getErrorParams('description', errors)}
                className={styles.input}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Button color='lightGrey' variant='outlined' onClick={onClose} size='large'>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            type='submit'
            disabled={!!errors.name}
            size='large'
            data-testid='create-apikey-dialog-submit'
          >
            Create API key
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export interface ApiKeyCreatedConfirmationDialogProps extends DialogProps {
  apiKey: ApiKey
}

export function ApiKeyCreatedConfirmationDialog({ apiKey, ...dialogProps }: ApiKeyCreatedConfirmationDialogProps) {
  const [isCopied, setIsCopied] = useState(false)
  const downloadable = [ApiKeyType.Secret, ApiKeyType.BrowserProxy].includes(apiKey.type)

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }, [isCopied])

  const onDownload = useCallback(() => {
    download(apiKey.token ?? '', `${apiKey.name ?? 'key'}.txt`, MIMEType.PlainText)
  }, [apiKey])

  const onCopy = useCallback(() => {
    const keyToCopy = apiKey.encodedKey ? apiKey.encodedKey : apiKey.token

    ampli.apiKeyCopied({ apiKeyType: ApiKeyTypeLogMap[apiKey.type] })
    copyToClipboard(keyToCopy ?? '')
    setIsCopied(true)
  }, [apiKey])

  const titleText: Record<ApiKeyType, string> = {
    [ApiKeyType.Public]: "Congrats, it's an API key!",
    [ApiKeyType.Secret]: 'Save this key somewhere!',
    [ApiKeyType.BrowserProxy]: 'Save this key somewhere!',
    [ApiKeyType.Encryption]: "Congrats, it's an API key!",
    [ApiKeyType.Management]: "Congrats, it's an API key!",
  }
  const subtitleText: Record<ApiKeyType, string> = {
    [ApiKeyType.Public]: 'You are the proud owner of a fresh out-of-the-box public key',
    [ApiKeyType.Secret]: 'We’ll only show this secret key once, so please store it somewhere safe.',
    [ApiKeyType.BrowserProxy]: 'We’ll only show this secret key once, so please store it somewhere safe.',
    [ApiKeyType.Encryption]: "You now have a fresh encryption key (don't share this one)",
    [ApiKeyType.Management]: 'You are the proud owner of a fresh management key',
  }

  const token = (apiKey: ApiKey) => {
    return apiKey.token || apiKey.encodedKey
  }

  return (
    <Dialog {...dialogProps} classes={{ paper: styles.createdDialog }}>
      <DialogTitle hideCloseButton onClose={dialogProps.onClose} className={styles.createdDialogTitle}>
        <Box>
          <Typography variant='h1' className={styles.heading} data-testid={`dialog-header-created-${apiKey.type}`}>
            {titleText[apiKey.type]}
          </Typography>
          <Typography variant='body1'>{subtitleText[apiKey.type]}</Typography>
        </Box>
      </DialogTitle>

      <DialogContent className={styles.createdDialogContent}>
        <Alert icon={<ErrorOutline />} severity='warning' className={styles.delayWarningAlert}>
          Please allow a few minutes for the key to propagate before using it in production.
        </Alert>
        <Typography className={styles.bankNoteKeyDisplay}>{token(apiKey)}</Typography>
        {downloadable && (
          <Button
            onClick={onDownload}
            variant='contained'
            startIcon={<Download />}
            className={styles.dialogAction}
            data-testid='apikey-download-txt-button'
          >
            Download .txt
          </Button>
        )}
        <Button
          onClick={onCopy}
          variant={downloadable ? 'outlined' : 'contained'}
          startIcon={isCopied ? <Check /> : <FileCopyOutlined />}
          className={styles.dialogAction}
        >
          {isCopied ? 'Copied!' : 'Copy to clipboard'}
        </Button>
        <Box className={styles.setupInstructions}>
          <Typography variant='semiBody1'>Next steps</Typography>
          <ol>
            <NextSteps keyType={apiKey.type} />
          </ol>
        </Box>
        <Typography variant='semiBody1'>Have questions?</Typography>
        <Typography>
          <Link href={SUPPORT_PAGE_URL} target='_blank' underline='hover'>
            Reach out to us
          </Link>
          . Our support engineers are happy to help.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

function NextSteps({ keyType }: { keyType: ApiKeyType }) {
  const { currentSubscriptionId } = useCurrentSubscription()

  switch (keyType) {
    case ApiKeyType.Public:
      return (
        <>
          <li>
            Add the Fingerprint Agent to your website or mobile app. See the install instructions for all our{' '}
            <Link
              component={RouterLink}
              to={buildRoute(AppRoute.Integrations, { subscriptionId: currentSubscriptionId })}
              underline='hover'
            >
              client libraries
            </Link>
            .
          </li>
          <li>Copy your API Key above and paste it in the Agent configuration.</li>
          <li>Start making identification requests!</li>
        </>
      )

    case ApiKeyType.Secret:
      return (
        <>
          <li>
            Copy your API key above and use it as the Auth-API-Key header when making requests to the{' '}
            <Link href={DOCS_SERVER_API} target='_blank' underline='hover'>
              Server API
            </Link>
            .
          </li>
          <li>
            To make things easy, we recommend using one of our{' '}
            <Link
              component={RouterLink}
              to={buildRoute(AppRoute.Integrations, { subscriptionId: currentSubscriptionId })}
              underline='hover'
            >
              Server libraries
            </Link>
            .
          </li>
          <li>Start making Server API requests!</li>
        </>
      )

    case ApiKeyType.Encryption:
      return (
        <>
          <li>
            Copy your API key above and use it in your backend{' '}
            <Link href={DOCS_SEALED_KEYS} target='_blank' underline='hover'>
              decrypt client-side payloads
            </Link>
            .
          </li>
          <li>Once your backend is ready, return to this page and activate the key.</li>
          <li>Continue making identification requests!</li>
        </>
      )

    case ApiKeyType.Management:
      return (
        <>
          <li>
            Review the{' '}
            <Link href={DOCS_PUBLIC_MANAGEMENT_API} target='_blank' underline='hover'>
              developer docs
            </Link>{' '}
            for the management API key.
          </li>
          <li>Copy your API key above and paste it in your backend.</li>
          <li>Start making management API requests!</li>
        </>
      )

    case ApiKeyType.BrowserProxy:
      return (
        <>
          <li>
            Review the documentation of your chosen proxy integration:{' '}
            <Link href={DOCS_INTEGRATIONS.akamai} target='_blank' underline='hover'>
              Akamai
            </Link>
            ,{' '}
            <Link href={DOCS_INTEGRATIONS.cloudFront} target='_blank' underline='hover'>
              AWS CloudFront
            </Link>
            ,{' '}
            <Link href={DOCS_INTEGRATIONS.azure} target='_blank' underline='hover'>
              Azure
            </Link>
            , or{' '}
            <Link href={DOCS_INTEGRATIONS.cloudflare} target='_blank' underline='hover'>
              Cloudflare
            </Link>
            .
          </li>
          <li>Copy your API key above and use it according to the integration guide.</li>
          <li>Start proxying requests!</li>
        </>
      )
  }
}
