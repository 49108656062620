import { USE_WEBHOOK_SIGNATURE } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { useLogPageView } from 'helpers/vendor'
import {
  useWebhookCreateMutation,
  useWebhookDeleteMutation,
  useWebhooks,
  useWebhookUpdateMutation,
} from 'hooks/api/webhooks'
import { ApplicationFeatureName, Limits, SubscriptionWebhook, WebhookSignature } from 'models'
import { ampli } from 'models/ampli'
import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AppRoute, buildRoute } from '../../../../appRoutes'
import { useIsApplicationFeatureEnabled } from '../../../../hooks'
import { useEntityLimit } from '../../../../hooks/api/context'
import { WebhookVerificationStatus } from '../../../../models'
import { WebhookCreatedConfirmationDialog } from '../../components/WebhookDialog/confirmation/WebhookCreatedConfirmationDialog'
import { default as Webhooks } from '../../components/Webhooks/Webhooks'

export function WebhooksPage() {
  useLogPageView((route) => ampli.webhooksPageViewed({ route }))
  const history = useHistory()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const [isPendingVerification, setIsPendingVerification] = useState(true)
  const [isWebhookConfirmationModalOpen, setIsWebhookConfirmationModalOpen] = useState<boolean>(false)
  const [webhookSignature, setWebhookSignature] = useState<WebhookSignature | null>(null)
  const [webhookId, setWebhookId] = useState<string | null>(null)

  const { data: webhooks, isLoading, error } = useWebhooks(subscriptionId, isPendingVerification, 30)

  const { mutate: sendWebhookCreateRequest } = useWebhookCreateMutation()
  const { mutate: sendWebhookUpdateRequest } = useWebhookUpdateMutation()
  const { mutate: sendWebhookDeleteRequest } = useWebhookDeleteMutation()

  const isWebhookSignatureEnabled = useIsApplicationFeatureEnabled(
    subscriptionId,
    ApplicationFeatureName.WebhookSignatures
  )

  useEffect(() => {
    if (webhooks) {
      setIsPendingVerification(
        webhooks
          .map(({ verificationStatus }) => verificationStatus === WebhookVerificationStatus.Verifying)
          .some((status) => status)
      )
    }
  }, [webhooks])

  const handleWebhookCreate = useCallback<ComponentProps<typeof Webhooks>['onWebhookAdd']>(
    (payload) => {
      sendWebhookCreateRequest(
        { data: payload, params: { subscriptionId } },
        {
          onSuccess: (webhook: SubscriptionWebhook) => {
            if (
              USE_WEBHOOK_SIGNATURE &&
              isWebhookSignatureEnabled &&
              webhook.webhookSignatures &&
              webhook.webhookSignatures.length > 0
            ) {
              setIsWebhookConfirmationModalOpen(true)
              setWebhookSignature(webhook.webhookSignatures[0])
              setWebhookId(webhook.id)
            } else {
              history.push(buildRoute(AppRoute.WebhookVerification, { subscriptionId, webhookId: webhook.id }))
            }
          },
        }
      )
    },
    [history, isWebhookSignatureEnabled, sendWebhookCreateRequest, subscriptionId]
  )

  // TODO fix type issue
  const handleWebhookUpdate = useCallback<(params: SubscriptionWebhook) => void>(
    (payload) => {
      sendWebhookUpdateRequest({ data: payload, params: { id: payload.id, subscriptionId } })
    },
    [sendWebhookUpdateRequest, subscriptionId]
  )

  const handleWebhookDelete = useCallback<ComponentProps<typeof Webhooks>['onWebhookDelete']>(
    (id) => {
      sendWebhookDeleteRequest({ params: { id, subscriptionId } })
    },
    [sendWebhookDeleteRequest, subscriptionId]
  )

  const handleWebhookCreatedConfirmationDialogClose = useCallback(() => {
    setIsWebhookConfirmationModalOpen(false)
    setWebhookSignature(null)
    if (webhookId) {
      history.push(buildRoute(AppRoute.WebhookVerification, { subscriptionId, webhookId }))
    }
  }, [history, subscriptionId, webhookId])

  return (
    <>
      <Webhooks
        webhooks={webhooks ?? []}
        webhooksLimit={useEntityLimit(subscriptionId, Limits.Webhooks)}
        isLoading={isLoading}
        error={error}
        onWebhookAdd={handleWebhookCreate}
        onWebhookEdit={handleWebhookUpdate}
        onWebhookDelete={handleWebhookDelete}
      />

      {webhookSignature && (
        <WebhookCreatedConfirmationDialog
          open={isWebhookConfirmationModalOpen}
          onClose={handleWebhookCreatedConfirmationDialogClose}
          webhookSignature={webhookSignature}
        />
      )}
    </>
  )
}
