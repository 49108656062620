import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { CheckCircleOutlined, DeleteOutlined, EmailOutlined, MailOutline } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { accountSettingsNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useLogPageView } from 'helpers/vendor'
import { useConfirmationDialog, useDocumentTitle, useToast } from 'hooks'
import { useUserCreateMutation, useUserDeleteMutation, useUsers, useUserUpdateMutation } from 'hooks/api/users'
import { useRouteState } from 'hooks/router'
import { EditUserFormData, User, USER_ROLE_ANALYTICS_LABELS, UserFormData } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'

import { usePermissions } from '../../hooks'
import {
  useAccountTransferConfirmMutation,
  useAccountTransferIntentMutation,
  useAccountTransferMutation,
  useAccountTransferRequest,
  useCancelAccountTransferMutation,
} from '../../hooks/api/accountTransfer'
import { useUserContext } from '../../hooks/api/context'
import { useQueryParams } from '../../hooks/queryParams'
import { CancelTransferOwnershipDialog } from './components/CancelTransferOwnershipDialog/CancelTransferOwnershipDialog'
import { TransferOwnership } from './components/TransferOwnership/TransferOwnership'
import { UserDialog } from './components/UserDialog/UserDialog'
import { UserManagement } from './components/UserManagement/UserManagement'
import { ManagedUser } from './components/UserManagement/UserManagementData'

enum TransferDialog {
  Request = 'request',
  Cancel = 'cancel',
}

enum UserManagementAction {
  ConfirmTransfer = 'confirm_transfer',
}

export function UserManagementPage() {
  useDocumentTitle('Manage Users')
  useLogPageView(() => ampli.settingsPageViewed())

  const { showToast } = useToast()
  const { openDialog } = useConfirmationDialog()

  const state = useRouteState<{ isUserDialogOpen?: boolean }>()
  const { action, actionId } = useQueryParams()
  const { data: users, isLoading: usersLoading } = useUsers()

  const [isUserDialogOpen, setIsUserDialogOpen] = useState(state?.isUserDialogOpen ?? false)
  const [userData, setUserData] = useState<User | undefined>()

  const { mutate: addUser, isLoading: isAddingUser, error: addUserError } = useUserCreateMutation()
  const { mutate: updateUser, isLoading: isUpdatingUser, error: updateUserError } = useUserUpdateMutation()
  const { mutate: deleteUser, isLoading: isDeletingUser } = useUserDeleteMutation()

  const { data: visitorData, isLoading: isLoadingFpjs } = useVisitorData({ extendedResult: true })
  const { data: pendingAccountTransfer, isLoading: isLoadingPendingTransfer } = useAccountTransferRequest()
  const { data: context, isLoading: isLoadingContext } = useUserContext()
  const {
    mutate: requestAccountTransfer,
    isLoading: isRequestingAccountTransfer,
    error: requestAccountTransferError,
    reset: resetAccountTransferMutation,
  } = useAccountTransferMutation()
  const { mutate: cancelAccountTransfer, isLoading: isCancellingAccountTransfer } = useCancelAccountTransferMutation()
  const { mutate: confirmAccountTransfer, isLoading: isConfirmingAccountTransfer } = useAccountTransferConfirmMutation()

  const { mutate: requestAccountTransferOtp, isLoading: isRequestingAccountTransferOtp } =
    useAccountTransferIntentMutation()

  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState<TransferDialog | undefined>(undefined)
  const [transferTarget, setTransferTarget] = useState<ManagedUser | undefined>(undefined)

  const { userPermissions } = usePermissions()

  function onExitUserDialog() {
    setUserData(undefined)
    setIsUserDialogOpen(false)
  }

  function onCloseUserDialog() {
    if (userData) {
      ampli.userEditingCanceled({
        userID: userData.id,
      })
    } else {
      ampli.addNewUserCancelled()
    }

    onExitUserDialog()
  }

  async function onAddUser(data: UserFormData) {
    await addUser(
      { data },
      {
        onSuccess: () => {
          showToast({
            severity: 'success',
            icon: <EmailOutlined />,
            message: (
              <>
                Success! A blazingly fast carrier pigeon has delivered the invite link to {data.name} at{' '}
                <strong>{data.email}</strong>.
              </>
            ),
          })
          ampli.newUserInvitationSent()
          onExitUserDialog()
        },
      }
    )
  }

  async function onEditUser(data: EditUserFormData) {
    await updateUser(
      { data, params: { userId: userData?.id ?? '' } },
      {
        onSuccess: () => {
          showToast({
            severity: 'success',
            icon: <CheckCircleOutlined />,
            message: (
              <>
                Ta-da! User <strong>{data.name}</strong> has been updated.
              </>
            ),
          })
          onExitUserDialog()
        },
      }
    )
  }

  async function onDeleteUser(user: User) {
    await deleteUser(
      { params: { userId: user.id } },
      {
        onSuccess: () => {
          showToast({
            severity: 'error',
            icon: <DeleteOutlined />,
            message: (
              <>
                It’s been done. User <strong>{user.name} was removed</strong> from your team.
              </>
            ),
          })
        },
      }
    )
  }

  const onConfirmTransfer = useCallback(
    async (transferRequestId: string) => {
      await confirmAccountTransfer(
        { queryParams: { id: transferRequestId } },
        {
          onSuccess: () =>
            showToast({
              severity: 'success',
              icon: <CheckCircleOutlined />,
              message: 'Done! You are now the owner of this Fingerprint account.',
            }),
        }
      )
    },
    [confirmAccountTransfer, showToast]
  )

  async function handleTransferAccount(password: string, target: ManagedUser) {
    await requestAccountTransfer(
      { data: { newOwnerEmail: target.email, password, fpjsVisitorId: visitorData?.visitorId } },
      {
        onSuccess: () => {
          showToast({
            severity: 'success',
            icon: <MailOutline />,
            message: (
              <>
                Success! We’ve sent {target.name} at <strong>{target.email}</strong> an invite to become the new owner
                of this Fingerprint account.
              </>
            ),
          })
          ampli.ownershipTransferStarted()
          setIsTransferDialogOpen(undefined)
        },
      }
    )
  }

  async function handleCancelTransferAccount(target: ManagedUser) {
    if (pendingAccountTransfer) {
      await cancelAccountTransfer(
        {
          params: {
            id: pendingAccountTransfer.id,
          },
        },
        {
          onSuccess: () => {
            showToast({
              severity: 'success',
              icon: <CheckCircleOutlined />,
              message: `It’s been done. The transfer of account ownership to ${target.name} has been canceled.`,
            })
            ampli.ownershipTransferCancelled()
            setIsTransferDialogOpen(undefined)
          },
        }
      )
    }
  }

  function handleCloseTransferDialog() {
    setIsTransferDialogOpen(undefined)
    // Clear possible OTP errors so they don't show up next time the dialog is opened.
    resetAccountTransferMutation()
  }

  useEffect(() => {
    switch (action as UserManagementAction) {
      case UserManagementAction.ConfirmTransfer:
        onConfirmTransfer(actionId)
        break
    }
  }, [action, actionId, onConfirmTransfer])

  return (
    <>
      <Header title='Account Settings' />
      <TabNavigation items={accountSettingsNavItems} />
      <MainContent>
        <UserManagement
          users={users ?? []}
          permissions={{
            edit: userPermissions.canEdit,
            delete: userPermissions.canDelete,
            transferOwnership: userPermissions.canTransferOwnership,
          }}
          onOpenDeleteDialog={(id) => {
            const user = users?.find(({ id: userId }) => userId === id)
            if (!user) {
              return
            }

            const analyticsRoleLabel = USER_ROLE_ANALYTICS_LABELS[user.role]
            if (analyticsRoleLabel) {
              ampli.userDeletingStarted({
                userID: user.id,
                userRole: analyticsRoleLabel,
              })
            }

            openDialog({
              label: 'Delete user',
              content: (
                <Typography variant='bodyM'>
                  Are you sure you want to remove{' '}
                  <Typography variant='bodyM' sx={{ fontWeight: 500 }}>
                    {user.name}
                  </Typography>{' '}
                  ({user.email}) from your team?
                  <br />
                  <br />
                  Don’t worry, you can always invite them again if you change your mind.
                </Typography>
              ),
              confirmText: `Yes, remove ${user.name ?? 'them'}`,
              onConfirm: () => onDeleteUser(user),
              onReject: () => {
                const analyticsRoleLabel = USER_ROLE_ANALYTICS_LABELS[user.role]
                if (analyticsRoleLabel) {
                  ampli.userDeletingCanceled({
                    userID: user.id,
                    userRole: analyticsRoleLabel,
                  })
                }
              },
            })
          }}
          onOpenAddDialog={() => {
            ampli.addUserClicked()
            setUserData(undefined)
            setIsUserDialogOpen(true)
          }}
          onOpenEditDialog={(id) => {
            const user = users?.find(({ id: userId }) => userId === id)
            if (!user) {
              return
            }

            const analyticsRoleLabel = USER_ROLE_ANALYTICS_LABELS[user.role]
            if (analyticsRoleLabel) {
              ampli.userEditingStarted({
                userID: user.id,
                userRole: analyticsRoleLabel,
              })
            }

            setUserData(user)
            setIsUserDialogOpen(true)
          }}
          isSsoEnabled={context?.isSsoEnabled}
          pendingAccountTransfer={pendingAccountTransfer}
          onTransferAccount={(target) => {
            setTransferTarget(target)
            setIsTransferDialogOpen(TransferDialog.Request)
          }}
          onCancelTransferAccount={(target) => {
            setTransferTarget(target)
            setIsTransferDialogOpen(TransferDialog.Cancel)
          }}
          isLoading={
            usersLoading ||
            isDeletingUser ||
            isLoadingPendingTransfer ||
            isConfirmingAccountTransfer ||
            isLoadingFpjs ||
            isLoadingContext
          }
        />
      </MainContent>

      <UserDialog
        data={userData}
        open={isUserDialogOpen}
        isLoading={isAddingUser || isUpdatingUser}
        error={addUserError ?? updateUserError}
        onAddUser={onAddUser}
        onEditUser={onEditUser}
        onClose={onCloseUserDialog}
      />

      {context && (
        <TransferOwnership
          target={transferTarget}
          open={isTransferDialogOpen === TransferDialog.Request}
          onClose={handleCloseTransferDialog}
          onConfirmTransfer={handleTransferAccount}
          isLoading={isRequestingAccountTransfer || isRequestingAccountTransferOtp}
          context={context}
          onSendOtp={(onSuccess, onError) => {
            requestAccountTransferOtp({}, { onSuccess, onError })
          }}
          isSendingOtp={isRequestingAccountTransferOtp}
          otpError={requestAccountTransferError?.param === 'otp' ? requestAccountTransferError : undefined}
        />
      )}

      <CancelTransferOwnershipDialog
        target={transferTarget}
        open={isTransferDialogOpen === TransferDialog.Cancel}
        onClose={handleCloseTransferDialog}
        onConfirmCancelTransfer={handleCancelTransferAccount}
        isLoading={isCancellingAccountTransfer}
      />
    </>
  )
}
