import { Button, DialogProps, Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'features/commonUI'
import React from 'react'

import CalloutBox from '../../apiKeys/components/CalloutBox/CalloutBox'
import styles from './OtpConfirmationDialog.module.scss'

export interface OtpConfirmationDialogProps extends DialogProps {
  onConfirm: () => void
  onClose: () => void
  calloutBox?: { message: React.ReactNode; title: string }
  title?: string
  extraInfo?: string
  confirmText?: string
  cancelText?: string
}

export function OtpConfirmationDialog({
  onClose,
  onConfirm,
  calloutBox,
  title = 'Confirmation',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  extraInfo = '',
  ...props
}: OtpConfirmationDialogProps) {
  return (
    <Dialog onClose={onClose} classes={{ paper: styles.dialog }} {...props}>
      <DialogTitle>
        <Typography component='span' variant='h1'>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent className={styles.content}>
        {calloutBox && (
          <CalloutBox type='warning' title={calloutBox.title}>
            <>{calloutBox.message}</>
          </CalloutBox>
        )}
        <br />
        <Typography variant='bodyM' color='black'>
          {extraInfo}
        </Typography>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant='outlined' color='lightGrey' onClick={onClose}>
          {cancelText}
        </Button>
        <Button className={styles.confirmButton} variant='contained' type='submit' onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
