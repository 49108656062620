import { useQueryClient } from '@tanstack/react-query'
import { AppRoute } from 'appRoutes'
import { redirect_to } from 'const'
import { useAmplitudeExperimentsContext } from 'helpers/vendor'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useExchangeRefreshTokenMutation, useLogOutMutation } from './api/auth'
import { useUserContext } from './api/context'
import { useIsFirstRender } from './firstRender'
import { RefreshableJWTStorageProvider, useRefreshableJWTStorage } from './jwt'
import { useQueryParams } from './queryParams'
import { useToast } from './toast'

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { mutateAsync: exchangeRefreshTokenMutation } = useExchangeRefreshTokenMutation()
  const exchangeToken = useCallback(
    async (refreshToken: string) => {
      const result = await exchangeRefreshTokenMutation({ data: { refreshToken } })
      return result || null
    },
    [exchangeRefreshTokenMutation]
  )

  return (
    <RefreshableJWTStorageProvider handleTokenRequestExchange={exchangeToken}>
      <AuthStateObserver>{children}</AuthStateObserver>
    </RefreshableJWTStorageProvider>
  )
}

function AuthStateObserver({ children }: PropsWithChildren<unknown>) {
  useObserveAuthState()
  return <>{children}</>
}

export function useObserveAuthState() {
  const history = useHistory()
  const { invalidateExperimentCache } = useAmplitudeExperimentsContext()
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  const { accessTokenPayload } = useAuth()
  const [prevUserId, setPrevUserId] = useState<string | undefined>(accessTokenPayload?.id)

  useEffect(() => {
    const currentUserId = accessTokenPayload?.id

    if (currentUserId === prevUserId) {
      return
    }

    setPrevUserId(currentUserId)

    // No action needed if user went from unauthenticated to authenticated state
    if (!prevUserId) {
      return
    }

    queryClient.clear()
    invalidateExperimentCache()

    if (!currentUserId) {
      // TODO: fix this
      // showToast({ message: 'Your session has expired, please log in again', severity: 'info' })
      window.location.replace(AppRoute.Login)
    }
  }, [queryClient, prevUserId, accessTokenPayload, invalidateExperimentCache, history, showToast])
}

export function useAuth() {
  const { mutate: logOutMutation } = useLogOutMutation()
  const {
    isAuthorized,
    possiblyStaleAccessToken,
    refreshToken,
    accessTokenPayload,
    getAccessToken,
    setTokens,
    clearTokens,
  } = useRefreshableJWTStorage()

  return useMemo(
    () => ({
      isAuthorized,
      isImpersonating: !!accessTokenPayload?.impersonatorId,
      possiblyStaleAccessToken,
      accessTokenPayload,
      setCredentials: setTokens,
      removeCredentials: async (processRefreshToken = true) => {
        if (refreshToken && processRefreshToken) {
          // We don't want user to get stuck in authorized state if token invalidation fails, thus no await
          logOutMutation({ data: { refreshToken: refreshToken } })
        }

        clearTokens()
      },
      getAccessToken,
    }),
    [
      isAuthorized,
      accessTokenPayload,
      possiblyStaleAccessToken,
      setTokens,
      getAccessToken,
      refreshToken,
      clearTokens,
      logOutMutation,
    ]
  )
}

/**
 * Hook will redirect unauthorized users to log in page
 *
 * @example
 * function ProtectedComponent() {
 *   useUnauthorizedRedirect()
 *   return <div>sensitive data</div>
 * }
 */
export function useUnauthorizedRedirect() {
  const { isAuthorized } = useAuth()
  const history = useHistory()

  if (!isAuthorized) {
    let route = AppRoute.Login as string

    if (history.location.pathname !== '/') {
      route += `?${redirect_to}=${encodeURIComponent(history.location.pathname + history.location.search ?? '')}`
    }

    return route
  }

  return null
}

/**
 * Hook will redirect already authorized users to home page
 *
 * @example
 * function LogInPage() {
 *   useHomepageRedirect()
 *   return <div>Login form</div>
 * }
 */
export function useHomepageRedirect() {
  const isFirstRender = useIsFirstRender()
  const { isAuthorized } = useAuth()
  const history = useHistory()
  const { redirect_to } = useQueryParams()

  if (isAuthorized && isFirstRender) {
    history.replace(redirect_to ?? '/')
  }
}

/**
 * Hook will redirect users that have accepted ToS and PP to the home page
 */
export function useConsensualRedirect() {
  const history = useHistory()
  const { data: context } = useUserContext()

  if (context && context.hasConsent) {
    history.replace('/')
  }
}
