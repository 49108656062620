import { OpenInNew } from '@mui/icons-material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, Divider, Link, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { AppRoute, buildRoute, useRouteParams } from 'appRoutes'
import { appSettingNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useCertificates, useSubscription, useToast } from 'hooks'
import { useSubscriptionTokens, useTokenCreateMutation } from 'hooks/api/tokens'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { Suspense, useCallback, useEffect, useMemo } from 'react'
import { Link as RouterLink, Redirect } from 'react-router-dom'

import { apiKeyDisplayNameMap } from '../../../apiKeys/components/KeySection/KeySection'
import {
  IntegrationMetadata,
  sdkIntegrations,
  ThirdPartyIntegration,
} from '../../components/IntegrationMetadata/integrationMetadata'
import { IntegrationReadmeContext } from '../../components/IntegrationMetadata/MDX/IntegrationReadmeContext'
import { ApiKeyWidgetData } from '../../components/IntegrationMetadata/MDX/MDXApiKeyWidget/MDXApiKeyWidget'
import { createMDXBaseComponents } from '../../components/IntegrationMetadata/MDX/MDXBaseComponents'
import { getIntegrationReadmeProps } from '../../components/IntegrationMetadata/utils'
import { useIntegrationByTag, useIntegrationData } from '../../integrationHooks'
import { IntegrationCloudflarePage } from '../IntegrationCloudflare/IntegrationCloudflare'
import { IntegrationCloudFrontPage } from '../integrationCloudFront/IntegrationCloudFront'
import { IntegrationCustomPage } from '../IntegrationCustom/IntegrationCustom'
import { IntegrationFastlyVCLPage } from '../IntegrationFastly/IntegrationFastly'
import GithubIcon from './github.svg'

export function IntegrationPage() {
  const { subscriptionId, integrationTag } = useRouteParams<AppRoute.Integration>()
  const integration = useIntegrationByTag(integrationTag)

  // TODO: the event is migrated from legacy snippet selection
  // consider renaming the event and making in more generic
  useEffect(() => {
    if (sdkIntegrations.includes(integrationTag)) {
      ampli.selectSnippetType({
        context: AnalyticsContext.AccountSettings,
        snippetType: integrationTag,
      })
    }
  }, [integrationTag])

  if (!integration) {
    return <Redirect to={buildRoute(AppRoute.Integrations, { subscriptionId })} />
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation value={buildRoute(AppRoute.Integrations, { subscriptionId })} items={appSettingNavItems} />

      <MainContent>
        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
          <Link
            color='inherit'
            underline='hover'
            component={RouterLink}
            to={buildRoute(AppRoute.Integrations, { subscriptionId })}
          >
            Integrations
          </Link>
          <Typography component='span'>{integration.title}</Typography>
        </Breadcrumbs>

        {integration.beforeContent && (
          <Box mb='24px'>
            <integration.beforeContent />
          </Box>
        )}
        <IntegrationComponent integration={integration} />
        {integration.afterContent && (
          <Box mt='24px'>
            <integration.afterContent />
          </Box>
        )}
      </MainContent>
    </>
  )
}

function IntegrationComponent({ integration }: { integration: IntegrationMetadata }) {
  switch (integration.integrationTag) {
    case ThirdPartyIntegration.Cloudflare:
      return <IntegrationCloudflarePage />
    case ThirdPartyIntegration.CloudFront:
      return <IntegrationCloudFrontPage />
    case ThirdPartyIntegration.FastlyVCL:
      return <IntegrationFastlyVCLPage />
    case ThirdPartyIntegration.CustomProxy:
      return <IntegrationCustomPage />
    default:
      return <IntegrationComponentMDX integration={integration} />
  }
}

function IntegrationComponentMDX({ integration }: { integration: IntegrationMetadata }) {
  if (!integration.mdx) {
    throw new Error('Unsupported format')
  }

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading: isSubscriptionLoading } = useSubscription(currentSubscriptionId)
  const { data: subscriptionTokens, isLoading: isTokenLoading } = useSubscriptionTokens(currentSubscriptionId)
  const { data: subscriptionCertificates, isLoading: isCertificateLoading } = useCertificates(currentSubscriptionId)
  const { integrationData, isLoading: isIntegrationDataLoading } = useIntegrationData(currentSubscriptionId)
  const { mutate: sendCreateApiKeyRequest, isLoading: isCreatingApiKey, data: secretApiKey } = useTokenCreateMutation()
  const { showToast } = useToast()

  const onGenerate = useCallback(
    (data: ApiKeyWidgetData) => {
      sendCreateApiKeyRequest(
        {
          data,
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: (data) => {
            ampli.apiKeyAdded({ apiKeyType: apiKeyDisplayNameMap[data.type], environmentChanged: false })
          },
          onError: (e) => {
            showToast({ message: e.message, severity: 'error' })
          },
        }
      )
    },
    [sendCreateApiKeyRequest, currentSubscriptionId, showToast]
  )

  const isLoading = isSubscriptionLoading || isTokenLoading || isCertificateLoading || isIntegrationDataLoading

  const mdxProps = getIntegrationReadmeProps(
    integration?.integrationTag,
    subscription,
    subscriptionTokens,
    subscriptionCertificates,
    integrationData,
    secretApiKey
  )
  const keyWidgetProps = { onGenerate, apiKey: secretApiKey, isLoading: isCreatingApiKey }

  const loader = useMemo(
    () => (
      <Box>
        <Skeleton height={40} width={300} />
        <Skeleton />
        <Skeleton />
        <Skeleton width={100} />
      </Box>
    ),
    []
  )

  const MDXComponentsWithContext = createMDXBaseComponents(AnalyticsContext.AccountSettings)

  return (
    <>
      <Paper>
        <Stack padding={3} spacing={3} divider={<Divider />}>
          <Stack direction='row' flexWrap='wrap' alignItems='center' gap={2}>
            <Paper variant='outlined'>
              <Box padding={3}>
                <integration.iconComponent style={{ width: '40px', height: '40px', display: 'block' }} />
              </Box>
            </Paper>

            <Box flexGrow={1}>
              <Typography variant='h1'> {integration.title}</Typography>
            </Box>

            {integration.sourceCodeLink && (
              <Button
                width={{ xs: '100%', sm: 'auto' }}
                size='large'
                color='grey'
                component={Link}
                target='_blank'
                href={integration.sourceCodeLink}
                variant='outlined'
                startIcon={<GithubIcon />}
              >
                Github
              </Button>
            )}

            {integration.docsLink && (
              <Button
                width={{ xs: '100%', sm: 'auto' }}
                size='large'
                color='grey'
                component={Link}
                target='_blank'
                href={integration.docsLink}
                variant='outlined'
                startIcon={<OpenInNew />}
              >
                View docs
              </Button>
            )}
          </Stack>

          {isLoading ? (
            loader
          ) : (
            <IntegrationReadmeContext.Provider value={{ ...mdxProps, ...keyWidgetProps }}>
              <Suspense fallback={loader}>
                <integration.mdx {...mdxProps} components={MDXComponentsWithContext} />
              </Suspense>
            </IntegrationReadmeContext.Provider>
          )}
        </Stack>
      </Paper>
    </>
  )
}
