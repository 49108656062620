import { Button, Card, Link, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { ampli } from 'models/ampli'

import styles from './UpgradeToEnterprise.module.scss'

export interface UpgradeToEnterpriseProps {
  className?: string
  pathname: string
}

export default function UpgradeToEnterprise({ className, pathname }: UpgradeToEnterpriseProps) {
  return (
    <Card className={clsx(styles.container, className)}>
      <Typography variant='semiBody2' component='h2' className={styles.title}>
        Upgrade to Enterprise
      </Typography>
      <Typography variant='body3'>
        Get hands-on support and a custom contract for your large scale organization.
      </Typography>

      <Button
        color='primary'
        variant='outlined'
        title='Contact us'
        component={Link}
        href={AppRoute.ContactSalesEnterprise}
        underline='hover'
        className={styles.button}
        onClick={() => {
          ampli.appCtaClicked({ 'App Page Path': pathname, ctaName: 'Contact us' })
        }}
      >
        Contact us
      </Button>
    </Card>
  )
}
