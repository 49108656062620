import { ErrorOutline } from '@mui/icons-material'
import { Alert, Paper, Stack, Typography } from '@mui/material'
import { CustomLayer, CustomLayerProps, Datum, LineSvgProps, ResponsiveLine, SliceTooltipProps } from '@nivo/line'
import { formatDatetime, formatTime } from 'features/commonUI'
import { formatNumShort } from 'helpers/format'
import { ComponentProps, Fragment, useCallback, useMemo, useState } from 'react'

import styles from './HealthChart.module.scss'

type HealthChartProps = {
  data: LineSvgProps['data']
  colors: string[]
  sliceTooltip?: LineSvgProps['sliceTooltip']
  axisYTicks?: number
  axisYUnit?: string
  className?: string
  fillGaps?: boolean
}
export function HealthChart({
  data,
  colors,
  sliceTooltip,
  axisYTicks,
  axisYUnit,
  className,
  fillGaps,
}: HealthChartProps) {
  const [focusId, setFocusId] = useState<number>()
  const onFocus = useCallback<ComponentProps<typeof HealthChartLegend>['onFocus']>((id) => {
    setFocusId((focused) => (focused === id ? undefined : id))
  }, [])

  const displayedData = useMemo(() => (focusId == null ? data : [data[focusId]]), [data, focusId])
  const displayedColors = useMemo(
    () => (focusId == null || colors == null ? colors : [colors[focusId]]),
    [colors, focusId]
  )

  const hasGaps = useMemo(
    () =>
      fillGaps &&
      data.some((serie) => {
        const firstIndex = serie.data.findIndex((value: Datum) => value.y !== null)
        const reverseIndex = [...serie.data].reverse().findIndex((value: Datum) => value.y !== null)
        const lastIndex = reverseIndex === -1 ? -1 : serie.data.length - reverseIndex - 1

        // The serie doesn't have data or it's full.
        if (firstIndex === lastIndex) {
          return false
        }

        const loopEnd = lastIndex === -1 ? serie.data.length : lastIndex
        for (let i = firstIndex + 1; i < loopEnd; i++) {
          if (serie.data[i].y === null) {
            return true
          }
        }

        return false
      }),
    [data, fillGaps]
  )

  return (
    <>
      {hasGaps ? (
        <Alert severity='warning' icon={<ErrorOutline />}>
          Latency chart may have some gaps in data due to low request volume. Gaps in data are represented with a dashed
          line.
        </Alert>
      ) : null}
      <HealthChartLegend data={data} colors={colors} focusId={focusId} onFocus={onFocus} />
      <div className={className}>
        <ResponsiveLine
          data={displayedData}
          colors={displayedColors}
          theme={{ legends: { text: { fontSize: 12 } }, axis: { ticks: { text: { fill: '#757575' } } } }}
          margin={{ top: 8, right: 24, bottom: 24, left: 60 }}
          animate={false}
          enablePoints={true}
          pointSize={2}
          curve='monotoneX'
          enableGridX={false}
          enableSlices='x'
          sliceTooltip={sliceTooltip ?? ((props) => <DefaultSliceTooltip {...props} axisYUnit={axisYUnit} />)}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
          }}
          gridXValues={6}
          gridYValues={axisYTicks}
          axisLeft={{
            tickValues: axisYTicks,
            tickSize: 0,
            format: (n: number) => `${formatNumShort(n, n > 1000 ? 1 : 0)}${axisYUnit ? ' ' + axisYUnit : ''}`,
          }}
          xScale={{
            type: 'time',
            format: '%H:%M',
            precision: 'minute',
          }}
          axisBottom={{
            format: '%H:%M',
          }}
          layers={[
            'axes',
            'grid',
            'crosshair',
            'legends',
            ...(fillGaps ? [Filler] : []),
            'lines',
            'markers',
            'points',
            'slices',
          ]}
        />
      </div>
    </>
  )
}
const dashedStyle = {
  strokeDasharray: '4, 2',
  strokeWidth: 2,
}
const Filler: CustomLayer = ({
  series,
  lineGenerator,
  xScale,
  yScale,
}: Omit<CustomLayerProps, 'xScale' | 'yScale'> & {
  xScale: (v: Datum['data']['x']) => number
  yScale: (v: Datum['data']['y']) => number
}) => {
  return series.map(({ id, data, color }) => {
    const dataWithoutGaps = data.filter((d) => d.data.y != null)
    const line = lineGenerator(
      dataWithoutGaps.map((d) => ({
        x: xScale(d.data.x),
        y: yScale(d.data.y),
      }))
    )
    return line ? <path key={id} d={line} fill='none' stroke={color} style={dashedStyle} /> : null
  })
}

function HealthChartLegend({
  data,
  colors,
  focusId,
  onFocus,
}: {
  data: LineSvgProps['data']
  colors?: string[]
  focusId: number | undefined
  onFocus: (id: number) => void
}) {
  return (
    <Stack direction='row' flexWrap='wrap' rowGap={1} columnGap={2}>
      {data.map((line, index) => (
        <Stack
          key={line.id}
          direction='row'
          gap={1}
          alignItems='center'
          style={{ cursor: 'pointer' }}
          onClick={() => onFocus(index)}
        >
          <div style={{ width: 8, height: 8, backgroundColor: colors?.[index] }} />
          <Typography variant={focusId === index ? 'bodySMedium' : 'bodyS'} noWrap>
            {line.id}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}

export const DefaultSliceTooltip = ({ slice, axisYUnit }: SliceTooltipProps & { axisYUnit?: string }) => {
  const fromDate = new Date(slice.points[0]?.data?.x?.valueOf())
  const toDate = new Date(fromDate.valueOf() + 5 * 60 * 1000)

  return (
    <Paper className={styles.tooltip}>
      <Stack gap={1}>
        <Typography variant='bodyMMedium'>
          {formatDatetime(fromDate, 'standard', 'utc')} &mdash; {formatTime(toDate, 'standard', 'utc')} UTC
        </Typography>
        <div className={styles.grid}>
          {slice.points.map((p) => (
            <Fragment key={p.id}>
              <Typography variant='bodyM'>{p.serieId}:</Typography>
              <Typography variant='bodyMMedium'>
                <>{p.data.y}</>
                {axisYUnit ? <>&nbsp;{axisYUnit}</> : null}
              </Typography>
            </Fragment>
          ))}
        </div>
      </Stack>
    </Paper>
  )
}
