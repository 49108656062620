import { BarLegendProps, LegendData, ResponsiveBar } from '@nivo/bar'
import { BoxLegendSvg } from '@nivo/legends'
import { formatNumShort } from 'helpers/format'
import { ChartType, PartialStatsDataPoint, UsageCounterPeriod, UsageCounterType } from 'models'
import { primaryColor } from 'styles/material-ui'

import {
  customTickWrapper,
  customTooltipWrapper,
  formatCounterName,
  generateTickValues,
  roundUpToShort,
} from '../../../../helpers/chart'
import { sortByFixedOrder } from '../../../../helpers/data'
import styles from './UsageChart.module.scss'

export interface UsageChartProps {
  data: PartialStatsDataPoint[]
  granularity: UsageCounterPeriod
  chartType: ChartType
  timezone: string | undefined
}

export function UsageChart({ data, granularity, chartType, timezone }: UsageChartProps) {
  const keys = sortByFixedOrder(Object.keys(data[0]).filter(isDataKey)) as UsageCounterType[]

  const mainCounter = chartType === ChartType.CallBased ? UsageCounterType.ApiCalls : UsageCounterType.UniqueVisitors
  const chartCounters = [mainCounter, UsageCounterType.ThrottledCalls, UsageCounterType.RestrictedCalls]

  const BarLegend = ({
    height,
    legendData,
    width,
  }: {
    height: number
    legendData: [BarLegendProps, readonly LegendData[]][]
    width: number
  }) => (
    <>
      {legendData.map((legend: [BarLegendProps, LegendData[]]) => {
        const [props, data] = legend

        return (
          <BoxLegendSvg
            key={JSON.stringify(props.data?.map(({ id }) => id))}
            {...props}
            data={props.data ?? data}
            containerHeight={height}
            containerWidth={width}
          />
        )
      })}
    </>
  )

  // Find the height of the tallest bar.
  const maxValue = data
    .map((point) => keys.reduce((total, key) => total + point[key]!, 0))
    .reduce((a, b) => Math.max(a, b))
  const maxRange = roundUpToShort(maxValue)
  let tickValues: number | number[]

  if (maxValue === 1) {
    tickValues = [0, 1]
  } else if (maxValue === 2) {
    tickValues = [0, 1, 2]
  } else {
    tickValues = generateTickValues(4, 0, maxRange)
  }

  return (
    <div className={styles.root}>
      {maxValue === 0 ? (
        <div className={styles.zeroState}>No data available for this period</div>
      ) : (
        <div className={styles.chart} data-testid='usage-chart'>
          <ResponsiveBar
            data={data}
            keys={keys}
            indexBy='timestamp'
            padding={0.3}
            margin={{ top: 30, bottom: 56, left: 48 }}
            colors={(data) => {
              return colorMap[data.id as UsageCounterType].bar
            }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            labelFormat={formatNumberWithDigits(1)}
            valueFormat={formatNumberWithDigits(1)}
            labelTextColor={formatLabelColor}
            layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues,
              format: formatTickLabel(),
            }}
            valueScale={{ type: 'linear', min: 0, max: maxRange }}
            gridYValues={tickValues}
            axisBottom={{
              renderTick: customTickWrapper(granularity, timezone) as <T>(data: T) => JSX.Element,
            }}
            labelSkipWidth={32}
            labelSkipHeight={12}
            animate={false}
            motionConfig={{ damping: 15 }}
            legends={
              chartType === ChartType.UniqueVisitors
                ? []
                : [
                    {
                      dataFrom: 'keys',
                      data: keys.map((id) => ({
                        color: colorMap[id as UsageCounterType].bar,
                        id,
                        label: formatCounterName(id as UsageCounterType),
                      })),
                      anchor: 'top-right',
                      direction: 'row',
                      justify: false,
                      itemsSpacing: 1,
                      translateX: -48,
                      translateY: -24,
                      itemWidth: 110,
                      itemHeight: 20,
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
            }
            tooltip={customTooltipWrapper(
              granularity,
              (data) => ({
                timestamp: data.data.timestamp,
                points: chartCounters.map((counterType) => ({
                  counterType,
                  value: data.data?.[counterType] ?? 0,
                })),
              }),
              timezone
            )}
          />
        </div>
      )}
    </div>
  )
}

function formatLabelColor({ data }: any) {
  return colorMap[data.id as UsageCounterType.ThrottledCalls].label
}

function formatNumberWithDigits(fractionDigits = 0) {
  return (num: number) => formatNumShort(num, fractionDigits)
}

function formatTickLabel() {
  return (num: number) => formatNumShort(num, num > 1000 ? 1 : 0)
}

function isDataKey(key: string) {
  return key !== 'timestamp' && key !== 'index'
}

const colorMap: Record<UsageCounterType, { bar: string; label: string }> = {
  [UsageCounterType.ApiCalls]: { bar: primaryColor, label: '#ffffff' },
  [UsageCounterType.ThrottledCalls]: { bar: '#ffc1ab', label: 'rgba(0, 0, 0, 0.54)' },
  [UsageCounterType.RestrictedCalls]: { bar: '#EEEEEE', label: 'rgba(0, 0, 0, 0.54)' },
  [UsageCounterType.UniqueVisitors]: { bar: '#434875', label: '#ffffff' },
}
