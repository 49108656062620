import { Tooltip } from '@mui/material'

import styles from './PlatformFilterIndicator.module.scss'

interface PlatformFilterIndicatorProps {
  platforms: string[]
  hideNonSelected?: boolean
}

export const colorMap: Record<string, string> = {
  ios: '#2196F3',
  android: '#4CAF50',
  js: '#FF9800',
  none: '#F2F2F2',
}

type PlatformKey = 'js' | 'android' | 'ios'
type PlatformName = 'web' | 'Android' | 'iOS'

const platformNames: Record<PlatformKey, PlatformName> = {
  js: 'web',
  android: 'Android',
  ios: 'iOS',
}

const allPlatforms = ['js', 'android', 'ios']

export function PlatformFilterIndicator({ platforms, hideNonSelected = true }: PlatformFilterIndicatorProps) {
  const buildTooltipText = () => {
    const platformStrings = platforms.map((platform: PlatformKey) => platformNames[platform])

    let tooltip = 'Signal supported on '

    if (platformStrings.length === 1) {
      tooltip += platformStrings[0]
    } else if (platformStrings.length === 2) {
      tooltip += platformStrings.join(' and ')
    } else if (platformStrings.length > 2) {
      tooltip += platformStrings.slice(0, -1).join(', ') + ', and ' + platformStrings[platformStrings.length - 1]
    }

    return tooltip
  }

  return (
    <Tooltip title={buildTooltipText()} placement={'top-start'}>
      <div className={styles.platformDiv}>
        {hideNonSelected &&
          platforms.map((signal, index) => (
            <span
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: colorMap[signal] || '',
                marginLeft: index === 0 ? '0' : '-5px',
                border: '1px solid white',
              }}
            />
          ))}

        {!hideNonSelected &&
          allPlatforms.map((signal, index) => (
            <span
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: platforms.includes(signal) ? colorMap[signal] : colorMap['none'],
                marginLeft: index === 0 ? '0' : '-5px',
                border: '1px solid white',
              }}
            />
          ))}
      </div>
    </Tooltip>
  )
}
