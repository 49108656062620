import { Button, InputLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { APITypesMap, GenericError, REGIONS } from 'const'
import { useIsFirstRender } from 'hooks/firstRender'
import { RegionCode } from 'models'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ampli, ForkedPathShownProperties } from '../../../../models/ampli'
import OnboardingWrapper from '../OnboardingWrapper/OnboardingWrapper'
import styles from './CreateAppForm.module.scss'

const availableRegions = Object.values(REGIONS)

const isDeveloperOptions = [
  {
    title: "I'm a developer",
    subTitle: 'I can install a code snippet. Available for JavaScript, Android, iOS, and more.',
    value: 'true',
  },
  {
    title: "I'm not a developer",
    subTitle: 'Help me get started with device intelligence and stopping fraud.',
    value: 'false',
  },
] as const

// Note: this is a static string passed to amplitude events, and its purpose is to distinguish
// between events that are sent on different versions of forked paths.
//
// !! Ask Aleksandra about how this string should be changed when texts or options change on forked path page!
export const pathChoices: ForkedPathShownProperties['pathChoices'] = 'developer,non-developer'

export const pathSelectedOptions = new Map<string, (typeof isDeveloperOptions)[number]['title']>([
  ['true', isDeveloperOptions[0].title],
  ['false', isDeveloperOptions[1].title],
])

const expectedIdentificationsOptions = [
  {
    title: '0-100,000',
    value: 'upTo100k',
  },
  {
    title: '100,000-500,000',
    value: 'from100kTo500k',
  },
  {
    title: '500,000+',
    value: 'moreThan500k',
  },
]

type FormData = Pick<
  APITypesMap['subscriptionStart']['body'],
  | 'name'
  | 'domain'
  | 'regionCode'
  | 'fraudType'
  | 'otherFraudType'
  | 'jobLevel'
  | 'jobFunction'
  | 'privacyPolicy'
  | 'termsOfService'
  | 'isDeveloper'
  | 'expectedIdentifications'
>

enum CollectionSteps {
  IsDeveloper,
  ExpectedIdentifications,
  SelectRegion,
}

export type Props = {
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: FormData) => Promise<void>
}

export default function CreateAppForm({ isLoading, onSubmit }: Props) {
  const [currentIsDeveloperSelection, setCurrentIsDeveloperSelection] = useState(true)
  const [currentExpectedIdentificationsSelection, setExpectedIdentificationsSelection] = useState('upTo100k')
  const [currentRegionSelection, setCurrentRegionSelection] = useState<RegionCode | null>(null)
  const theme = useTheme()
  const isFirstRender = useIsFirstRender()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: 'My First App',
      privacyPolicy: true,
      termsOfService: true,
      fraudType: null,
      otherFraudType: null,
      isDeveloper: 'true',
      expectedIdentifications: 'upTo100k',
    },
  })
  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
    watch,
  } = formMethods

  const [step, setStep] = useState<number>(CollectionSteps.IsDeveloper)
  const [formShown, setFormShown] = useState(false)

  useEffect(() => {
    if (step !== CollectionSteps.SelectRegion || formShown) {
      return
    }
    ampli.regionSelectionShown({ context: 'onboarding' })
    setFormShown(true)
  }, [formShown, step])

  useEffect(() => {
    ampli.forkedPathShown({ pathChoices })
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'regionCode') {
        setCurrentRegionSelection(value.regionCode as RegionCode)
      } else if (name === 'isDeveloper') {
        setCurrentIsDeveloperSelection(value.isDeveloper === 'true')
      } else if (name === 'expectedIdentifications') {
        setExpectedIdentificationsSelection(value.expectedIdentifications ?? 'upTo100k')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const handleBackStepRegionSelection = () => {
    if (currentIsDeveloperSelection) {
      setStep(CollectionSteps.IsDeveloper)
      return
    }
    setStep(CollectionSteps.ExpectedIdentifications)
  }

  return (
    <OnboardingWrapper classes={{ root: styles.wrapper }}>
      <form className={styles.block} onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <input type='hidden' {...register('name')} />

          <div className={clsx(styles.step, step === CollectionSteps.IsDeveloper ? styles.activeStep : null)}>
            <Typography className={styles.heading} variant='h1' data-testid='appform-header'>
              Welcome to Fingerprint
            </Typography>
            <Typography className={styles.subheader}>
              Fingerprint offers best-in-class device intelligence for web and mobile. To get things moving, we have
              some questions.
            </Typography>
            <RadioGroup name='isDeveloper' className={clsx(styles.radioGroup, smDown ? styles.smallScreen : undefined)}>
              {isDeveloperOptions.map((option) => (
                <div
                  className={clsx(
                    styles.radioItem,
                    styles.noShadow,
                    currentIsDeveloperSelection === (option.value === 'true') ? styles.radioItemSelected : undefined
                  )}
                  key={`item_${option.value}`}
                >
                  <InputLabel className={styles.radioLabel} data-testid={`isDeveloper_${option.value}`}>
                    <Radio
                      size='small'
                      className={styles.radioButton}
                      value={option.value}
                      checked={getValues('isDeveloper') === option.value}
                      {...register('isDeveloper')}
                    />
                    <div className={styles.groupV}>
                      <div className={styles.optionTitle}>{option.title}</div>
                      <div className={styles.optionSubtitle}>{option.subTitle}</div>
                    </div>
                  </InputLabel>
                </div>
              ))}
            </RadioGroup>
            <div className={styles.buttons}>
              <div />
              <Button
                className={styles.button}
                title='Next step'
                data-testid='developerNextStepButton'
                variant='contained'
                size='large'
                fullWidth={smDown}
                color='primary'
                onClick={() =>
                  setStep(
                    currentIsDeveloperSelection ? CollectionSteps.SelectRegion : CollectionSteps.ExpectedIdentifications
                  )
                }
              >
                Next step
              </Button>
            </div>
          </div>

          <div
            className={clsx(styles.step, step === CollectionSteps.ExpectedIdentifications ? styles.activeStep : null)}
          >
            <Typography className={styles.heading} variant='h1'>
              How many identifications do you need per month?
            </Typography>
            <Typography className={styles.subheader}>
              For example, if your project sees 100,000 hits, transactions, etc. monthly, you may need 100,000
              identifications each month.
            </Typography>
            <RadioGroup
              name='expectedIdentifications'
              className={clsx(styles.radioGroup, smDown ? styles.smallScreen : undefined)}
            >
              {expectedIdentificationsOptions.map((option) => (
                <div
                  className={clsx(
                    styles.radioItem,
                    styles.noShadow,
                    currentExpectedIdentificationsSelection === option.value ? styles.radioItemSelected : undefined
                  )}
                  key={`item_${option.value}`}
                >
                  <InputLabel className={styles.radioLabel} data-testid={`expectedIdentifications_${option.value}`}>
                    <Radio
                      size='small'
                      className={styles.radioButton}
                      value={option.value}
                      checked={getValues('expectedIdentifications') === option.value}
                      {...register('expectedIdentifications')}
                    />
                    <div className={styles.groupV}>
                      <div className={styles.optionTitle}>{option.title}</div>
                    </div>
                  </InputLabel>
                </div>
              ))}
            </RadioGroup>
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                title='Next step'
                variant='outlined'
                size='large'
                color='lightGrey'
                fullWidth={smDown}
                onClick={() => setStep(CollectionSteps.IsDeveloper)}
              >
                Back
              </Button>
              <Button
                className={styles.button}
                title='Next step'
                variant='contained'
                size='large'
                fullWidth={smDown}
                color='primary'
                onClick={() => setStep(CollectionSteps.SelectRegion)}
              >
                Next step
              </Button>
            </div>
          </div>

          <div className={clsx(styles.step, step === CollectionSteps.SelectRegion ? styles.activeStep : null)}>
            <Typography className={styles.heading} variant='h1' data-testid='appform-header-last-step'>
              Last step, pick a server region
            </Typography>
            <Typography className={styles.subheader}>
              Select a region nearest your users so our API remains blazingly fast. Also, this is where your data will
              be processed and stored.
            </Typography>

            <RadioGroup name='regionCode' className={clsx(styles.radioGroup, smDown ? styles.smallScreen : undefined)}>
              {availableRegions.map((region) => (
                <div
                  className={clsx(
                    styles.radioItem,
                    styles.noShadow,
                    currentRegionSelection === region.code ? styles.radioItemSelected : undefined
                  )}
                  key={`item_${region.code}`}
                >
                  <InputLabel className={styles.radioLabel} data-testid={`region_${region.code}`}>
                    <Radio
                      size='small'
                      className={styles.radioButton}
                      value={region.code}
                      {...register('regionCode')}
                    />
                    <div className={styles.groupV}>
                      <div className={styles.optionTitle}>{region.shortTitle}</div>
                      <div className={styles.optionSubtitle}>{region.regionName}</div>
                    </div>
                    {region.label && <div className={styles.optionLabel}>{region.label}</div>}
                  </InputLabel>
                </div>
              ))}
            </RadioGroup>

            <div className={styles.buttons}>
              <Button
                className={styles.button}
                title='Next step'
                variant='outlined'
                size='large'
                color='lightGrey'
                fullWidth={smDown}
                onClick={handleBackStepRegionSelection}
              >
                Back
              </Button>
              <Button
                disabled={isFirstRender || !isValid || isLoading || currentRegionSelection === null}
                className={styles.button}
                title='Next step'
                data-testid='onboardingSubmitButton'
                variant='contained'
                size='large'
                fullWidth={smDown}
                color='primary'
                type='submit'
              >
                Done
              </Button>
            </div>
          </div>
        </FormProvider>
      </form>
    </OnboardingWrapper>
  )
}
