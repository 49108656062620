import { Download } from '@mui/icons-material'
import { Button, Link, Typography } from '@mui/material'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import InfoDrawer from 'components/InfoDrawer/InfoDrawer'
import { DOCS_SERVER_API, GenericError, USE_IDENTIFICATION_EXPORT } from 'const'
import { Header, MainContent } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { PaginatedVisitsResponse, SubscriptionStatus, SubscriptionType, Visit, VisitsFilter } from 'models'
import { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import ExportIdentificationEventsModal from '../ExportEvent/ExportIdentificationEventsModal'
import { VisitHistoryFilter } from '../VisitHistoryFilter/VisitHistoryFilter'
import { VisitsTable } from '../VisitsTable/VisitsTable'
import styles from './VisitHistory.module.scss'

export type VisitHistoryProps = {
  query: UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError>
  form: UseFormReturn<VisitsFilter>
}

export function VisitHistory({ query, form }: VisitHistoryProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const getVisitPath = useCallback(
    (visit: Visit): string =>
      buildRoute(AppRoute.IdentificationVisitDetails, {
        subscriptionId: currentSubscriptionId,
        requestId: visit.requestId,
      }),
    [currentSubscriptionId]
  )

  const [isFiltering, setIsFiltering] = useState(false)

  return (
    <>
      <Header title='Identification Events' info={<VisitHistoryInfoDrawer />} />
      <MainContent>
        <VisitsTable
          query={query}
          withContainer
          title='Events'
          getVisitPath={getVisitPath}
          className={styles.tableContainer}
          actions={
            <div className={clsx(styles.actionDiv)}>
              <VisitHistoryFilter form={form} onFilterChange={(isFiltering) => setIsFiltering(isFiltering)} />
              <VisitorsExportButton className={styles.exportButton} />
            </div>
          }
          isFiltering={isFiltering}
        />
      </MainContent>
    </>
  )
}

const EXPORT_ENABLED_FOR_STATUSES = [
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Active,
  SubscriptionStatus.ProofOfConcept,
]
const VisitorsExportButton = ({ className }: { className?: string }) => {
  const [exportEventsModalOpen, setExportEventsModalOpen] = useState(false)

  const { subscription } = useCurrentSubscriptionData()

  const isPocWithRestrictedStatus =
    subscription?.status === SubscriptionStatus.Restricted && subscription?.type === SubscriptionType.ProofOfConcept
  const isExportEnabled =
    !!USE_IDENTIFICATION_EXPORT &&
    !!subscription &&
    subscription.featureSettings?.visitsExport !== false &&
    (EXPORT_ENABLED_FOR_STATUSES.includes(subscription.status) || isPocWithRestrictedStatus)

  return (
    <>
      {isExportEnabled && (
        <Button
          variant='outlined'
          color='inherit'
          startIcon={<Download />}
          className={className}
          onClick={() => setExportEventsModalOpen(true)}
        >
          Export
        </Button>
      )}
      <ExportIdentificationEventsModal
        isOpen={exportEventsModalOpen}
        handleClose={() => setExportEventsModalOpen(false)}
      />
    </>
  )
}

function VisitHistoryInfoDrawer() {
  return (
    <InfoDrawer title='Visit History' action='Read more' actionHref={DOCS_SERVER_API}>
      <Typography variant='body1'>Inspect all identification requests for the current application.</Typography>
      <Typography variant='body1'>
        You can also access this data using the{' '}
        <Link href={`${DOCS_SERVER_API}`} target='_blank' underline='hover'>
          Server API
        </Link>
        .
      </Typography>
    </InfoDrawer>
  )
}
