import { Check, Close } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Link, Paper, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { AppRoute, buildRoute } from 'appRoutes'
import InfoDrawer from 'components/InfoDrawer/InfoDrawer'
import LoaderComponent from 'components/Loader/Loader'
import { SupportEmail } from 'components/SupportEmail'
import { GenericError } from 'const'
import { Header, MainContent } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { WebhookVerificationStatus } from 'models'
import { ampli } from 'models/ampli'
import { Link as RouterLink } from 'react-router-dom'

import ActionPanel from '../ActionPanel/ActionPanel'
import styles from './WebhookVerificationPanel.module.scss'

export interface WebhookVerificationPanelProps {
  status?: WebhookVerificationStatus
  webhookId: string
  isLoading?: boolean
  isSendingEvent?: boolean
  error?: GenericError | null
  onSendEvent: () => void
}

export default function WebhookVerificationPanel(props: WebhookVerificationPanelProps) {
  const { isSendingEvent } = props

  return (
    <>
      <Header title='Verify webhook' info={<VerifyWebhookInfoDrawer />} />
      <MainContent>
        <Paper className={styles.container}>
          <Step {...props} />

          {isSendingEvent && <LoaderComponent />}
        </Paper>
      </MainContent>
    </>
  )
}

function Step({ status, webhookId, isLoading, isSendingEvent, error, onSendEvent }: WebhookVerificationPanelProps) {
  const { currentSubscriptionId } = useCurrentSubscription()

  const BackButton = ({
    text = 'Done',
    variant = 'contained',
  }: {
    text?: string
    variant?: 'contained' | 'outlined'
  }) => (
    <Button
      variant={variant}
      color='primary'
      component={RouterLink}
      to={buildRoute(AppRoute.Webhooks, { subscriptionId: currentSubscriptionId })}
    >
      {text}
    </Button>
  )

  const sendEventButton = (
    <Button variant='contained' color='primary' onClick={onSendEvent} disabled={isSendingEvent}>
      Send test event
    </Button>
  )

  if (error) {
    return (
      <ActionPanel actions={<BackButton />}>
        <Alert severity='error'>
          {error?.message ?? (
            <>
              Something went wrong. Please try again later or contact <SupportEmail />
            </>
          )}
        </Alert>
      </ActionPanel>
    )
  }

  if (isLoading || !status) {
    return <Loader />
  }

  switch (status) {
    case WebhookVerificationStatus.Unverified:
      return (
        <ActionPanel
          title='In order to start receiving live events you need to verify your webhook first.'
          actions={sendEventButton}
        >
          <ol className={styles.list}>
            <Typography variant='body2' component='li'>
              Deploy code responsible for event handling to the provided URL.
            </Typography>
            <Typography variant='body2' component='li'>
              Send test event via button below. Your endpoint should respond with HTTP status 2xx to the test event.
            </Typography>
            <Typography variant='body2' component='li'>
              If the event is successfully handled your webhook will be switched to the &quot;verified&quot; status and
            </Typography>
            <Typography variant='body2' component='li'>
              In case of error please fix webhook handler code and send test event again.
            </Typography>
          </ol>
        </ActionPanel>
      )

    case WebhookVerificationStatus.Verifying:
      return (
        <ActionPanel
          title='Webhook verification in progress'
          decoration={<CircularProgress color='inherit' size={14} className={styles.progress} />}
          actions={<BackButton text='Back to webhooks' variant='outlined' />}
        >
          <Typography variant='body2' className={styles.text}>
            You can wait for it to finish or close this window now.
          </Typography>
        </ActionPanel>
      )

    case WebhookVerificationStatus.Verified:
      ampli.webhookVerifiedDisplayed()
      return (
        <ActionPanel
          title='Webhook has been successfully verified'
          decoration={<Check className={styles.icon} />}
          color='green'
          actions={<BackButton />}
        >
          <Typography variant='body2' className={styles.text}>
            You can close this window now.
          </Typography>
        </ActionPanel>
      )

    case WebhookVerificationStatus.Failed:
      return (
        <ActionPanel
          title='Webhook has not been successfully verified'
          decoration={<Close className={styles.icon} />}
          color='red'
          actions={sendEventButton}
        >
          <Typography variant='body2' className={styles.text}>
            Please see the details of the failed requests on the{' '}
            <Link
              component={RouterLink}
              to={buildRoute(AppRoute.WebhookEvents, { subscriptionId: currentSubscriptionId, webhookId })}
            >
              webhook events
            </Link>{' '}
            page. After the issue in your handler is solved please try to send test event again.
          </Typography>
        </ActionPanel>
      )
  }
}

function Loader() {
  return (
    <>
      <Skeleton data-testid='verify-webhook-loader' />
      <Skeleton style={{ marginBottom: 16 }} />

      {Array(4)
        .fill(undefined)
        .map(() => (
          <>
            <Skeleton width={24} />
            <Skeleton style={{ marginBottom: 16 }} />
          </>
        ))}

      <Skeleton height={40} width={150} style={{ marginTop: 40, marginLeft: 'auto' }} />
    </>
  )
}

function VerifyWebhookInfoDrawer() {
  return (
    <InfoDrawer title='Verify webhook'>
      <Typography>
        Send a test event so we can check your webhook is working. After that, we will start sending events.
      </Typography>
    </InfoDrawer>
  )
}
