import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from 'hooks/toast'
import { TrafficRule } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const listCacheKey = 'subscription/trafficRules'
const configurationUpdateSuccessMessage =
  'Success, your configuration has been saved. It may take a few minutes to take effect.'
const configrationUpdateErrorMessage = 'An error occurred when trying to save your configuration. Please try again.'

export function useTrafficRules(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [listCacheKey, subscriptionId] as const,
    queryFn: async ({ queryKey: [_, subscriptionId] }) =>
      extractData(withErrorHandling(buildRequest('trafficRules', { params: { subscriptionId } }))),
    enabled: subscriptionId != null,
  })
}

export function useTrafficRuleCreateMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('trafficRuleCreate', {
    onSuccess: (trafficRule) => {
      showToast({ message: configurationUpdateSuccessMessage, severity: 'success' })
      const cacheKey = [listCacheKey, trafficRule.subscriptionId]

      queryClient.setQueryData<TrafficRule[]>(cacheKey, (data) => (data ? [...data, trafficRule] : [trafficRule]))
      queryClient.invalidateQueries(cacheKey)
    },
    onError: () => {
      showToast({ message: configrationUpdateErrorMessage, severity: 'error' })
    },
  })
}

export function useTrafficRuleDeleteMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('trafficRuleDelete', {
    onMutate: ({ params }) => {
      if (params) {
        const cacheKey = [listCacheKey, params.subscriptionId]
        const snapshot = queryClient.getQueryData<TrafficRule[]>(cacheKey) || []
        const next = snapshot.slice()

        for (let i = 0; i < next.length; i++) {
          if (next[i].id === params.trafficRuleId) {
            next.splice(i, 1)
          }
        }

        queryClient.setQueryData<TrafficRule[]>(cacheKey, () => next)

        return () => queryClient.setQueryData<TrafficRule[]>(cacheKey, snapshot)
      }

      return () => {}
    },
    onSuccess: (_, { params }) => {
      showToast({ message: configurationUpdateSuccessMessage, severity: 'success' })
      if (params) {
        queryClient.invalidateQueries([listCacheKey, params.subscriptionId])
      }
    },
    onError: () => {
      showToast({ message: configrationUpdateErrorMessage, severity: 'error' })
    },
  })
}

export function useTrafficRuleUpdateMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('trafficRuleUpdate', {
    onMutate: ({ data, params }) => {
      if (params && data) {
        const cacheKey = [listCacheKey, params.subscriptionId]
        const snapshot = queryClient.getQueryData<TrafficRule[]>(cacheKey) || []

        const optimisticUiData = snapshot.map((row) => (row.id === data.id ? { ...row, data } : row))
        queryClient.setQueryData<TrafficRule[]>(cacheKey, () => optimisticUiData)

        return () => queryClient.setQueryData<TrafficRule[]>(cacheKey, snapshot)
      }

      return () => {}
    },
    onSuccess: (_, { params }) => {
      showToast({ message: configurationUpdateSuccessMessage, severity: 'success' })
      if (params) {
        queryClient.invalidateQueries([listCacheKey, params.subscriptionId])
      }
    },
    onError: () => {
      showToast({ message: configrationUpdateErrorMessage, severity: 'error' })
    },
  })
}

export function useTrafficRuleBulkUpdateMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('trafficRuleBulkUpdate', {
    onSuccess: (updatedTrafficRules, { params, data }) => {
      showToast({ message: configurationUpdateSuccessMessage, severity: 'success' })
      if (params && data) {
        const cacheKey = [listCacheKey, params.subscriptionId]
        const snapshot = queryClient.getQueryData<TrafficRule[]>(cacheKey) || []
        const next = snapshot.filter((rule) => rule.type !== data.type).concat(updatedTrafficRules)

        queryClient.setQueryData<TrafficRule[]>(cacheKey, () => next)
        queryClient.invalidateQueries(cacheKey)
      }
    },
    onError: () => {
      showToast({ message: configrationUpdateErrorMessage, severity: 'error' })
    },
  })
}
