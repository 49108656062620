import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { useLogPageView } from 'helpers/vendor'
import { SubscriptionWebhook } from 'models'
import { ampli } from 'models/ampli'
import { Link as RouterLink } from 'react-router-dom'

import WebhookEventsTable, { WebhookEventsTableProps } from '../WebhookEventsTable/WebhookEventsTable'
import styles from './WebhookEvents.module.scss'

export interface WebhookEventsProps {
  webhook?: SubscriptionWebhook
  isLoadingWebhooks?: boolean
}

export default function WebhookEvents({
  webhook,
  isLoadingWebhooks,
  ...tableProps
}: WebhookEventsProps & WebhookEventsTableProps) {
  useLogPageView(() => ampli.webhookEventsListViewed())
  const { currentSubscriptionId } = useCurrentSubscription()

  return (
    <Grid item xs={12}>
      <Breadcrumbs aria-label='breadcrumbs' separator={<NavigateNextIcon />}>
        <Link
          component={RouterLink}
          to={buildRoute(AppRoute.Webhooks, { subscriptionId: currentSubscriptionId })}
          color='inherit'
          underline='hover'
        >
          Webhooks
        </Link>

        <Typography component='span'>Webhook events</Typography>
      </Breadcrumbs>

      {isLoadingWebhooks ? (
        <Skeleton width={480} height={40} className={styles.header} />
      ) : (
        webhook && (
          <Typography variant='h1' className={styles.header}>
            {webhook.url}
          </Typography>
        )
      )}

      <Typography variant='body2' component='h1'>
        Webhook events
      </Typography>

      <WebhookEventsTable {...tableProps} className={styles.tableContainer} />
    </Grid>
  )
}
