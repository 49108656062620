import { addMinutes, isBefore, isEqual } from 'date-fns'

export function calculateTicks(from: string, to: string) {
  const fromDate = new Date(from)
  const toDate = new Date(to)

  const ticks = []
  for (let date = fromDate; isBefore(date, toDate) || isEqual(date, toDate); date = addMinutes(date, 5)) {
    ticks.push(date)
  }

  return ticks
}
