import { ReactNode } from 'react'

import { StatisticReturnTypes, SubscriptionStatistic } from '../../../../models'
import { BigIntValue, DataCard, NumericValue } from '../../../commonUI'
import { WIDGETS } from './const'

export function InsightsNumericWidget({
  type,
  statistic,
  action,
  isError,
}: {
  type: SubscriptionStatistic
  statistic?: StatisticReturnTypes
  action?: ReactNode
  isError: boolean
}) {
  return (
    <DataCard title={WIDGETS[type].title} info={WIDGETS[type].info} hasError={isError} action={action}>
      {WIDGETS[type].type === 'bigint' && (
        <BigIntValue
          value={statistic?.data.currentPeriod ? BigInt(statistic?.data.currentPeriod) : undefined}
          previousValue={statistic?.data?.prevPeriod ? BigInt(statistic?.data.prevPeriod) : undefined}
        />
      )}
      {WIDGETS[type].type === 'precise' && (
        <NumericValue
          value={parseFloat(statistic?.data?.currentPeriod?.toString() ?? '0')}
          previousValue={parseFloat(statistic?.data?.prevPeriod?.toString() ?? '0')}
          precise
        />
      )}
    </DataCard>
  )
}
