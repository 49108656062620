import { ErrorOutline, GitHub, OpenInNew } from '@mui/icons-material'
import {
  Alert,
  AlertColor,
  AlertTitle,
  Button,
  Divider,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import clsx from 'clsx'
import { ComponentType, ReactNode, SVGProps, useMemo } from 'react'

import IntegrationKeyValueList, {
  IntegrationKeyValueListItem,
} from '../../IntegrationKeyValueList/IntegrationKeyValueList'
import { IntegrationVersionBadge } from '../IntegrationVersionBadge/IntegrationVersionBadge'
import { IntegrationStatus, Metadata } from '../types'
import IntegrationStatusChip from './IntegrationStatusChip'
import styles from './IntegrationTopFrame.module.scss'

export type IntegrationPageAlertProps = {
  alertSeverity?: AlertColor
  alertTitle?: string
  alertContent?: ReactNode
}

export type IntegrationTopFrameProps = IntegrationPageAlertProps & {
  title: string
  description?: string
  metadata?: Metadata[]
  Logo: ComponentType<SVGProps<any>> | string
  integrationStatus: IntegrationStatus
  docsUrl?: string
  repoUrl?: string
  connectButton?: ReactNode
  statusLabel?: ReactNode
  topContent?: ReactNode
}

export default function IntegrationTopFrame({
  title,
  integrationStatus,
  metadata,
  Logo,
  docsUrl,
  repoUrl,
  connectButton,
  description,
  statusLabel,
  alertSeverity,
  alertTitle,
  alertContent,
}: IntegrationTopFrameProps) {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const keyValueItems: IntegrationKeyValueListItem[] = useMemo(() => {
    const items: IntegrationKeyValueListItem[] = [
      {
        key: 'Status',
        value: <IntegrationStatusChip tooltipLabel={statusLabel} status={integrationStatus} />,
      },
    ]

    if (metadata?.length) {
      items.push(
        ...metadata
          .filter((m) => m.value)
          .map((m) => ({
            key: m.title,
            value: m.value,
            badge: m.versionBadge ? <IntegrationVersionBadge versionBadge={m.versionBadge} /> : undefined,
          }))
      )
    }

    return items
  }, [integrationStatus, metadata, statusLabel])

  const showConnectButton = useMemo(() => {
    switch (integrationStatus) {
      default:
        return false
      case IntegrationStatus.NotInstalled:
      case IntegrationStatus.UpdatesDisabled:
        return true
    }
  }, [integrationStatus])

  const rightSide = (
    <div className={styles.rightSide}>
      {connectButton && showConnectButton ? connectButton : null}
      {repoUrl ? (
        <Button
          size='large'
          component={Link}
          underline='hover'
          color='inherit'
          className={clsx(styles.actionButton, styles.repoLink)}
          target='_blank'
          href={repoUrl}
          variant='outlined'
          startIcon={<GitHub />}
        >
          Github
        </Button>
      ) : null}
      {docsUrl ? (
        <Button
          size='large'
          component={Link}
          underline='hover'
          color='inherit'
          className={clsx(styles.actionButton, styles.docsLink)}
          target='_blank'
          href={docsUrl}
          variant='outlined'
          startIcon={<OpenInNew />}
        >
          View docs
        </Button>
      ) : null}
    </div>
  )

  return (
    <Paper className={styles.headerFrame}>
      {alertContent && alertSeverity ? (
        <Alert icon={<ErrorOutline />} title={alertTitle} className={styles.topContent} severity={alertSeverity}>
          {alertTitle ? <AlertTitle>{alertTitle}</AlertTitle> : null}
          {alertContent}
        </Alert>
      ) : null}
      <div className={styles.headerTitle}>
        <Paper variant='outlined' className={styles.logoFrame}>
          {typeof Logo === 'string' ? <img alt={title} src={Logo} /> : <Logo />}
        </Paper>
        <div className={styles.titleContainer}>
          <div>
            <Typography className={styles.title} variant='h1'>
              {title}
            </Typography>
            {description ? (
              <Typography className={styles.description} variant='body2'>
                {description}
              </Typography>
            ) : null}
          </div>
          {!smDown ? rightSide : null}
        </div>
        {smDown ? rightSide : null}
      </div>
      <Divider className={styles.divider} />
      <IntegrationKeyValueList
        className={styles.keyValueContainer}
        itemClassName={styles.keyValueContainerItem}
        direction='row'
        items={keyValueItems}
      />
    </Paper>
  )
}
