import { FilterList } from '@mui/icons-material'
import { Button, Popover } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode, useState } from 'react'

import styles from './Filter.module.scss'

export interface FilterProps {
  isApplied?: boolean
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  compact?: boolean
  className?: string
  containerClassName?: string
  triggerContent?: ReactNode
}

export function Filter({
  isApplied,
  isOpen,
  onOpen,
  onClose,
  compact,
  className,
  containerClassName,
  triggerContent,
  children,
}: PropsWithChildren<FilterProps>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }

    onOpen()
  }

  return (
    <>
      <Button
        variant='outlined'
        color='inherit'
        startIcon={<FilterList />}
        className={clsx(
          styles.filterButton,
          {
            [styles.appliedFilterButton]: isApplied,
            [styles.compact]: compact,
          },
          className
        )}
        classes={{ startIcon: clsx(styles.icon, { [styles.appliedIcon]: isApplied }) }}
        onClick={handleClick}
      >
        {compact ? null : triggerContent ?? 'Filter'}
      </Button>

      <Popover
        keepMounted
        open={!!anchorEl && isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: clsx(styles.container, containerClassName) }}
      >
        {children}
      </Popover>
    </>
  )
}
