import { ValueWithDifference } from './ValueWithDifference'

export function BigIntValue({ value, previousValue }: { value?: bigint; previousValue?: bigint }) {
  if (value == null) {
    return <ValueWithDifference />
  }

  // Do not divide by zero.
  if (previousValue == null || previousValue === 0n) {
    return <ValueWithDifference value={value} difference={value} percentage={value === 0n ? 0 : 1} />
  }

  const difference = value - previousValue
  const percentage = Number((difference * 100000n) / previousValue) / 100000
  const shownPercentage = Number.isFinite(percentage) ? percentage : percentage > 0 ? 1 : -1

  return <ValueWithDifference value={value} difference={difference} percentage={shownPercentage} />
}
