import { useCurrentSubscription } from 'features/subscription'
import { useCurrentUser } from 'hooks/api/users'
import { useSubscriptionStatsQuery } from 'hooks/subscriptionStats'
import { useMemo } from 'react'

import { useDateRangeContext } from '../../../commonUI/components/DateRangeContext/DateRangeContext'
import { interpretOverviewDateRange } from '../../../commonUI/components/DateRangeContext/interpretOverviewDateRange'

export function useInsightsStats() {
  const { dateRange } = useDateRangeContext()
  const { data: currentUser } = useCurrentUser()
  const { startDate, endDate } = interpretOverviewDateRange(dateRange, currentUser?.timezone)

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data, error, isLoading } = useSubscriptionStatsQuery(
    currentSubscriptionId,
    startDate?.toISO()!,
    endDate?.toISO()!,
    currentUser != null
  )

  return useMemo(() => ({ data: data?.stats, error: !!error, isLoading }), [data, error, isLoading])
}
