import { Typography } from '@mui/material'
import { formatDate } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useSubscription } from 'hooks'
import { ExpandedSubscription, SubscriptionStatus, SubscriptionType } from 'models'

import { Widget } from './Widget'

export function ScheduleWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <ScheduleWidgetContent subscription={subscription} />
}

export function ScheduleWidgetContent({ subscription }: { subscription?: ExpandedSubscription }) {
  if (subscription == null) {
    return null
  }

  // Future cancel is displayed as a chip in the plan widget
  if (subscription.cancelAt != null) {
    return null
  }

  if (subscription.type === SubscriptionType.Free) {
    return null
  }

  // Canceled
  if (subscription.status === SubscriptionStatus.Canceled && subscription.canceledAt) {
    return (
      <Widget title='Plan canceled on'>
        <Typography variant='bodyMMedium'>{formatDate(subscription.canceledAt, 'shortWithYear')}</Typography>
      </Widget>
    )
  }

  // PoC
  if (subscription.type === SubscriptionType.ProofOfConcept && subscription.trialEndAt) {
    const hasFinishedPOC =
      subscription.status === SubscriptionStatus.Restricted && new Date() > new Date(subscription.trialEndAt)

    return (
      <Widget title={`Proof of concept ${hasFinishedPOC ? 'ended' : 'ends'} on`}>
        <Typography variant='bodyMMedium'>{formatDate(subscription.trialEndAt, 'shortWithYear')}</Typography>
      </Widget>
    )
  }

  // Trialing
  if (subscription.type === SubscriptionType.TrialOnly && subscription.trialEndAt) {
    const hasFinishedTrial = subscription.status === SubscriptionStatus.Canceled

    return (
      <Widget title={`Trial ${hasFinishedTrial ? 'ended' : 'ends'} on`}>
        <Typography variant='bodyMMedium'>{formatDate(subscription.trialEndAt, 'shortWithYear')}</Typography>
      </Widget>
    )
  }

  // Enterprise
  if (subscription.type === SubscriptionType.Prepaid && subscription.currentPeriodEndsAt) {
    const hasEnded = new Date() > new Date(subscription.currentPeriodEndsAt)

    return (
      <Widget title={`Contract ${hasEnded ? 'ended' : 'ends'} on`}>
        <Typography variant='bodyMMedium'>{formatDate(subscription.currentPeriodEndsAt, 'shortWithYear')}</Typography>
      </Widget>
    )
  }

  return subscription.currentPeriodEndsAt ? (
    <Widget title='Next payment on'>
      <Typography variant='bodyMMedium'>{formatDate(subscription.currentPeriodEndsAt, 'shortWithYear')}</Typography>
    </Widget>
  ) : null
}
