import { Box, ListItemIcon, ListItemText, MenuItemProps } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { STATUS_PAGE_URL } from 'const'
import { useBuildRequest } from 'hooks/api/base'
import { ServiceStatusResponse } from 'models'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

const DOT_MAP: Record<ServiceStatusResponse['status'], { color: string; label: string }> = {
  operational: { color: '#4CAF50', label: 'All systems are go' },
  degraded: { color: '#663D00', label: 'Systems degraded' },
  downtime: { color: '#F44336', label: 'Systems are down' },
}

export const ServiceStatusMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  const { isLoading, data: response } = useServiceStatus()

  const status = response?.data?.status
  const { color, label } = status ? DOT_MAP[status] : { color: '#434344', label: 'Loading status' }

  if (!isLoading && status == null) {
    // Failed to get status for some reason, hide the placeholder.
    return null
  }

  return (
    <GenericMenuItem href={STATUS_PAGE_URL} {...props}>
      <ListItemIcon>
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: color,
            marginLeft: '8px',
          }}
        />
      </ListItemIcon>
      <ListItemText primary={label} sx={{ color: color }} {...props.labelProps} />
    </GenericMenuItem>
  )
}

function useServiceStatus() {
  const buildRequest = useBuildRequest()
  return useQuery({
    queryKey: ['service-status'],
    queryFn: () => buildRequest('serviceStatus'),
    staleTime: 60_000,
  })
}
