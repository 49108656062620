import { useValueFromPreviousRender } from 'hooks/useValueFromPreviousRender'
import omitBy from 'lodash/omitBy'
import { VisitsFilter } from 'models'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

export const useSubmittedFilteredFormValues = (form: UseFormReturn<VisitsFilter>) => {
  const values = form.getValues()
  const filtered: VisitsFilter = omitBy(values, (v) => v == null || v === '')
  const [filters, setFilters] = useState<VisitsFilter>(filtered)

  const previousSubmitCount = useValueFromPreviousRender(form.formState.submitCount)
  const justSubmitted = previousSubmitCount == null || form.formState.submitCount > previousSubmitCount
  const isSubmitSuccessful = form.formState.isSubmitSuccessful
  useEffect(() => {
    if (isSubmitSuccessful && justSubmitted) {
      setFilters(filtered)
    }
  }, [filtered, isSubmitSuccessful, justSubmitted])

  return filters
}
