import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Grid, IconButton, Paper, TableCell, Typography } from '@mui/material'
import clsx from 'clsx'
import { EntityEmptyState } from 'features/commonUI'
import { formatMatchRule } from 'helpers/format'
import { useConfirmationDialog } from 'hooks'
import { usePermissions } from 'hooks/permissions'
import { TrafficRule, TrafficRuleCreatePayload, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'

import { default as ActionTable } from '../ActionTable/ActionTable'
import HttpHeaderDialog from '../HttpHeaderDialog/HttpHeaderDialog'
import styles from './HeaderList.module.scss'

interface HeaderSectionProps {
  handleCreateHeaderRule: (payload: TrafficRuleCreatePayload) => void
  handleUpdateHeaderRule: (payload: TrafficRuleCreatePayload & { id: string }) => void
  handleDeleteHeaderRule: (trafficRuleId: string) => void
  isLoading: boolean
  trafficRules: TrafficRule[]
  isCreationLimitExceeded: boolean
  currentEnvironment: WorkspaceEnvironment
}

export function HeaderList({
  handleCreateHeaderRule,
  handleUpdateHeaderRule,
  handleDeleteHeaderRule,
  isLoading,
  trafficRules,
  isCreationLimitExceeded,
  currentEnvironment,
}: HeaderSectionProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [editableHeaderRuleId, setEditableHeaderRuleId] = useState<string>()
  const { isReadOnly } = usePermissions()

  const handleHeaderFormSubmit = useCallback(
    (payload: TrafficRuleCreatePayload & ({ id: undefined } | { id: string })) => {
      const func = payload.id ? handleUpdateHeaderRule : handleCreateHeaderRule
      func(payload)
      setIsDialogOpen(false)
    },
    [handleCreateHeaderRule, handleUpdateHeaderRule]
  )

  const onClickAddOrEdit = useCallback((headerRuleId?: string) => {
    if (!headerRuleId) {
      ampli.addHeaderRuleClicked()
    }
    setEditableHeaderRuleId(headerRuleId)
    setIsDialogOpen(true)
  }, [])

  return (
    <>
      <HeaderListView
        onAddClick={() => onClickAddOrEdit()}
        onEditIntent={(id) => onClickAddOrEdit(id)}
        onDeleteIntent={handleDeleteHeaderRule}
        isLoading={isLoading}
        trafficRules={trafficRules}
        isReadOnly={isReadOnly}
        isCreationLimitExceeded={isCreationLimitExceeded}
        currentEnvironment={currentEnvironment}
      />
      <HttpHeaderDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={handleHeaderFormSubmit}
        trafficRule={editableHeaderRuleId ? trafficRules.find((rule) => rule.id === editableHeaderRuleId) : undefined}
        environment={currentEnvironment}
      />
    </>
  )
}

export interface HeaderListViewProps {
  trafficRules: TrafficRule[]
  className?: string
  isLoading?: boolean
  isReadOnly: boolean
  isCreationLimitExceeded: boolean
  onAddClick: () => unknown
  onEditIntent: (id: string) => unknown
  onDeleteIntent: (id: string) => unknown
  currentEnvironment: WorkspaceEnvironment
}

export function HeaderListView({
  trafficRules,
  className,
  isLoading,
  isReadOnly,
  isCreationLimitExceeded,
  onAddClick,
  onEditIntent,
  onDeleteIntent,
  currentEnvironment,
}: HeaderListViewProps) {
  const { openDialog } = useConfirmationDialog()
  const headersTrafficRules = trafficRules.filter(
    (rule) => rule.type === TrafficRuleType.HTTPHeader && rule.workspaceEnvironmentId === currentEnvironment?.id
  )

  const isEmpty = headersTrafficRules.length === 0 && !isLoading

  return (
    <Grid item xs={12} className={className}>
      <Box className={styles.header}>
        <Box>
          <Typography variant='h2'>Forbidden HTTP Headers</Typography>
          <Typography variant='bodyM'>Filter out unwanted identification requests by header.</Typography>
        </Box>
        {!isEmpty && (
          <Button
            className={styles.button}
            variant='outlined'
            disabled={isCreationLimitExceeded || isReadOnly}
            onClick={onAddClick}
          >
            Add Rule
          </Button>
        )}
      </Box>
      {isEmpty ? (
        <Paper>
          <EntityEmptyState
            isButtonDisabled={isCreationLimitExceeded || isReadOnly}
            entity='header_traffic_rule'
            title='No header rules'
            description='All requests are allowed by default.'
            buttonText='Add Rule'
            onClick={onAddClick}
          />
        </Paper>
      ) : (
        <ActionTable
          titleVariant='h6'
          isLoading={isLoading}
          emptyStateText='No HTTP header rules'
          columnsNumber={5}
          actionsSize='small'
          entities={headersTrafficRules}
          row={(rule: TrafficRule) => (
            <>
              <TableCell>{rule.key}</TableCell>
              <TableCell>{rule.matchRule && formatMatchRule(rule.matchRule)}</TableCell>
              <TableCell>{rule.value}</TableCell>
              <TableCell>{rule.description}</TableCell>
              <TableCell align='right'>
                <IconButton
                  size='small'
                  aria-label='Edit Rule'
                  onClick={() => onEditIntent(rule.id)}
                  disabled={isReadOnly}
                >
                  <EditIcon color='primary' fontSize='small' className={clsx({ [styles.disabled]: isReadOnly })} />
                </IconButton>
                <IconButton
                  size='small'
                  aria-label='Delete Rule'
                  onClick={() =>
                    openDialog({
                      label: 'Delete the HTTP header rule?',
                      onConfirm: () => onDeleteIntent(rule.id),
                    })
                  }
                  disabled={isReadOnly}
                >
                  <DeleteOutlineIcon
                    color='primary'
                    fontSize='small'
                    className={clsx({ [styles.disabled]: isReadOnly })}
                  />
                </IconButton>
              </TableCell>
            </>
          )}
          headerRow={
            <>
              <TableCell style={{ width: '20%' }}>Header</TableCell>
              <TableCell style={{ width: '20%' }}>Rule</TableCell>
              <TableCell style={{ width: '20%' }}>Value</TableCell>
              <TableCell style={{ width: '30%' }}>Description</TableCell>
              <TableCell style={{ width: '96px' }} />
            </>
          }
        />
      )}
    </Grid>
  )
}
