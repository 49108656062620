import { MoreHoriz } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'

import styles from './UserManagementActionMenu.module.scss'

export interface UserManagementActionMenuProps {
  isPendingTransfer?: boolean
  onTransfer: () => void
  onCancelTransfer: () => void
  className?: string
}

export function UserManagementActionMenu({
  isPendingTransfer,
  onTransfer,
  onCancelTransfer,
  className,
}: UserManagementActionMenuProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  function openMenu(event: MouseEvent<HTMLButtonElement>) {
    setAnchor(event.currentTarget)
  }

  function closeMenu() {
    setAnchor(null)
  }

  return (
    <>
      <IconButton id='more-actions-button' size='small' title='More actions' onClick={openMenu} className={className}>
        <MoreHoriz />
      </IconButton>

      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'more-actions-button',
        }}
        classes={{ list: styles.menuList }}
      >
        {isPendingTransfer && (
          <MenuItem
            onClick={() => {
              onCancelTransfer()
              closeMenu()
            }}
            classes={{ root: styles.menuItem }}
          >
            <Typography variant='bodyMMedium'>Cancel ownership transfer</Typography>
          </MenuItem>
        )}

        {!isPendingTransfer && (
          <MenuItem
            onClick={() => {
              onTransfer()
              closeMenu()
            }}
            classes={{ root: styles.menuItem }}
          >
            <Typography variant='bodyMMedium'>Transfer ownership</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
