import { Snackbar } from '@mui/material'
import { MainGrid } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle } from 'hooks'
import { useWebhookEventResendMutation } from 'hooks/api/webhookEvents'
import { useWebhooks } from 'hooks/api/webhooks'
import { usePaginatedWebhookEvents } from 'hooks/webhookEvents'
import { WebhookEventsFilterData } from 'models'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import WebhookEvents from '../../components/WebhookEvents/WebhookEvents'

export function WebhookEventsPage() {
  useDocumentTitle('Webhook events')

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { webhookId } = useParams<{ webhookId: string }>()

  const [filter, setFilter] = useState<WebhookEventsFilterData | undefined>()
  const hasFilter = filter && !!filter.id

  const paginatedWebhookEvents = usePaginatedWebhookEvents({
    subscriptionId,
    webhookId,
    filter,
  })
  const { data: webhooks, isLoading: isLoadingWebhooks } = useWebhooks(subscriptionId)
  const { mutate: resendWebhookEvent } = useWebhookEventResendMutation()
  const webhook = webhooks?.find(({ id }) => id === webhookId)

  const [isShowingResendConfirmation, setIsShowingResendConfirmation] = useState(false)
  const [recentlyResentEvents, setRecentlyResentEvents] = useState<string[]>([])

  function handleCloseResendConfirmation(_: Object, reason: string) {
    if (reason === 'clickaway') {
      return
    }

    setIsShowingResendConfirmation(false)
  }

  const pageStartRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    if (pageStartRef.current) {
      pageStartRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [pageStartRef])

  return (
    <MainGrid>
      <span ref={pageStartRef} />
      <WebhookEvents
        {...paginatedWebhookEvents}
        isFilterApplied={hasFilter}
        emptyAlert={
          hasFilter ? 'No  results for this filter.' : 'Start making identifications to see your webhook events.'
        }
        onFilter={(data) => setFilter(data)}
        recentlyResentEvents={recentlyResentEvents}
        onResend={(requestId) => {
          setRecentlyResentEvents([...recentlyResentEvents, requestId])

          resendWebhookEvent(
            {
              params: {
                subscriptionId,
                webhookId,
                requestId,
              },
            },
            {
              onSuccess: () => {
                setIsShowingResendConfirmation(true)
                setTimeout(() => {
                  setRecentlyResentEvents(recentlyResentEvents.filter((event) => event !== requestId))
                }, 5000)
              },
              onError: () => setRecentlyResentEvents(recentlyResentEvents.filter((event) => event !== requestId)),
            }
          )
        }}
        webhook={webhook}
        isLoadingWebhooks={isLoadingWebhooks}
      />

      <Snackbar
        open={isShowingResendConfirmation}
        onClose={handleCloseResendConfirmation}
        autoHideDuration={3000}
        message='Resending webhook event.'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </MainGrid>
  )
}
