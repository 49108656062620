import { HelpOutline } from '@mui/icons-material'
import { Alert, Button, InputLabel, Link, OutlinedInput, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { DOCS_CLOUDFRONT_v2, DOCS_CLOUDFRONT_v2_MGMT_LAMBDA_DETAILS } from 'const'
import { DialogActions } from 'features/commonUI'
import { muiRegister } from 'helpers/reactHookForm'
import { MouseEventHandler, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { CloudFrontIntegration } from '../../../../../models'
import styles from './IntegrationCloudFrontConnectModal.module.scss'

type Props = {
  onClose: () => void
  integration?: CloudFrontIntegration
  onSubmit?: (managementLambdaURL: string, authToken: string) => Promise<void>
  mode: 'enableUpdates' | 'manage'
}

type FormState = {
  managementLambdaURL: string
  authToken: string
}

type ErrorInfo = {
  type: 'invalidMgmtLambdaUrlFormat' | 'invalidToken'
}

type ValidationError = Error & {
  data: Array<{
    keyword: string
    params?: { format?: 'AWSLambdaURL' }
  }>
}

const errorMessages = {
  validationError: 'Validation error',
  invalidToken: 'Incorrect token for CloudFront management lambda.',
}

export default function IntegrationCloudFrontConnectModal({ onClose, onSubmit, mode, integration }: Props) {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const [error, setSubmitError] = useState<Error | ValidationError | undefined>()
  const errorInfo = useMemo<ErrorInfo | undefined>(() => {
    switch (error?.message) {
      case errorMessages.validationError: {
        const hasFormatError =
          'cause' in error && (error.cause as any[])?.find((v) => v.params?.format === 'AWSLambdaURL')
        if (hasFormatError) {
          return {
            type: 'invalidMgmtLambdaUrlFormat',
          }
        }

        return undefined
      }
      case errorMessages.invalidToken:
        return {
          type: 'invalidToken',
        }
      default:
        return undefined
    }
  }, [error])
  const form = useForm<FormState>({
    defaultValues: {
      managementLambdaURL: integration?.managementLambdaPublicURL,
      authToken: '',
    },
  })

  const handleSubmit = useCallback(
    async (values: FormState) => {
      setSubmitError(undefined)

      try {
        await onSubmit?.(values.managementLambdaURL, values.authToken)

        onClose()
      } catch (error) {
        setSubmitError(error as Error)
      }
    },
    [onClose, onSubmit]
  )

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} className={styles.container}>
      <Typography variant='h1'>
        {mode === 'enableUpdates' ? 'Enable updates for CloudFront integration' : 'Manage CloudFront integration'}
      </Typography>
      {errorInfo?.type === 'invalidMgmtLambdaUrlFormat' ? (
        <Alert severity='error'>
          The provided Management Lambda URL was invalid.{' '}
          <Link target='_blank' color='inherit' href={DOCS_CLOUDFRONT_v2_MGMT_LAMBDA_DETAILS}>
            See documentation
          </Link>
          .
        </Alert>
      ) : errorInfo?.type === 'invalidToken' ? (
        <Alert severity='error'>The provided auth token was invalid.</Alert>
      ) : error ? (
        <Alert severity='error'>{error.message}</Alert>
      ) : null}
      {mode === 'enableUpdates' ? (
        <>
          <Typography variant='body2'>
            First, configure and deploy the CloudFront integration application in your AWS account.{' '}
            <Link target='_blank' href={DOCS_CLOUDFRONT_v2}>
              Learn more about CloudFront integration.
            </Link>
          </Typography>
          <Typography variant='body2'>
            Once you have deployed the integration, add your details here to enable automatic updates. Automatic updates
            of the integration are necessary for keeping maximum identification accuracy.
          </Typography>
          <Alert severity='info'>Remember to update these details if they change in your AWS account.</Alert>
        </>
      ) : null}
      <div>
        <InputLabel htmlFor='managementLambdaURL'>CloudFront Management Lambda URL</InputLabel>
        <OutlinedInput
          fullWidth
          id='managementLambdaURL'
          placeholder='Enter your CloudFront Management Lambda URL'
          endAdornment={
            <InputHelp compact={mdDown} href={DOCS_CLOUDFRONT_v2_MGMT_LAMBDA_DETAILS}>
              Where can I find this?
            </InputHelp>
          }
          {...muiRegister(form.register, 'managementLambdaURL', {
            required: true,
          })}
        />
      </div>
      <div>
        <InputLabel htmlFor='authToken'>Authentication Token</InputLabel>
        <OutlinedInput
          fullWidth
          id='authToken'
          type='password'
          autoComplete='new-password'
          placeholder='Enter your API Token'
          endAdornment={
            <InputHelp compact={mdDown} href={DOCS_CLOUDFRONT_v2_MGMT_LAMBDA_DETAILS}>
              Where can I find this?
            </InputHelp>
          }
          {...muiRegister(form.register, 'authToken', {
            required: true,
          })}
        />
      </div>
      <DialogActions className={styles.actions}>
        <Button variant='outlined' color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' type='submit' disabled={!form.formState.isDirty}>
          Save Changes
        </Button>
      </DialogActions>
    </form>
  )
}

interface InputHelpProps {
  href: string
  compact?: boolean
  onClick?: MouseEventHandler
}

function InputHelp({ href, compact, onClick, children }: PropsWithChildren<InputHelpProps>) {
  return (
    <Link className={styles.helpLink} underline='none' href={href} target='_blank' onClick={onClick}>
      {compact ? <HelpOutline className={styles.helpIcon} /> : children}
    </Link>
  )
}
