import { CssBaseline } from '@mui/material'
import App from 'App'
import AppProviders from 'AppProviders'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { GIT_REF } from './const'

// @ts-ignore
window.CODE_VERSION = GIT_REF

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <AppProviders>
    <BrowserRouter>
      <CssBaseline />
      <StrictMode>
        <App />
      </StrictMode>
    </BrowserRouter>
  </AppProviders>
)
