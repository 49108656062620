import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { RouterPath } from '../../helpers/types'
import styles from './DashboardBanner.module.scss'

export interface DashboardBannerProps {
  title: string | React.ReactNode
  action?: string
  actionUrl?: RouterPath
  actionHref?: string
  secondaryAction?: string
  secondaryActionUrl?: RouterPath
  secondaryActionHref?: string
  secondaryActionOnClose?: () => void
  dismissTitle?: string
  dismissBody?: string | React.ReactNode
  onClose?: () => void
  onShow?: () => void
  onClickAction?: () => void
  onClickSecondaryAction?: () => void
  children?: string | ReactNode
  icon?: ReactElement
  variant?: 'primary' | 'alert' | 'warning' | 'info'
  wideButton?: boolean
  className?: string
  vertical?: boolean
  testIds?: {
    title?: string
    body?: string
    actionButton?: string
    secondaryActionButton?: string
  }
}

export default function DashboardBanner({
  title,
  action,
  actionUrl,
  actionHref,
  secondaryAction,
  secondaryActionUrl,
  secondaryActionHref,
  secondaryActionOnClose,
  dismissTitle,
  dismissBody,
  onClose,
  onShow,
  onClickAction,
  onClickSecondaryAction,
  children,
  icon,
  variant,
  wideButton,
  className,
  vertical,
  testIds,
}: DashboardBannerProps) {
  const [isDismissConfirmOpen, setIsDismissConfirmOpen] = useState(false)
  const shouldConfirmDismiss = dismissTitle && dismissBody

  function handleRequestDismiss() {
    setIsDismissConfirmOpen(true)
  }

  function handleCancelDismiss() {
    setIsDismissConfirmOpen(false)
  }

  function handleConfirmDismiss() {
    setIsDismissConfirmOpen(false)
    onClose?.()
    secondaryActionOnClose?.()
  }

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  // TODO rework this, it's a mess.

  let buttonComponent: 'button' | 'a' | typeof RouterLink = RouterLink

  if (onClickAction) {
    buttonComponent = 'button'
  }
  if (actionHref) {
    buttonComponent = 'a'
  }

  if (actionUrl) {
    buttonComponent = RouterLink
  }

  let secondaryButtonComponent: 'button' | 'a' | typeof RouterLink = RouterLink

  if (onClickSecondaryAction) {
    secondaryButtonComponent = 'button'
  }
  if (secondaryActionHref) {
    secondaryButtonComponent = 'a'
  }

  if (secondaryActionUrl) {
    secondaryButtonComponent = RouterLink
  }

  useEffect(() => {
    onShow?.()
    // Avoid triggering on-rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section
        className={clsx(styles.root, className, {
          [styles.alert]: variant === 'alert',
          [styles.warning]: variant === 'warning',
          [styles.vertical]: vertical,
          [styles.withoutCloseIcon]: !onClose,
        })}
      >
        <div className={styles.textSection}>
          {icon && (
            <span
              className={clsx(styles.icon, {
                [styles.alertIcon]: variant === 'alert',
                [styles.warningIcon]: variant === 'warning' || variant === 'info',
              })}
            >
              {icon}
            </span>
          )}
          <div className={styles.text}>
            <Typography variant='h3' component='h1' className={styles.title} data-testid={testIds?.title}>
              {title}
            </Typography>
            <Typography variant='body2' data-testid={testIds?.body}>
              {children}
            </Typography>
          </div>
        </div>

        <div className={clsx(styles.actions, { [styles.withClose]: onClose })}>
          {onClose && (
            <IconButton
              size='small'
              onClick={() => {
                if (shouldConfirmDismiss) {
                  handleRequestDismiss()
                } else {
                  onClose()
                }
              }}
            >
              <Close />
            </IconButton>
          )}
          <div className={styles.buttons}>
            {secondaryAction &&
              (secondaryActionOnClose ? (
                <Button
                  variant='outlined'
                  color='primary'
                  size='large'
                  className={clsx(styles.bannerButton, {
                    [styles.alertButtonSecondary]: variant === 'alert',
                    [styles.warningButtonSecondary]: variant === 'warning',
                  })}
                  data-testid={testIds?.secondaryActionButton}
                  onClick={() => {
                    if (shouldConfirmDismiss) {
                      handleRequestDismiss()
                    } else {
                      secondaryActionOnClose()
                    }
                  }}
                  fullWidth={smDown}
                >
                  {secondaryAction}
                </Button>
              ) : (
                <Button
                  component={secondaryButtonComponent}
                  target={secondaryActionHref ? '_blank' : undefined}
                  to={secondaryActionUrl}
                  href={secondaryActionHref}
                  onClick={onClickSecondaryAction}
                  variant='outlined'
                  color='primary'
                  size='large'
                  data-testid={testIds?.secondaryActionButton}
                  className={clsx(styles.bannerButton, {
                    [styles.alertButtonSecondary]: variant === 'alert',
                    [styles.warningButtonSecondary]: variant === 'warning',
                    [styles.wideButton]: wideButton,
                  })}
                  fullWidth={smDown}
                >
                  {secondaryAction}
                </Button>
              ))}
            {action && (
              <Button
                component={buttonComponent}
                target={actionHref ? '_blank' : undefined}
                to={actionUrl}
                href={actionHref}
                onClick={onClickAction}
                variant='contained'
                color='primary'
                size='large'
                data-testid={testIds?.actionButton}
                className={clsx(styles.bannerButton, {
                  [styles.alertButton]: variant === 'alert',
                  [styles.warningButton]: variant === 'warning',
                  [styles.wideButton]: wideButton,
                })}
                fullWidth={smDown}
              >
                {action}
              </Button>
            )}
          </div>
        </div>
      </section>

      {shouldConfirmDismiss && (
        <ConfirmDismiss
          open={isDismissConfirmOpen}
          title={dismissTitle}
          onClose={handleCancelDismiss}
          onConfirm={handleConfirmDismiss}
        >
          {dismissBody}
        </ConfirmDismiss>
      )}
    </>
  )
}

interface ConfirmDismissProps {
  title: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

function ConfirmDismiss({ title, open, children, onClose, onConfirm }: PropsWithChildren<ConfirmDismissProps>) {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.confirmDismiss }}>
      <header className={styles.dialogTitle}>
        <Typography variant='h2'>{title}</Typography>
        <IconButton size='small' onClick={onClose}>
          <Close />
        </IconButton>
      </header>

      <DialogContent className={styles.dialogContent}>{children}</DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button color='primary' variant='contained' onClick={onConfirm}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
