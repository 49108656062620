import { Alert, Paper, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { SupportEmail } from 'components/SupportEmail'
import { ampli } from 'models/ampli'

import { AppRoute } from '../../../../appRoutes'
import ApplicationsCompactTable from '../ApplicationsCompactTable/ApplicationsCompactTable'
import ApplicationsList from '../ApplicationsList/ApplicationsList'
import ApplicationsTable from '../ApplicationsTable/ApplicationsTable'
import styles from './ApplicationsOverview.module.scss'
import { ApplicationsOverviewSubscriptionDetails } from './ApplicationsOverviewData'

export type ApplicationsOverviewProps = {
  isLoading?: boolean
  isError?: boolean
  subscriptions?: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsOverview({ subscriptions, isLoading, isError }: ApplicationsOverviewProps) {
  const theme = useTheme()

  const displayFullTable = useMediaQuery(theme.breakpoints.up('lg'))
  const displayCompactTable = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const displayList = useMediaQuery(theme.breakpoints.down('sm'))

  const displayLoadingState = !subscriptions && isLoading
  const displayErrorState = !isLoading && isError

  return (
    <>
      <Paper className={styles.applicationsContainer}>
        <Typography variant='h3' className={styles.heading}>
          Applications
        </Typography>

        {displayLoadingState && <Skeleton height='100px' />}
        {displayErrorState && (
          <div className={styles.errorSection}>
            <Alert severity='error'>
              Something went wrong. Please try again later or contact <SupportEmail />
            </Alert>
          </div>
        )}

        {!!subscriptions && (
          <>
            {displayFullTable && <ApplicationsTable subscriptions={subscriptions} />}
            {displayCompactTable && <ApplicationsCompactTable subscriptions={subscriptions} />}
            {displayList && <ApplicationsList subscriptions={subscriptions} />}
          </>
        )}
      </Paper>

      <div className={styles.bannerSection}>
        <DashboardBanner
          variant='info'
          title={
            <Typography variant='h3'>Need additional security, support, and enterprise-scale features?</Typography>
          }
          action='Talk to Sales'
          actionUrl={AppRoute.ContactSalesEnterprise}
          onClickAction={() => {
            ampli.appCtaClicked({ 'App Page Path': AppRoute.AccountApplications, ctaName: 'Talk to Sales' })
          }}
        >
          <Typography variant='bodyM'>Talk to our Sales team to learn more about Enterprise pricing.</Typography>
        </DashboardBanner>
      </div>
    </>
  )
}
