import { useSearchParams } from 'hooks/useSearchParams'
import { VisitsFilter } from 'models'
import { useEffect } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'

import { useSubmittedFilteredFormValues } from './useSubmittedFilteredFormValues'

const MANAGED_KEYS: Array<keyof VisitsFilter> = [
  'requestId',
  'visitorId',
  'ipAddress',
  'url',
  'linkedId',
  'origin',
  'since',
  'before',
]

export const useQueryParamSync = (
  form: UseFormReturn<VisitsFilter>,
  onSubmit: SubmitHandler<VisitsFilter> = () => {}
) => {
  const filters = useSubmittedFilteredFormValues(form)
  const [currentSearchParams, setSearchParams] = useSearchParams<VisitsFilter>(MANAGED_KEYS)

  useEffect(
    () => {
      for (const [key, value] of Object.entries(currentSearchParams)) {
        form.setValue(key as keyof VisitsFilter, value, { shouldDirty: true })
      }
      form.handleSubmit(onSubmit)()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setSearchParams(filters)
  }, [filters, setSearchParams])

  return currentSearchParams
}
