import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { RegionFlag } from 'components'
import { WithSubscriptionId } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { replaceSubscriptionInPath } from 'helpers/path'
import { usePermissions } from 'hooks/permissions'
import { RegionCode, Subscription, SubscriptionStatus } from 'models'
import { memo, useRef, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import styles from './SubscriptionSelect.module.scss'

export interface SubscriptionSelectProps {
  subscriptions: Subscription[]
  isLoading: boolean
}

export default memo(function SubscriptionSelect({ subscriptions, isLoading }: SubscriptionSelectProps) {
  const history = useHistory()
  const { currentSubscriptionId: selectedId } = useCurrentSubscription()
  const selectedSubscription = subscriptions.find((subscription) => subscription.id === selectedId)
  const activeSubscriptions = subscriptions.filter(({ status }) => status !== SubscriptionStatus.Canceled)
  const canceledSubscriptions = subscriptions.filter(({ status }) => status === SubscriptionStatus.Canceled)
  const hasActiveSubscriptions = activeSubscriptions.length > 0
  const hasCanceledSubscriptions = canceledSubscriptions.length > 0
  const { isReadOnly, subscriptionPermissions } = usePermissions()

  const menuRef = useRef<HTMLButtonElement | null>(null)
  const [menuOpened, setMenuOpened] = useState(false)

  const handleClick = () => {
    setMenuOpened(true)
  }

  const handleClose = () => {
    setMenuOpened(false)
  }

  const handleCreateClick = () => {
    handleClose()
    history.push('/subscriptions/start')
  }

  const handleSubscriptionClick = (id: string) => {
    handleClose()

    for (const customRedirect of customRedirects) {
      const { from, to } = customRedirect

      if (from.test(history.location.pathname)) {
        history.push(to(id))
        return
      }
    }

    const routeMatch = matchPath<WithSubscriptionId>(history.location.pathname, {
      path: AppRoute.SubscriptionOverview,
    })
    const isSubscriptionRoute = routeMatch && routeMatch.params.subscriptionId

    if (isSubscriptionRoute) {
      history.push(replaceSubscriptionInPath(history.location, id))
    } else {
      history.push(buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: id }))
    }
  }

  return (
    <div className={styles.root}>
      <Button
        ref={menuRef}
        variant='outlined'
        size='large'
        disabled={isLoading}
        title={selectedSubscription?.name}
        startIcon={<RegionFlag size='s' regionCode={selectedSubscription?.regionCode ?? RegionCode.Use1} />}
        endIcon={menuOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleClick}
        className={styles.selector}
        disableRipple={false}
        color='grey'
      >
        {isLoading ? 'Loading...' : <span className={styles.name}>{selectedSubscription?.name}</span>}
      </Button>

      <Menu
        anchorEl={menuRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        keepMounted
        open={menuOpened}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        {activeSubscriptions.map((subscription) => (
          // https://github.com/mui/material-ui/issues/15903#issuecomment-723807594
          <div key={subscription.id}>
            <SubscriptionItem
              id={subscription.id}
              name={subscription.name}
              isSelected={subscription === selectedSubscription}
              onClick={handleSubscriptionClick}
            />
          </div>
        ))}

        {!isReadOnly && subscriptionPermissions.canCreate(subscriptions) && (
          <MenuItem
            onClick={handleCreateClick}
            classes={{ root: clsx(styles.item, styles.itemAddNew), selected: styles.selected }}
          >
            <AddIcon />
            <Typography>New application</Typography>
          </MenuItem>
        )}

        {hasActiveSubscriptions && hasCanceledSubscriptions && <Divider className={styles.divider} />}

        {hasCanceledSubscriptions &&
          canceledSubscriptions.map((subscription) => (
            <div key={subscription.id}>
              <SubscriptionItem
                id={subscription.id}
                name={subscription.name}
                isSelected={subscription === selectedSubscription}
                onClick={handleSubscriptionClick}
                isCanceled
              />
            </div>
          ))}
      </Menu>
    </div>
  )
})

interface SubscriptionItemProps {
  id: string
  name: string
  isSelected?: boolean
  isCanceled?: boolean
  onClick: (id: string) => void
}

function SubscriptionItem({ id, name, isSelected, isCanceled, onClick }: SubscriptionItemProps) {
  return (
    <MenuItem
      selected={isSelected}
      onClick={() => onClick(id)}
      classes={{ root: clsx([styles.item, { [styles.canceled]: isCanceled }]), selected: styles.selected }}
    >
      <Typography>{name}</Typography>
      {isCanceled && <span className={styles.label}>Canceled</span>}
    </MenuItem>
  )
}

const customRedirects = [
  {
    from: /\/subscriptions\/.*\/visits.*/,
    to: (subscriptionId: string) => buildRoute(AppRoute.IdentificationVisits, { subscriptionId }),
  },
  {
    from: /\/subscriptions\/.*\/webhooks\/.*\/events/,
    to: (subscriptionId: string) => buildRoute(AppRoute.Webhooks, { subscriptionId }),
  },
  {
    from: /\/subscriptions\/.*\/upgrade/,
    to: (subscriptionId: string) => buildRoute(AppRoute.SubscriptionOverview, { subscriptionId }),
  },
]
