import { Dialog, useMediaQuery, useTheme } from '@mui/material'
import { useAccountPreferences } from 'hooks'
import { AccountModalName } from 'models'
import { useCallback, useState } from 'react'

import BotdMessagePane from '../../BotDMessagePane/BotdMessagePane'

export interface IntroductionModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function IntroductionModal() {
  const { getModalState, updateModalState } = useAccountPreferences()
  const [isIntroModalOpen, updateIsIntroModalOpen] = useState(!getModalState(AccountModalName.BotDIntro).isHidden)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCloseModal = useCallback(() => {
    updateModalState(AccountModalName.BotDIntro, { isHidden: true })
    updateIsIntroModalOpen(false)
  }, [updateModalState])

  return (
    <Dialog open={isIntroModalOpen} onClose={handleCloseModal} fullScreen={smDown}>
      <BotdMessagePane
        title='Introducing Bot Detection'
        subtitle='Identify friendly and malicious bots in real-time with one API call.'
        buttonText='Get Started'
        onClose={handleCloseModal}
        onClickButton={handleCloseModal}
      />
    </Dialog>
  )
}
