import { Check, Download, FileCopyOutlined, InfoOutlined } from '@mui/icons-material'
import { Alert, Box, Button, Link, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { DOCS_WEBHOOK_SECURITY_URL, SUPPORT_PAGE_URL } from '../../../../../const'
import { copyToClipboard } from '../../../../../helpers/clipboard'
import { download } from '../../../../../helpers/download'
import { MIMEType } from '../../../../../helpers/types'
import { WebhookSignature } from '../../../../../models'
import { Dialog, DialogContent, DialogProps, DialogTitle } from '../../../../commonUI'
import styles from './WebhookCreatedConfirmationDialog.module.scss'

export interface WebhookCreatedConfirmationDialogProps extends DialogProps {
  webhookSignature: WebhookSignature
}

export function WebhookCreatedConfirmationDialog({
  webhookSignature,
  onClose,
  ...dialogProps
}: WebhookCreatedConfirmationDialogProps) {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }, [isCopied])

  const onDownload = useCallback(() => {
    download(
      webhookSignature.signatureKey ?? '',
      `Webhook-${webhookSignature.createdAt ?? 'key'}.txt`,
      MIMEType.PlainText
    )
  }, [webhookSignature])

  const onCopy = useCallback(() => {
    copyToClipboard(webhookSignature.signatureKey ?? '')
    setIsCopied(true)
  }, [webhookSignature])

  return (
    <Dialog {...dialogProps} classes={{ paper: styles.createdDialog }}>
      <DialogTitle onClose={onClose} className={styles.createdDialogTitle}>
        <Box>
          <Typography variant='h1' className={styles.heading}>
            Webhook created
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent className={styles.createdDialogContent}>
        <h3 className={styles.simpleHeader}>Be sure to verify all incoming webhook requests.</h3>
        <Typography className={styles.alert}>
          Each webhook request contains a signature within the FPJS-Event-Signature HTTP header. Compare this signature
          with your own calculated HMAC signature to verify webhook authenticity.{' '}
          <Link href={DOCS_WEBHOOK_SECURITY_URL} target='_blank'>
            Learn more about webhook signing
          </Link>
          .
        </Typography>

        <Alert severity='warning' icon={<InfoOutlined />} className={styles.alert}>
          We&apos;ll only show this key once, so please store it somewhere safe.
        </Alert>

        <Typography className={styles.bankNoteKeyDisplay}>{webhookSignature.signatureKey}</Typography>
        <Button onClick={onDownload} variant='contained' startIcon={<Download />} className={styles.dialogAction}>
          Download .txt
        </Button>
        <Button
          onClick={onCopy}
          variant={'outlined'}
          startIcon={isCopied ? <Check /> : <FileCopyOutlined />}
          className={styles.dialogAction}
        >
          {isCopied ? 'Copied!' : 'Copy to clipboard'}
        </Button>
        <Typography variant='semiBody1' className={styles.footer}>
          Have questions?
        </Typography>
        <Typography>
          <Link href={SUPPORT_PAGE_URL} target='_blank' underline='hover'>
            Reach out to us
          </Link>
          . Our support engineers are happy to help.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
