import {
  applicationNameMaxLength,
  domainNameRegex,
  emailMaxLength,
  emailRegex,
  fullURLRegex,
  ipAddressRegex,
  originRegex,
  originWildcardsRegex,
  passwordMaxLength,
  passwordMinLength,
} from './regex'

export function isValidDomain(domain: string) {
  return domainNameRegex.test(domain)
}

export function isValidSubDomain(domain: string) {
  return domainNameRegex.test(domain) && domain.split('.').length > 2 && !ipAddressRegex.test(domain)
}

export function isValidEmail(email: string) {
  return emailRegex.test(email)
}

export function isContainDuplicates(array: unknown[]) {
  return new Set(array).size !== array.length
}

export function isValidOrigin(origin: string, allowWildcards = false) {
  const regex = allowWildcards ? originWildcardsRegex : originRegex
  return regex.test(origin) || ipAddressRegex.test(origin)
}

export function validateDomainName(source: string) {
  if (!fullURLRegex.test(source)) {
    return 'Invalid URL format'
  }

  if (!source.startsWith('https://')) {
    return 'https:// scheme is required'
  }

  if (ipAddressRegex.test(source)) {
    return 'Domain name required'
  }

  return true
}

export function isValidRegex(regex: string) {
  try {
    new RegExp(regex)
    return true
  } catch (e) {
    return false
  }
}

export const OPTIONAL_EMAIL_VALIDATION = {
  pattern: { value: emailRegex, message: 'Valid email required' },
  maxLength: { value: emailMaxLength, message: 'The email is too long' },
}

export const EMAIL_VALIDATION = {
  required: 'Email required',
  ...OPTIONAL_EMAIL_VALIDATION,
}

export const APPLICATION_NAME_VALIDATION = {
  required: { value: true, message: 'Application name is required' },
  maxLength: { value: applicationNameMaxLength, message: 'Application names can only be 80 characters long' },
}

export const DOMAIN_VALIDATION = {
  required: false,
  pattern: { value: domainNameRegex, message: 'Invalid domain name' },
}

export const PASSWORD_VALIDATION = {
  required: 'Password required',
}

export const PASSWORD_CREATE_VALIDATION = {
  ...PASSWORD_VALIDATION,
  maxLength: {
    value: passwordMaxLength,
    message: `Password must have at most ${passwordMaxLength} characters`,
  },
  minLength: {
    value: passwordMinLength,
    message: `Password must have at least ${passwordMinLength} characters`,
  },
}

export const URL_MAX_LENGTH = 8_192
