import { HikingOutlined, MenuBookOutlined } from '@mui/icons-material'
import { MenuItemProps } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { PRODUCTBOARD_PUBLIC_ROADMAP } from 'const'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

export const DeveloperMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  return (
    <>
      <GenericMenuItem
        icon={<MenuBookOutlined />}
        label={'Developer Docs'}
        href={buildRoute(AppRoute.ReadmeLogin)}
        {...props}
      />
      <GenericMenuItem
        icon={<HikingOutlined />}
        label={'Product Roadmap'}
        href={PRODUCTBOARD_PUBLIC_ROADMAP}
        {...props}
      />
    </>
  )
}
