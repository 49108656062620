import { Grid } from '@mui/material'
import { PropsWithChildren } from 'react'

import styles from './Layout.module.scss'

type Props = {
  children: React.ReactNode
}

export function MainContent({ children }: Props) {
  return (
    <MainGrid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </MainGrid>
  )
}

type MainGridProps = {
  spacing?: number
}
export function MainGrid({ children, spacing = 3 }: PropsWithChildren<MainGridProps>) {
  return (
    <Grid container spacing={spacing} className={styles.content}>
      {children}
    </Grid>
  )
}

export function MainColumn({ children }: PropsWithChildren) {
  return <div className={styles.column}>{children}</div>
}
