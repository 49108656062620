import { Region } from '@fingerprintjs/fingerprintjs-pro'
import { Divider, Grid, Link, Paper, Typography } from '@mui/material'
import { CodeSnippet } from 'components'
import { dnsLookupURL, DOCS_SCRIPT_URL_PATTERN_URL } from 'const'
import { CalloutBox } from 'features/apiKeys'
import { IntegrationLinkButton, useWebIntegrationMetadata } from 'features/integrations'
import { formatSnippetObject } from 'helpers/data'
import { ApiKeyType, SSLCertificate } from 'models'
import { ampli } from 'models/ampli'
import { memo, useCallback, useMemo } from 'react'

import { regionCodeToRegion, useLogPageView } from '../../../../helpers/vendor'
import { DNSRecordsTable, DnsRecordType } from '../DNSRecordsTable/DNSRecordsTable'
import { ExportDNSRecordsButton } from '../ExportDNSRecordsButton/ExportDNSRecordsButton'
import styles from './Issued.module.scss'
import StepProps from './types'

export default memo(function IssuedStep({ certificate }: Required<StepProps>) {
  useLogPageView(() => ampli.certificateIssuedPageViewed())
  const albDnsRecords = useMemo(() => getALBDnsRecords(certificate), [certificate])
  const codeSnippetData = getCodeSnippetData(certificate)
  const onCopyFirstARecord = useCallback(() => ampli.aRecordIpOneCopied(), [])
  const onCopySecondARecord = useCallback(() => ampli.aRecordIpTwoCopied(), [])

  const webIntegrations = useWebIntegrationMetadata()

  return (
    <ol className={styles.list}>
      <li style={{ listStyle: 'none' }} className={styles.listItem}>
        <span className={styles.tableItem}>
          <Typography component='span'>
            1. The last step requires setting up these A records in your DNS configuration. Add these two IP addresses
            as A record targets for each subdomain.
          </Typography>

          <ExportDNSRecordsButton records={albDnsRecords} />
        </span>

        <DNSRecordsTable
          className={styles.panelRow}
          records={albDnsRecords.map((record, i) => ({
            ...record,
            onCopy: i % 2 === 0 ? onCopyFirstARecord : onCopySecondARecord,
          }))}
        />
      </li>

      <Divider className={styles.divider} />

      <li className={styles.listItem}>
        <Typography component='span'>
          It may take some time before your changes take effect. You can verify that your subdomain is working by using
          a DNS lookup tool (e.g. using{' '}
          <Link
            href={dnsLookupURL('A', albDnsRecords[0].domainName)}
            target='_blank'
            underline='hover'
            onClick={() => ampli.dnsCheckerLinkClicked()}
          >
            DNS Checker
          </Link>
          ).
        </Typography>
      </li>

      <Divider className={styles.divider} />

      <li className={styles.listItem}>
        <Typography component='span'>
          After your A record is live, configure the JS agent to use it (note the{' '}
          <code className={styles.code}>endpoint</code> and <code className={styles.code}>scriptUrlPattern</code>{' '}
          parameters in the <code className={styles.code}>load</code> method
          {albDnsRecords.length > 2 && ', make sure it matches the domain of the website'}
          ):
        </Typography>
        <Paper className={styles.codeSnippet} data-testid='code-snippet'>
          <EndpointSetupCodeSnippet {...codeSnippetData} />
        </Paper>

        <Grid item className={styles.calloutBox}>
          <CalloutBox type='info' title=''>
            <Typography variant='body1'>
              JS agent automatically substitutes the following substrings in the provided{' '}
              <code className={styles.code}>scriptUrlPattern</code> string:
              <ul>
                <li>
                  <code className={styles.code}>version</code> — the major version of the JS agent;
                </li>
                <li>
                  <code className={styles.code}>apiKey</code> — the public key set via the apiKey parameter
                </li>
                <li>
                  <code className={styles.code}>loaderVersion</code> — the exact version of the
                  @fingerprintjs/fingerprintjs-pro package.
                </li>
              </ul>
              You should not modify them manually.{' '}
              <Link target='_blank' href={DOCS_SCRIPT_URL_PATTERN_URL} underline='hover'>
                View documentation
              </Link>{' '}
              for more information.
            </Typography>
          </CalloutBox>
        </Grid>

        <Grid container spacing={2}>
          {webIntegrations.map((integration) => (
            <Grid item key={integration.title} xs={12} sm={6} lg={3}>
              <IntegrationLinkButton integration={integration} external />
            </Grid>
          ))}
        </Grid>
      </li>
    </ol>
  )
})

const EndpointSetupCodeSnippet = memo(({ apiKey, region, domainName }: FPLoadConfigData) => {
  const loadConfig = getPresentationalLoadConfig({ apiKey, region, domainName })
  const snippet = `FingerprintJS.load(${formatSnippetObject(loadConfig)})`

  return (
    <CodeSnippet onCopy={() => ampli.endpointCodeCopied()} language='javascript'>
      {snippet}
    </CodeSnippet>
  )
})

interface FPLoadConfigData {
  apiKey?: string
  domainName?: string
  region: Region
}

function getCodeSnippetData(certificate: SSLCertificate): FPLoadConfigData {
  let codeSnippetData = {
    domainName: certificate.domainName,
  } as FPLoadConfigData

  if (certificate.subscription && typeof certificate.subscription !== 'string') {
    const subscription = certificate.subscription
    const token = subscription.tokens?.find((t) => t.type === ApiKeyType.Public)?.token
    const region = regionCodeToRegion(subscription.regionCode)
    codeSnippetData = { ...codeSnippetData, apiKey: token, region }
  }

  return codeSnippetData
}

function getALBDnsRecords(certificate: SSLCertificate) {
  const targetBalancer = certificate.targetBalancer

  if (!targetBalancer) {
    return []
  }

  const domains = [certificate.domainName, ...certificate.sans]

  return domains.flatMap((domain) =>
    targetBalancer.staticIps.map((ip) => ({
      domainName: domain,
      dnsValidationName: domain,
      type: DnsRecordType.A,
      dnsValidationValue: ip,
    }))
  )
}

export function getPresentationalLoadConfig({ apiKey, region, domainName }: FPLoadConfigData) {
  const apiKeyArg = apiKey ? `apiKey: "${apiKey}"` : ''
  const regionArg = region === 'us' ? '' : `region: "${region}"`
  const endpointArg = domainName ? `endpoint: "https://${domainName}"` : ''
  const scriptUrlPattern = domainName
    ? `scriptUrlPattern: "https://${domainName}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js"`
    : ''

  const configArray = []
  if (apiKeyArg) {
    configArray.push(apiKeyArg)
  }
  if (regionArg) {
    configArray.push(regionArg)
  }
  if (endpointArg) {
    configArray.push(endpointArg)
  }
  if (scriptUrlPattern) {
    configArray.push(scriptUrlPattern)
  }

  return configArray.length === 0 ? '' : `{${configArray.join(', ')}}`
}
