import { IntegrationData, IntegrationProvider } from 'models'
import { useMemo } from 'react'

import { useCloudflareIntegrationData } from '../../hooks/api/integrations'
import { useCloudFrontIntegrationData } from '../../hooks/api/integrations_cloudfront'
import { useSdkHealthQuery } from '../health/hooks/useSdkHealthQuery'
import {
  IntegrationCategory,
  integrationList,
  IntegrationMetadata,
  ThirdPartyIntegration,
} from './components/IntegrationMetadata/integrationMetadata'
import { useIntegrationStatsQuery } from './hooks/useIntegrationStatsQuery'

export function useIntegrationData(subscriptionId: string) {
  const { data: cloudflareIntegrationData, isLoading: isLoadingCloudflareData } =
    useCloudflareIntegrationData(subscriptionId)

  let integrationData: IntegrationData | undefined
  if (cloudflareIntegrationData) {
    integrationData = {
      provider: IntegrationProvider.Cloudflare,
      ...cloudflareIntegrationData,
    }
  }

  return {
    integrationData,
    isLoading: isLoadingCloudflareData,
  }
}

export function useIntegrationsMetadata(subscriptionId: string) {
  const cloudflareQuery = useCloudflareIntegrationData(subscriptionId)
  const cloudfrontQuery = useCloudFrontIntegrationData(subscriptionId)
  const fastlyStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.FastlyVCL, subscriptionId)
  const cloudFrontStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.CloudFront, subscriptionId)
  const customIntegrationStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.CustomProxy, subscriptionId)

  const { data: sdkHealth } = useSdkHealthQuery(subscriptionId)

  const integrationsMetadata = useMemo(() => {
    const list: IntegrationMetadata[] = integrationList
      .map((integration) => {
        if (integration.integrationTag === ThirdPartyIntegration.Cloudflare && cloudflareQuery.data) {
          return {
            ...integration,
            category: IntegrationCategory.Active,
          }
        }

        const cloudFrontRequestsCount =
          cloudFrontStatsQuery.data?.proxyRequests.map((it) => it.value).reduce((sum, curr) => (sum += curr), 0) || 0
        const cloudFrontInstalled =
          sdkHealth?.platform.find((t) => t.sdkName === 'CloudFront') ||
          cloudfrontQuery.data?.isEnabled ||
          cloudFrontRequestsCount > 0

        if (integration.integrationTag === ThirdPartyIntegration.CloudFront && cloudFrontInstalled) {
          return {
            ...integration,
            category: IntegrationCategory.Active,
          }
        }

        const fastlyRequestsCount =
          fastlyStatsQuery.data?.proxyRequests.map((it) => it.value).reduce((sum, curr) => (sum += curr), 0) || 0
        const fastlyInstalled = sdkHealth?.platform.find((t) => t.sdkName === 'FastlyVCL') || fastlyRequestsCount > 0

        if (integration.integrationTag === ThirdPartyIntegration.FastlyVCL && fastlyInstalled) {
          return {
            ...integration,
            category: IntegrationCategory.Active,
          }
        }

        const customIntegrationRequestCount =
          customIntegrationStatsQuery.data?.proxyRequests
            .map((it) => it.value)
            .reduce((sum, curr) => (sum += curr), 0) || 0
        if (integration.integrationTag === ThirdPartyIntegration.CustomProxy && customIntegrationRequestCount > 0) {
          return {
            ...integration,
            category: IntegrationCategory.Active,
          }
        }

        return integration
      })
      .filter((integration) => integration.category !== IntegrationCategory.CustomIntegrations)

    return list
  }, [
    cloudflareQuery.data,
    cloudfrontQuery.data,
    sdkHealth,
    fastlyStatsQuery.data,
    cloudFrontStatsQuery.data,
    customIntegrationStatsQuery.data,
  ])

  return {
    integrationsMetadata,
  }
}

export function useIntegrationByTag(integrationTag: string) {
  return integrationList.find((it) => it.integrationTag === integrationTag)
}

export function useWebIntegrationMetadata() {
  return useMemo(() => integrationList.filter((it) => it.category === IntegrationCategory.WebLibraries), [])
}

export function useMobileIntegrationMetadata() {
  return useMemo(() => integrationList.filter((it) => it.category === IntegrationCategory.MobileLibraries), [])
}

export function useServerIntegrationMetadata() {
  return useMemo(() => integrationList.filter((it) => it.category === IntegrationCategory.ServerLibraries), [])
}

export function usePlatformProxyIntegrationMetadata() {
  return useMemo(
    () =>
      integrationList.filter(
        (it) =>
          it.integrationTag === ThirdPartyIntegration.Cloudflare ||
          it.integrationTag === ThirdPartyIntegration.Azure ||
          it.integrationTag === ThirdPartyIntegration.CloudFront
      ),
    []
  )
}
