import { Tab, Tabs } from '@mui/material'
import { useCurrentSubscription } from 'features/subscription'
import { useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { USE_SUSPECT_SCORE_PAGE } from '../../../../const'
import styles from './TabNavigation.module.scss'

interface Props {
  items: {
    label: string
    to: string
    testid: string
  }[]
  value?: string
}

export function TabNavigation({ items, value }: Props) {
  const { pathname } = useLocation()
  const { currentSubscriptionId } = useCurrentSubscription()

  const substitute = useCallback(
    (path: string) => path.replace(':subscriptionId', currentSubscriptionId),
    [currentSubscriptionId]
  )

  const selectedRoute = useMemo(() => {
    if (value != null) {
      return value
    }

    const item = items.find((i) => pathname.startsWith(substitute(i.to)))
    if (item != null) {
      return substitute(item.to)
    }

    return false
  }, [items, pathname, substitute, value])

  return (
    <div className={styles.wrapper}>
      <Tabs
        value={selectedRoute}
        variant='scrollable'
        scrollButtons={'auto'}
        allowScrollButtonsMobile={true}
        classes={{
          root: styles.tabs,
          scrollButtons: styles.scrollButtons,
        }}
      >
        {items.map((item) => {
          const route = substitute(item.to)

          return (
            <Tab
              classes={{ root: styles.tab, selected: styles.tabSelected }}
              component={Link}
              data-testid={item.testid}
              key={item.label}
              label={item.label}
              to={route}
              value={route}
              disableRipple
            />
          )
        })}
      </Tabs>
    </div>
  )
}

// Presets
export const appSettingNavItems = [
  { label: 'General', to: '/subscriptions/:subscriptionId/general', testid: 'tabnav-general' },
  { label: 'Plan', to: '/subscriptions/:subscriptionId/plan', testid: 'tabnav-plan' },
  { label: 'Health', to: '/subscriptions/:subscriptionId/health', testid: 'tabnav-health' },
  { label: 'Subdomains', to: '/subscriptions/:subscriptionId/custom-domain', testid: 'tabnav-subdomains' },
  { label: 'Webhooks', to: '/subscriptions/:subscriptionId/webhooks', testid: 'tabnav-webhooks' },
  { label: 'API Keys', to: '/subscriptions/:subscriptionId/api-keys', testid: 'tabnav-apikeys' },
  { label: 'Request Filtering', to: '/subscriptions/:subscriptionId/traffic-rules', testid: 'tabnav-requestFiltering' },
  { label: 'Integrations', to: '/subscriptions/:subscriptionId/integrations', testid: 'tabnav-integrations' },
]

if (USE_SUSPECT_SCORE_PAGE) {
  appSettingNavItems.splice(7, 0, {
    label: 'Suspect Score',
    to: '/subscriptions/:subscriptionId/suspect-score',
    testid: 'tabnav-smartSignals',
  })
}

// FIXME: Solve import order to use route constants here
export const accountSettingsNavItems = [
  { label: 'Applications', to: '/settings/applications', testid: 'tabnav-applications' },
  { label: 'Users', to: '/settings/users', testid: 'tabnav-users' },
  { label: 'Billing', to: '/settings/billing', testid: 'tabnav-billing' },
  { label: 'My Profile', to: '/profile/settings', testid: 'tabnav-myprofile' },
  { label: 'Danger Zone', to: '/settings/danger_zone', testid: 'tabnav-dangerzone' },
]
