import { InfoOutlined, SentimentNeutralOutlined } from '@mui/icons-material'
import { Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './DataCard.module.scss'

type Props = PropsWithChildren<{
  title?: string
  info?: ReactNode
  action?: ReactNode
  subtitle?: ReactNode
  hasError?: boolean
}>

export function DataCard({ title, info, action, subtitle, children, hasError }: Props) {
  if (hasError) {
    return (
      <Paper className={clsx(styles.card, styles.error)}>
        <SentimentNeutralOutlined color='gray' fontSize='16' />
        <Typography variant='bodyS'>There was an error displaying the data.</Typography>
      </Paper>
    )
  }

  return (
    <Paper className={styles.card}>
      <div className={styles.header}>
        <div className={styles.titleRow}>
          {title ? <Typography variant='bodyMMedium'>{title}</Typography> : <Skeleton width={80} height={16} />}
          {info ? (
            <Tooltip
              title={
                <Typography variant='bodyS' color='white'>
                  {info}
                </Typography>
              }
            >
              <InfoOutlined fontSize='14' color='secondary-text' />
            </Tooltip>
          ) : null}
          {action ? <div className={styles.actionContainer}>{action}</div> : null}
        </div>
        {subtitle ?? null}
      </div>
      {children ?? <Skeleton width={128} height={28} />}
    </Paper>
  )
}
