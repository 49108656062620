import { accountSettingsNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useLogPageView } from 'helpers/vendor'
import { useDocumentTitle, useSubscriptions } from 'hooks'
import { ampli } from 'models/ampli'

import ApplicationsOverview from './components/ApplicationsOverview/ApplicationsOverview'

export function AccountApplicationsPage() {
  useDocumentTitle('Applications')
  useLogPageView(() => ampli.applicationsViewed())

  const { data: subscriptions, isError, isLoading } = useSubscriptions()

  return (
    <>
      <Header title='Account Settings' />
      <TabNavigation items={accountSettingsNavItems} />

      <MainContent>
        <ApplicationsOverview subscriptions={subscriptions} isError={isError} isLoading={isLoading} />
      </MainContent>
    </>
  )
}
