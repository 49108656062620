declare global {
  interface Window {
    Cypress: {
      env: (name: string) => string
    }
    playwright: Record<string, string>
  }
}

export const globalExperimentsDisable =
  process.env.GLOBAL_EXPERIMENTS_DISABLE === 'true' ||
  (typeof window !== 'undefined' && window.playwright && window.playwright.GLOBAL_EXPERIMENTS_DISABLE === 'true') ||
  (typeof window !== 'undefined' && window.Cypress && window.Cypress.env('GLOBAL_EXPERIMENTS_DISABLE') === 'true')
