import { Button } from '@mui/material'
import CreatePasswordInput from 'components/CreatePasswordInput/CreatePasswordInput'
import { APITypesMap } from 'const'
import { useIsFirstRender } from 'hooks/firstRender'
import { FormProvider, useForm } from 'react-hook-form'

import { AuthForm } from '../AuthForm/AuthForm'
import styles from '../AuthForm/AuthForm.module.scss'
import { AuthWrapper } from '../AuthWrapper/AuthWrapper'
import { Props } from '../types'

export type FormData = APITypesMap['invitationConfirm']['body']

export function InvitationConfirmForm({ onSubmit, error, isLoading }: Props<FormData>) {
  const formMethods = useForm<FormData>({ mode: 'onChange' })
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods
  const isFirstRender = useIsFirstRender()

  return (
    <AuthWrapper
      isLoading={isLoading}
      title='Set up your profile'
      subtitle='Please enter a password to continue with profile setup. Then, you can access your team’s account.'
      showDisclaimer
    >
      <AuthForm onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <CreatePasswordInput error={error} autoFocus={!isLoading} />
          <Button
            disabled={isFirstRender || !isValid || isLoading}
            className={styles.button}
            title='Continue'
            variant='contained'
            size='large'
            color='primary'
            type='submit'
          >
            Continue
          </Button>
        </FormProvider>
      </AuthForm>
    </AuthWrapper>
  )
}
