import { ExpandLess, ExpandMore, Refresh, RemoveCircle } from '@mui/icons-material'
import { Button, Collapse, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import CodeViewer from 'components/CodeViewer/CodeViewer'
import KeyValueList from 'components/KeyValueList/KeyValueList'
import HttpStatusTag from 'components/Tag/HttpStatusTag/HttpStatusTag'
import Tag from 'components/Tag/Tag'
import { HTTP_STATUS } from 'const/HttpStatus'
import { formatDatetime } from 'features/commonUI'
import { formatJSON } from 'helpers/format'
import { WebhookEvent } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './EventDetails.module.scss'

export interface EventDetailsProps {
  didResendRecently?: boolean
  onResend: (requestId: string) => void
}

export default function EventDetails({
  subscriptionID,
  endpointID,
  verification,
  requestID,
  requestBody,
  responseBody,
  visitorID,
  responseStatusCode,
  timestamp,
  attempts,
  responseHeaders,
  didResendRecently,
  onResend,
}: WebhookEvent & EventDetailsProps) {
  const hasResponse = !!responseStatusCode
  const [areHeadersExpanded, setAreHeadersExpanded] = useState(false)
  const request = requestBody ? JSON.parse(requestBody) : {}
  const succeeded = responseStatusCode === 200

  const onCopyHeader = useCallback(() => {
    ampli.webhookRequestHeadersCopied()
  }, [])

  const onCopyBody = useCallback(() => {
    ampli.webhookRequestBodyCopied()
  }, [])

  const onCopyRequest = useCallback(() => {
    ampli.webhookRequestCopied()
  }, [])

  const visitInfo = [
    {
      key: 'Date',
      value: formatDatetime(new Date(timestamp), 'precise'),
    },
    {
      key: 'visitorId',
      value: visitorID ?? '-',
      monospace: true,
    },
    {
      key: 'linkedId',
      value: request.linkedId ?? '-',
      monospace: true,
    },
  ]

  const eventInfo = [
    {
      key: 'Request attempts',
      value: attempts,
    },
    {
      key: 'HTTP response code',
      value: responseStatusCode,
    },
  ]

  return (
    <div className={styles.eventDetails}>
      <header className={clsx(styles.header, styles.row)}>
        <Typography variant='semiBody1' component='h1'>
          Event info
        </Typography>
      </header>

      <section className={styles.infoContainer}>
        <div className={styles.info}>
          <KeyValueList items={visitInfo} className={styles.keyValues} />
          <KeyValueList items={eventInfo} className={styles.keyValues} />
        </div>

        {!succeeded &&
          attempts >= 2 &&
          (verification ? (
            <Button
              component={RouterLink}
              to={buildRoute(AppRoute.WebhookVerification, { subscriptionId: subscriptionID, webhookId: endpointID })}
              variant='outlined'
              color='inherit'
              startIcon={<Refresh />}
              className={styles.resendButton}
            >
              Retry verification
            </Button>
          ) : (
            <Button
              variant='outlined'
              color='inherit'
              startIcon={<Refresh />}
              onClick={() => onResend(requestID)}
              disabled={didResendRecently}
              className={styles.resendButton}
            >
              Resend event
            </Button>
          ))}
      </section>

      {hasResponse ? (
        <>
          <div className={clsx(styles.responseHeader, styles.row)}>
            <Typography variant='semiBody1' component='h1'>
              Response
            </Typography>
            <HttpStatusTag status={responseStatusCode as keyof typeof HTTP_STATUS} className={styles.tag} />
          </div>

          {!verification && responseHeaders && (
            <>
              <span className={styles.headersToggle} onClick={() => setAreHeadersExpanded(!areHeadersExpanded)}>
                <Typography className={clsx(styles.subtitle, { [styles.headersOnly]: !responseBody })}>
                  Headers
                </Typography>
                {areHeadersExpanded ? (
                  <ExpandLess color='inherit' fontSize='medium' className={styles.toggleIcon} />
                ) : (
                  <ExpandMore color='inherit' fontSize='medium' className={styles.toggleIcon} />
                )}
              </span>
              <Collapse in={areHeadersExpanded}>
                <CodeViewer
                  code={responseHeaders.replace(/\n$/, '')}
                  language='text'
                  className={styles.viewer}
                  onCopy={onCopyHeader}
                />
              </Collapse>
            </>
          )}

          {responseBody && (
            <>
              <Typography className={styles.subtitle}>Body</Typography>
              <CodeViewer
                code={getFormattedResponse(responseBody)}
                showLineNumbers
                skeletonPrefix='{'
                skeletonIndent={15}
                className={styles.viewer}
                onCopy={onCopyBody}
              />
            </>
          )}
        </>
      ) : (
        <div className={clsx(styles.responseHeader, styles.row)}>
          <Typography variant='semiBody1' component='h1'>
            Response
          </Typography>
          <Tag color='darkGray' label='No response' icon={<RemoveCircle />} compact />
        </div>
      )}

      {!verification && (
        <>
          <Typography className={clsx(styles.title, styles.row)}>Request</Typography>
          <CodeViewer
            code={formatJSON(request)}
            showLineNumbers
            skeletonPrefix='{'
            skeletonIndent={15}
            className={styles.viewer}
            onCopy={onCopyRequest}
          />
        </>
      )}
    </div>
  )
}

function getFormattedResponse(response: string) {
  try {
    return formatJSON(JSON.parse(response))
  } catch (_) {
    return response
  }
}
