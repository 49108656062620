import { useCurrentSubscription } from 'features/subscription'
import { useLogPageView } from 'helpers/vendor'
import { useDocumentTitle, useToast } from 'hooks'
import { useVisitsInfiniteQuery } from 'hooks/visits'
import { VisitsFilter } from 'models'
import { ampli } from 'models/ampli'
import { useForm } from 'react-hook-form'

import { VisitHistory } from '../../components/VisitHistory/VisitHistory'
import { useSubmittedFilteredFormValues } from '../../hooks/useSubmittedFilteredFormValues'
import { defaultValues } from '../../utils/visitorFormUtils'

export function IdentificationVisitsPage() {
  useDocumentTitle('Identification Events')
  useLogPageView(() => ampli.identificationEventListViewed())

  const { showToast } = useToast()

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const form = useForm<VisitsFilter>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  const filters = useSubmittedFilteredFormValues(form)
  const mappedFilters = translateFilters(filters)
  const query = useVisitsInfiniteQuery(
    {
      subscriptionId,
      ...mappedFilters,
    },
    {
      refetchOnWindowFocus: false,
      onError: () =>
        showToast({
          message: 'An error occurred when displaying events. Please try again.',
          severity: 'error',
        }),
    }
  )

  return <VisitHistory query={query} form={form} />
}

// Add the CIDR notation if it's missing.
function translateIpAddressFilter(ipAddress?: string) {
  if (ipAddress === undefined) {
    return {}
  }

  if (ipAddress === '' || ipAddress.includes('/')) {
    return { ipAddress }
  }

  const isIpV4 = ipAddress.includes('.')
  const postFix = isIpV4 ? 32 : 128

  return {
    ipAddress: `${ipAddress}/${postFix}`,
  }
}

export function translateFilters(values: VisitsFilter): VisitsFilter {
  return {
    ...values,
    ...translateIpAddressFilter(values.ipAddress),
  }
}
