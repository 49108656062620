import { regionCodeToRegion } from 'helpers/vendor'
import {
  ApiKey,
  ApiKeyType,
  CloudflareIntegrationData,
  DeploymentStatus,
  IntegrationData,
  IntegrationProvider,
  RegionCode,
  SSLCertificate,
  SSLCertificateStatus,
  Subscription,
} from 'models'

type LoadOptionProps = {
  indentationUnit?: string
  indentationSize?: number
  skipPublicApiKey?: boolean
  skipScriptUrlPattern?: boolean
  quotationMark?: string
  useFingerprintJSImportPrefix?: boolean
}

type Region = 'us' | 'eu' | 'ap'

export interface IntegrationReadmeProps {
  integrationTag?: string
  integrationVariantTag?: string
  activeSubscriptionId?: string
  publicApiKey?: string
  secretApiKey?: string
  region?: string
  endpoint?: string
  scriptUrlPattern?: string
  cdnPath?: string
  indentedLoadOptions?: (props: LoadOptionProps) => string
}

const DEFAULT_INDENTATION = '  '
const DEFAULT_REGION = 'us'
export const WILDCARD_SUBDOMAIN_HINT_TEXT = '// TODO: Replace the asterisk with the actual subdomain you use.'
export const FLUTTER_WEB_HINT_TEXT = '// Only necessary for the web platform'

export function createIndentedLoadOptionsBuilder(args: IntegrationReadmeProps) {
  return function indentedLoadOptions({
    indentationUnit = DEFAULT_INDENTATION,
    indentationSize = 0,
    skipPublicApiKey = false,
    skipScriptUrlPattern = false,
    quotationMark = '"',
    useFingerprintJSImportPrefix = false,
  }: LoadOptionProps) {
    const loadOptions = getLoadOptions(args, skipPublicApiKey, skipScriptUrlPattern)

    if (Object.keys(loadOptions).length === 0) {
      return ''
    }

    const indent = indentationUnit.repeat(indentationSize + 1)
    const indentPlus = indentationUnit.repeat(indentationSize + 2)

    // The user might have used `*.example.com` in their custom subdomain setup
    const wildcardSubdomainNote =
      loadOptions.scriptUrlPattern?.startsWith('https://*') || loadOptions.endpoint?.startsWith('https://*')
        ? `${indentPlus}${WILDCARD_SUBDOMAIN_HINT_TEXT}\n`
        : ''

    return (
      '{\n' +
      Object.keys(loadOptions)
        .map((key: keyof LoadOptions) => {
          if (key === 'endpoint') {
            return `${indent}endpoint: [\n${wildcardSubdomainNote}${indentPlus}${quotationMark}${
              loadOptions.endpoint
            }${quotationMark},\n${indentPlus}${
              useFingerprintJSImportPrefix ? 'FingerprintJS.defaultEndpoint' : 'FingerprintJSPro.defaultEndpoint'
            }\n${indent}]`
          }
          if (key === 'scriptUrlPattern') {
            return `${indent}scriptUrlPattern: [\n${wildcardSubdomainNote}${indentPlus}${quotationMark}${
              loadOptions.scriptUrlPattern
            }${quotationMark},\n${indentPlus}${
              useFingerprintJSImportPrefix
                ? 'FingerprintJS.defaultScriptUrlPattern'
                : 'FingerprintJSPro.defaultScriptUrlPattern'
            }\n${indent}]`
          }
          return `${indent}${key}: ${quotationMark}${loadOptions[key]}${quotationMark}`
        })
        .join(',\n') +
      '\n' +
      indentationUnit.repeat(indentationSize) +
      '}'
    )
  }
}

type LoadOptions = {
  apiKey?: string
  endpoint?: string
  scriptUrlPattern?: string
  region?: Region
}

function getLoadOptions(
  integrationProps: IntegrationReadmeProps,
  skipPublicApiKey = false,
  skipScriptUrlPattern = false
): LoadOptions {
  const loadOptions: LoadOptions = {}

  if (!skipPublicApiKey) {
    loadOptions.apiKey = integrationProps.publicApiKey
  }

  if (integrationProps.endpoint) {
    loadOptions.endpoint = integrationProps.endpoint
  }

  if (!skipScriptUrlPattern && integrationProps.scriptUrlPattern) {
    loadOptions.scriptUrlPattern = integrationProps.scriptUrlPattern
  }

  if (integrationProps.region !== DEFAULT_REGION) {
    loadOptions.region = integrationProps.region as Region
  }

  return loadOptions
}

export function getIntegrationReadmeProps(
  integrationTag?: string,
  activeSubscription?: Subscription,
  tokens?: ApiKey[],
  certificates?: SSLCertificate[],
  integrationData?: IntegrationData,
  secretToken?: ApiKey
): IntegrationReadmeProps {
  const noApiKeyMessage = 'API key not found. Create one in App Settings -> API Keys'

  const publicApiKey = tokens?.find((token) => token.type === ApiKeyType.Public)?.token ?? noApiKeyMessage
  const secretApiKey = secretToken?.token ?? '<SECRET_API_KEY>'
  const region = regionCodeToRegion(activeSubscription?.regionCode ?? RegionCode.Use1)

  const integrationReadmeProps: IntegrationReadmeProps = {
    integrationTag: integrationTag,
    activeSubscriptionId: activeSubscription?.id,
    publicApiKey,
    secretApiKey,
    region,
    cdnPath: `https://fpjscdn.net/v3/${publicApiKey}`,
  }

  const customDomainName = certificates?.find((it) => it.status === SSLCertificateStatus.Issued)?.domainName
  if (customDomainName) {
    integrationReadmeProps.endpoint = `https://${customDomainName}`
    integrationReadmeProps.cdnPath = `https://${customDomainName}/web/v3/${publicApiKey}`
    integrationReadmeProps.scriptUrlPattern = `https://${customDomainName}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
  }

  switch (integrationData?.provider) {
    case IntegrationProvider.Cloudflare: {
      const { domain, workerPath, agentDownloadPath, ingressApiPath, lastDeploymentStatus } =
        integrationData as CloudflareIntegrationData

      // TODO: reconsider this behavior once we decide how to do CF integration management and CF integration lifecycle
      if (lastDeploymentStatus !== DeploymentStatus.Error) {
        integrationReadmeProps.cdnPath = `https://${domain}/${workerPath}/${agentDownloadPath}?apiKey=${publicApiKey}`
        integrationReadmeProps.scriptUrlPattern = `https://${domain}/${workerPath}/${agentDownloadPath}?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`
        integrationReadmeProps.endpoint = `https://${domain}/${workerPath}/${ingressApiPath}${
          region === 'us' ? '' : `?region=${region}`
        }`
      }

      break
    }
  }

  return {
    ...integrationReadmeProps,
    indentedLoadOptions: createIndentedLoadOptionsBuilder(integrationReadmeProps),
  }
}

function getCodeReplacementMapFromProps(props: IntegrationReadmeProps) {
  const getAndroidLoadOptions =
    (language: 'Java' | 'Kotlin') =>
    (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const loadOptions = getLoadOptions(props) as Record<string, string>
      // scriptUrlPattern is not needed/supported in Android
      delete loadOptions.scriptUrlPattern
      // rename endpoint to endpointUrl
      if (loadOptions.endpoint) {
        loadOptions.endpointUrl = loadOptions.endpoint
        delete loadOptions.endpoint
      }
      // Rename region to SDK enum
      if (loadOptions.region) {
        const regionMap: Record<Region, string> = {
          us: 'Configuration.Region.US',
          eu: 'Configuration.Region.EU',
          ap: 'Configuration.Region.AP',
        }
        loadOptions.region = regionMap[(loadOptions.region as Region) ?? 'us']
      }

      if (Object.keys(loadOptions).length === 0) {
        return ''
      }

      const indentation = indentationUnit.repeat(indentationSize).slice(0, -1)
      return Object.keys(loadOptions)
        .map((key: keyof LoadOptions | 'endpointUrl') => {
          const quote = key === 'region' ? '' : '"' // region is an enum, so no quotes
          const wildcardSubdomainNote =
            loadOptions.endpointUrl?.startsWith('https://*') && key === 'endpointUrl'
              ? `${WILDCARD_SUBDOMAIN_HINT_TEXT}\n${indentation} `
              : ''
          const keyPrefix = language === 'Kotlin' ? `${key} = ` : '' // Java does not have named parameters
          return `${wildcardSubdomainNote}${keyPrefix}${quote}${loadOptions[key]}${quote}`
        })
        .join(`,\n${indentation} `)
    }

  const getDefaultEndpointsImport = () => {
    if (props.endpoint || props.scriptUrlPattern) {
      return ['FingerprintJSPro']
    }
    return []
  }

  return {
    PUBLIC_API_KEY: () => props.publicApiKey,
    SECRET_API_KEY: () => props.secretApiKey,
    REGION: () => props.region,
    CDN_PATH: () => props.cdnPath,
    LOAD_OPTIONS: (indentationUnit?: string, indentationSize?: number) =>
      props.indentedLoadOptions?.({ indentationUnit, indentationSize }),
    PRO_NPM_LOAD_OPTIONS: (indentationUnit?: string, indentationSize?: number) =>
      props.indentedLoadOptions?.({ indentationUnit, indentationSize, useFingerprintJSImportPrefix: true }),
    CDN_LOAD_OPTIONS: (indentationUnit?: string, indentationSize?: number) =>
      props.indentedLoadOptions?.({
        indentationUnit,
        indentationSize,
        skipPublicApiKey: true,
        skipScriptUrlPattern: true,
        useFingerprintJSImportPrefix: true,
      }),
    SDK_IMPORTED_ENTITIES: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const indentation = indentationUnit.repeat(indentationSize)
      const imports = getDefaultEndpointsImport()
      if (imports.length === 0) {
        return ''
      } else {
        return `,\n${indentation}` + imports.join(`,\n${indentation}`)
      }
    },

    // Integration specific code replacements
    REGION_GO_SDK: () => {
      if (props.region === 'eu') {
        return 'cfg.ChangeRegion(sdk.RegionEU)\n'
      }
      if (props.region === 'ap') {
        return 'cfg.ChangeRegion(sdk.RegionAsia)\n'
      }
      return ''
    },
    REGION_PHP_SDK: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const indentation = indentationUnit.repeat(indentationSize)
      if (props.region === 'eu') {
        return `,\n${indentation}Configuration::REGION_EUROPE`
      }
      if (props.region === 'ap') {
        return `,\n${indentation}Configuration::REGION_ASIA`
      }
      return ''
    },
    REGION_NODE_SDK: () => {
      switch (props.region) {
        case 'eu':
          return 'Region.EU'
        case 'ap':
          return 'Region.AP'
        default:
          return 'Region.Global'
      }
    },
    REGION_DOTNET_SDK: () => {
      if (props.region === 'eu') {
        return 'configuration.Region = Region.Eu;\n'
      }
      if (props.region === 'ap') {
        return 'configuration.Region = Region.Asia;\n'
      }
      return ''
    },
    REGION_JAVA_SDK: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const indentation = indentationUnit.repeat(indentationSize)
      if (props.region === 'eu') {
        return `,\n${indentation}Region.EUROPE`
      }
      if (props.region === 'ap') {
        return `,\n${indentation}Region.ASIA`
      }
      return ''
    },
    REGION_IOS_SDK: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const indentation = indentationUnit.repeat(indentationSize)
      if (props.endpoint) {
        const wildcardSubdomainNote = props.endpoint?.startsWith('https://*')
          ? `\n${indentation}${WILDCARD_SUBDOMAIN_HINT_TEXT}`
          : ''
        // iOS SDK merges endpoint and region into one property
        // by `domain` we actually mean `endpoint` here
        return `,${wildcardSubdomainNote}\n${indentation}region: Region.custom(domain: "${props.endpoint}")`
      }
      if (props.region === 'eu') {
        return `,\n${indentation}region: Region.eu`
      }
      if (props.region === 'ap') {
        return `,\n${indentation}region: Region.ap`
      }
      return ''
    },
    LOAD_OPTIONS_PROPS_REACT_NATIVE: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const loadOptions = getLoadOptions(props) as Record<string, any>
      // scriptUrlPattern is not needed/supported in React Native
      delete loadOptions.scriptUrlPattern
      // rename endpoint to endpointUrl
      if (loadOptions.endpoint) {
        loadOptions.endpointUrl = loadOptions.endpoint
        delete loadOptions.endpoint
      }

      if (Object.keys(loadOptions).length === 0) {
        return ''
      }
      const indentation = indentationUnit.repeat(indentationSize)
      return Object.keys(loadOptions)
        .map((key: keyof LoadOptions | 'endpointUrl') => {
          const wildcardSubdomainNote =
            props.endpoint?.startsWith('https://*') && key === 'endpointUrl'
              ? `${WILDCARD_SUBDOMAIN_HINT_TEXT}\n${indentation}`
              : ''
          return `${wildcardSubdomainNote}${key}={'${loadOptions[key]}'}`
        })
        .join(`\n${indentation}`)
    },
    LOAD_OPTIONS_FLUTTER_SDK: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      const loadOptions = getLoadOptions(props, true) as Record<string, string>

      // Rename region to SDK enum
      if (loadOptions.region) {
        const regionMap: Record<Region, string> = {
          us: 'Region.us',
          eu: 'Region.eu',
          ap: 'Region.ap',
        }
        loadOptions.region = regionMap[(loadOptions.region as Region) ?? 'us']
      }

      if (Object.keys(loadOptions).length === 0) {
        return ''
      }
      const indentation = indentationUnit.repeat(indentationSize)

      return (
        `,\n${indentation}` +
        Object.keys(loadOptions)
          .map((key: keyof LoadOptions) => {
            const wildcardSubdomainNote =
              loadOptions.endpoint?.startsWith('https://*') && key === 'endpoint'
                ? `${WILDCARD_SUBDOMAIN_HINT_TEXT}\n${indentation}`
                : ''

            const scriptUrlPatternNote = key === 'scriptUrlPattern' ? `${FLUTTER_WEB_HINT_TEXT}\n${indentation}` : ''

            const quote = key === 'region' ? '' : '"' // region is an enum, so no quotes
            return `${wildcardSubdomainNote}${scriptUrlPatternNote}${key}: ${quote}${loadOptions[key]}${quote}`
          })
          .join(`,\n${indentation}`)
      )
    },
    LOAD_OPTIONS_ANDROID_SDK_KOTLIN: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      return getAndroidLoadOptions('Kotlin')(indentationUnit, indentationSize)
    },
    LOAD_OPTIONS_ANDROID_SDK_JAVA: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      return getAndroidLoadOptions('Java')(indentationUnit, indentationSize)
    },
    WILDCARD_SUBDOMAIN_NOTE: (indentationUnit = DEFAULT_INDENTATION, indentationSize: number = 0) => {
      if (props.cdnPath?.startsWith('https://*')) {
        const indentation = indentationUnit.repeat(indentationSize)
        return `${WILDCARD_SUBDOMAIN_HINT_TEXT}\n${indentation}`
      } else {
        return ''
      }
    },
  } as Record<string, Function>
}

export function normalizeCodeAndReplacements(code: string, props: IntegrationReadmeProps) {
  const codeReplacementMap = getCodeReplacementMapFromProps(props)

  const lines = code.split('\n')
  let indentationUnit: string | undefined, indentationPadding: string | undefined

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i]
    let indentation = line.match(/^\s+/)?.pop() || ''

    // The code block itself can be indented, so we want to remove this padding from the final code snippet
    if (i === 0) {
      indentationPadding = indentation
    }

    if (indentationPadding) {
      line = line.slice(indentationPadding.length)
      indentation = indentation.slice(indentationPadding.length)
    }

    // First line with indentation defines a single indentation unit (e.g. two spaces or a tab)
    if (indentation.length > 0 && !indentationUnit) {
      indentationUnit = indentation
    }

    const indentationSize = indentationUnit ? indentation.length / indentationUnit.length : 0

    // eslint-disable-next-line no-loop-func
    line = line.replace(/%%([A-Z_]+)%%/g, (_, keyword) =>
      codeReplacementMap[keyword]?.(indentationUnit, indentationSize)
    )

    lines[i] = line
  }

  return lines.join('\n').trim()
}
