import { Typography } from '@mui/material'
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line'
import { customTickWrapper, customTooltipWrapper } from 'helpers/chart'
import { formatNumShort } from 'helpers/format'
import { BotdCounterType, ClientCounterType, UsageChartData, UsageCounterPeriod } from 'models'
import { useMemo } from 'react'

import styles from './BotdChart.module.scss'

export interface BotdChartProps {
  counters: UsageChartData
  granularity: UsageCounterPeriod
  timezone?: string
}

export default function BotdChart({ counters, granularity, timezone }: BotdChartProps) {
  const { lines, colors, isContinuous } = useMemo(() => countersToLines(counters), [counters])
  const gridYLineCount = 6

  return (
    <div className={styles.root}>
      <Typography component='h2'>Trending</Typography>

      <div className={styles.chart}>
        <ResponsiveLine
          data={lines}
          colors={colors}
          margin={{ top: 20, right: 8, bottom: 50, left: 40 }}
          animate={true}
          enablePoints={!isContinuous}
          pointSize={8}
          enableGridX={false}
          enableSlices='x'
          sliceTooltip={customTooltipWrapper(
            granularity,
            ({ slice }: SliceTooltipProps) => {
              const timestamp = slice.points[0]?.data?.x as string
              const points = slice.points.map(({ serieId, data }) => ({
                counterType: serieId as ClientCounterType,
                value: +data.y,
              }))

              return { timestamp, points }
            },
            timezone
          )}
          gridYValues={gridYLineCount}
          yScale={{
            type: 'linear',
          }}
          axisLeft={{
            tickValues: gridYLineCount,
            tickSize: 0,
            format: (n: number) => formatNumShort(n, n > 1000 ? 1 : 0),
          }}
          axisBottom={{
            renderTick: customTickWrapper(granularity, timezone) as <T>(data: T) => JSX.Element,
          }}
        />
      </div>
    </div>
  )
}

function countersToLines(counters: UsageChartData) {
  let maxValue = 0
  let minValue = Infinity
  let isContinuous = true

  const keys = Object.keys(counters).filter((key) =>
    [BotdCounterType.GoodBots, BotdCounterType.BadBots, BotdCounterType.Humans].includes(key as BotdCounterType)
  )

  const colors = keys.map(getBotdCounterColor)
  const lines = keys.map((type: keyof UsageChartData) => ({
    id: type,
    data:
      counters[type]?.map(({ timestamp, value }) => {
        if (value > maxValue) {
          maxValue = value
        } else if (value < minValue) {
          minValue = value
        }

        // null shows up as a hole in the data, rather than a point at 0, which is what we want.
        // https://nivo.rocks/storybook/?path=/story/line--holes-in-data
        let displayValue: number | null = value
        if (displayValue === 0) {
          displayValue = null
          isContinuous = false
        }

        return {
          x: timestamp,
          y: displayValue,
        }
      }) ?? [],
  }))

  return { lines, minValue, maxValue, colors, isContinuous }
}

function getBotdCounterColor(type: ClientCounterType) {
  switch (type) {
    case BotdCounterType.GoodBots:
      return '#4CAF50'
    case BotdCounterType.BadBots:
      return '#F44336'
    case BotdCounterType.Humans:
      return '#99999966'
    default:
      return '#000000'
  }
}
