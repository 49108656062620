import { Box, Button, Typography } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './GenericEmptyState.module.scss'

type GenericEmptyStateProps = {
  icon?: ReactNode
  title?: ReactNode
  description?: ReactNode
  buttonProps?: ComponentProps<typeof Button>
  buttonTo?: string
}

export function GenericEmptyState({ icon, title, description, buttonProps, buttonTo }: GenericEmptyStateProps) {
  return (
    <Box className={styles.emptyState}>
      {icon ? <Box className={styles.emptyStateIcon}>{icon}</Box> : null}
      {title || description ? (
        <Box className={styles.emptyStateTextContent}>
          {title ? <Typography variant='semiBody1'>{title}</Typography> : null}
          {description ? (
            <Typography variant='bodyM' textAlign='center'>
              {description}
            </Typography>
          ) : null}
        </Box>
      ) : null}
      {buttonProps ? (
        <Button
          {...buttonProps}
          component={buttonTo ? RouterLink : 'button'}
          to={buttonTo}
          className={clsx(styles.button, buttonProps.className)}
          variant={buttonProps.variant ?? 'contained'}
          color={buttonProps.color ?? 'primary'}
        >
          {buttonProps.children}
        </Button>
      ) : null}
    </Box>
  )
}
