import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  Paper,
  Radio,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import Stepper from 'components/Stepper/Stepper'
import { formatDate, TextFieldWithCounter } from 'features/commonUI'
import { Preview } from 'features/commonUI'
import { MessageData } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { ChangeEvent, useEffect, useState } from 'react'

import { AppRoute, buildRoute } from '../../../../appRoutes'
import { SUPPORT_PAGE_URL } from '../../../../const'
import { date } from '../../../../helpers/data'
import { getDateInUserTZ } from '../../../../helpers/date'
import { formatPriceInDollars } from '../../../../helpers/format'
import { useSubscription, useToast } from '../../../../hooks'
import { useCurrentUser } from '../../../../hooks/api/users'
import { SubscriptionTier } from '../../../../models'
import styles from './Form.module.scss'
import surveyBotAvatar from './surveybot.svg?url'

export type CancellationSurveyFormData = {
  reasons: string[]
  selectedCompetitor?: string
  message?: string
}

export enum Step {
  Feedback,
  Confirmation,
}

const STEP_LABELS: Record<Step, string> = {
  [Step.Feedback]: 'Feedback',
  [Step.Confirmation]: 'Confirmation',
}

export type Props = {
  activeStep: Step
  nextStep: () => void
  previousStep: () => void
  isLoading?: boolean
  onSubmit: (data: CancellationSurveyFormData) => void
  onCancel?: () => void
  slackMessages: MessageData[]
}

const checkboxes = [
  { name: 'longRequestTime', label: 'Long request latency' },
  { name: 'expensive', label: 'Too expensive' },
  { name: 'switching', label: 'Switching products' },
  { name: 'features', label: 'Missing features' },
  { name: 'issues', label: 'Technical issues' },
  { name: 'confusing', label: 'Too confusing' },
  { name: 'notEnoughValue', label: 'I don’t receive enough value from Fingerprint' },
  { name: 'other', label: 'Other (please describe below)' },
] as const

const competitors = [
  { name: 'dontKnowYet', label: "I don't know yet" },
  { name: 'arkoseLabs', label: 'Arkose Labs' },
  { name: 'castle', label: 'Castle' },
  { name: 'cloudflare', label: 'Cloudflare' },
  { name: 'seon', label: 'SEON' },
  { name: 'shield', label: 'SHIELD' },
  { name: 'stytch', label: 'Stytch' },
  { name: 'threatMetrix', label: 'ThreatMetrix' },
  { name: 'other', label: 'Something else' },
] as const

type Checkboxes = typeof checkboxes
type CheckboxNames = Checkboxes[number]['name']
const messageMaxLength = 500

export function Form({ isLoading, onCancel, onSubmit, activeStep, nextStep, previousStep, slackMessages }: Props) {
  const { data: user } = useCurrentUser()
  const [continueClicked, setContinueClicked] = useState(false)
  const [message, setMessage] = useState('')
  const [points, setPoints] = useState('')
  const [previewMessage, setPreviewMessage] = useState('')
  const [state, setState] = useState({
    longRequestTime: false,
    expensive: false,
    switching: false,
    features: false,
    issues: false,
    confusing: false,
    notEnoughValue: false,
    other: false,
  })
  const [selectedCompetitor, setSelectedCompetitor] = useState<string | undefined>(undefined)

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)
  const endDate = subscription?.currentPeriodEndsAt
    ? formatDate(getDateInUserTZ(date(subscription?.currentPeriodEndsAt), user?.timezone))
    : 'end date'
  const amount = formatPriceInDollars(subscription?.upcomingAmount ?? 0)
  const planName = subscription?.latestTier === SubscriptionTier.Pro ? 'Fingerprint Pro' : 'Fingerprint Pro Plus'

  const { showToast } = useToast()

  useEffect(() => {
    if (message.length === 0 && points.length === 0) {
      setPreviewMessage('')
    } else {
      setPreviewMessage(`${user?.name ?? 'User'} says: ${message ? message + '\n' + points : points}`)
    }
  }, [user, message, points])

  useEffect(() => {
    if (activeStep === Step.Confirmation && (message.length > 0 || points.length > 0)) {
      showToast({
        message: 'Thanks! Your feedback will be sent once cancellation is confirmed below.',
        severity: 'success',
      })
    }
  }, [showToast, activeStep, message, points])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState((v) => {
      const newState = {
        ...v,
        [event.target.name]: event.target.checked,
      }

      const pointList = checkboxes.reduce((acc, val) => {
        if (newState[val.name]) {
          acc.push(val.label)
        }
        return acc
      }, [] as string[])
      setPoints(
        pointList.length > 2
          ? pointList.slice(0, pointList.length - 1).join(', ') + ' and ' + pointList[pointList.length - 1]
          : pointList.join(', ')
      )

      if (event.target.name === 'switching' && !event.target.checked) {
        setSelectedCompetitor(undefined)
      }

      return newState
    })
  }

  const handleCompetitorsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompetitor(event.target.value)
  }

  const otherFieldNotFilled = state.other && message.length < 10
  const errorMessageShown = points.length === 0

  return (
    <Box>
      <Stepper
        steps={Object.values(STEP_LABELS).map((label) => ({ label }))}
        activeStep={activeStep}
        onMoveBack={activeStep === Step.Confirmation ? undefined : previousStep}
        action='Keep plan'
        actionTo={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: currentSubscriptionId })}
        additionalActions={
          <Typography variant='body1' className={styles.topAction}>
            Billing question?{' '}
            <Link href={SUPPORT_PAGE_URL} style={{ textDecoration: 'none' }}>
              Contact support
            </Link>
          </Typography>
        }
      >
        <div className={styles.container}>
          {activeStep === Step.Feedback && (
            <Grid container direction={{ xs: 'column', md: 'row' }}>
              <Paper className={styles.generalInfo}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='h2'>Share your feedback. Help us improve!</Typography>
                    <Typography variant='body2' className={styles.subheader}>
                      Please let us know the reason you’re leaving. Feedback is sent directly to our Slack.
                    </Typography>
                    <FormControl
                      required
                      error={errorMessageShown}
                      className={styles.checkboxList}
                      component='fieldset'
                      sx={{ m: 3 }}
                      variant='standard'
                    >
                      <Typography variant='body2' className={styles.checkboxListSub}>
                        Select as many as you like (at least one required)
                      </Typography>
                      {continueClicked && errorMessageShown && (
                        <FormHelperText>Please select at least one option</FormHelperText>
                      )}
                      <FormGroup>
                        {checkboxes.map(({ name, label }: { name: CheckboxNames; label: string }) => (
                          <FormControlLabel
                            key={name}
                            control={
                              <Checkbox
                                data-testid={`cancellation_survey_checkbox_${name}`}
                                checked={state[name]}
                                onChange={handleChange}
                                name={name}
                              />
                            }
                            label={label}
                          />
                        ))}
                      </FormGroup>
                      {state.switching && (
                        <FormControl
                          required
                          error={!selectedCompetitor}
                          className={styles.checkboxList}
                          component='fieldset'
                          sx={{ m: 3 }}
                          variant='standard'
                        >
                          <Typography variant='body2' className={styles.checkboxListSub}>
                            Which product are you switching to?
                          </Typography>
                          {continueClicked && !selectedCompetitor && (
                            <FormHelperText>Please select at least one option</FormHelperText>
                          )}
                          <FormGroup>
                            {competitors.map(({ name, label }: { label: string; name: string }) => (
                              <FormControlLabel
                                key={name}
                                control={
                                  <Radio
                                    data-testid={`cancellation_survey_competitor_${name}`}
                                    checked={selectedCompetitor === name}
                                    onChange={handleCompetitorsChange}
                                    name='cancellation_survey_competitor'
                                    value={name}
                                  />
                                }
                                label={label}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <InputLabel htmlFor='message'>How can we improve? (optional but oh-so appreciated)</InputLabel>
                    <TextFieldWithCounter
                      defaultValue={message}
                      placeholder='Message #product-team'
                      errorMessage={otherFieldNotFilled ? 'Please describe your feedback above' : undefined}
                      maxSym={messageMaxLength}
                      onChange={(val) => {
                        setMessage(val)
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction={{ xs: 'column-reverse', sm: 'row' }}
                  justifyContent='flex-end'
                  className={styles.buttonContainer}
                >
                  <Button
                    className={clsx(styles.button, styles.buttonGrey)}
                    title='Continue'
                    variant='outlined'
                    size='large'
                    color='grey'
                    onClick={onCancel}
                  >
                    Keep plan
                  </Button>

                  <Button
                    disabled={isLoading}
                    className={styles.button}
                    data-testid='confirm-cancel-feedback'
                    title='Continue'
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => {
                      if (
                        errorMessageShown ||
                        (state.switching && !selectedCompetitor) ||
                        otherFieldNotFilled ||
                        message.length > messageMaxLength
                      ) {
                        setContinueClicked(true)
                        return
                      }
                      nextStep()
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Paper>
              <div className={styles.previewContainer}>
                <Preview
                  header='#churn-surveys'
                  tag='Preview'
                  messages={slackMessages}
                  botMessage={previewMessage}
                  botAvatar={surveyBotAvatar}
                  maxLength={messageMaxLength}
                />
              </div>
            </Grid>
          )}
        </div>

        {activeStep === Step.Confirmation && (
          <Grid container direction={{ xs: 'column-reverse', md: 'row' }}>
            <Paper className={styles.generalInfo}>
              <Typography variant='h2'>Cancel subscription</Typography>
              <Typography variant='body2' className={styles.subheader}>
                If you confirm, your subscription will be canceled at the end of the billing period. You’ll continue to
                have access to your subscription until <Typography variant='semiBody2'>{endDate}</Typography>. If you
                need to preserve events data, we recommend exporting.
              </Typography>

              <Grid
                container
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent='flex-end'
                className={styles.buttonContainer}
              >
                <Button
                  className={clsx(styles.button, styles.buttonGrey)}
                  title='Continue'
                  variant='outlined'
                  size='large'
                  color='grey'
                  onClick={previousStep}
                >
                  Back
                </Button>

                <Button
                  disabled={isLoading}
                  className={styles.button}
                  title='Continue'
                  variant='contained'
                  size='large'
                  color='danger'
                  data-testid='confirm-cancel'
                  onClick={async () => {
                    onSubmit({
                      message: message,
                      selectedCompetitor: competitors.find((c) => c.name === selectedCompetitor)?.label,
                      reasons: checkboxes.reduce((acc, val) => {
                        if (state[val.name]) {
                          acc.push(val.label)
                        }
                        return acc
                      }, [] as string[]),
                    })
                  }}
                >
                  Cancel subscription
                </Button>
              </Grid>
            </Paper>
            <div className={styles.previewContainer}>
              <Typography variant='h2' className={styles.header} style={{ marginBottom: '16px' }}>
                Cancellation Summary
              </Typography>
              <Typography variant='caption'>Plan details</Typography>
              <Typography variant='h3'>{planName}</Typography>
              <div className={styles.summaryRow}>
                <Typography variant='body2' className={styles.summaryTitle}>
                  End of last billing cycle
                </Typography>
                <Typography variant='semiBody2'>{endDate}</Typography>
              </div>
              <div className={styles.summaryRow}>
                <Typography variant='body2' className={styles.summaryTitle}>
                  Amount due
                </Typography>
                <Typography variant='semiBody2'>{amount}</Typography>
              </div>
              <Divider className={styles.divider} />
              <Typography variant='body3' className={styles.description}>
                If you confirm and cancel your subscription, you can still access it until {endDate}.
              </Typography>
            </div>
          </Grid>
        )}
      </Stepper>

      {isLoading && <FullpageLoader />}
    </Box>
  )
}
