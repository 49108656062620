import { Skeleton, Tooltip, Typography } from '@mui/material'
import {
  BILLING_TYPES,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  SUBSCRIPTION_DISPLAY_PLANS,
  SubscriptionDisplayPlan,
} from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum, formatNumShort, formatPriceInDollars } from 'helpers/format'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { useSubscription } from 'hooks'
import { BillingType, CouponDurationType, ExpandedSubscription, SubscriptionStatus, SubscriptionType } from 'models'

import { Widget } from './Widget'

export function UsageWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <ApiCallsWidgetContent subscription={subscription} />
}

export function ApiCallsWidgetContent({ subscription }: { subscription?: ExpandedSubscription }) {
  const title =
    subscription?.billingType === BillingType.UniqueVisitors
      ? BILLING_TYPES.unique_visitors.name
      : BILLING_TYPES.api_calls.name
  const currentUsage = subscription?.usageQuota?.billed ?? 0
  const included = getIncludedUsage(subscription)

  const infoContent = extractUsageTooltipContent(subscription)

  const shortenUsage = currentUsage > 500_000
  const showIncluded =
    subscription != null &&
    [SubscriptionType.Prepaid, SubscriptionType.Free, SubscriptionType.ProofOfConcept, SubscriptionType.Paid].includes(
      subscription.type
    ) &&
    subscription.status !== SubscriptionStatus.Trialing &&
    subscription.usageQuota != null &&
    included != null

  return (
    <Widget
      title={title}
      info={
        infoContent ? (
          <Typography variant='bodyS' color='white'>
            {infoContent}
          </Typography>
        ) : null
      }
    >
      <Tooltip title={shortenUsage ? formatNum(currentUsage) ?? '' : ''}>
        <div>
          {subscription != null ? (
            <Typography variant='bodyMMedium'>
              {shortenUsage ? formatNumShort(currentUsage) : formatNum(currentUsage)}
            </Typography>
          ) : (
            <Skeleton width={80} />
          )}
          {showIncluded ? (
            <Typography variant='bodyMMedium' color='#757575'>
              /{formatNumShort(included)}
            </Typography>
          ) : null}
        </div>
      </Tooltip>
    </Widget>
  )
}

function extractUsageTooltipContent(subscription?: ExpandedSubscription) {
  if (
    subscription == null ||
    subscription.status === SubscriptionStatus.Canceled ||
    subscription.billingType === BillingType.UniqueVisitors
  ) {
    return null
  }

  const currentPlan = getSubscriptionDisplayPlan(subscription)
  const planName = SUBSCRIPTION_DISPLAY_PLANS[currentPlan].name

  if (subscription.status === SubscriptionStatus.Trialing) {
    return `Your free trial of Fingerprint ${planName} comes with unlimited usage. Enjoy!`
  }

  const quota = getIncludedUsage(subscription)
  if (quota == null) {
    return null
  }

  const included = `${formatNum(quota)} API calls included`
  const hasDiscount = subscription.discounts?.find((d) => d.coupon?.duration === CouponDurationType.Repeating) != null

  if (hasDiscount) {
    return `${included}.`
  }

  switch (getSubscriptionDisplayPlan(subscription)) {
    case SubscriptionDisplayPlan.Free:
    case SubscriptionDisplayPlan.Enterprise:
      return `${included}.`

    case SubscriptionDisplayPlan.Pro:
    case SubscriptionDisplayPlan.Plus:
      return `${included} then ${formatPriceInDollars(PRO_PLUS_BILLING_DATA.overagePrice * 1000, false)} per 1000.`

    case SubscriptionDisplayPlan.Plus99: {
      return `${included} then ${formatPriceInDollars(PRO_PLUS_99_BILLING_DATA.overagePrice * 1000, false)} per 1000.`
    }
  }
}

function getIncludedUsage(subscription?: ExpandedSubscription) {
  if (subscription == null) {
    return undefined
  }

  if (subscription.usageQuota?.quota) {
    return subscription.usageQuota.quota
  }

  const displayPlan = getSubscriptionDisplayPlan(subscription)

  switch (displayPlan) {
    case SubscriptionDisplayPlan.Pro:
    case SubscriptionDisplayPlan.Plus:
      return PRO_PLUS_BILLING_DATA.prepaidQuantity

    case SubscriptionDisplayPlan.Plus99:
      return PRO_PLUS_99_BILLING_DATA.prepaidQuantity

    default:
      return undefined
  }
}
