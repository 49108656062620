import { accountSettingsNavItems, Header, Layout, MainContent, TabNavigation } from 'features/commonUI'

import { useDocumentTitle } from '../../hooks'
import { useUserContext } from '../../hooks/api/context'
import { EmailNotificationsForm } from './components/EmailNotificationsForm'
import { PersonalInfoForm } from './components/PersonalInfoForm'
import { ProfileSecurityForm } from './components/ProfileSecurityForm'
import { TimeForm } from './components/TimeForm'

export function ProfileSettingsPage() {
  useDocumentTitle('Profile settings')
  const { data: context } = useUserContext()

  return (
    <Layout>
      <Header title='Account Settings' />
      <TabNavigation items={accountSettingsNavItems} />
      <MainContent>
        <PersonalInfoForm />
        {context != null && !context.isSsoEnabled ? <ProfileSecurityForm /> : null}
        <TimeForm />
        <EmailNotificationsForm />
      </MainContent>
    </Layout>
  )
}
