import { CircularProgress } from '@mui/material'
import { AppRoute, buildRoute, useRouteParams } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { ComponentProps, memo, useCallback } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { useAddCertificateMutation, useCertificate } from '../../../hooks/api/ssl_certificates'
import CertificateCreate from '../components/CertificateCreate/CertificateCreate'

export const CustomSubdomainCreatePage = memo(function () {
  const history = useHistory()
  const { subdomainId } = useRouteParams<AppRoute.CustomSubdomainView>()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: certificate, error: loadingError, isLoading } = useCertificate(subscriptionId, subdomainId, true, true)

  const { mutate: addCertificate, error: creationError, isLoading: isCreating } = useAddCertificateMutation()

  const handleCertificateCreate = useCallback<ComponentProps<typeof CertificateCreate>['onCertificateCreate']>(
    (data) => {
      addCertificate(
        {
          data: {
            domains: data.domains,
          },
          params: { subscriptionId },
        },
        {
          onSuccess: (cert) =>
            history.push(buildRoute(AppRoute.CustomSubdomainView, { subscriptionId, subdomainId: cert.id })),
        }
      )
    },
    [addCertificate, subscriptionId, history]
  )

  if (!subscriptionId || (subdomainId && isLoading)) {
    return <CircularProgress color='primary' />
  }

  if (loadingError?.message) {
    return (
      <Redirect
        to={buildRoute(AppRoute.CustomSubdomains, { subscriptionId }, { errorMessage: loadingError.message })}
      />
    )
  }

  if (creationError?.message) {
    return (
      <Redirect
        to={buildRoute(AppRoute.CustomSubdomains, { subscriptionId }, { errorMessage: creationError.message })}
      />
    )
  }

  return (
    <CertificateCreate
      certificate={certificate}
      onCertificateCreate={handleCertificateCreate}
      isCreating={isCreating}
    />
  )
})
