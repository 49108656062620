import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Grid, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { getMonth, getYear, setMonth, setYear } from 'date-fns'

import { MONTHS } from '../../../../helpers/dateRange'
import styles from './Header.module.scss'

interface HeaderProps {
  date: Date
  minDate: Date
  maxDate: Date
  setDate: (date: Date) => void
  nextDisabled: boolean
  prevDisabled: boolean
  onClickNext: () => void
  onClickPrevious: () => void
}

const generateYears = (from: Date, to: Date) => {
  const fromYear = from.getFullYear()
  const toYear = to.getFullYear()
  const years = []

  for (let i = fromYear; i <= toYear; i++) {
    years.push(i)
  }

  return years
}

export default function Header({
  date,
  minDate,
  maxDate,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: HeaderProps) {
  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setDate(setMonth(date, event.target.value as number))
  }

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setDate(setYear(date, event.target.value as number))
  }

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item className={styles.iconContainer}>
        <IconButton className={styles.icon} disabled={prevDisabled} onClick={onClickPrevious} size='large'>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          variant='standard'
          value={getMonth(date)}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          variant='standard'
          value={getYear(date)}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}
          data-testid='date-range-picker-year-select'
        >
          {generateYears(minDate, maxDate).map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={styles.iconContainer}>
        <IconButton className={styles.icon} disabled={nextDisabled} onClick={onClickNext} size='large'>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
