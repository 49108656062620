import { Alert, Paper } from '@mui/material'
import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import { SupportEmail } from 'components/SupportEmail'
import { GenericError } from 'const'
import { UsageChartData, UsageCounterType } from 'models'

import BotdTable, { BotdTableProps } from '../BotdTable/BotdTable'
import styles from './BotdOverview.module.scss'
import { HistoryWidget, HistoryWidgetProps } from './HistoryWidget/HistoryWidget'

export interface BotdOverviewProps extends HistoryWidgetProps {
  counters?: UsageChartData
  tableProps: BotdTableProps
  error?: GenericError | null
  isLoading?: boolean
}

export function BotdOverview({ counters, tableProps, error, isLoading, ...props }: BotdOverviewProps) {
  if (error) {
    return (
      <Alert severity='error' className={styles.alert}>
        {error?.message ?? (
          <>
            Something went wrong. Please try again later or contact <SupportEmail />
          </>
        )}
      </Alert>
    )
  }

  const hasApiCalls = counters?.[UsageCounterType.ApiCalls]?.some(({ value }) => value > 0)

  return (
    <>
      <Paper className={styles.root}>
        <HistoryWidget counters={counters} {...props} />

        {hasApiCalls && <BotdTable isFiltering={!!props.appliedFilter} {...tableProps} />}
      </Paper>

      {isLoading && <FullpageLoader testId='botd-loader' />}
    </>
  )
}
