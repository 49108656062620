import { Duration, max, subHours } from 'date-fns'
import { currentDateInZonedTime } from 'helpers/date'
import { calculateGranularityFromRange, DateRange } from 'helpers/dateRange'
import { useCurrentUser } from 'hooks/api/users'
import { DateTime } from 'luxon'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

export type DateRangeContextProps = {
  initialDateRange?: Required<DateRange>
  initialMinDate?: Date
  initialMaxDate?: Date
  maxRange?: Duration
  today: Date
}
export type DateRangeContextValue = Partial<
  DateRangeContextProps & {
    dateRange: Required<DateRange>
    setDateRange: React.Dispatch<React.SetStateAction<Required<DateRange> | undefined>>
  }
>
const DateRangeContext = createContext<DateRangeContextValue>({})

export function DateRangeContextProvider({
  children,
  initialDateRange,
  initialMinDate,
  initialMaxDate,
  maxRange,
}: PropsWithChildren<DateRangeContextProps>) {
  const { data: currentUser } = useCurrentUser()

  const [dateRange, setDateRange] = useState<Required<DateRange>>()

  const today = useMemo(() => currentDateInZonedTime(currentUser?.timezone), [currentUser?.timezone])
  const defaultDateRange = useMemo(() => {
    const optionalDates = [subHours(today, 7 * 24)]
    if (initialMinDate) {
      optionalDates.push(initialMinDate)
    }
    return { startDate: max(optionalDates), endDate: today }
  }, [initialMinDate, today])

  useEffect(() => {
    if (dateRange == null) {
      setDateRange(initialDateRange ?? defaultDateRange)
    }
  }, [dateRange, defaultDateRange, initialDateRange])

  const value = useMemo(
    () => ({
      dateRange,
      setDateRange,
      initialDateRange: initialDateRange ?? defaultDateRange,
      initialMinDate,
      initialMaxDate,
      maxRange,
      today,
    }),
    [dateRange, defaultDateRange, initialDateRange, initialMaxDate, initialMinDate, maxRange, today]
  )

  return <DateRangeContext.Provider value={value}>{children}</DateRangeContext.Provider>
}

export function useDateRangeContext() {
  return useContext(DateRangeContext)
}

export function calculateGranularity(startDate?: DateTime, endDate?: DateTime) {
  if (startDate == null || endDate == null) {
    return undefined
  }
  return calculateGranularityFromRange({ startDate: startDate.toJSDate(), endDate: endDate.toJSDate() })
}
