import { Box, Link, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Header, MainContent } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'
import { MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'

import { Integrations } from './components/Integrations/Integrations'
import styles from './GetStartedPage.module.scss'

export function GetStartedPage() {
  const history = useHistory()

  useDocumentTitle('Get Started')

  const inviteMemberOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    history.push(AppRoute.UserManagement, { isUserDialogOpen: true })
  }

  return (
    <>
      <Header title='Get Started' />
      <MainContent>
        <Box rowGap='8px' display='flex' flexDirection='column' marginBottom='24px'>
          <Typography variant='h1' className={styles.title} data-testid='installpage-header'>
            Install Fingerprint
          </Typography>
          <Typography variant='body2' className={styles.subtitle}>
            Choose a platform to get started. Need help with installation?{' '}
            <Link
              component='a'
              style={{ textDecoration: 'none' }}
              href={buildRoute(AppRoute.UserManagement)}
              onClick={inviteMemberOnClick}
            >
              Invite a team member
            </Link>
            .
          </Typography>
        </Box>
        <Integrations />
      </MainContent>
    </>
  )
}
