import { format, intlFormatDistance } from 'date-fns'
import { TrafficRuleStringMatchRule, UsageCounterPeriod } from 'models'

import { formatDatetime } from '../features/commonUI'
import { getDateInUserTZ, roundDateToStartOfMonth } from './date'
import { getLocale, isLocale24h } from './locale'

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatDate(date: Date, formatString?: string) {
  return format(date, formatString ?? 'MMMM do, yyyy')
}

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatDateShortFullYear(date: Date, formatString?: string) {
  return format(date, formatString ?? 'MMM d, yyyy')
}

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatDateShortWithoutYear(date: Date, formatString?: string) {
  return format(date, formatString ?? 'MMM d')
}

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatDateShort(date: Date) {
  return date.toLocaleDateString(getLocale(), { day: 'numeric', month: 'numeric', year: '2-digit' })
}

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatMonth(date: Date) {
  return format(date, 'MMMM, yyyy')
}

/**
 * @deprecated Use `formatDate` from `features/commonUI`
 */
export function formatTimeShort(time: Date) {
  return shortTimeFormatter.format(time).toLowerCase()
}

export function formatDateByGranularity(time: string | number, granularity: UsageCounterPeriod, timezone?: string) {
  const dateInTz = getDateInUserTZ(time, timezone)

  switch (granularity) {
    case UsageCounterPeriod.Hour:
      dateInTz.setMinutes(0)
      return formatDatetime(dateInTz)
    case UsageCounterPeriod.Day:
      return formatDateShort(dateInTz)
    case UsageCounterPeriod.Month:
      return formatMonth(roundDateToStartOfMonth(new Date(time)))
  }
}

export function formatDateTimeDistance(time: string, options?: Parameters<typeof intlFormatDistance>[2]) {
  const now = new Date()
  const value = intlFormatDistance(new Date(time), now, { locale: 'en-US', ...options })

  if (value === 'now') {
    return `just ${value}`
  }

  return value
}

export function formatNum(num: number | bigint, precise?: boolean) {
  return precise ? preciseNumberFormatter.format(num) : numberFormatter.format(num)
}

export function formatNumShort(num: number | bigint, fractionDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: fractionDigits,
  } as any).format(num)
}

export function formatMoney(num: number | bigint) {
  return moneyFormatter.format(num)
}

export function formatPercent(num: number | bigint, precise = false) {
  return precise ? precisePercentFormatter.format(num) : percentFormatter.format(num)
}

export function formatPriceInDollars(priceInCents: number, showCents = true) {
  const value = formatMoney(priceInCents / 100)
  if (!showCents) {
    return value.replace(/\.00$/, '')
  }
  return value
}

export function applyDiscount(price: number, discountPercent?: number) {
  if (discountPercent) {
    price -= (discountPercent / 100) * price
  }
  return price
}

const matchRuleFormatMap: Record<TrafficRuleStringMatchRule, string> = {
  [TrafficRuleStringMatchRule.BeginsWith]: 'starts with',
  [TrafficRuleStringMatchRule.Contains]: 'contains',
  [TrafficRuleStringMatchRule.EndsWith]: 'ends with',
  [TrafficRuleStringMatchRule.Regex]: 'regular expression',
}

export function formatMatchRule(matchRule: TrafficRuleStringMatchRule) {
  return matchRuleFormatMap[matchRule] || matchRule
}

const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 4 })

const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent', currency: 'USD', maximumFractionDigits: 0 })
const precisePercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  currency: 'USD',
  maximumFractionDigits: 2,
})

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
} as any)

const preciseNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 4,
} as any)

const shortTimeFormatter = new Intl.DateTimeFormat(getLocale(), {
  hour: 'numeric',
  minute: isLocale24h() ? 'numeric' : undefined,
})

export function formatTimezone(tz: string) {
  return tz.replace(/_/, ' ')
}

export function formatJSON(obj: unknown, indent = 2) {
  return JSON.stringify(obj, null, indent)
}
