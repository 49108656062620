import { List, ListItem, ListItemText } from '@mui/material'

import { DateRange, isSameRange } from '../../helpers/dateRange'
import { DefinedRange } from './types'

type DefinedRangesProps = {
  setRange: (range: DateRange, label: string) => void
  selectedRange: DateRange
  ranges: DefinedRange[]
}

export default function DefinedRanges({ ranges, setRange, selectedRange }: DefinedRangesProps) {
  return (
    <List>
      {ranges.map((range) => (
        <ListItem button key={range.label} onClick={() => setRange(range, range.label)}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              style: {
                fontWeight: isSameRange(range, selectedRange) ? 'bold' : 'normal',
              },
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
