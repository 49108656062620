import { Skeleton, Tooltip, Typography } from '@mui/material'
import { HealthChip } from 'features/commonUI'
import { formatNum, formatNumShort, formatPercent } from 'helpers/format'

import styles from './ValueWithDifference.module.scss'

type Props = {
  value?: number | bigint
  difference?: number | bigint
  percentage?: number
  precise?: boolean
}

export function ValueWithDifference({ value, difference, percentage, precise }: Props) {
  return (
    <div className={styles.container}>
      <Value value={value} precise={precise} />
      <Chip difference={difference} percentage={percentage} />
    </div>
  )
}

function Value({ value, precise }: Pick<Props, 'value' | 'precise'>) {
  if (value == null) {
    return <Skeleton width={96} height={28} />
  }

  return (
    <Tooltip title={precise ? `${value}` : formatNum(value)}>
      <Typography variant='h2' className={styles.value}>
        {value > 500000n ? formatNumShort(value) : formatNum(value, precise)}
      </Typography>
    </Tooltip>
  )
}

function Chip({ difference, percentage }: Pick<Props, 'difference' | 'percentage'>) {
  if (difference == null || percentage == null || Number.isNaN(percentage) || !Number.isFinite(percentage)) {
    return null
  }

  const positive = difference >= 0n
  const prefix = positive ? '+' : ''
  const title = `${prefix}${difference.toString()}`
  const label = `${prefix}${formatPercent(percentage)}`
  const color = positive ? 'green' : 'red'

  return (
    <Tooltip title={title}>
      <div className={styles.chipContainer}>
        <HealthChip color={color} label={label} />
      </div>
    </Tooltip>
  )
}
