import { Button, ButtonProps } from '@mui/material'
import { OAuthProvider } from 'models'

import GitHubLogoSvg from './github-logo.svg'
import GoogleLogoSvg from './google-logo.svg'
import MicrosoftLogoSvg from './ms-logo.svg'

export interface OAuthButtonProps extends ButtonProps {
  provider: OAuthProvider
  isSignUp?: boolean
  'data-testid'?: string
}

export function OAuthButton({ provider, isSignUp = false, ...buttonProps }: OAuthButtonProps) {
  return (
    <Button {...buttonProps} variant='outlined' color='grey' startIcon={getProviderIcon(provider)}>
      {getProviderName(provider)}
    </Button>
  )
}

function getProviderName(provider: OAuthProvider) {
  switch (provider) {
    case OAuthProvider.Google:
      return 'Continue with Google'
    case OAuthProvider.GitHub:
      return 'Continue with GitHub'
    case OAuthProvider.Microsoft:
      return 'Continue with Microsoft'
  }
}

function getProviderIcon(provider: OAuthProvider) {
  switch (provider) {
    case OAuthProvider.Google:
      return <GoogleLogoSvg width={16} />
    case OAuthProvider.GitHub:
      return <GitHubLogoSvg width={16} />
    case OAuthProvider.Microsoft:
      return <MicrosoftLogoSvg width={16} />
  }
}
