import { DateRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'

import { ZonedDateRange } from './ZonedDateRange'

/**
 * Agreed behavior:
 *  - end of date range is always the start of hour for the current time (even if it's a different date)
 *  - beginning of date range is the start of hour for the current time on a given day
 */
export function interpretOverviewDateRange(dateRange?: Required<DateRange>, timezone?: string): ZonedDateRange {
  if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null) {
    return { endDate: undefined, startDate: undefined }
  }

  // Using this structure to be able to mock it.
  const now = DateTime.fromJSDate(new Date(Date.now()))
  const hour = now.hour
  const startDate = DateTime.fromJSDate(dateRange.startDate).set({ hour }).startOf('hour').setZone(timezone)
  const endDate = DateTime.fromJSDate(dateRange.endDate).set({ hour }).startOf('hour').setZone(timezone)

  if (startDate.hasSame(endDate, 'day')) {
    // Backend doesn't let the to field be set into the future.
    const isToday = startDate.hasSame(now.setZone(timezone), 'day')
    return {
      startDate: startDate.startOf('day'),
      endDate: isToday ? endDate : endDate.plus({ day: 1 }).startOf('day'),
    }
  }

  return { startDate, endDate }
}
