import { ArrowBack } from '@mui/icons-material'
import { Button, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import clsx from 'clsx'
import { ReactNode } from 'react'

import Loader from '../../Loader/Loader'
import styles from './WizardWrapper.module.scss'

export interface WizardStep {
  component: ReactNode
  /**
   * When true, this step will not get its own progress bar segment.
   */
  nested?: boolean
  onGoBack?: () => void
  actions?: ReactNode
}

export interface WizardWrapperProps {
  steps: WizardStep[]
  currentStep: number
  furthestCompletedStep?: number
  /**
   * After this step, you can no longer go back by clicking the stepper labels.
   */
  commitStep?: number
  onSelectStep?: (step: number) => void
  isEmbedded?: boolean
  isLoading?: boolean
}

export default function WizardWrapper({
  steps,
  currentStep,
  furthestCompletedStep = currentStep,
  commitStep,
  onSelectStep,
  isEmbedded,
  isLoading,
}: WizardWrapperProps) {
  const currentStepData = steps[currentStep]
  const displaySteps = steps.filter(({ nested }) => !nested).map((_, index) => index)

  const { actualToDisplay, displayToActual } = getStepMapping(steps)
  const currentDisplayStep = actualToDisplay[currentStep]
  const furthestCompletedDisplayStep = actualToDisplay[furthestCompletedStep]

  const isCommitted = commitStep && currentStep > commitStep

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  function canSelectDisplayStep(displayStep: number) {
    const isCommittedStep = isCommitted && displayToActual[displayStep] <= commitStep

    return (
      onSelectStep &&
      displayStep <= furthestCompletedDisplayStep &&
      displayStep !== currentDisplayStep &&
      !isCommittedStep
    )
  }

  function handleSelectDisplayStep(selectedDisplayStep: number) {
    if (!canSelectDisplayStep(selectedDisplayStep)) {
      return
    }

    const actualStep = displayToActual[selectedDisplayStep]
    onSelectStep?.(actualStep)
  }

  return (
    <div className={clsx({ [styles.embedded]: isEmbedded })}>
      <Stepper activeStep={currentDisplayStep} connector={<></>} alternativeLabel classes={{ root: styles.stepper }}>
        {displaySteps.map((step) => (
          <Step key={step} onClick={() => handleSelectDisplayStep(step)} classes={{ alternativeLabel: styles.step }}>
            <StepLabel
              classes={{
                labelContainer: clsx(
                  styles.label,
                  { [styles.clickable]: canSelectDisplayStep(step) },
                  { [styles.completed]: step <= currentDisplayStep },
                  { [styles.completedBefore]: step > currentDisplayStep && step <= furthestCompletedDisplayStep },
                  { [styles.loading]: isLoading }
                ),
                iconContainer: styles.stepIcon,
              }}
            />
          </Step>
        ))}
      </Stepper>

      <div className={styles.content}>
        {currentStepData.onGoBack && (
          <div className={styles.controls}>
            <Button
              variant={smDown ? 'outlined' : 'text'}
              startIcon={<ArrowBack />}
              onClick={currentStepData.onGoBack}
              disabled={isLoading}
              className={styles.backButton}
            >
              Back
            </Button>
          </div>
        )}
        {currentStepData.component}
      </div>

      {isLoading && <Loader className={styles.loader} data-testid='wizard-wrapper-loader' />}
    </div>
  )
}

/**
 * Generates lookup arrays to convert between display step indices and actual step indices.
 * A display step is represented by one tick of the stepper and can be composed of multiple actual steps.
 * Since steps can be nested the mapping is not 1:1.
 * For example step 2.2 would have an actual step index of 3 but a display step index of 2.
 *
 * On the returned arrays the corresponding step can be looked up by index:
 *
 * actualToDisplay[<actual step>] = <display step>,
 * displayToActual[<display step>] = <actual step>
 *
 * @param steps List of steps to calculate conversions for.
 * @return Lookup arrays to convert between display and actual steps.
 */
function getStepMapping(steps: WizardStep[]) {
  const actualToDisplay: number[] = []
  const displayToActual: number[] = []

  let currentDisplayStep = -1

  steps.forEach(({ nested }, actualStep) => {
    if (!nested) {
      ++currentDisplayStep
      displayToActual.push(actualStep)
    }

    actualToDisplay.push(currentDisplayStep)
  })

  return { actualToDisplay, displayToActual }
}
