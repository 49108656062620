import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GenericError } from 'const'
import { SubscriptionWebhook } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const listCacheKey = 'subscription/webhooks'

export function useWebhooks(subscriptionId: string, withPolling?: boolean, pollingRate = 10) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery<SubscriptionWebhook[], GenericError, SubscriptionWebhook[], readonly [string, string]>({
    queryKey: [listCacheKey, subscriptionId] as const,
    queryFn: ({ queryKey: [_, subscriptionId] }) =>
      extractData(withErrorHandling(buildRequest('subscriptionWebhooks', { params: { subscriptionId } }))),
    enabled: subscriptionId != null,
    refetchInterval: withPolling ? pollingRate * 1000 : undefined,
  })
}

export function useWebhookCreateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionWebhookCreate', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([listCacheKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useWebhookUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionWebhookUpdate', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([listCacheKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useWebhookDeleteMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionWebhookDelete', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([listCacheKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useSendWebhookTestEvent() {
  const queryClient = useQueryClient()

  return useRequestMutation('webhookSendTestEvent', {
    onSuccess: (_, { params }) => {
      if (params) {
        queryClient.invalidateQueries([listCacheKey, params.subscriptionId])
      }
    },
  })
}
