import { Button, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ResponsivePaper from '../ResponsivePaper/ResponsivePaper'
import styles from './StatusDialog.module.scss'

export interface Action {
  name: string
  onAction?: () => void
  actionTo?: string
}

export interface StatusDialogProps {
  icon?: ReactNode
  title: string | ReactNode
  subtitle: string | ReactNode
  primaryAction?: Action
  secondaryAction?: Action
  isLoading?: boolean
}

export default function StatusDialog({
  icon,
  title,
  subtitle,
  primaryAction,
  secondaryAction,
  isLoading,
}: StatusDialogProps) {
  return (
    <ResponsivePaper className={styles.container}>
      {icon && <span className={styles.iconWrapper}>{icon}</span>}

      <Typography variant='h2' data-testid='statusDialogHeader'>
        {title}
      </Typography>
      <Typography variant='body3'>{subtitle}</Typography>

      {primaryAction && (
        <Button
          component={primaryAction.actionTo ? RouterLink : 'button'}
          to={primaryAction.actionTo}
          onClick={primaryAction.onAction}
          variant='contained'
          color='primary'
          disabled={isLoading}
          className={styles.primaryAction}
          fullWidth
          data-testid='statusDialogPrimaryAction'
        >
          {primaryAction.name}
        </Button>
      )}
      {secondaryAction && (
        <Button
          component={secondaryAction.actionTo ? RouterLink : 'button'}
          to={secondaryAction.actionTo}
          onClick={secondaryAction.onAction}
          variant='outlined'
          color='primary'
          disabled={isLoading}
          className={styles.secondaryAction}
          fullWidth
        >
          {secondaryAction.name}
        </Button>
      )}
    </ResponsivePaper>
  )
}
