import { ampli } from 'models/ampli'
import { useCallback } from 'react'
import ReactCountryFlag from 'react-country-flag'

import { WithCopyButton } from '../WithCopyButton/WithCopyButton'
import styles from './IpLocation.module.scss'

export interface IpLocationProps {
  ipAddress: string
  ipLocation?: {
    country?: {
      code: string
      name: string
    }
  }
  source?: 'botd' | 'visits'
}

export default function IpLocation({ ipAddress, ipLocation, source }: IpLocationProps) {
  const onCopy = useCallback(() => {
    switch (source) {
      case 'visits':
        ampli.identificationIpAddressCopied()
        break
      case 'botd':
        ampli.botDIpAddressCopied()
        break
      default:
        break
    }
  }, [source])

  const country = ipLocation?.country
  const flag = country && (
    <ReactCountryFlag svg={isWindows} countryCode={country?.code} title={country?.name} className={styles.flag} />
  )

  return (
    <WithCopyButton text={ipAddress} className={styles.ipLocation} onCopy={onCopy}>
      <span className={styles.flag}>{flag}</span>
      {ipAddress}
    </WithCopyButton>
  )
}

// Flag emojis don't work on Windows.
// Windows 10 has emoji support but flags still don't work.
// For that reason we have to rely on OS detection rather than checking for feature support.
// https://github.com/danalloway/react-country-flag/issues/8
const isWindows = navigator.platform === 'Win32' || navigator.platform === 'Win64'
