import { Box, Grid, Link, Paper, Stack, Typography } from '@mui/material'
import { PRODUCTBOARD_PUBLIC_ROADMAP_SUBMISSION_URL } from 'const'
import { Subscription } from 'models'

import IntegrationListItem from '../IntegrationListItem/IntegrationListItem'
import { IntegrationCategory, IntegrationMetadata } from '../IntegrationMetadata/integrationMetadata'

type Props = {
  subscription: Pick<Subscription, 'id' | 'type'>
  integrations: IntegrationMetadata[]
}

export default function IntegrationList({ integrations, subscription }: Props) {
  const integrationByCategoryMap = integrations.reduce((acc, integration) => {
    if (!acc[integration.category]) {
      acc[integration.category] = []
    }

    acc[integration.category].push(integration)

    return acc
  }, {} as Record<IntegrationCategory, IntegrationMetadata[]>)

  const integrationCategoriesToDisplay = Object.values(IntegrationCategory) as IntegrationCategory[]

  return (
    <Stack spacing={3}>
      {integrationCategoriesToDisplay.map((categoryTitle) =>
        integrationByCategoryMap[categoryTitle]?.length ? (
          <Paper data-testid='integration_category' data-category={categoryTitle} key={categoryTitle}>
            <Box padding={3}>
              <Typography variant='h3' mb={3}>
                {categoryTitle}
              </Typography>

              <Grid container spacing={2}>
                {integrationByCategoryMap[categoryTitle].map((integration) => (
                  <Grid item key={integration.title} xs={12} sm={6} lg={4}>
                    <IntegrationListItem subscription={subscription} integration={integration} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        ) : null
      )}

      {/* TODO: fix theme color to secondary text */}
      <Typography variant='body2' color={(theme) => theme.typography.caption.color}>
        Don’t see the integration or library you need?{' '}
        <Link href={PRODUCTBOARD_PUBLIC_ROADMAP_SUBMISSION_URL} target='_blank' rel='noreferrer'>
          Let us know!
        </Link>
      </Typography>
    </Stack>
  )
}
