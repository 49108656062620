import { CheckCircle, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Alert, Collapse, Link, TableHead, Typography } from '@mui/material'
import clsx from 'clsx'
import CodeViewer from 'components/CodeViewer/CodeViewer'
import IpLocation from 'components/IpLocation/IpLocation'
import Loader from 'components/Loader/Loader'
import {
  ColumnDescriptor,
  SortableTableHeadCell,
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableBodyError,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from 'components/Table/Table'
import { SUPPORT_EMAIL_MAILTO } from 'const'
import { formatDatetime } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatJSON } from 'helpers/format'
import { camelCaseToPascalCase } from 'helpers/string'
import { useBotdVisit } from 'hooks/botdVisits'
import { PaginatedData } from 'hooks/noSqlPaginatedQuery'
import { BadBot, BotdVisit, BotResult, GoodBot, isGoodBot, OrderLowercase } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'

import styles from './BotdTable.module.scss'
import AppleIcon from './BotTypeIcons/Apple.svg'
import BotIcon from './BotTypeIcons/BadBot.svg'
import DuckDuckGoIcon from './BotTypeIcons/DuckDuckGo.svg'
import GoogleIcon from './BotTypeIcons/Google.svg'

export interface BotdTableProps extends PaginatedData<BotdVisit> {
  isFiltering?: boolean
  order?: OrderLowercase
  sortBy?: keyof BotdVisit
  onSort: (orderBy: keyof BotdVisit, order: OrderLowercase) => void
}

export default function BotdTable({
  data,
  hasPreviousEntries,
  hasNextEntries,
  order,
  sortBy,
  onSort,
  error,
  isLoading,
  onRequestPreviousEntries,
  onRequestNextEntries,
  isFiltering,
}: BotdTableProps) {
  return (
    <TableContainer className={styles.root}>
      <Typography variant='h2' className={styles.title}>
        Bot Visits
      </Typography>

      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            {defaultColumns.map(({ label, sortable, ...props }) =>
              sortable ? (
                <SortableTableHeadCell
                  {...props}
                  key={label}
                  columnId={props.key}
                  order={order}
                  orderBy={sortBy}
                  handleSort={onSort}
                  className={undefined}
                >
                  {label}
                </SortableTableHeadCell>
              ) : (
                <TableCell {...props} key={label} monospace={false} className={undefined}>
                  {label}
                </TableCell>
              )
            )}

            {/* Expand button column. */}
            <TableCell width='3%' />
          </TableRow>
        </TableHead>

        <TableBody
          columnCount={defaultColumns.length}
          showBodyLoader={data.length === 0}
          error={error}
          isLoading={isLoading}
        >
          <TableBodyData>
            {data.map((botdVisit) => (
              <VisitRow key={botdVisit.requestId} {...botdVisit} />
            ))}
          </TableBodyData>

          <TableBodyEmpty>
            <Alert severity='info'>
              {isFiltering ? 'No results for this filter.' : 'Details about detected bots will appear here.'}
            </Alert>
          </TableBodyEmpty>

          <TableBodyError>
            <Alert severity='error'>{error?.message ?? 'There was an error getting the visits.'}</Alert>
          </TableBodyError>
        </TableBody>
      </Table>

      <TableFooter>
        {!error && (
          <TablePagination
            hasNextEntries={hasNextEntries && data.length > 0}
            hasPreviousEntries={hasPreviousEntries}
            onRequestNextEntries={onRequestNextEntries}
            onRequestPreviousEntries={onRequestPreviousEntries}
            isLoading={isLoading}
          />
        )}
      </TableFooter>

      {isLoading && <Loader testId='botd-visits-table-loader' />}
    </TableContainer>
  )
}

function VisitRow(botdVisit: BotdVisit) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: visitDetails, isLoading, error } = useBotdVisit(subscriptionId, botdVisit.requestId, isExpanded)

  function toggleExpanded() {
    setIsExpanded((isExpanded) => !isExpanded)
    ampli.botDEventDetailsViewed()
  }
  const onCopyRequest = useCallback(() => {
    ampli.botDRequestCopied()
  }, [])

  return (
    <>
      <TableRow onClick={toggleExpanded} interactive>
        {defaultColumns.map(({ key, ...props }) => (
          <TableCell {...props} classes={{ root: styles.visitCell }} key={key}>
            <>{preprocess(key, botdVisit)}</>
          </TableCell>
        ))}
        <TableCell classes={{ root: clsx(styles.visitCell, styles.chevronCell) }}>
          {isExpanded ? (
            <ExpandLess fontSize='small' className={styles.chevron} />
          ) : (
            <ExpandMore fontSize='small' className={styles.chevron} />
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={7} classes={{ root: styles.detailCell }}>
          <Collapse in={isExpanded}>
            {error ? (
              <Alert severity='error' className={styles.alert}>
                Could not get visit details, try again later or{' '}
                <Link href={SUPPORT_EMAIL_MAILTO} underline='none'>
                  contact support
                </Link>
                .
              </Alert>
            ) : (
              <CodeViewer
                code={formatJSON(visitDetails?.data ? { ...visitDetails.data, requestId: botdVisit?.requestId } : {})}
                showLineNumbers
                skeletonPrefix='{'
                skeletonSuffix='}'
                skeletonIndent={15}
                loadingLines={9}
                isLoading={isLoading}
                className={styles.viewer}
                onCopy={onCopyRequest}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const defaultColumns: ColumnDescriptor<BotdVisit>[] = [
  { key: 'botType', label: 'Bot', width: '15%' },
  { key: 'botResult', label: 'Type', width: '10%' },
  { key: 'ipAddress', label: 'IP Address', width: '18%' },
  { key: 'referer', label: 'URL', width: '17%', className: styles.referer },
  { key: 'requestId', label: 'Request ID', monospace: true, width: '20%' },
  { key: 'timestamp', label: 'Date', width: '17%', sortable: true },
]

const preprocess = (key: keyof BotdVisit, visit?: BotdVisit) => {
  switch (key) {
    case 'timestamp': {
      const timestamp = visit?.timestamp
      return timestamp ? formatDatetime(new Date(timestamp * 1000), 'precise') : ''
    }

    case 'botResult': {
      const botResult = visit?.botResult
      switch (botResult) {
        case BotResult.Bad:
          return <span className={styles.malicious}>Malicious</span>
        case BotResult.Good:
          return <span className={styles.friendly}>Friendly</span>
        case BotResult.NotDetected:
        default:
          return <span>Human</span>
      }
    }

    case 'botType': {
      const botType = visit?.botType!
      return (
        <span className={styles.typeCell}>
          <span className={styles.iconWrapper}>{getBotTypeIcon(botType)}</span>
          <span className={styles.botType}>{camelCaseToPascalCase(botType || BadBot.Unknown)}</span>
        </span>
      )
    }

    case 'referer': {
      const referer = visit?.referer
      return <span>{referer || '-'}</span>
    }

    case 'ipAddress': {
      const ipAddress = visit?.ipAddress
      return ipAddress ? <IpLocation ipAddress={ipAddress} ipLocation={visit?.ipLocation} source='botd' /> : '-'
    }

    default:
      return visit?.[key]
  }
}

function getBotTypeIcon(botType: GoodBot | BadBot) {
  switch (botType) {
    case GoodBot.Google:
      return <GoogleIcon />
    case GoodBot.Duckduckgo:
      return <DuckDuckGoIcon />
    case GoodBot.Apple:
      return <AppleIcon />
    default:
      return isGoodBot(botType) ? <CheckCircle className={styles.muiIcon} /> : <BotIcon />
  }
}
