import { Autocomplete, Button, Link, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import { DOCS_INTEGRATIONS, GenericError, SUPPORT_PAGE_URL } from '../../../../const'
import WizardStep, { WizardStepActions, WizardStepContent } from '../../../Wizard/Step/WizardStep'
import styles from './ChooseDomain.module.scss'

export interface ChooseDomainProps {
  domains?: string[]
  subscriptionDomain?: string
  savedDomain?: string
  onSubmitDomain: (domain: string) => void
  error?: GenericError | Error | null
  isLoading?: boolean
}

export default function ChooseDomain({
  domains,
  subscriptionDomain,
  savedDomain,
  onSubmitDomain,
  error,
  isLoading,
}: ChooseDomainProps) {
  const [currentDomain, setCurrentDomain] = useState<string | null>(null)
  const [inputError, setInputError] = useState('')

  function handleChangeDomain(domain: string | null) {
    setCurrentDomain(domain)
    setInputError('')
  }

  async function handleDeploy() {
    if (!currentDomain) {
      setInputError('Select a domain to continue.')
      return
    }

    onSubmitDomain(currentDomain)
  }

  useEffect(() => {
    // Use the saved domain by default if it exists in the user's Cloudflare account
    if (savedDomain && domains?.includes(savedDomain)) {
      handleChangeDomain(savedDomain)
      return
    }
    // Use the subscription domain by default if it exists in the user's Cloudflare account
    if (subscriptionDomain && domains?.includes(subscriptionDomain)) {
      handleChangeDomain(subscriptionDomain)
    }
  }, [savedDomain, subscriptionDomain, domains])

  const domainsError =
    !isLoading && !domains?.length
      ? {
          message: (
            <>
              We could not retrieve any domains from your Cloudflare account with the provided credentials. Please make
              sure your domain is added to Cloudflare. If you have any questions, please see the{' '}
              <Link href={DOCS_INTEGRATIONS.cloudflare} target='_blank' underline='none'>
                {' '}
                documentation
              </Link>{' '}
              or contact our{' '}
              <Link href={SUPPORT_PAGE_URL} target='_blank' underline='none'>
                support team
              </Link>
              .
            </>
          ),
        }
      : null

  return (
    <WizardStep
      title='Choose Domain'
      subtitle='Choose the domain from your Cloudflare account.'
      error={error || domainsError}
    >
      <WizardStepContent>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={domains ?? []}
          value={currentDomain}
          onChange={(_, newValue) => {
            handleChangeDomain(newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder='domain.com' error={!!inputError} helperText={inputError} autoFocus />
          )}
          disabled={isLoading}
          className={styles.input}
          data-testid='domain-autocomplete'
        />
      </WizardStepContent>

      <WizardStepActions>
        <Button
          key='deploy'
          color='primary'
          variant='contained'
          onClick={handleDeploy}
          disabled={isLoading || !currentDomain || Boolean(domainsError) || Boolean(inputError)}
          className={styles.button}
        >
          Continue
        </Button>
      </WizardStepActions>
    </WizardStep>
  )
}
