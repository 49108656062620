import { grey } from '@mui/material/colors'
import { alpha, createTheme } from '@mui/material/styles'
import { CSSProperties } from 'react'

export const primaryColor = '#FF5D22'
export const fontPrimaryColor = 'rgb(0 0 0 / 87%)'
export const fontSecondaryColor = 'rgba(0, 0, 0, 0.54)'

// https://mui.com/material-ui/guides/migration-v4/#button
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
    lightGrey: true
    neutral: true
    danger: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    orangeSubtle: true
    blueSubtle: true
    yellowSubtle: true
  }
  interface ChipPropsVariantOverrides {
    light: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    tiny: true
    '14': true
    '16': true
  }
  interface SvgIconPropsColorOverrides {
    'secondary-text': true
    gray: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    button: true
    buttonDisabled: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    lightGrey: Palette['primary']
    neutral: Palette['primary']
    danger: Palette['primary']
    orangeSubtle: Palette['primary']
    blueSubtle: Palette['primary']
    yellowSubtle: Palette['primary']
  }

  interface PaletteOptions {
    lightGrey: PaletteOptions['primary']
    neutral: PaletteOptions['primary']
    danger: PaletteOptions['primary']
    orangeSubtle: PaletteOptions['primary']
    blueSubtle: PaletteOptions['primary']
    yellowSubtle: PaletteOptions['primary']
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

const theme = createTheme({
  palette: {
    grey: {
      main: grey[300],
      dark: grey[400],
    },
    lightGrey: {
      main: '#999999',
      dark: '#999999',
    },
    neutral: {
      main: '#757575',
      dark: '#757575',
    },
    danger: {
      main: '#F44336',
      dark: '#DA3A33',
    },
    orangeSubtle: {
      main: '#FEF1EC',
      contrastText: '#FF5D22',
    },
    blueSubtle: {
      main: '#E9F4FE',
      contrastText: '#0D3C61',
    },
    yellowSubtle: {
      main: '#FFEFD1',
      contrastText: '#663D00',
    },
  },
})

// TODO: rewrite this to be more consistent with the v5 style
export default createTheme(theme, {
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: '#4CAF50',
    },
    info: {
      main: '#FEF1EC',
      contrastText: '#FF5D22',
    },
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'light', color: 'success' },
          style: {
            border: 0,
            backgroundColor: '#edf7ed',
            color: '#1e4620',
            fontSize: '12px',
            fontWeight: 'normal',
            padding: '0 8px',
            height: 'auto',
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'button' },
          style: {
            cursor: 'pointer',
            // TODO: unify MUI palette and mockups
            borderColor: '#E0E0E0',
            borderWidth: '1px',
            borderStyle: 'solid',
            transition: theme.transitions.create('background-color'),
            '&:hover': {
              // TODO: unify MUI palette and mockups
              backgroundColor: '#FAFAFA',
            },
          },
        },
        {
          props: { variant: 'buttonDisabled' },
          style: {
            // TODO: unify MUI palette and mockups
            borderColor: '#E0E0E0',
            borderWidth: '1px',
            borderStyle: 'solid',
          },
        },
      ],
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          transition: 'background-color 0.3s',
          backgroundColor: 'hsl(16, 79%, 51%)',
          '&:hover': {
            backgroundColor: 'hsl(16, 79%, 51%)',
          },
          '&:active': {
            color: '#ffffff',
            borderColor: '#b34118 !important',
            backgroundColor: `hsl(16, 76%, 40%)`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: { disableRipple: true },
      variants: [
        {
          props: { variant: 'contained', color: 'danger' },
          style: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#A9282C',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'danger' },
          style: {
            color: theme.palette.danger.main,
            borderColor: theme.palette.danger.main,
            boxShadow: 'none',
            '&:hover': {
              color: '#fff',
              boxShadow: 'none',
              borderColor: theme.palette.danger.main,
              backgroundColor: theme.palette.danger.main,
            },
            '&:active': {
              color: '#fff',
              boxShadow: 'none',
              borderColor: '#A9282C',
              backgroundColor: '#A9282C',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: theme.palette.text.primary,
            borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'lightGrey' },
          style: {
            color: 'rgba(153, 153, 153, 1)',
            borderColor: 'rgba(153, 153, 153, 1)',
          },
        },
        {
          props: { variant: 'text', color: 'grey' },
          style: {
            color: 'rgb(0 0 0 / 54%)',
            cursor: 'pointer',
            fontWeight: 500,
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: 'rgb(0 0 0 / 4%)',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: 'rgba(33, 150, 243, 1)',
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      ],
      styleOverrides: {
        sizeLarge: {
          fontSize: '12px',
          lineHeight: '24px',
        },
        containedPrimary: {
          boxShadow: 'none',
          transition: 'background-color 0.3s',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'hsl(16, 79%, 51%)',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&:active': {
            color: '#ffffff',
            borderColor: '#b34118 !important',
            backgroundColor: `hsl(16, 76%, 40%)`,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${primaryColor}`,
          transition: 'background-color 0.3s',
          '&:hover': {
            color: '#ffffff',
            backgroundColor: `${primaryColor}`,
          },
          '&:active': {
            color: '#ffffff',
            borderColor: '#b34118 !important',
            backgroundColor: `hsl(16, 76%, 40%)`,
          },
        },
      },
    },
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h1Widget: 'h1',
        h2: 'h2',
        h3: 'h3',
        caption: 'h2',
        body1: 'p',
        semiBody1: 'p',
        body2: 'p',
        semiBody2: 'p',
        body3: 'p',
        semiBody3: 'p',
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textTransform: 'uppercase',
          opacity: 0.5,
          fontWeight: 400,
          fontSize: '0.75rem',
        },
        gutters: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: '44px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginLeft: 0,
          marginTop: '4px',
          height: '15px',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '125%',
          letterSpacing: '0.004em',
          textTransform: 'unset',
          '&.Mui-error': {
            color: '#F44336',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'inherit !important',
          opacity: 0.54,
        },
        root: {
          '&.Mui-completed': {
            color: 'inherit !important',
            opacity: 0.87,
          },
          '&.Mui-active': {
            color: 'inherit !important',
            opacity: 0.87,
          },
        },
      },
    },
    MuiOutlinedInput: {
      notched: false,
      styleOverrides: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: '#8e8e8e',
          },
        },
        notchedOutline: {
          borderColor: '#E0E0E0',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          border: '1px solid #A6D0F2',
          backgroundColor: '#EAF4FC',
        },
        standardWarning: {
          background: '#FFEFD1',
          border: '1px solid #FFDA94',
        },
        standardError: {
          border: '1px solid #F2BDCD',
          background: '#FFE4EC',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 500,
          color: fontSecondaryColor,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginBottom: 24,
          '& *': {
            fontSize: 14,
          },
        },
        li: {
          '&:last-child *': {
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      },
    },
    MuiInputLabel: {
      shrink: false,
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '140%',
          marginBottom: '4px',
          color: fontPrimaryColor,
        },
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'tiny' },
          style: { fontSize: '16px' },
        },
        {
          props: { fontSize: '14' },
          style: { fontSize: '14px' },
        },
        {
          props: { fontSize: '16' },
          style: { fontSize: '16px' },
        },
        {
          props: { color: 'secondary-text' },
          style: { color: '#757575' },
        },
        {
          props: { color: 'gray' },
          style: { color: '#999999' },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#212121',
          opacity: 0.9,
          lineHeight: 'initial',
        },
        arrow: {
          color: '#212121',
          opacity: 0.9,
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '120%',
    },
    h1Widget: {
      fontWeight: 500,
      fontSize: '30px',
      lineHeight: '100%',
      letterSpacing: '0.01em',
    },
    h2: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '140%',
      letterSpacing: '0.01em',
    },
    h3: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '140%',
      letterSpacing: '0.01em',
    },
    caption: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '120%',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      color: fontSecondaryColor,
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
    },
    semiBody1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '150%',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
    },
    semiBody2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '140%',
    },
    body3: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '125%',
      letterSpacing: '0.004em',
    },
    semiBody3: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '125%',
      letterSpacing: '0.004em',
    },
    button: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
    },

    bodyS: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.004em',
      color: '#757575',
    },
    bodySMedium: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.004em',
      color: '#212121',
    },

    bodyM: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#757575',
    },
    bodyMMedium: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#212121',
    },

    bodyLMedium: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#212121',
    },

    //  Disable unused variants gradually as we migrate to the new ones.
    // h4: undefined,
    // h5: undefined,
    // h6: undefined,
    // inherit: undefined,
    // overline: undefined,
    // subtitle1: undefined,
    // subtitle2: undefined,
  },
})

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: CSSProperties
    h1Widget: CSSProperties
    h2: CSSProperties
    caption: CSSProperties
    body1: CSSProperties
    semiBody1: CSSProperties
    body2: CSSProperties
    semiBody2: CSSProperties
    body3: CSSProperties
    semiBody3: CSSProperties
    bodyS: CSSProperties
    bodySMedium: CSSProperties
    bodyM: CSSProperties
    bodyMMedium: CSSProperties
    bodyLMedium: CSSProperties
  }

  // Allow configuration of custom variants using `createTheme`
  interface TypographyVariantsOptions {
    h1?: CSSProperties
    h1Widget?: CSSProperties
    h2?: CSSProperties
    caption?: CSSProperties
    body1?: CSSProperties
    semiBody1?: CSSProperties
    body2?: CSSProperties
    semiBody2?: CSSProperties
    body3?: CSSProperties
    semiBody3?: CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true
    h1Widget: true
    h2: true
    h3: true
    caption: true
    body1: true
    semiBody1: true
    body2: true
    semiBody2: true
    body3: true
    semiBody3: true
    button: true
    bodyS: true
    bodySMedium: true
    bodyM: true
    bodyMMedium: true
    bodyLMedium: true

    //  Disable unused variants gradually as we migrate to the new ones.
    // h4: false
    // h5: false
    // h6: false
    // inherit: false
    // overline: false
    // subtitle1: false
    // subtitle2: false
  }
}
