import { Button, Grid, Stack, Typography } from '@mui/material'
import { WizardStepContent } from 'components/Wizard/Step/WizardStep'
import {
  IntegrationCategory,
  IntegrationLinkButton,
  useMobileIntegrationMetadata,
  useWebIntegrationMetadata,
} from 'features/integrations'
import { useCurrentSubscription } from 'features/subscription'
import { AnalyticsContext } from 'models'
import { useState } from 'react'

import { useCloudflareDomains, useCloudflareSetup, useSubscriptions } from '../../../hooks'
import { useGenerateWorkerName } from '../../../hooks/api/integrations'
import { default as WizardStepComponent } from '../../Wizard/Step/WizardStep'
import WizardWrapper, { WizardStep } from '../../Wizard/Wrapper/WizardWrapper'
import AccountCredentials from './AccountCredentials/AccountCredentials'
import ChooseDomain from './ChooseDomain/ChooseDomain'
import DeploymentInProgress from './DeploymentInProgress/DeploymentInProgress'
import DeployWorker from './DeployWorker/DeployWorker'
import { embeddedCtx } from './EmbeddedContext'

export enum CloudflareSetupSteps {
  AccountCredentials,
  ChooseDomain,
  DeployWorker,
  DeploymentInProgress,
  InstallSnippet,
}

export interface AccountCredentialsData {
  accountId: string
}

export interface ChooseDomainData {
  domain: string
  zoneId: string
  workerName: string
}

export interface DeployWorkerData {
  integrationId: string
}

export interface CloudflareSetupStepData {
  [CloudflareSetupSteps.AccountCredentials]?: AccountCredentialsData
  [CloudflareSetupSteps.ChooseDomain]?: ChooseDomainData
  [CloudflareSetupSteps.DeployWorker]?: DeployWorkerData
}

export interface CloudflareSetupData {
  furthestCompletedStep: number
  currentStep: number

  stepData?: CloudflareSetupStepData
}

export interface CloudflareSetupWizardProps {
  isLoading?: boolean
  isEmbedded?: boolean
  onClose?: () => void
  onFinished?: () => void
  analyticsContext: AnalyticsContext
}

export default function CloudflareSetupWizard({
  isLoading,
  onFinished,
  isEmbedded,
  analyticsContext,
}: CloudflareSetupWizardProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const {
    mutate: validateDomain,
    error: domainValidationError,
    isLoading: isValidatingDomain,
    reset: resetValidateDomain,
  } = useGenerateWorkerName()

  const [apiToken, setApiToken] = useState<string>()
  const [cloudflareSetupData, { setCurrentStep, setAccountCredentials, setDomainData, setDeployWorkerData }] =
    useCloudflareSetup()

  const currentStep = cloudflareSetupData.currentStep
  const accountCredentialsData = cloudflareSetupData.stepData?.[CloudflareSetupSteps.AccountCredentials]
  const chooseDomainData = cloudflareSetupData.stepData?.[CloudflareSetupSteps.ChooseDomain]
  const deployWorkerData = cloudflareSetupData.stepData?.[CloudflareSetupSteps.DeployWorker]

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } = useSubscriptions(true)
  const [subscription] = subscriptions
  const {
    data: cloudflareDomains,
    isFetching: isLoadingCloudflareDomains,
    error: cloudflareDomainsError,
  } = useCloudflareDomains({
    subscriptionId,
    cloudflareAccountId: accountCredentialsData?.accountId,
    cloudflareApiToken: apiToken,
    queryEnabled: currentStep === CloudflareSetupSteps.ChooseDomain,
  })

  const webIntegrations = useWebIntegrationMetadata()
  const mobileIntegrations = useMobileIntegrationMetadata()

  function handleChooseCredentials(accountId: string, apiToken: string) {
    setApiToken(apiToken)
    setAccountCredentials({ accountId })
  }

  function handleChooseDomain(domain: string) {
    // If a domain has been validated and saved, there's no need to check it again.
    if (domain === chooseDomainData?.domain) {
      setCurrentStep(CloudflareSetupSteps.DeployWorker)
      return
    }

    validateDomain(
      {
        data: {
          subscriptionId,
          accountId: accountCredentialsData?.accountId ?? '',
          apiToken: apiToken ?? '',
          domain,
        },
      },
      {
        onSuccess: ({ zoneId, workerName }) => {
          setDomainData({ domain, zoneId, workerName })
          setCurrentStep(CloudflareSetupSteps.DeployWorker)
        },
      }
    )
  }

  const { currentSubscriptionId } = useCurrentSubscription()

  function handleStartDeployment(id: string) {
    setDeployWorkerData({ integrationId: id })
    setCurrentStep(CloudflareSetupSteps.DeploymentInProgress)
  }

  const steps: WizardStep[] = [
    {
      component: (
        <AccountCredentials
          subscriptionId={currentSubscriptionId}
          onChooseCredentials={handleChooseCredentials}
          analyticsContext={analyticsContext}
          onNext={() => {
            resetValidateDomain()
            setCurrentStep(CloudflareSetupSteps.ChooseDomain)
          }}
        />
      ),
    },
    {
      component: (
        <ChooseDomain
          domains={cloudflareDomains?.result.map((d) => d.domain) ?? []}
          subscriptionDomain={subscription?.domain}
          savedDomain={chooseDomainData?.domain}
          onSubmitDomain={handleChooseDomain}
          error={domainValidationError ?? cloudflareDomainsError}
          isLoading={isValidatingDomain || isLoadingCloudflareDomains || isLoadingSubscriptions}
        />
      ),
      onGoBack: () => {
        setCurrentStep(CloudflareSetupSteps.AccountCredentials)
      },
    },
    {
      component: (
        <DeployWorker
          analyticsContext={analyticsContext}
          domain={chooseDomainData?.domain ?? ''}
          subscriptionId={currentSubscriptionId ?? ''}
          apiToken={apiToken ?? ''}
          accountId={accountCredentialsData?.accountId ?? ''}
          zoneId={chooseDomainData?.zoneId ?? ''}
          workerName={chooseDomainData?.workerName ?? ''}
          onSubmitId={handleStartDeployment}
        />
      ),
      onGoBack: () => {
        /**
         * The user can restart the wizard after page reload and continue with the DeployWorker step
         * But if the go back from there, their Cloudflare token is not saved in component state, nor restored from local storage
         * So we need to go all the way back to the AccountCredentials step to get the token again
         */
        setCurrentStep(apiToken ? CloudflareSetupSteps.ChooseDomain : CloudflareSetupSteps.AccountCredentials)
        resetValidateDomain()
      },
    },
    {
      component: (
        <DeploymentInProgress
          integrationId={deployWorkerData?.integrationId ?? ''}
          onCompletedDeploy={() => setCurrentStep(CloudflareSetupSteps.InstallSnippet)}
          onRestartDeployment={() => setCurrentStep(CloudflareSetupSteps.DeployWorker)}
        />
      ),
      nested: true,
    },
    {
      component: (
        <WizardStepComponent title='Install Fingerprint SDK'>
          <WizardStepContent>
            <Stack spacing={3} mb={3} mt={-2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h3'>{IntegrationCategory.WebLibraries}</Typography>
                </Grid>

                {webIntegrations.map((integration) => (
                  <Grid item key={integration.title} xs={12} sm={4}>
                    <IntegrationLinkButton integration={integration} external />
                  </Grid>
                ))}
                <Grid item xs={12} mt={2}>
                  <Typography variant='h3'>{IntegrationCategory.MobileLibraries}</Typography>
                </Grid>

                {mobileIntegrations.map((integration) => (
                  <Grid item key={integration.title} xs={12} sm={4}>
                    <IntegrationLinkButton integration={integration} external />
                  </Grid>
                ))}
              </Grid>
            </Stack>

            <Button variant='contained' onClick={onFinished}>
              Done
            </Button>
          </WizardStepContent>
        </WizardStepComponent>
      ),
    },
  ]

  return (
    <embeddedCtx.Provider value={!!isEmbedded}>
      <WizardWrapper
        steps={steps}
        isEmbedded={isEmbedded}
        currentStep={currentStep}
        furthestCompletedStep={cloudflareSetupData.furthestCompletedStep}
        commitStep={CloudflareSetupSteps.DeployWorker}
        onSelectStep={setCurrentStep}
        isLoading={isLoading || isLoadingCloudflareDomains}
      />
    </embeddedCtx.Provider>
  )
}
