import { OpenInNew } from '@mui/icons-material'
import { CheckCircleOutlineOutlined, ChevronRight } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Grid,
  Link,
  Paper,
  Skeleton,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { useCertificates, useSubscription, useToast } from 'hooks'
import { useIntegrationStatusGetStarted } from 'hooks/api/integration_status'
import { useSubscriptionTokens, useTokenCreateMutation } from 'hooks/api/tokens'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { Suspense, useCallback, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { apiKeyDisplayNameMap } from '../../../apiKeys/components/KeySection/KeySection'
import { createMDXBaseComponents } from '../../../integrations/components/IntegrationMetadata/MDX/MDXBaseComponents'
import { useCurrentSubscription } from '../../../subscription/hooks/currentSubscription'
import {
  IntegrationCategory,
  integrationList,
  IntegrationMetadata,
} from './../../../integrations/components/IntegrationMetadata/integrationMetadata'
import { IntegrationReadmeContext } from './../../../integrations/components/IntegrationMetadata/MDX/IntegrationReadmeContext'
import { ApiKeyWidgetData } from './../../../integrations/components/IntegrationMetadata/MDX/MDXApiKeyWidget/MDXApiKeyWidget'
import { getIntegrationReadmeProps } from './../../../integrations/components/IntegrationMetadata/utils'
import { useIntegrationData } from './../../../integrations/integrationHooks'
import styles from './Integrations.module.scss'

const ANALYTICS_CONTEXT = AnalyticsContext.GetStarted

const CustomStepConnector = styled(StepConnector)<StepConnectorProps>(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E0E0E0',
  },
}))

function IntegrationComponentMDX({
  integration,
  currentSubscriptionId,
}: {
  integration: IntegrationMetadata
  currentSubscriptionId: string
}) {
  if (!integration.mdx) {
    throw new Error('Unsupported format')
  }

  const { data: subscription, isLoading: isSubscriptionLoading } = useSubscription(currentSubscriptionId)
  const { data: subscriptionTokens, isLoading: isTokenLoading } = useSubscriptionTokens(currentSubscriptionId)
  const { data: subscriptionCertificates, isLoading: isCertificateLoading } = useCertificates(currentSubscriptionId)
  const { integrationData, isLoading: isIntegrationDataLoading } = useIntegrationData(currentSubscriptionId)
  const { mutate: sendCreateApiKeyRequest, isLoading: isCreatingApiKey, data: secretApiKey } = useTokenCreateMutation()
  const { showToast } = useToast()

  const onGenerate = useCallback(
    (data: ApiKeyWidgetData) => {
      sendCreateApiKeyRequest(
        {
          data,
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: (data) => {
            ampli.apiKeyAdded({ apiKeyType: apiKeyDisplayNameMap[data.type], environmentChanged: false })
          },
          onError: (e) => {
            showToast({ message: e.message, severity: 'error' })
          },
        }
      )
    },
    [sendCreateApiKeyRequest, currentSubscriptionId, showToast]
  )

  const isLoading = isSubscriptionLoading || isTokenLoading || isCertificateLoading || isIntegrationDataLoading

  const mdxProps = getIntegrationReadmeProps(
    integration?.integrationTag,
    subscription,
    subscriptionTokens,
    subscriptionCertificates,
    integrationData,
    secretApiKey
  )
  const keyWidgetProps = { onGenerate, apiKey: secretApiKey, isLoading: isCreatingApiKey }

  const loader = useMemo(
    () => (
      <Box>
        <Skeleton height={40} width={300} />
        <Skeleton />
        <Skeleton />
        <Skeleton width={100} />
      </Box>
    ),
    []
  )
  const MDXComponentsWithContext = createMDXBaseComponents(ANALYTICS_CONTEXT)

  return (
    <div>
      <Stack spacing={3} paddingTop='4px'>
        <Stack direction='row' flexWrap='wrap' alignItems='center' gap={2}>
          {integration.docsLink && (
            <Link component={Link} className={styles.openDocsLink} target='_blank' href={integration.docsLink}>
              Open docs
              <OpenInNew className={styles.linkIcon} />
            </Link>
          )}
        </Stack>

        {isLoading ? (
          loader
        ) : (
          <IntegrationReadmeContext.Provider value={{ ...mdxProps, ...keyWidgetProps }}>
            <Suspense fallback={loader}>
              <integration.mdx {...mdxProps} components={MDXComponentsWithContext} />
            </Suspense>
          </IntegrationReadmeContext.Provider>
        )}
      </Stack>
    </div>
  )
}

const CheckInstallationButton = ({ onClick, loading }: { onClick: () => void; loading: boolean }) => (
  <LoadingButton
    onClick={onClick}
    loading={loading}
    variant='contained'
    size='large'
    className={styles.checkInstallationButton}
  >
    {/* known issue: https://github.com/mui/material-ui/issues/27853 */}
    <span>Check installation</span>
  </LoadingButton>
)

export function Integrations() {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const integrationsByCategory = useMemo(() => {
    return {
      [IntegrationCategory.WebLibraries]: integrationList.filter(
        (integration) => integration.category === IntegrationCategory.WebLibraries
      ),
      [IntegrationCategory.MobileLibraries]: integrationList.filter(
        (integration) => integration.category === IntegrationCategory.MobileLibraries
      ),
    }
  }, [])
  const currentCategory = activeTabIndex === 0 ? IntegrationCategory.WebLibraries : IntegrationCategory.MobileLibraries

  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationMetadata>(
    integrationsByCategory[currentCategory][0]
  )

  const { currentSubscriptionId } = useCurrentSubscription()

  const {
    data: integrationStatus,
    refetch: refetchIntegrationStatus,
    isFetching: isIntegrationStatusFetching,
  } = useIntegrationStatusGetStarted(currentSubscriptionId, false)

  const handleCardClick = (integration: IntegrationMetadata) => {
    setSelectedIntegration(integration)
    ampli.selectSnippetType({
      context: ANALYTICS_CONTEXT,
      snippetType: integration.integrationTag,
    })
  }

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue)
    const currentCategory = newValue === 0 ? IntegrationCategory.WebLibraries : IntegrationCategory.MobileLibraries
    setSelectedIntegration(integrationsByCategory[currentCategory][0])
  }

  const handleCheckInstallation = () => {
    refetchIntegrationStatus()
  }

  const renderIntegrations = (integrations: IntegrationMetadata[]) => (
    <Grid container spacing={1} columns={7} sx={{ marginTop: 2 }}>
      {integrations.map((integration) => (
        <Grid item xs={3.5} lg={1} key={integration.title}>
          <button
            className={clsx(styles.integrationButton, {
              [styles.selected]: selectedIntegration?.integrationTag === integration.integrationTag,
            })}
            onClick={() => handleCardClick(integration)}
          >
            <integration.iconComponent className={styles.icon} />
            <Typography variant='bodyM' className={styles.integrationTitle}>
              {integration.title}
            </Typography>
          </button>
        </Grid>
      ))}
    </Grid>
  )

  const steps = [
    {
      label: 'Choose your framework',
      content: (
        <>
          <Typography variant='body2' className={styles.subtitle}>
            You can always install on another platform later.
          </Typography>
          <Tabs classes={{ flexContainer: styles.tabs }} value={activeTabIndex} onChange={handleTabChange}>
            <Tab classes={{ root: styles.tab, selected: styles.selected }} label='Web' />
            <Tab classes={{ root: styles.tab, selected: styles.selected }} label='Mobile' />
          </Tabs>
          {renderIntegrations(integrationsByCategory[currentCategory])}
        </>
      ),
    },
    {
      label: `Integrate with ${selectedIntegration?.title}`,
      content: selectedIntegration && (
        <IntegrationComponentMDX integration={selectedIntegration} currentSubscriptionId={currentSubscriptionId} />
      ),
    },
    {
      label: 'Send request',
      content: (
        <Typography variant='body2' className={styles.subtitle}>
          Open the page where you installed Fingerprint and wait a second, the script automatically runs on page load.
        </Typography>
      ),
    },
    {
      label: 'Verify installation',
      content: !integrationStatus ? (
        <>
          <Typography variant='body2' className={styles.subtitle}>
            Let&apos;s make sure the script is working as expected.
          </Typography>
          <CheckInstallationButton onClick={handleCheckInstallation} loading={isIntegrationStatusFetching} />
        </>
      ) : integrationStatus?.currentStep === 'apiCalls' ? (
        <>
          <Typography variant='body2' className={styles.subtitle}>
            No API call has been made. Please make sure you opened the page where you installed Fingerprint.
          </Typography>
          <CheckInstallationButton onClick={handleCheckInstallation} loading={isIntegrationStatusFetching} />
        </>
      ) : (
        <>
          <Typography variant='body2' className={styles.successMessage}>
            <CheckCircleOutlineOutlined color='success' className={styles.successIcon} />
            Success! Your script is working. Go to events to view live data.
          </Typography>
          <Link
            component={RouterLink}
            to={buildRoute(AppRoute.IdentificationVisits, { subscriptionId: currentSubscriptionId })}
            className={styles.eventsLink}
          >
            Go to events
            <ChevronRight className={styles.linkIcon} />
          </Link>
        </>
      ),
    },
  ]

  return (
    <Paper className={styles.container}>
      <Stepper orientation='vertical' activeStep={-1} connector={<CustomStepConnector />}>
        {steps.map((step, index) => (
          <Step key={index} active expanded>
            <StepLabel
              classes={{ labelContainer: styles.labelContainer, iconContainer: styles.stepIcon, label: styles.label }}
            >
              {step.label}
            </StepLabel>
            <StepContent classes={{ root: styles.content }}>{step.content}</StepContent>
          </Step>
        ))}
      </Stepper>
    </Paper>
  )
}
