import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Box, CircularProgress, Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { appSettingNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useCurrentSubscriptionData } from 'features/subscription'
import { APPLICATION_NAME_VALIDATION, DOMAIN_VALIDATION } from 'helpers/validation'
import { useSubscriptionRename, useSubscriptionUpdate, useToast } from 'hooks'
import { IntegrationStep, SubscriptionStatus } from 'models'
import { ampli } from 'models/ampli'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { USE_WORKSPACE_ENVIRONMENT } from '../../const'
import GeneralSettingsEntry from './components/GeneralSettingsEntry/GeneralSettingsEntry'
import UpdateGeneralSettingDialog from './components/UpdateGeneralSettingDialog/UpdateGeneralSettingDialog'
import { WorkspaceEnvironmentTable } from './components/WorkspaceEnvironmentTable/WorkspaceEnvironmentTable'
import styles from './SubscriptionSettingsPage.module.scss'

type FieldName = 'name' | 'domain'
type DialogProps = {
  title: string
  validationRules: Record<string, unknown>
  onSubmit: (data: { newValue: string }) => void
}

type FormValues = { newValue: string }

export function SubscriptionSettingsPage() {
  const { subscription } = useCurrentSubscriptionData()
  const { mutate: sendSubscriptionRenameRequest, isLoading: isRenamingApp } = useSubscriptionRename()
  const { mutate: sendUpdateSubscriptionRequest, isLoading: isUpdatingApp } = useSubscriptionUpdate()
  const [editField, setEditField] = useState<FieldName>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { hasMadeRequests, isLoading: isLoadingUsageStatus } = useHasMadeRequestsPreviously()
  const { showToast } = useToast()

  const editModalProps: Record<FieldName, DialogProps> = {
    name: {
      title: 'Change App Name',
      validationRules: APPLICATION_NAME_VALIDATION,
      onSubmit: (data: FormValues) => {
        sendSubscriptionRenameRequest(
          { params: { id: subscription?.id ?? '' }, data: { name: data.newValue } },
          {
            onSuccess: () => {
              setIsModalOpen(false)
              ampli.renameApplicationComplete()
              showToast({ severity: 'success', message: 'App name was updated' })
            },
          }
        )
      },
    },
    domain: {
      title: 'Change Domain Name',
      validationRules: DOMAIN_VALIDATION,
      onSubmit: ({ newValue }: FormValues) => {
        sendUpdateSubscriptionRequest(
          { params: { id: subscription?.id ?? '' }, data: { domain: newValue || null } },
          {
            onSuccess: () => {
              setIsModalOpen(false)
              showToast({ severity: 'success', message: 'App domain was updated' })
            },
          }
        )
      },
    },
  }

  function handleOpenModal(field: FieldName) {
    setEditField(field)
    setIsModalOpen(true)
  }

  if (!subscription) {
    return <CircularProgress data-testid='general-settings-page-loader' />
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainContent>
        <Box className={styles.settings}>
          {!isLoadingUsageStatus && !hasMadeRequests && (
            <Alert severity='warning' icon={<InfoOutlinedIcon />}>
              We couldn&apos;t help but notice your app has no usage. Get up to speed in minutes using our powerful
              integrations.{' '}
              <Link
                component={RouterLink}
                to={buildRoute(AppRoute.Integrations, { subscriptionId: subscription.id })}
                color='inherit'
              >
                Install Fingerprint now
              </Link>
            </Alert>
          )}
          <GeneralSettingsEntry
            label='App Name'
            value={subscription.name}
            linkText='Change name'
            onClickLink={() => {
              handleOpenModal('name')
              ampli.renameApplicatonViewed()
            }}
          />
          <GeneralSettingsEntry
            label='App Domain'
            value={subscription.domain!}
            linkText={subscription.domain ? 'Change domain' : 'Add domain'}
            onClickLink={() => handleOpenModal('domain')}
          />
          {USE_WORKSPACE_ENVIRONMENT && <WorkspaceEnvironmentTable />}
        </Box>
      </MainContent>
      {isModalOpen && editField && (
        <UpdateGeneralSettingDialog
          isOpen
          onClose={() => {
            setIsModalOpen(false)
            if (editField === 'name') {
              ampli.renameApplicationCancelled()
            }
          }}
          editField={editField}
          defaultValue={subscription[editField]}
          disabled={isRenamingApp || isUpdatingApp}
          {...editModalProps[editField]}
        />
      )}
    </>
  )
}

function useHasMadeRequestsPreviously() {
  const { subscription } = useCurrentSubscriptionData()
  const [hasMadeRequests, setHasMadeRequests] = useState<boolean>()

  useEffect(() => {
    const hasNotMadeApiCalls =
      subscription?.status !== SubscriptionStatus.Canceled && subscription?.integrationStep === IntegrationStep.ApiCalls
    setHasMadeRequests(!hasNotMadeApiCalls)
  }, [subscription?.integrationStep, subscription?.status])

  return { hasMadeRequests, isLoading: hasMadeRequests === undefined }
}
