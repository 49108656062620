import { FormHelperText, InputLabel } from '@mui/material'
import clsx from 'clsx'
import { ClipboardEvent, createRef, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { GenericError } from '../../const'
import styles from './EmailVerificationInput.module.scss'

export interface EmailVerificationInputProps {
  digits: number
  label?: string
  loading?: boolean
  error?: GenericError | null
  onCompleted?: (result: { completed: boolean; code: string }) => void
  onChanged?: (code: string) => void
  onSubmit?: (code: string) => void
  className?: string
}

export default function EmailVerificationInput({
  digits,
  label,
  error,
  onCompleted,
  onChanged,
  onSubmit,
  loading,
  className,
}: EmailVerificationInputProps) {
  useEffect(() => {
    if (digits < 3 || digits > 12) {
      throw new Error('invalid_digits')
    }
  }, [digits])

  const inputRef = useMemo(() => {
    return Array.from({ length: digits }, () => {
      return createRef<HTMLInputElement>()
    })
  }, [digits])

  const [verificationCode, setVerificationCode] = useState<string>(Array.from({ length: digits }, () => ' ').join(''))

  useEffect(() => {
    onChanged?.(verificationCode)
  }, [verificationCode, onChanged])

  useEffect(() => {
    if (!loading) {
      setVerificationCode(Array.from({ length: digits }, () => ' ').join(''))
      const timeOut = setTimeout(() => {
        inputRef[0].current?.focus()
        clearTimeout(timeOut)
      }, 300)
    }
  }, [loading, digits, inputRef])

  const handleChange = useCallback(
    (code: string) => {
      const formattedCode = code.replace(/\s/g, '')
      const completed = formattedCode.length === digits
      onCompleted && onCompleted({ completed, code: formattedCode })
    },
    [digits, onCompleted]
  )

  const changeVerificationCode = useCallback(
    (index: number, value: string) => {
      setVerificationCode(() => {
        const newDigits = verificationCode.split('')
        newDigits[index] = value ? value : ' '
        const code = newDigits.join('')
        handleChange(code)
        return code
      })
    },
    [handleChange, verificationCode]
  )
  const getVerificationCodeSlice = useCallback((index: number) => verificationCode[index], [verificationCode])

  const findTargetElementAndFocus = useCallback(
    (index: number) => {
      if (index in inputRef) {
        inputRef[index].current?.focus()
      }
    },
    [inputRef]
  )

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const codeInput = event.clipboardData.getData('text/plain').trim().replace(/\D+/g, '').substr(0, digits)
    setVerificationCode(codeInput)
    handleChange(codeInput)
  }

  const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit?.(verificationCode)
    }
  }

  return (
    <div className={clsx(styles.root, className)}>
      <InputLabel>{label}</InputLabel>

      <div
        data-testid='email-verification-input-container'
        className={clsx(styles.inputContainer, 'email-verification-code', !!error && 'hasError')}
      >
        {Array.from({ length: digits }, (_, index) => (
          <input
            key={`verification_input_${index}`}
            autoFocus={index === 0}
            value={getVerificationCodeSlice(index)}
            id={`vi_${index}`}
            ref={inputRef[index]}
            type='text'
            pattern='[0-9]*'
            disabled={loading}
            className={clsx(!!error && styles.hasError)}
            onFocus={() => {
              const el = inputRef[index].current
              if (el && el?.value.length > 0) {
                el.setSelectionRange(0, el.value.length)
              }
            }}
            onKeyPress={onEnter}
            onPaste={handlePaste}
            onChange={(e) => {
              const el = inputRef[index].current
              const value = e.target.value.trim()
              if (el) {
                if (!value.length) {
                  changeVerificationCode(index, ' ')
                  findTargetElementAndFocus(index - 1)
                } else if (!isNaN(Number(value))) {
                  changeVerificationCode(index, value[0])
                  findTargetElementAndFocus(index + 1)
                }
              }
            }}
          />
        ))}
      </div>
      <FormHelperText error={true}>
        {error ? error.message ?? 'That did not work, please try again.' : ' '}
      </FormHelperText>
    </div>
  )
}
