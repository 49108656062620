import { useParams } from 'react-router-dom'

export enum AppRoute {
  // External routes (e.g. auth)
  Signup = '/signup',
  SignupConfirm = '/signup/confirm',
  SignupConfirmWithIntent = '/signup/confirm/:signupIntent',
  SSOCallbackRoute = '/sso/callback',
  Login = '/login',
  PasswordReset = '/reset',
  PasswordResetConfirm = '/reset/:id',
  InvitationConfirm = '/invitation_confirmation/:id',
  Impersonate = '/impersonate',

  // Application level routes
  SubscriptionPlan = '/subscriptions/:subscriptionId/plan',
  ChangePlan = '/subscriptions/:subscriptionId/change_plan',
  Integration = '/subscriptions/:subscriptionId/integrations/:integrationTag',
  Integrations = '/subscriptions/:subscriptionId/integrations',
  CustomSubdomains = '/subscriptions/:subscriptionId/custom-domain',
  CustomSubdomainNew = '/subscriptions/:subscriptionId/custom-domain/new',
  CustomSubdomainView = '/subscriptions/:subscriptionId/custom-domain/:subdomainId',
  SubscriptionCancellationSurvey = '/subscriptions/:subscriptionId/plan/cancel',
  SubscriptionCancellationSurveyConfirm = '/subscriptions/:subscriptionId/plan/cancel/confirm',
  SubscriptionSettings = '/subscriptions/:subscriptionId/general',
  Invoice = '/subscriptions/:subscriptionId/invoices/:invoiceId',
  UpcomingInvoice = '/subscriptions/:subscriptionId/invoices/upcoming',
  Webhooks = '/subscriptions/:subscriptionId/webhooks',
  WebhookEvents = '/subscriptions/:subscriptionId/webhooks/:webhookId/events',
  WebhookVerification = '/subscriptions/:subscriptionId/webhooks/:webhookId/verify',
  IdentificationVisits = '/subscriptions/:subscriptionId/visits',
  IdentificationVisitDetails = '/subscriptions/:subscriptionId/visits/:requestId',
  Botd = '/subscriptions/:subscriptionId/botd',
  SmartSignals = '/subscriptions/:subscriptionId/smart-signals',
  Health = '/subscriptions/:subscriptionId/health',
  SdkHealth = '/subscriptions/:subscriptionId/health/sdk',
  ApiKeys = '/subscriptions/:subscriptionId/api-keys',
  RequestFiltering = '/subscriptions/:subscriptionId/traffic-rules',
  SubscriptionRoot = '/subscriptions/:subscriptionId',
  SubscriptionOverview = '/subscriptions/:subscriptionId/overview',
  SuspectScore = '/subscriptions/:subscriptionId/suspect-score',
  SubscriptionGetStarted = '/subscriptions/:subscriptionId/get-started',

  // Account level routes
  AccountApplications = '/settings/applications',
  UserManagement = '/settings/users',
  Billing = '/settings/billing',
  AccountDangerZone = '/settings/danger_zone',
  ProfileSettings = '/profile/settings',
  ContactSalesEnterprise = '/contact-sales/enterprise',

  // Other
  Home = '/',
  Onboarding = '/onboarding',
  AddSecondaryApplication = '/subscriptions/start',
  Consent = '/consent',
  Unsubscribe = '/unsubscribe',
  UnsubscribeNewsletter = '/newsletter/unsubscribe',
  ReadmeLogin = '/readme_login',
}

// Optionally add query param typings:
//
//   type CommonQueryParams = {/** UTM or other stuff */}
//   type RouteQueryParamMap = {
//     [index: string]: Record<string, string> // type indexing
//     [Route.WebhookEvents]: {
//       errorMessage?: string
//     }
//   }
//   type SearchParams<R> = CommonQueryParams & RouteQueryParamMap<R>
//
type SearchParams = Record<string, string>

export function buildRoute<R extends AppRoute>(
  route: R,
  pathParams = {} as RouteParams<R>,
  searchParams?: SearchParams
) {
  let href: string = route

  for (const [key, value] of Object.entries(pathParams)) {
    href = href.replace(`:${key}`, `${value}`)
  }

  // Safety measure
  if (href.includes('/:')) {
    throw new Error('Invalid route parameters')
  }

  if (searchParams) {
    href = href + '?' + new URLSearchParams(searchParams).toString()
  }

  return href
}

export function useRouteParams<Route extends AppRoute>() {
  return useParams<{ [Param in keyof RouteParams<Route>]: string }>()
}

// Transforms route literal into a record type with route path params as keys
type InferRouteSegments<Route> = Route extends `${infer Left}/${infer Right}` ? Left | InferRouteSegments<Right> : Route
type InferRouteParamNames<RouteSegments> = RouteSegments extends `:${infer ParamName}` ? ParamName : never
type ParamNamesToRecord<ParamNames extends string> = { [Param in ParamNames]: string | number }
type RouteParams<Route> = ParamNamesToRecord<InferRouteParamNames<InferRouteSegments<Route>>>

export const SUBSCRIPTION_SHORTCUT_ROUTES = Object.values(AppRoute)
  .filter((route) => route.startsWith(AppRoute.SubscriptionRoot) && route !== AppRoute.SubscriptionRoot)
  .map((route) => route.replace(AppRoute.SubscriptionRoot, '').replace(':subscriptionId/', ''))
