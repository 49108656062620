export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type DefinedRange = {
  startDate: Date
  endDate: Date
  label: string
}

export enum Marker {
  FirstMonth,
  SecondMonth,
}
