import { Dns, Settings, VpnKey } from '@mui/icons-material'
import { IconButton, Table, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { TableBody, TableBodyData, TableCell, TableRow } from 'components/Table/Table'
import { formatNum } from 'helpers/format'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import PlanTag from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsCompactTable.module.scss'

export type ApplicationsCompactTableProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsCompactTable({ subscriptions }: ApplicationsCompactTableProps) {
  return (
    <Table>
      <TableBody columnCount={5}>
        <TableBodyData>
          {subscriptions.map((it) => (
            <TableRow key={it.id}>
              <TableCell className={styles.applicationCell}>
                <Typography variant='bodyMMedium' className={styles.applicationName}>
                  {it.name}
                </Typography>

                <div className={styles.property}>
                  <Typography variant='bodyMMedium' className={styles.propertyValue}>
                    {it.domain || '-'}
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Domain
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={styles.applicationCell}>
                <div className={styles.property}>
                  <Typography variant='bodyMMedium' className={styles.usageValue}>
                    {it.usageQuota?.billed ? formatNum(it.usageQuota.billed) : '-'}
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Usage
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={styles.applicationCell}>
                <div className={styles.property}>
                  <Typography variant='bodyMMedium' className={styles.propertyValue}>
                    <PlanTag compact subscription={it} />
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Plan
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={styles.applicationCell}>
                <div className={styles.property}>
                  <Typography variant='bodyMMedium' className={styles.propertyValue}>
                    <StatusTag compact status={it.status} />
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Status
                  </Typography>
                </div>
              </TableCell>
              <TableCell align='right' className={styles.actionsCell}>
                <div className={styles.actionSet}>
                  <IconButton
                    size='small'
                    title='Application keys'
                    href={buildRoute(AppRoute.ApiKeys, { subscriptionId: it.id })}
                  >
                    <VpnKey fontSize='tiny' />
                  </IconButton>
                  <IconButton
                    size='small'
                    title='Application visits'
                    href={buildRoute(AppRoute.IdentificationVisits, { subscriptionId: it.id })}
                  >
                    <Dns fontSize='tiny' />
                  </IconButton>
                  <IconButton
                    size='small'
                    title='Application settings'
                    href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: it.id })}
                  >
                    <Settings fontSize='tiny' />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
