import { Check, Close } from '@mui/icons-material'
import { Button, FormHelperText, IconButton, InputLabel, Link, OutlinedInput, Tooltip, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { APITypesMap } from 'const'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { EMAIL_VALIDATION } from 'helpers/validation'
import { useDebounce } from 'hooks/debounce'
import { useSavedEmail } from 'hooks/savedEmail'
import { UserConsentModel } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { AuthForm } from '../AuthForm/AuthForm'
import styles from '../AuthForm/AuthForm.module.scss'
import { AuthWrapper } from '../AuthWrapper/AuthWrapper'
import { OAuthSection } from '../OAuth/OAuthSection'
import { Props } from '../types'

export type LogInFormData = APITypesMap['logIn']['body']
export type LogInFormProps = Props<LogInFormData> & Required<Pick<Props<LogInFormData>, 'onCheckSso'>>

export function LogInForm({
  onSubmit,
  error,
  isLoading,
  isLoadingOAuth,
  isSsoEnabled,
  onCheckSso,
  oAuthProviders,
}: LogInFormProps) {
  const { savedEmail, forgetEmail } = useSavedEmail()

  const formMethods = useForm<LogInFormData & Partial<UserConsentModel>>({
    defaultValues: { email: savedEmail },
    mode: 'onChange',
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = formMethods
  const email = watch('email')

  const debouncedEmail = useDebounce(email)
  const [emailMethodShown, setEmailMethodShown] = useState(false)
  const { search } = useLocation()

  useEffect(() => {
    onCheckSso(debouncedEmail)
  }, [debouncedEmail, onCheckSso])

  const errorParams = getErrorParams('email', errors, error)

  const onLoginClick = useCallback(() => {
    ampli.logInClicked({ source: 'login form' })
  }, [])

  return (
    <AuthWrapper
      isLoading={isLoading}
      title='Log In'
      subtitle={
        <>
          New to Fingerprint?{' '}
          <Link component={RouterLink} to={{ pathname: AppRoute.Signup, search }} data-testid='layoutSignupButton'>
            Sign up
          </Link>
        </>
      }
    >
      <AuthForm onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <div className={styles.field}>
            {oAuthProviders && <OAuthSection isLoading={isLoadingOAuth} providers={oAuthProviders} />}
            {!emailMethodShown && (
              <Button
                title='Continue with email'
                variant='text'
                size='large'
                color='primary'
                fullWidth
                className={styles.showEmailButton}
                onClick={() => setEmailMethodShown(true)}
                data-testid='showEmailButton'
              >
                {isLoading ? 'Please wait...' : 'Continue with email'}
              </Button>
            )}

            {emailMethodShown && (
              <>
                <InputLabel htmlFor='email'>Email</InputLabel>
                <OutlinedInput
                  id='email'
                  type='email'
                  placeholder='Email Address'
                  data-testid='emailAddressField'
                  {...muiRegister(register, 'email', EMAIL_VALIDATION)}
                  autoFocus={!isLoading && !savedEmail}
                  error={errorParams.error}
                  endAdornment={
                    savedEmail !== '' && email === savedEmail ? (
                      <Tooltip title='Not you?' arrow placement='top'>
                        <IconButton
                          onClick={() => {
                            setValue('email', '')
                            setValue('password', '')
                            forgetEmail()
                          }}
                          edge='end'
                          size='small'
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    ) : undefined
                  }
                  className={styles.input}
                  fullWidth
                />
                <FormHelperText sx={{ marginBottom: '8px' }} error={errorParams.error}>
                  {errorParams.helperText}
                </FormHelperText>
                {isSsoEnabled ? (
                  <div className={styles.ssoHint}>
                    <Check className={styles.icon} /> <Typography variant='body2'>Single sign-on enabled</Typography>
                  </div>
                ) : (
                  <>
                    <PasswordInput
                      error={error}
                      inputProps={{ 'data-testid': 'passwordField' }}
                      autoFocus={!isLoading && !!savedEmail}
                      email={email}
                      className={clsx(styles.field, styles.input)}
                    />
                  </>
                )}

                <Button
                  disabled={isLoading}
                  className={styles.loginButton}
                  data-testid='loginButton'
                  title='Log In'
                  variant='contained'
                  size='large'
                  color='primary'
                  type='submit'
                  onClick={onLoginClick}
                >
                  Log In
                </Button>
                {!isSsoEnabled && (
                  <FormHelperText error={errorParams.error} className={styles.resetWrapper}>
                    <Link
                      component={RouterLink}
                      to={{ pathname: AppRoute.PasswordReset, state: { email } }}
                      underline='hover'
                      data-testid='resetPasswordLink'
                      className={clsx(styles.reset, { [styles.resetError]: errorParams.error })}
                    >
                      Forgot password?
                    </Link>
                  </FormHelperText>
                )}
              </>
            )}
          </div>
        </FormProvider>
      </AuthForm>
    </AuthWrapper>
  )
}
