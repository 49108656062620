import { useQuery } from '@tanstack/react-query'
import { APITypesMap, CounterQueryType, counterQueryTypeToCounterTypes, counterQueryTypeToProduct } from 'const'
import { DateTime } from 'luxon'
import { ClientCounterType, UsageCounterPeriod } from 'models'
import { useMemo } from 'react'

import { filterUndefinedProperties } from '../../helpers/common'
import { useBuildRequest, useGenericErrorHandler, withMeta } from './base'

// get usage counters for a time period for subscription
export function useUsageCounters({
  subscriptionId,
  from,
  to,
  queryType,
  customTypes,
  period,
  timezone,
  enabled = true,
  keepPreviousData = false,
}: {
  subscriptionId?: string
  from?: DateTime
  to?: DateTime
  queryType?: CounterQueryType
  customTypes?: ClientCounterType[]
  period?: UsageCounterPeriod
  timezone?: string
  enabled?: boolean
  keepPreviousData?: boolean
}) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  const queryParams = useMemo(
    () =>
      getChartDataRequestParams({
        from,
        to,
        queryType,
        customTypes,
        period,
        timezone,
      }),
    [from, to, queryType, customTypes, period, timezone]
  )

  const typesKey = queryParams?.type?.slice().sort().join('.')

  return withMeta(
    useQuery({
      queryKey: ['usageCounters', subscriptionId, from, to, period, typesKey, queryType, timezone] as const,
      queryFn: ({ queryKey: [_, subscriptionId] }) =>
        withErrorHandling(
          buildRequest('usageCounters', {
            params: {
              subscriptionId: subscriptionId!,
            },
            queryParams,
          })
        ),
      enabled: enabled && subscriptionId != null && queryParams != null && period != null,
      keepPreviousData,
    })
  )
}

function getChartDataRequestParams({
  from,
  to,
  queryType,
  customTypes,
  period,
  timezone,
}: {
  from?: DateTime
  to?: DateTime
  queryType?: CounterQueryType
  customTypes?: ClientCounterType[]
  period?: UsageCounterPeriod
  timezone?: string
}): APITypesMap['usageCounters']['queryParams'] | undefined {
  if (!from || !to || !queryType || !period || !timezone) {
    return undefined
  }

  const usageCounterTypes = customTypes?.sort() ?? counterQueryTypeToCounterTypes[queryType]
  const usageCounterProduct = counterQueryTypeToProduct[queryType]

  const start = from.toFormat("yyyy-MM-dd'T'HH")
  const end = to.toFormat("yyyy-MM-dd'T'HH")

  return filterUndefinedProperties({
    from: start,
    to: end,
    type: usageCounterTypes,
    period,
    product: usageCounterProduct,
  })
}
