import { Alert, AlertTitle, Button, FormHelperText, InputLabel, Link, OutlinedInput, Typography } from '@mui/material'
import { DOCS_INTEGRATIONS, SUPPORT_PAGE_URL } from 'const'
import { DialogActions } from 'features/commonUI'
import { muiRegister } from 'helpers/reactHookForm'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import styles from './IntegrationCloudflareEdit.module.scss'

export type IntegrationCloudflareEditProps = {
  onClose: () => void
  onSubmit?: (apiToken: string) => Promise<void>
  validateToken: (token: string) => Promise<boolean>
  isAuthError?: boolean
}

type FormState = {
  apiToken: string
}

const INVALID_API_TOKEN_TYPE = 'INVALID_API_TOKEN'

export default function IntegrationCloudflareEdit({
  onClose,
  onSubmit,
  validateToken,
  isAuthError,
}: IntegrationCloudflareEditProps) {
  const form = useForm<FormState>({
    defaultValues: {
      apiToken: '',
    },
  })

  const handleSubmit = useCallback(
    async (values: FormState) => {
      if (!(await validateToken(values.apiToken))) {
        form.setError('apiToken', {
          message: 'The API token seems to be invalid.',
          type: INVALID_API_TOKEN_TYPE,
        })

        return
      }

      await onSubmit?.(values.apiToken)

      onClose()
    },
    [form, onClose, onSubmit, validateToken]
  )

  const showAuthError = isAuthError || form.formState.errors.apiToken?.type === INVALID_API_TOKEN_TYPE

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} className={styles.container}>
      <Typography variant='h1'>Manage Cloudflare token</Typography>
      {showAuthError && (
        <Alert severity='error'>
          The provided Cloudflare API token is invalid. Please double-check the{' '}
          <Link target='_blank' color='inherit' href={`${DOCS_INTEGRATIONS.cloudflare}#cloudflare-api-token`}>
            token permissions
          </Link>
          !
        </Alert>
      )}
      <Typography variant='body2' className={styles.description}>
        Fingerprint automatically keeps your Cloudflare integration up to date using the API token you provided. If you
        need to update this token, you can generate a new one in your Cloudflare dashboard and paste it below. Learn
        more in our{' '}
        <Link target='_blank' href={DOCS_INTEGRATIONS.cloudflare}>
          developer docs.
        </Link>
      </Typography>
      <Alert icon={false} severity='warning'>
        <AlertTitle>Heads up!</AlertTitle>
        Updating the token redeploys your Cloudflare integration worker. Manual changes to the configuration will be
        overwritten, but existing added routes are not affected. Please{' '}
        <Link target='_blank' href={SUPPORT_PAGE_URL}>
          contact support
        </Link>{' '}
        with any questions.
      </Alert>
      <div>
        <InputLabel>Cloudflare API Token</InputLabel>
        <OutlinedInput
          placeholder='Enter valid token here...'
          fullWidth
          type='password'
          autoComplete='new-password'
          error={!!form.formState.errors.apiToken}
          {...muiRegister(form.register, 'apiToken', {
            required: true,
          })}
        />
        {form.formState.errors.apiToken && (
          <FormHelperText error>{form.formState.errors.apiToken.message}</FormHelperText>
        )}
      </div>
      <DialogActions className={styles.actions}>
        <Button variant='outlined' color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' type='submit' disabled={!form.formState.isDirty}>
          Update token
        </Button>
      </DialogActions>
    </form>
  )
}
