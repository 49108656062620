import { DefaultDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useSubscription } from 'hooks'
import { useCurrentUser } from 'hooks/api/users'

import SubscriptionOverview from './components/SubscriptionOverview/SubscriptionOverview'

export function SubscriptionOverviewPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  // Prefetch the current user for timezone.
  useCurrentUser()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  useDocumentTitle(`Overview - ${subscription?.name ?? 'Subscription'}`)

  return (
    <DefaultDateRangeContext>
      <SubscriptionOverview />
    </DefaultDateRangeContext>
  )
}
