import { Add, DeleteOutline, ErrorOutline } from '@mui/icons-material'
import {
  Alert,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { APITypesMap } from 'const'
import { isContainDuplicates, isValidSubDomain } from 'helpers/validation'
import { ampli } from 'models/ampli'
import * as React from 'react'
import { memo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { pluralize } from '../../../../helpers/pluralize'
import styles from './Create.module.scss'
import StepProps from './types'

const maxSanDomainsCount = 50

interface CreateStepProps extends StepProps {
  onSubmit: (data: FormData) => void
  isLoading?: boolean
}

type FormData = APITypesMap['certificateCreate']['body'] & { newDomain?: string }

export default memo(function CreateStep({ certificate, onSubmit, isLoading }: CreateStepProps) {
  const {
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      domains: certificate?.sans ?? [],
    },
  })

  const domains = watch('domains')
  const [newDomainName, setNewDomainName] = useState('')
  const [isInputVisible, setIsInputVisible] = useState(domains.length === 0)

  const onFormSubmit = useCallback(
    (data: FormData) => {
      if (!data?.domains || data.domains.length === 0) {
        setError('newDomain', {
          type: 'manual',
          message: 'You should provide at least one domain',
        })
        return
      }
      ampli.certificateDomainStepCompleted()
      onSubmit(data)
    },
    [onSubmit, setError]
  )

  const handleDomainNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.trim()

      setNewDomainName(newValue)

      if (!isValidSubDomain(newValue)) {
        setError('newDomain', {
          type: 'manual',
          message: 'Invalid domain format',
        })

        return
      }

      if (isContainDuplicates([...domains, newValue])) {
        setError('newDomain', {
          type: 'manual',
          message: 'This domain is already added',
        })

        return
      }

      clearErrors('newDomain')
    },
    [setError, clearErrors, domains]
  )

  const shouldHideInput = useCallback(() => {
    return domains.length > 0 && (!errors.newDomain || newDomainName.length === 0)
  }, [domains, errors, newDomainName])

  function hideInput() {
    setNewDomainName('')
    setIsInputVisible(false)
  }

  const onSubdomainSubmit = useCallback(() => {
    if (newDomainName && !errors.newDomain) {
      ampli.certificateDomainAdded()
      setValue('domains', [...domains, newDomainName])
      hideInput()
    }
  }, [domains, newDomainName, errors, setValue])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubdomainSubmit()
      } else if (e.key === 'Escape' && shouldHideInput()) {
        hideInput()
      }
    },
    [onSubdomainSubmit, shouldHideInput]
  )

  const handleRemoveDomain = useCallback(
    (domainToDel: string) => {
      ampli.certificateDomainDeleted()
      const updatedDomains = domains.filter((domain) => domain !== domainToDel)
      setValue('domains', updatedDomains)

      if (updatedDomains.length === 0) {
        setIsInputVisible(true)
      }
    },
    [domains, setValue]
  )

  return (
    <Paper className={styles.container}>
      <Alert className={styles.alert} severity='warning' icon={<ErrorOutline />}>
        <Typography variant='body2'>
          Enter up to {pluralize(maxSanDomainsCount, 'domain name')} to use with this SSL certificate.
        </Typography>
      </Alert>

      <Typography component='h2' variant='h6' className={styles.title}>
        Domain Names
      </Typography>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <List>
          {domains?.map((domain, index) => (
            <React.Fragment key={domain}>
              <ListItem className={styles.domain} classes={{ container: styles.listItemContainer }}>
                <ListItemText primary={domain} />

                <input {...register(`domains.${index}`)} type='hidden' value={domain} />
                <ListItemSecondaryAction>
                  <IconButton size='small' onClick={() => handleRemoveDomain(domain)} title='Remove subdomain'>
                    <DeleteOutline />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {index !== domains.length - 1 && <Divider />}
            </React.Fragment>
          ))}

          {isInputVisible && (
            <ListItem className={styles.listInput}>
              <InputLabel htmlFor='add_san_field'>Enter domain name:</InputLabel>
              <ClickAwayListener
                onClickAway={() => {
                  if (shouldHideInput()) {
                    hideInput()
                  }
                }}
              >
                <TextField
                  variant='outlined'
                  id='add_san_field'
                  error={!!errors.newDomain}
                  className={styles.domainNameInput}
                  placeholder='metrics.yoursite.com'
                  helperText={errors.newDomain?.message}
                  value={newDomainName}
                  onChange={handleDomainNameChange}
                  onKeyDown={handleKeyDown}
                  onBlur={onSubdomainSubmit}
                  autoFocus
                />
              </ClickAwayListener>
            </ListItem>
          )}
        </List>

        <Button
          startIcon={<Add />}
          color='primary'
          className={styles.addButton}
          disabled={isLoading || isInputVisible || domains.length >= maxSanDomainsCount}
          onClick={() => {
            ampli.certificateNewDomainClicked()
            setIsInputVisible(true)
          }}
        >
          Add domain
        </Button>
        <Button type='submit' color='primary' variant='contained' disabled={isLoading || isInputVisible}>
          Next
        </Button>
      </form>
    </Paper>
  )
})
