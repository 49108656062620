import { ShowChartOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { Link as RouterLink } from 'react-router-dom'

import styles from './EmptyState.module.scss'

export const EmptyState = () => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  return (
    <Box className={styles.emptyState}>
      <Box className={styles.emptyStateKeyIcon}>
        <ShowChartOutlined />
      </Box>
      <Box className={styles.emptyStateTextContent}>
        <Typography variant='semiBody1'>Not enough data</Typography>
        <Typography variant='bodyS'>We need more data to generate this chart. Make some API requests!</Typography>
      </Box>
      <Button
        component={RouterLink}
        variant='contained'
        color='primary'
        size='small'
        className={styles.emptyStateButton}
        to={buildRoute(AppRoute.Integrations, { subscriptionId })}
      >
        Go to integrations
      </Button>
    </Box>
  )
}
