import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import { DOCS_SUSPECT_SCORE_WEIGHTS } from '../../const'
import { parseIntValues } from '../../helpers/common'
import { useDocumentTitle, useToast } from '../../hooks'
import { useSuspectScores, useSuspectScoresUpdateMutation } from '../../hooks/api/suspect_score'
import { Platform, SuspectScore } from '../../models'
import { ampli } from '../../models/ampli'
import { appSettingNavItems, Header, MainGrid, TabNavigation } from '../commonUI'
import { useCurrentSubscription } from '../subscription'
import { EditableTabs } from './components/EditableTabs/EditableTabs'
import { SuspectScoreList } from './components/SuspectScoreList/SuspectScoreList'
import { LOADER_WEIGHTS, PLATFORM_NAMES } from './content'

export function SuspectScorePage() {
  useDocumentTitle('Suspect Score')

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: suspectScores, isLoading } = useSuspectScores(currentSubscriptionId)
  const { mutate: createSuspectScoreOverrides, isLoading: isUpdating } = useSuspectScoresUpdateMutation()
  const { showToast } = useToast()

  const [selected, setSelected] = useState<string>(Platform.Browser)
  const [dirtyTabs, setDirtyTabs] = useState<Partial<Record<Platform, boolean>>>({})

  const handleDirtyStateChanged = useCallback(
    (tab: Platform, dirtyState: boolean) => {
      setDirtyTabs((prevState) => ({ ...prevState, [tab]: dirtyState }))
    },
    [setDirtyTabs]
  )
  const handleSaveWeights = useCallback(
    (platform: Platform, data: Record<string, string>) => {
      createSuspectScoreOverrides(
        {
          data: { [platform]: parseIntValues(data) },
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: () => {
            showToast({
              message: 'Suspect Score weights updated. Please allow a few minutes for changes to propagate.',
              severity: 'success',
            })
            ampli.suspectScoreWeightsSaved({ clientType: PLATFORM_NAMES[platform] })
          },
        }
      )
    },
    [createSuspectScoreOverrides, currentSubscriptionId, showToast]
  )
  const handleResetWeights = useCallback(
    (platform: Platform, data: Partial<Record<SuspectScore, number>>) => {
      createSuspectScoreOverrides(
        {
          data: { [platform]: data },
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: () => {
            showToast({
              message:
                'Suspect Score weights restored to defaults. Please allow a few minutes for changes to propagate.',
              severity: 'success',
            })
            ampli.suspectScoreWeightsDefaultsRestored({ clientType: PLATFORM_NAMES[platform] })
          },
        }
      )
    },
    [createSuspectScoreOverrides, currentSubscriptionId, showToast]
  )

  const tabs = useMemo(
    () =>
      Object.values(Platform).map((platform) => ({
        id: platform,
        name: PLATFORM_NAMES[platform],
        component: (
          <>
            {
              <SuspectScoreList
                platform={platform}
                weights={suspectScores?.scores[platform] ?? LOADER_WEIGHTS[platform]}
                defaultWeights={suspectScores?.defaults[platform] ?? LOADER_WEIGHTS[platform]}
                onSave={(data) => handleSaveWeights(platform, data)}
                onReset={() => handleResetWeights(platform, suspectScores!.defaults[platform])}
                onDirtyStateChanged={(dirtyState) => handleDirtyStateChanged(platform, dirtyState)}
                isLoading={isLoading || isUpdating}
              />
            }
          </>
        ),
      })),
    [handleDirtyStateChanged, handleResetWeights, handleSaveWeights, isLoading, isUpdating, suspectScores]
  )

  return (
    <>
      <Header title='App settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainGrid>
        <Grid item xs={12}>
          <Stack gap='24px'>
            <Box component='hgroup' display='flex' flexDirection='column' gap='8px'>
              <Typography variant='h2'>Suspect Score weights</Typography>
              <Typography variant='bodyM'>
                Adjust the weight of each Smart Signal that contributes to the Suspect Score.{' '}
                <Link
                  href={DOCS_SUSPECT_SCORE_WEIGHTS}
                  target='_blank'
                  underline='hover'
                  onClick={() => ampli.suspectScoreWeightsDocOpened()}
                >
                  Learn more about Suspect Score
                </Link>
                .
              </Typography>
            </Box>
            <EditableTabs selected={selected} onChange={setSelected} dirtyTabs={dirtyTabs} tabs={tabs} />
          </Stack>
        </Grid>
      </MainGrid>
    </>
  )
}
