import { Dns, Info, Settings, VpnKey } from '@mui/icons-material'
import { IconButton, TableHead, Tooltip, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Table, TableBody, TableBodyData, TableCell, TableRow } from 'components/Table/Table'
import { formatNum } from 'helpers/format'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import PlanTag from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsTable.module.scss'

export type ApplicationsTableProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsTable({ subscriptions }: ApplicationsTableProps) {
  return (
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell>Application</TableCell>
          <TableCell>Domain</TableCell>
          <TableCell>
            Usage{' '}
            <Tooltip title='API calls made during the current billing period' arrow placement='top-end'>
              <Info fontSize='tiny' className={styles.tooltipIcon} />
            </Tooltip>
          </TableCell>
          <TableCell>Plan</TableCell>
          <TableCell>Status</TableCell>
          <TableCell align='right'>Go to</TableCell>
        </TableRow>
      </TableHead>

      <TableBody columnCount={6}>
        <TableBodyData>
          {subscriptions.map((it) => (
            <TableRow key={it.id}>
              <TableCell>
                <Typography variant='bodyMMedium'>{it.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='bodyM'>{it.domain || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='bodyM' className={styles.usageValue}>
                  {it.usageQuota?.billed ? formatNum(it.usageQuota.billed) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <PlanTag subscription={it} />
              </TableCell>
              <TableCell>
                <StatusTag status={it.status} />
              </TableCell>
              <TableCell align='right'>
                <div className={styles.actionSet}>
                  <IconButton
                    size='small'
                    title='Application keys'
                    href={buildRoute(AppRoute.ApiKeys, { subscriptionId: it.id })}
                  >
                    <VpnKey fontSize='tiny' />
                  </IconButton>
                  <IconButton
                    size='small'
                    title='Application visits'
                    href={buildRoute(AppRoute.IdentificationVisits, { subscriptionId: it.id })}
                  >
                    <Dns fontSize='tiny' />
                  </IconButton>
                  <IconButton
                    size='small'
                    title='Application settings'
                    href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: it.id })}
                  >
                    <Settings fontSize='tiny' />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
