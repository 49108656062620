import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { Button, Skeleton, Typography } from '@mui/material'
import Tag from 'components/Tag/Tag'
import { USER_ROLES } from 'const'
import { AccountTransferRequest, User } from 'models'

import { UserInvitedSince } from '../UserInvitedSince/UserInvitedSince'
import { ManagedUser, USER_ROLE_COLORS } from '../UserManagement/UserManagementData'
import { UserManagementActionMenu } from '../UserManagementActionMenu/UserManagementActionMenu'
import styles from './UsersList.module.scss'

export interface UsersListProps {
  currentUser?: User
  users?: ManagedUser[]
  onEditUser: (userId: string) => void
  onDeleteUser: (userId: string) => void

  pendingAccountTransfer?: AccountTransferRequest
  onTransferOwnership: (user: ManagedUser) => void
  onCancelOwnershipTransfer: (user: ManagedUser) => void
}

export function UsersList({
  currentUser,
  users,
  onEditUser,
  onDeleteUser,

  pendingAccountTransfer,
  onTransferOwnership,
  onCancelOwnershipTransfer,
}: UsersListProps) {
  return (
    <div>
      {!users ? (
        <Skeleton height='375px' data-testid='user-management-skeleton' />
      ) : (
        users.map((user) => {
          const { canReceiveOwnership, isPendingTransfer } = user
          const shouldShowExtraActions = canReceiveOwnership || isPendingTransfer

          return (
            <div key={user.id} className={styles.usersListItem}>
              <div className={styles.usersListItemRow}>
                <div className={styles.userIdentity}>
                  <Typography variant='bodyMMedium'>
                    {user.name} {currentUser?.id === user.id && '(you)'}
                  </Typography>
                  <Typography variant='bodyM'>{user.email}</Typography>
                </div>

                <Tag bold label={USER_ROLES[user.role].name} color={USER_ROLE_COLORS[user.role]} />
              </div>
              <div className={styles.usersListItemRow}>
                <UserInvitedSince pendingAccountTransfer={pendingAccountTransfer} user={user} />

                <div className={styles.userActions}>
                  {user.isEditable && (
                    <Button
                      size='small'
                      endIcon={<EditIcon fontSize='inherit' />}
                      color='neutral'
                      onClick={() => onEditUser(user.id)}
                    >
                      Edit
                    </Button>
                  )}

                  {user.isDeletable && (
                    <Button
                      size='small'
                      endIcon={<DeleteIcon fontSize='inherit' />}
                      color='neutral'
                      onClick={() => onDeleteUser(user.id)}
                    >
                      Delete
                    </Button>
                  )}

                  {shouldShowExtraActions && (
                    <UserManagementActionMenu
                      isPendingTransfer={isPendingTransfer}
                      onTransfer={() => onTransferOwnership(user)}
                      onCancelTransfer={() => onCancelOwnershipTransfer(user)}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
