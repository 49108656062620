import * as amplitude from '@amplitude/analytics-browser'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { ExpandedSubscription, OnboardingStep, SubscriptionStatus, SubscriptionType } from 'models'
import { ampli, GroupProperties } from 'models/ampli'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { getScreenDimensions } from '../helpers/browser'
import { isInternalFingerprintUser } from '../helpers/user'
import {
  enableAnalytics,
  initGTM,
  resetDataLayerProperties,
  trackUserId,
  updateDataLayerProperties,
  useAmplitude,
} from '../helpers/vendor'
import { CustomEventGTM, sendCustomEventGTM } from '../helpers/vendor'
import { useUserContext } from './api/context'
import { useSubscriptions } from './api/subscriptions'
import { useUserStats } from './api/userStats'
import { useViewportDimensions } from './dom'
import { useSmartSignals } from './smartSignals'
import { useAuth } from './user'

function useDevicePropertiesTracking() {
  const { isClientLoaded } = useAmplitude()
  const screenDimensions = useMemo(() => getScreenDimensions(), [])
  const viewportDimensions = useViewportDimensions(1000)

  useEffect(() => {
    if (!isClientLoaded || !ampli.isLoaded) {
      return
    }

    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('screenWidth', screenDimensions.width)
    identifyEvent.set('screenHeight', screenDimensions.height)
    identifyEvent.set('viewportWidth', viewportDimensions.width)
    identifyEvent.set('viewportHeight', viewportDimensions.height)

    ampli.client.identify(identifyEvent)
  }, [screenDimensions, viewportDimensions, isClientLoaded])
}

const FINAL_ONBOARDING_STATES = [OnboardingStep.Skipped, OnboardingStep.Finished]

function useDashboardSessionTracking() {
  const { data: user } = useUserContext()

  const [previousOnboardingStep, setPreviousOnboardingStep] = useState<OnboardingStep | undefined>(undefined)
  const currentOnboardingStep = user?.onboardingStep

  useEffect(() => {
    const wasUserAuthorized = !!previousOnboardingStep
    const isUserAuthorized = !!currentOnboardingStep

    if (!isUserAuthorized) {
      return
    }

    const isOnboardingFinished = FINAL_ONBOARDING_STATES.includes(currentOnboardingStep)
    const wasOnboardingInProgress = wasUserAuthorized && !FINAL_ONBOARDING_STATES.includes(previousOnboardingStep)

    if (!wasUserAuthorized && isOnboardingFinished) {
      ampli.dashboardSessionStarted()
    }

    if (wasOnboardingInProgress && isOnboardingFinished) {
      ampli.dashboardLaunchedFirstTime()
      ampli.dashboardSessionStarted()
    }

    setPreviousOnboardingStep(currentOnboardingStep)
  }, [currentOnboardingStep, previousOnboardingStep])
}

export function useCustomerBillingType() {
  const { data: subscriptions, refetch: refetchSubscriptions } = useSubscriptions(undefined, false)
  const [billingType, setBillingType] = useState<GroupProperties['billingType']>()
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  useEffect(() => {
    if (!userId) {
      return
    }
    refetchSubscriptions()
  }, [userId, refetchSubscriptions])

  useEffect(() => {
    if (!userId) {
      setBillingType(undefined)
      return
    }
    setBillingType(() => {
      const ignoredStatuses = [
        SubscriptionStatus.Canceled,
        SubscriptionStatus.Creating,
        SubscriptionStatus.Trialing,
        SubscriptionStatus.Incomplete,
        SubscriptionStatus.IncompleteExpired,
      ]

      const ignoredTypes = [SubscriptionType.Free, SubscriptionType.TrialOnly]

      const types = subscriptions
        ?.filter((s) => !ignoredTypes.includes(s.type))
        .filter((s) => !ignoredStatuses.includes(s.status))
        .map((s) => s.type)

      if (!types?.length) {
        return 'None'
      }
      if (types.every((t) => t === SubscriptionType.Prepaid)) {
        return 'Contract'
      }
      if (types.every((t) => t === SubscriptionType.Paid)) {
        return 'Subscription'
      }
      return 'Mix'
    })
  }, [subscriptions, userId])

  return billingType
}

function useGroupPropertiesTracking() {
  const { isClientLoaded } = useAmplitude()
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id
  const customerId = accessTokenPayload?.customerId
  const { data: visitorData } = useVisitorData({ extendedResult: true })
  const { data: smartSignals } = useSmartSignals(visitorData?.requestId)
  const { data: context } = useUserContext()
  const visitorId = visitorData?.visitorId
  const billingType = useCustomerBillingType()
  const isInternalUser = context?.email && isInternalFingerprintUser({ email: context.email })

  useEffect(() => {
    if (!isClientLoaded || !ampli.client) {
      return
    }

    ampli.client.setUserId(userId)

    if (customerId) {
      ampli.groupIdentify('customer', customerId ?? '', { billingType })
    }

    ampli.setGroup('customer', customerId ?? '')
    ampli.setGroup('fingerprint-device-id', visitorId ?? '')

    const userProperties: Record<string, unknown> = {
      isInternal: !!isInternalUser,
    }

    if (visitorId) {
      ampli.client.setDeviceId(visitorId)
      userProperties.visitorId = visitorId
    }

    if (smartSignals?.products?.botd?.data) {
      userProperties.botDetected = smartSignals.products.botd.data?.bot?.result === 'bad'
    }

    if (context?.isDeveloper !== undefined) {
      userProperties.isDeveloper = context?.isDeveloper
    }

    ampli.identify(userId, userProperties)
  }, [userId, customerId, visitorId, isClientLoaded, billingType, isInternalUser, smartSignals, context?.isDeveloper])
}

export function useApplicationGroupTracking() {
  const { isClientLoaded } = useAmplitude()
  const trackApplication = useCallback(
    (subscriptionId: ExpandedSubscription['id']) => {
      if (!isClientLoaded || !ampli.client) {
        return
      }

      ampli.setGroup('Application', subscriptionId)
    },
    [isClientLoaded]
  )

  return {
    trackApplication,
  }
}

export function useGTMDataLayerProperties() {
  const { isAuthorized, isImpersonating } = useAuth()

  const { data: userStats } = useUserStats(isAuthorized && !isImpersonating)

  useEffect(() => {
    if (isImpersonating || !isAuthorized) {
      resetDataLayerProperties()
    }

    if (isAuthorized && userStats) {
      const { createdAt, workspaces, milestones, filteringRules, webhooks, subdomains } = userStats.customer

      updateDataLayerProperties({
        created_at: createdAt ? new Date(createdAt).toISOString() : '',
        trialing: workspaces.hasTrialingOnly,
        paying: workspaces.hasPaid,
        enterprise: workspaces.hasPrepaid,
        region: workspaces.region,
        workspace_count: workspaces.workspaceCount,
        milestone_1_api_call: milestones.oneApiCall,
        milestone_100_api_call: milestones.hundredApiCalls,
        milestone_subdomain: subdomains.hasSubdomain,
        milestone_webhook: webhooks.hasActiveWebhook,
        milestone_filtering: filteringRules.hasFilteringRules,
      })
      if (milestones.oneApiCall) {
        sendCustomEventGTM(CustomEventGTM.FirstSeenApiCall)
      }
    }
  }, [isAuthorized, isImpersonating, userStats])
}

export function useAppAnalytics() {
  useDevicePropertiesTracking()
  useDashboardSessionTracking()
  useGroupPropertiesTracking()
  useGTMDataLayerProperties()
}

export function AnalyticsProvider({ children }: PropsWithChildren<{}>) {
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  useEffect(() => {
    initGTM()
    enableAnalytics()
  }, [])

  useEffect(() => {
    trackUserId(userId)
  }, [userId])

  return <>{children}</>
}
