import { Order, PaginationAndSortingOptions } from 'models'

import { InvoiceListItem } from '../models'

export function getQueryParamsWithSanitizePaginationAndSorting<T>({
  limit,
  offset,
  sortBy,
  order,
}: PaginationAndSortingOptions<T>) {
  const params = {
    limit: limit?.toString(),
    offset: offset?.toString(),
    sortBy,
    order: order?.toUpperCase() as Order,
  }
  Object.keys(params).forEach(
    (key: keyof PaginationAndSortingOptions<InvoiceListItem>) => params[key] === undefined && delete params[key]
  )

  return params
}
