import { Typography } from '@mui/material'
import AddEntityButton from 'components/AddEntityButton/AddEntityButton'
import InfoDrawer from 'components/InfoDrawer/InfoDrawer'
import PageHeader from 'components/PageHeader/PageHeader'
import { COMPANY_NAME, DOCS_WEBHOOKS_URL } from 'const'
import { appSettingNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'
import { SubscriptionWebhook } from 'models'
import { ampli } from 'models/ampli'
import { memo, useCallback, useState } from 'react'
import { flushSync } from 'react-dom'

import WebhookDialog, { WebhookFormData } from '../WebhookDialog/WebhookDialog'
import WebhooksTable, { WebhooksProps } from '../WebhooksTable/WebhooksTable'

export interface WebhooksPageProps extends Omit<WebhooksProps, 'entityData' | 'onWebhookAdd' | 'onWebhookEdit'> {
  onWebhookAdd: (data: WebhookFormData) => void
  onWebhookEdit: (data: WebhookFormData) => void
}

export default memo(function Webhooks(props: WebhooksPageProps) {
  useDocumentTitle('Webhooks')

  const { webhooks, webhooksLimit, onWebhookAdd, onWebhookEdit } = props
  const [editableWebhook, setEditableWebhook] = useState<SubscriptionWebhook>()
  const [webhookEditModalIsOpen, setWebhookEditModalIsOpen] = useState(false)

  const handleAddWebhook = useCallback(() => {
    ampli.addWebhookClicked()
    setWebhookEditModalIsOpen(true)
  }, [])

  const handleEditWebhook = useCallback(
    (webhookId: string) => {
      const editedWebhook = webhooks.find((w) => w.id === webhookId)
      // React 18 is now batching state changes, and without flushSync it starts rendering the dialog
      // with undefined as the editable webhook, resulting in bad default values to be loaded in the form.
      flushSync(() => setEditableWebhook(editedWebhook))
      setWebhookEditModalIsOpen(true)
    },
    [webhooks]
  )

  const handleWebhookSubmit = useCallback(
    (data: WebhookFormData) => {
      setWebhookEditModalIsOpen(false)

      if (editableWebhook) {
        onWebhookEdit(data)
        setEditableWebhook(undefined)
      } else {
        onWebhookAdd(data)
      }
    },
    [editableWebhook, onWebhookAdd, onWebhookEdit]
  )

  const handleWebhookDialogClose = useCallback(() => {
    setWebhookEditModalIsOpen(false)
    setEditableWebhook(undefined)
  }, [])

  const webhookData = {
    name: 'Webhook',
    count: webhooks?.length ?? 0,
    limit: webhooksLimit,
    onAddClick: handleAddWebhook,
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainContent>
        <PageHeader
          title='Webhooks'
          infoDrawer={<WebhooksInfoDrawer />}
          actions={<AddEntityButton entityData={webhookData} />}
        />
        <WebhooksTable
          {...props}
          entityData={webhookData}
          onWebhookAdd={handleAddWebhook}
          onWebhookEdit={handleEditWebhook}
        />

        <WebhookDialog
          open={webhookEditModalIsOpen}
          onClose={handleWebhookDialogClose}
          onSubmit={handleWebhookSubmit}
          webhook={editableWebhook}
        />
      </MainContent>
    </>
  )
})

function WebhooksInfoDrawer() {
  return (
    <InfoDrawer title='Webhooks' action='Read more' actionHref={DOCS_WEBHOOKS_URL}>
      <Typography variant='body1'>
        A webhook is when {COMPANY_NAME} makes an HTTP POST request to <b>your</b> application&#39;s API whenever an
        event occurs.
      </Typography>
      <Typography variant='body1'>
        This way, you don&#39;t have to perform complicated polling to determine if something new happened.
      </Typography>
    </InfoDrawer>
  )
}
