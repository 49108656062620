import { Box, Button, Grid, InputLabel, Link, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import Loader from '../../../../components/Loader/Loader'
import { DOCS_MULTIPLE_ENVIRONMENTS_URL } from '../../../../const'
import { getErrorParams } from '../../../../helpers/data'
import { muiRegister } from '../../../../helpers/reactHookForm'
import { useToast } from '../../../../hooks'
import { useWorkspaceEnvironmentCreateMutation } from '../../../../hooks/api/environment'
import { WorkspaceEnvironment } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '../../../commonUI'
import { useCurrentSubscription } from '../../../subscription'
import styles from './CreateWorkspaceEnvironmentDialog.module.scss'

export interface CreateEnvironmentDialogProps extends DialogProps {
  onClose: () => void
  environments?: WorkspaceEnvironment[]
}

type WorkspaceEnvironmentData = {
  environmentName: string
}

export default function CreateWorkspaceEnvironmentDialog({
  environments,
  ...dialogProps
}: CreateEnvironmentDialogProps) {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()

  const { showToast } = useToast()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutate: sendCreateWorkspaceEnvironmentRequest, isLoading } = useWorkspaceEnvironmentCreateMutation()

  const validate = (data: WorkspaceEnvironmentData) => {
    if (!data.environmentName) {
      setError('environmentName', {
        type: 'validate',
        message: 'Please give your environment a name.',
      })

      return false
    } else if (environments?.some((savedEnvironment) => savedEnvironment.name === data.environmentName)) {
      setError('environmentName', {
        type: 'validate',
        message: 'Environment with this name already exists.',
      })
      return false
    }

    return true
  }

  const onSubmit = (data: WorkspaceEnvironmentData) => {
    if (!validate(data)) {
      return
    }

    ampli.environmentSelected({ featureContext: 'Request Filtering' })
    ampli.environmentSelectionOpened({ featureContext: 'Request Filtering' })

    sendCreateWorkspaceEnvironmentRequest(
      {
        params: {
          subscriptionId: currentSubscriptionId,
        },
        data: {
          name: data.environmentName,
        },
      },
      {
        onSuccess: () => {
          ampli.environmentDeleteStarted()
          showToast({
            severity: 'success',
            message: 'Success! Environment created.',
            duration: 5000,
          })
          dialogProps.onClose()
        },
        onError: () => {
          showToast({
            message: 'An error occurred when creating your environment. Please try again.',
            severity: 'error',
          })
          dialogProps.onClose()
        },
      }
    )
  }

  return (
    <Dialog {...dialogProps} classes={{ paper: styles.dialog }}>
      {isLoading && <Loader testId='api-keys-loader' />}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
          <Typography variant='h1' component='h1' className={styles.heading}>
            Create new environment
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <Grid rowGap={'40px'}>
            <Typography className={styles.label}>
              Use environments to contain API keys and request filtering rules, like &quot;staging&quot;.{' '}
              <Link href={DOCS_MULTIPLE_ENVIRONMENTS_URL} target='_blank' underline='hover'>
                Learn more about environments.
              </Link>
            </Typography>

            <InputLabel>Name</InputLabel>
            <TextField
              defaultValue={''}
              placeholder='My dev environment'
              {...muiRegister(register, 'environmentName')}
              {...getErrorParams('environmentName', errors)}
              fullWidth
            />
          </Grid>
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Box className={styles.buttons}>
            <Button color='lightGrey' variant='outlined' onClick={dialogProps.onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='warning' type='submit'>
              Create Environment
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}
