import { Button, Divider, Grid, InputLabel, TextField, Typography } from '@mui/material'
import ControlledSelect from 'components/ControlledSelect/ControlledSelect'
import { GenericError } from 'const'
import { countries, states } from 'const/countryStates'
import { STRIPE_TAX_ID_TYPES } from 'const/stripeTaxIdTypes'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { EMAIL_VALIDATION } from 'helpers/validation'
import { AddressFormat, BillingInfo } from 'models/billing'
import { PropsWithChildren, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import styles from './EditBillingInfoDialog.module.scss'

type FormData = Pick<
  BillingInfo,
  | 'name'
  | 'email'
  | 'taxIdType'
  | 'taxId'
  | 'addressLine1'
  | 'addressCountry'
  | 'addressRegion'
  | 'addressCity'
  | 'addressPostalCode'
>

export interface EditBillingInfoDialogProps extends DialogProps {
  format?: AddressFormat
  billingInfo?: BillingInfo
  error?: GenericError | null
  onUpdate: (newBillingInfo: FormData) => void
}

export default function EditBillingInfoDialog({
  format = 'other',
  billingInfo,
  onUpdate,
  open,
  onClose,
  error,
  isLoading,
}: EditBillingInfoDialogProps) {
  const formMethods = useForm<FormData>({
    defaultValues: billingInfo,
  })

  const { reset, register, watch, handleSubmit, setValue, formState } = formMethods
  const { errors, touchedFields, isDirty } = formState
  const country = watch('addressCountry') || 'United States'
  const state = watch('addressRegion')
  const taxIdType = watch('taxIdType')
  const regionTextField = Object.keys(states).includes(country)

  const availableTaxIdTypes = useMemo(() => STRIPE_TAX_ID_TYPES.filter((it) => it.country === country), [country])
  const chosenTaxIdTypeData = useMemo(
    () => taxIdType && availableTaxIdTypes.find((it) => it.taxIdType === taxIdType),
    [availableTaxIdTypes, taxIdType]
  )

  useEffect(() => {
    if (!availableTaxIdTypes.length) {
      setValue('taxIdType', '')
      setValue('taxId', '')
    }

    if (!taxIdType || !chosenTaxIdTypeData) {
      setValue('taxIdType', '')
      setValue('taxId', '')
    }
  }, [availableTaxIdTypes, taxIdType, chosenTaxIdTypeData, setValue])

  useEffect(() => {
    if (regionTextField && !(state && states[country].includes(state))) {
      setValue('addressRegion', states[country][0])
    }
  }, [regionTextField, country, state, touchedFields, setValue])

  useEffect(() => {
    reset({ ...billingInfo, addressCountry: billingInfo?.addressCountry || 'United States' })
  }, [reset, billingInfo, open])

  let regionLabel: string | undefined
  let cityLabel: string | undefined
  let zipLabel: string | undefined

  switch (format) {
    case 'us': {
      regionLabel = 'State'
      cityLabel = 'City'
      zipLabel = 'Zip'
      break
    }
    case 'ca': {
      regionLabel = 'Province'
      cityLabel = 'City'
      zipLabel = 'Postal Code'
      break
    }
    default: {
      regionLabel = 'State / Region'
      cityLabel = 'City / Town'
      zipLabel = 'Zip / Postal Code'
      break
    }
  }

  return (
    <Dialog open={open} onClose={onClose} isLoading={isLoading}>
      <form onSubmit={handleSubmit(onUpdate)} aria-label='Edit billing info'>
        <FormProvider {...formMethods}>
          <DialogTitle onClose={onClose}>
            <Typography variant='h1' component='span'>
              Edit billing info
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Group label='Company'>
              <Grid item xs={12}>
                <InputLabel htmlFor='name'>Name</InputLabel>
                <TextField
                  id='name'
                  placeholder='Acme Corporation'
                  {...muiRegister(register, 'name')}
                  {...getErrorParams('name', errors, error)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor='email'>Billing email</InputLabel>
                <TextField
                  id='email'
                  placeholder='jony@lovefrom.com'
                  {...muiRegister(register, 'email', EMAIL_VALIDATION)}
                  {...getErrorParams(['email', 'stripeId'], errors, error)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
            </Group>

            <Divider className={styles.divider} />

            <Group label='Street address'>
              <Grid item xs={12}>
                <InputLabel htmlFor='addressLine1'>Street address</InputLabel>
                <TextField
                  id='addressLine1'
                  placeholder='1 Infinite Loop'
                  {...muiRegister(register, 'addressLine1')}
                  {...getErrorParams('addressLine1', errors, error)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledSelect
                  title='Country'
                  name='addressCountry'
                  items={countries.map((country) => ({ value: country, displayValue: country }))}
                  error={error}
                  dense
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {regionTextField ? (
                  <ControlledSelect
                    title={regionLabel}
                    name='addressRegion'
                    items={states[country].map((state) => ({ value: state, displayValue: state }))}
                    disabled={states[country].length === 0}
                    error={error}
                    dense
                  />
                ) : (
                  <>
                    <InputLabel htmlFor='addressRegion'>{regionLabel}</InputLabel>
                    <TextField
                      id='addressRegion'
                      placeholder='State / Region'
                      {...muiRegister(register, 'addressRegion')}
                      {...getErrorParams('addressRegion', errors, error)}
                      fullWidth
                      FormHelperTextProps={{ classes: { root: styles.helperText } }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor='addressCity'>{cityLabel}</InputLabel>
                <TextField
                  id='addressCity'
                  placeholder='Cupertino'
                  {...muiRegister(register, 'addressCity')}
                  {...getErrorParams('addressCity', errors, error)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor='addressPostalCode'>{zipLabel}</InputLabel>
                <TextField
                  id='addressPostalCode'
                  placeholder='95014'
                  {...muiRegister(register, 'addressPostalCode')}
                  {...getErrorParams('addressPostalCode', errors, error)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
            </Group>

            {!!availableTaxIdTypes.length && (
              <>
                <Divider className={styles.divider} />

                <Group label='Tax identification number'>
                  <Grid item xs={12}>
                    <ControlledSelect
                      title='Tax ID Type'
                      name='taxIdType'
                      items={[{ value: '', displayValue: 'None' }].concat(
                        availableTaxIdTypes.map((it) => ({ value: it.taxIdType, displayValue: it.description }))
                      )}
                      error={error}
                      dense
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor='taxId'>
                      Tax ID {chosenTaxIdTypeData ? `(${chosenTaxIdTypeData.description})` : ''}
                    </InputLabel>
                    <TextField
                      id='taxId'
                      disabled={!chosenTaxIdTypeData}
                      placeholder={chosenTaxIdTypeData ? chosenTaxIdTypeData.exampleValue : 'Choose a Tax ID Type'}
                      {...muiRegister(register, 'taxId', {
                        validate: {
                          required: (value) => {
                            if (!value && taxIdType) {
                              return 'This field is required.'
                            }

                            return true
                          },
                        },
                      })}
                      {...getErrorParams('taxId', errors, error)}
                      fullWidth
                      FormHelperTextProps={{ classes: { root: styles.helperText } }}
                    />
                  </Grid>
                </Group>
              </>
            )}
          </DialogContent>

          <DialogActions className={styles.actions}>
            <Typography variant='bodyS' className={styles.footnote}>
              Changes to billing details will not appear on past invoices.
            </Typography>
            <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
              Save changes
            </Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  )
}

interface GroupProps {
  label: string
}

function Group({ label, children }: PropsWithChildren<GroupProps>) {
  return (
    <fieldset className={styles.fieldset}>
      <Typography variant='bodySMedium' className={styles.legend}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </fieldset>
  )
}
