import { addMonths, differenceInSeconds, isLastDayOfMonth, setDate } from 'date-fns'
import { toDate, toZonedTime } from 'date-fns-tz'

import { pluralize } from './pluralize'

export function getDateInUserTZ(date: number | string | Date, timezone?: string) {
  if (timezone == null) {
    return new Date(date)
  }
  const parsed = toDate(new Date(date).valueOf())
  return toZonedTime(parsed, timezone)
}

/**
 * Since US canceled DST, December 1st, 00:00 becomes November 30th, 23:00 in the US timezones.
 * This behavior leads to wrong month names on the yearly usage chart starting from November.
 * This method should be used to avoid this bug and basically rounds the timestamp up to the 1st day of the month.
 * @param date
 */
export function roundDateToStartOfMonth(date: Date) {
  let result = setDate(date, 1)
  if (isLastDayOfMonth(date)) {
    result = addMonths(result, 1)
  }

  return result
}

export function currentDateInZonedTime(timezone?: string) {
  //We use new Date(Date.now()) construction here to be able to mock it
  const now = new Date(Date.now())
  return timezone ? getDateInUserTZ(now, timezone) : now
}

/**
 * Gets the number of days that have passed since a given date.
 * `from` should be less than `to`, otherwise 0 returned.
 *
 * @param from Start of the time period.
 * @param to End of the time period
 * @returns the number of days that have passed since a given date.
 */
export function differenceInDays(from: Date | string, to: Date | string) {
  const secondsInDay = 86400
  const passedSeconds = differenceInSeconds(new Date(from), new Date(to))

  if (passedSeconds >= 0) {
    return 0
  }
  const remainingDays = Math.floor(passedSeconds / secondsInDay)
  return Math.abs(remainingDays)
}

export const labelToUnitMap = {
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
}

/**
 * Converts a given date or string representation of a date to a human-readable format.
 * If the date is in the future, it returns the time left until the date.
 * If the date is in the past or undefined, it returns 'Soon'.
 *
 * @param date - The date to be converted. Can be a string, Date object, or undefined.
 * @param showLeft - Optional parameter to indicate whether to include the 'left' suffix. Default is true.
 * @returns A human-readable representation of the date or 'Soon' if the date is in the past or undefined.
 */
export function humanizeTimeToDate(date: string | Date | undefined, showLeft = true) {
  if (date) {
    const difference = new Date(date).getTime() - Date.now()
    for (const [label, unit] of Object.entries(labelToUnitMap)) {
      if (difference > unit) {
        const unitsTotal = Math.ceil(difference / unit)
        return `${pluralize(unitsTotal, label)}${showLeft ? ' left' : ''}`
      }
    }
  }

  return 'Soon'
}
