import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import ProtectedByStripe from 'components/Stripe/ProtectedByStripe/ProtectedByStripe'
import SimpleCardInput from 'components/Stripe/SimpleCardInput/SimpleCardInput'
import { GenericError } from 'const'
import { BillingDetails } from 'models'
import { memo } from 'react'
import { useForm } from 'react-hook-form'

import { getErrorParams } from '../../../helpers/data'
import { muiRegister } from '../../../helpers/reactHookForm'
import styles from './NewMethodDialog.module.scss'

export interface NewMethodDialogProps extends DialogProps {
  error: GenericError | null
  onCancel: () => void
  isLoading: boolean
  onAddPaymentMethod: (billingDetails: BillingDetails) => void
}

export default memo(function NewMethodDialog({
  error,
  onAddPaymentMethod,
  onCancel,
  isLoading,
  ...dialogProps
}: NewMethodDialogProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm()

  return (
    <Dialog data-testid='stripeForm' fullWidth maxWidth='sm' {...dialogProps}>
      <form onSubmit={handleSubmit(({ name }) => onAddPaymentMethod({ name }))}>
        <DialogTitle>Add Payment Method</DialogTitle>
        <DialogContent>
          <Typography variant='h6'>Card details</Typography>
          <TextField
            fullWidth
            className={styles.input}
            id='name'
            type='name'
            label='Name on card'
            data-testid='addPaymentMethodDialogName'
            variant='outlined'
            {...muiRegister(register, 'name', { required: 'Name on card required' })}
            {...getErrorParams('name', formErrors, error)}
          />
          <SimpleCardInput className={styles.input} />
          <ProtectedByStripe />
          {error && (
            <Typography className={styles.errorMessage} variant='body2'>
              {error.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            title='Confirm Add Payment Method'
            disabled={isLoading}
            data-testid='addPaymentMethodDialogAddButton'
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
})
