import { Button, Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { ExternalLayout } from 'features/commonUI'
import { ExperimentFlag, ExperimentValue, useLocalAmplitudeExperimentVariant } from 'helpers/vendor'
import { useEffect, useState } from 'react'

import GmailSVG from './gmail.svg'
import styles from './SignUpConfirm.module.scss'

export type SignUpConfirmCheckYourMailProps = {
  email: string
  cooldown: number
  onResend: () => void
  isResendingConfirmation: boolean
}

export function SignUpConfirmCheckYourMail({
  email,
  cooldown,
  onResend,
  isResendingConfirmation,
}: SignUpConfirmCheckYourMailProps) {
  const [isButtonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (isResendingConfirmation) {
      setButtonClicked(true)
    }
  }, [isResendingConfirmation])

  const handleClick = () => {
    onResend()
    setButtonClicked(true)
  }
  const isGmailEmail = email.includes('@gmail')
  const { variant } = useLocalAmplitudeExperimentVariant(ExperimentFlag.GmailSniperLink, isGmailEmail)
  const showGmailButton = variant?.value === ExperimentValue.Treatment && isGmailEmail

  const handleOpenGmail = () => {
    window.open(
      `https://mail.google.com/mail/u/${email}/#search/from%3A%40fingerprint.com+in%3Aanywhere+newer_than%3A1d`,
      '_blank'
    )
  }

  return (
    <ExternalLayout srcPage='signupConfirm'>
      <Paper className={styles.container}>
        <h1 className={styles.title}>Now, go check your email.</h1>
        <Typography variant='body2' className={styles.description}>
          We sent you a confirmation email. Click the link inside to continue with account setup.
        </Typography>

        <section className={styles.postcardWrapper}>
          <div className={styles.postcard}>
            <h2>{email}</h2>
            <p>This email contains your magic link to set up Fingerprint.</p>
            {showGmailButton && (
              <Button
                role='button'
                onClick={handleOpenGmail}
                className={styles.gmailButton}
                variant={'outlined'}
                color={'grey'}
                startIcon={<GmailSVG />}
              >
                Open Gmail
              </Button>
            )}
          </div>
        </section>

        {cooldown > 0 ? (
          <Typography variant='body2' className={clsx(styles.timer, { [styles.buttonClicked]: isButtonClicked })}>
            You may resend the email in {cooldown} seconds
          </Typography>
        ) : isResendingConfirmation ? (
          <Typography variant='body2' className={styles.sending}>
            Sending...
          </Typography>
        ) : (
          <Button role='button' variant='text' onClick={handleClick} className={styles.resendButton}>
            Send me another email
          </Button>
        )}
      </Paper>
    </ExternalLayout>
  )
}
