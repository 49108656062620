import { UnfoldMore } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { SubscriptionStatistic } from 'models'
import { useState } from 'react'

import { ampli } from '../../../../models/ampli'
import { WIDGETS } from './const'
import styles from './InsightsNumericWidget.module.scss'

export function InsightsAction({
  selected,
  all,
  unavailable,
  onSelect,
  blockIndex,
}: {
  selected: SubscriptionStatistic
  all: SubscriptionStatistic[]
  unavailable: Set<SubscriptionStatistic>
  onSelect: (selected: SubscriptionStatistic) => void
  blockIndex: number
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    ampli.chartSettingsOpened({ chartType: 'KPI', metricName: selected })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleItemClick = (item: SubscriptionStatistic) => {
    ampli.chartSettingsChanged({
      chartType: 'KPI',
      metricName: selected,
      newMetricName: item,
      slotColumn: blockIndex,
      slotRow: 1,
    })
    onSelect(item)
    handleClose()
  }

  return (
    <div className={styles.action}>
      <IconButton
        id='swap-button'
        aria-controls={open ? 'swap-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableTouchRipple
      >
        <UnfoldMore fontSize='16' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'swap-button',
        }}
      >
        {all.map((d) => {
          return (
            <MenuItem
              key={d}
              onClick={() => handleItemClick(d)}
              selected={d === selected}
              disabled={unavailable.has(d)}
            >
              {WIDGETS[d].optionLabel}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
