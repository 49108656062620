import {
  addDays,
  differenceInDays,
  differenceInHours,
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  parseISO,
  startOfMonth,
  startOfWeek,
  toDate,
} from 'date-fns'
import { UsageCounterPeriod } from 'models'

import { ampli } from '../models/ampli'
import { Falsy } from './types'

export const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export type DateRangeLabel = Parameters<typeof ampli.dateFilterApplied>['0']['dateRange']
export const ACCEPTED_DATE_RANGE_LABELS: string[] = [
  'Last 24 hours',
  'Last 3 days',
  'Last 7 days',
  'Last 30 days',
  'Last 90 days',
  'Last 6 months',
] satisfies Array<DateRangeLabel>

export interface DateRange {
  startDate?: Date
  endDate?: Date
}

export function calculateGranularityFromRange(range: Required<DateRange>) {
  const { startDate, endDate } = range
  if (differenceInHours(endDate, startDate) <= 48) {
    return UsageCounterPeriod.Hour
  }

  if (differenceInDays(endDate, startDate) <= 61) {
    return UsageCounterPeriod.Day
  }

  return UsageCounterPeriod.Month
}

export const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first
  const { startDate: sStart, endDate: sEnd } = second
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd)
  }
  return false
}

export const getDaysInMonth = (date: Date) => {
  const startWeek = startOfWeek(startOfMonth(date))
  const endWeek = endOfWeek(endOfMonth(date))
  const days = []
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr)
    curr = addDays(curr, 1)
  }
  return days
}

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
  (startDate && isSameDay(day, startDate)) as boolean

export const isEndOfRange = ({ endDate }: DateRange, day: Date) => (endDate && isSameDay(day, endDate)) as boolean

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date) =>
  (startDate &&
    endDate &&
    (isWithinInterval(day, { start: startDate, end: endDate }) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate))) as boolean

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate)
  }
  return false
}

export const parseOptionalDate = (date: Date | string | Falsy, defaultValue: Date) => {
  if (date) {
    let parsed: Date
    if (typeof date === 'string') {
      parsed = parseISO(date)
    } else {
      parsed = toDate(date)
    }
    if (isValid(parsed)) {
      return parsed
    }
  }
  return defaultValue
}
