import { Link, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { RouterPath } from 'helpers/types'
import { usePermissions } from 'hooks'
import { AccountBannerName } from 'models'
import { ampli } from 'models/ampli'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { BannerDescriptorProps, BannerSection } from '../BannerSection/BannerSection'

export function Banners({ children }: PropsWithChildren) {
  const {
    userPermissions: { canCreate: canCreateUser },
  } = usePermissions()
  const history = useHistory()

  const handleOnInviteClickAction = useCallback(
    (handleClose: () => void) => {
      handleClose()
      ampli.inviteUserClicked()
      history.push(AppRoute.UserManagement, { isUserDialogOpen: true })
    },
    [history]
  )

  const banners = useMemo(
    () => [
      {
        key: AccountBannerName.InviteUser,
        render: inviteBannerRenderer(handleOnInviteClickAction),
        shouldBeShown: canCreateUser,
      },
    ],
    [canCreateUser, handleOnInviteClickAction]
  )

  return <BannerSection banners={banners}>{children}</BannerSection>
}

function inviteBannerRenderer(handleOnInviteClickAction: (handleClose: () => void) => void) {
  return ({ handleClose }: BannerDescriptorProps) => (
    <DashboardBanner
      title='Invite a teammate'
      action='Add user'
      onClickAction={() => handleOnInviteClickAction(handleClose)}
      dismissTitle='Invite a teammate'
      dismissBody={
        <Typography variant='body1'>
          You can invite a teammate later in{' '}
          <Link component={RouterLink} to={addUserPath} underline='hover'>
            user management settings
          </Link>
          .
        </Typography>
      }
      secondaryAction='Not now'
      secondaryActionOnClose={handleClose}
    >
      Invite somebody who can help you set up your application.
    </DashboardBanner>
  )
}

const addUserPath: RouterPath = {
  pathname: AppRoute.UserManagement,
  state: {
    isUserDialogOpen: true,
  },
}
