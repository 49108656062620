import { useCallback } from 'react'
import { createContext, PropsWithChildren, useContext, useState } from 'react'

import { Toast, ToastProps } from '../components/Toast'

export interface ToastContextData {
  showToast: (props: ToastProps) => void
}

const ToastContext = createContext<ToastContextData>({
  showToast: () => null,
})

export const useToast = () => useContext(ToastContext)

export function ToastProvider({ children }: PropsWithChildren<{}>) {
  const [toast, setToast] = useState<ToastProps | undefined>()

  const showToast = useCallback((props: ToastProps) => {
    setToast({ id: Date.now().toString(), ...props })
  }, [])

  return (
    <ToastContext.Provider value={{ showToast }}>
      {toast && <Toast {...toast} />}
      {children}
    </ToastContext.Provider>
  )
}
