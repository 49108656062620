import { Delete } from '@mui/icons-material'
import { Button, Divider, IconButton, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import clsx from 'clsx'
import CardDescription from 'components/CardDescription/CardDescription'
import CardLogo from 'components/CardLogo'
import { GenericError } from 'const'
import { useLogPageView } from 'helpers/vendor'
import { usePermissions } from 'hooks/permissions'
import { BillingDetails, PaymentMethod } from 'models'
import { ampli } from 'models/ampli'
import { memo } from 'react'

import Loader from '../Loader/Loader'
import NewMethodDialog from './NewMethodDialog'
import style from './PaymentMethods.module.scss'

export interface PaymentMethodsProps {
  className?: string
  isLoading: boolean
  isAddingPaymentMethod: boolean
  paymentMethods: PaymentMethod[]
  addDialogIsOpen: boolean
  error: GenericError | null
  onAddNewPaymentClick: () => void
  onDialogCancel: () => void
  onMakeDefault: (id: string) => void
  onAddPaymentMethod: (billingDetails: BillingDetails) => void
  onDelete: (id: string) => void
}

export default memo(function PaymentMethods({
  className,
  isLoading,
  isAddingPaymentMethod,
  paymentMethods,
  addDialogIsOpen,
  error,
  onAddNewPaymentClick,
  onDialogCancel,
  onAddPaymentMethod,
  onMakeDefault,
  onDelete,
}: PaymentMethodsProps) {
  const { isReadOnly } = usePermissions()
  useLogPageView(() => ampli.paymentMethodPageViewed())

  return (
    <Paper className={clsx(style.root, className)}>
      <Typography variant='h3' component='h2' className={style.heading}>
        Payment Methods
      </Typography>

      <Divider />

      <Table>
        <TableBody>
          {paymentMethods.length === 0 ? (
            <TableRow>
              <TableCell align='center' colSpan={2}>
                <Typography>No payment methods</Typography>
              </TableCell>
            </TableRow>
          ) : (
            paymentMethods
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((paymentMethod) => (
                <TableRow key={paymentMethod.id}>
                  <TableCell>
                    <CardLogo cardBrand={paymentMethod.cardBrand} /> <CardDescription paymentMethod={paymentMethod} />
                  </TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='outlined'
                      size='small'
                      color='primary'
                      type='submit'
                      title='Set as Default'
                      aria-label='Set as Default'
                      disabled={isReadOnly || paymentMethod.isDefault}
                      onClick={() => onMakeDefault(paymentMethod.id)}
                    >
                      Set Default
                    </Button>
                    <IconButton
                      title='Delete'
                      aria-label='Delete'
                      disabled={isReadOnly}
                      onClick={() => onDelete(paymentMethod.id)}
                      className={style.iconButton}
                      size='medium'
                    >
                      <Delete fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>

      <div className={style.row}>
        <Button
          variant='contained'
          size='small'
          color='primary'
          type='submit'
          title='Add Payment Method'
          onClick={onAddNewPaymentClick}
          className={style.button}
          disabled={isReadOnly}
          data-testid='addPaymentMethodButton'
        >
          Add
        </Button>
      </div>

      <NewMethodDialog
        open={addDialogIsOpen}
        isLoading={isAddingPaymentMethod}
        error={error}
        onAddPaymentMethod={onAddPaymentMethod}
        onCancel={onDialogCancel}
      />

      {isLoading && <Loader testId='payment-methods-loader' />}
    </Paper>
  )
})
