import { CardElement } from '@stripe/react-stripe-js'
import clsx from 'clsx'
import { BaseReactProps } from 'helpers/types'

import styles from './SimpleCardInput.module.scss'

const options = {
  style: {
    base: {
      width: '100%',
      color: '#333333',
      fontFamily: "'Roboto', sans-serif",
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#rgba(0, 0, 0, 0.87)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export default function SimpleCardInput({ className }: BaseReactProps) {
  return (
    <div className={clsx(styles.container, className)}>
      <CardElement id='card' options={options} />
    </div>
  )
}
