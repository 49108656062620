import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import { Divider, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import {
  add,
  addDays,
  addMonths,
  addYears,
  differenceInCalendarMonths,
  Duration,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  max,
  min,
  startOfMonth,
  sub,
} from 'date-fns'
import { useState } from 'react'

import { DateRange, parseOptionalDate } from '../../helpers/dateRange'
import Month from './components/Month/Month'
import styles from './DateRangePicker.module.scss'
import DefinedRanges from './DefinedRanges'
import { DefinedRange, Marker, NavigationAction } from './types'

export interface DateRangePickerProps {
  initialDateRange?: DateRange
  definedRanges?: DefinedRange[]
  initialMinDate?: Date
  initialMaxDate?: Date
  maxRange?: Duration
  today: Date
  className?: string | string[]
  onChange: (dateRange: DateRange, isDefinedRange?: boolean, label?: string) => void
}

export default function DateRangePicker({
  initialDateRange,
  initialMinDate,
  initialMaxDate,
  maxRange = { years: 1 },
  today,
  definedRanges,
  className,
  onChange,
}: DateRangePickerProps) {
  const [minDateValid, setMinDateValid] = useState(getDefaultMinDate(initialMinDate, today))
  const [maxDateValid, setMaxDateValid] = useState(getDefaultMaxDate(initialMaxDate, today))
  const [initialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid)

  const dateRanges = definedRanges ?? getDefaultRanges(today, minDateValid, maxRange)

  const [dateRange, setDateRange] = useState<DateRange>({ ...initialDateRange })
  const [hoverDay, setHoverDay] = useState<Date>()
  const [firstMonth, setFirstMonth] = useState<Date>(initialFirstMonth || addMonths(today, -1))
  const [secondMonth, setSecondMonth] = useState<Date>(initialSecondMonth || today)

  const { startDate, endDate } = dateRange

  const setFirstMonthValidated = (date: Date) => {
    setFirstMonth(date)
    if (!isBefore(date, secondMonth)) {
      setSecondMonth(addMonths(date, 1))
    }
  }

  const setSecondMonthValidated = (date: Date) => {
    setSecondMonth(date)
    if (!isAfter(date, firstMonth)) {
      setFirstMonth(addMonths(date, -1))
    }
  }

  const setDateRangeValidated = (range: DateRange, isDefinedRange = false, label?: string) => {
    let { startDate: newStart, endDate: newEnd } = range

    if (newStart && newEnd) {
      newStart = max([newStart, minDateValid])
      newEnd = min([newEnd, maxDateValid])
      const newRange = { startDate: newStart, endDate: newEnd }

      setDateRange(newRange)
      onChange(newRange, isDefinedRange, label)

      setFirstMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, -1) : newStart)
      setSecondMonth(newEnd)
    } else {
      const emptyRange = {}

      setDateRange(emptyRange)
      onChange(emptyRange)

      setFirstMonth(addMonths(firstMonth, -1))
      setSecondMonth(today)
    }
  }

  const onDayClick = (day: Date, marker: Marker) => {
    const isOnlyOneDateSelected = !!startDate !== !!endDate

    if (isOnlyOneDateSelected) {
      let newRange: DateRange

      if (startDate) {
        newRange = { startDate, endDate: day }
      } else {
        newRange = { startDate: day, endDate }
      }

      if (isSameDay(newRange.endDate!, today)) {
        newRange = { ...newRange, endDate: today }
      }

      onChange(newRange)
      setDateRange(newRange)
      setMinDateValid(getDefaultMinDate(initialMinDate, today))
      setMaxDateValid(getDefaultMaxDate(initialMaxDate, today))
    } else {
      if (maxRange) {
        if (marker === Marker.FirstMonth) {
          const newMaxDate = add(day, maxRange)
          setMaxDateValid(newMaxDate)

          if (isAfter(secondMonth, newMaxDate)) {
            setSecondMonth(newMaxDate)
          }
        } else {
          const newMinDate = sub(day, maxRange)

          if (!initialMinDate || isBefore(initialMinDate, newMinDate)) {
            setMinDateValid(newMinDate)
          }

          if (isBefore(firstMonth, newMinDate)) {
            setFirstMonth(newMinDate)
          }
        }
      }
      setDateRange({ startDate: day, endDate: undefined })
    }
    setHoverDay(day)
  }

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === Marker.FirstMonth) {
      const firstNew = addMonths(firstMonth, action)
      if (isBefore(firstNew, secondMonth)) {
        setFirstMonth(firstNew)
      }
    } else {
      const secondNew = addMonths(secondMonth, action)
      if (isBefore(firstMonth, secondNew)) {
        setSecondMonth(secondNew)
      }
    }
  }

  const onDayHover = (date: Date) => {
    if (startDate && !endDate && (!hoverDay || !isSameDay(date, hoverDay))) {
      setHoverDay(date)

      if (isBefore(date, startDate)) {
        setDateRange({ startDate: undefined, endDate: startDate })
      }
    } else if (endDate && !startDate && (!hoverDay || !isSameDay(date, hoverDay))) {
      setHoverDay(date)

      if (isAfter(date, endDate)) {
        setDateRange({ startDate: endDate, endDate: undefined })
      }
    }
  }

  // helpers
  const inHoverRange = (day: Date) => {
    if (startDate && endDate) {
      return isWithinInterval(day, { start: startDate, end: endDate })
    } else if (startDate && hoverDay) {
      return isWithinInterval(day, { start: startDate, end: hoverDay })
    } else if (endDate && hoverDay) {
      return isWithinInterval(day, { start: hoverDay, end: endDate })
    } else {
      return false
    }
  }

  const helpers = {
    inHoverRange,
  }

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  }

  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2
  const canNavigateFirstMonthBack = !isBefore(firstMonth, minDateValid)
  const canNavigateSecondMonthForward = !isAfter(secondMonth, maxDateValid)
  const commonProps = {
    dateRange,
    minDate: minDateValid,
    maxDate: maxDateValid,
    helpers,
    handlers,
  }
  const headerDateFormat = 'MMMM dd, yyyy'

  return (
    <div data-testid='date-range-picker' className={clsx(styles.dateRangePicker, className)}>
      <Grid container direction='row' wrap='nowrap'>
        <Grid>
          <Grid container className={styles.header} alignItems='center'>
            <Grid item className={styles.headerItem} data-testid={`date-range-picker-first-month-header`}>
              <Typography variant='subtitle1'>
                {startDate ? format(startDate, headerDateFormat) : 'Start Date'}
              </Typography>
            </Grid>
            <Grid item className={styles.headerItem}>
              <ArrowRightAlt color='action' />
            </Grid>
            <Grid item className={styles.headerItem} data-testid={`date-range-picker-second-month-header`}>
              <Typography variant='subtitle1'>{endDate ? format(endDate, headerDateFormat) : 'End Date'}</Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction='row' justifyContent='center' wrap='nowrap'>
            <Month
              {...commonProps}
              date={firstMonth}
              setDate={setFirstMonthValidated}
              canNavBack={canNavigateFirstMonthBack}
              canNavForward={canNavigateCloser}
              marker={Marker.FirstMonth}
            />
            <div className={styles.divider} />
            <Month
              {...commonProps}
              date={secondMonth}
              setDate={setSecondMonthValidated}
              canNavBack={canNavigateCloser}
              canNavForward={canNavigateSecondMonthForward}
              marker={Marker.SecondMonth}
            />
          </Grid>
        </Grid>
        <div className={styles.divider} />
        <Grid>
          <DefinedRanges
            selectedRange={dateRange}
            ranges={dateRanges}
            setRange={(range, label) => setDateRangeValidated(range, true, label)}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const getDefaultMinDate = (minDate: Date | undefined, today: Date) => parseOptionalDate(minDate, addYears(today, -1))

const getDefaultMaxDate = (maxDate: Date | undefined, today: Date) => parseOptionalDate(maxDate, today)

const getDefaultRanges = (today: Date, minDate: Date, maxRange: Duration): DefinedRange[] => {
  const threeDays = addDays(today, -2)
  const sevenDays = addDays(today, -6)
  const thirtyDays = addDays(today, -29)
  const ninetyDays = addDays(today, -89)
  const sixMonths = startOfMonth(addMonths(today, -5))
  const year = addDays(addYears(today, -1), 1)

  const definedRanges = [
    {
      label: 'Last 24 hours',
      startDate: addDays(today, -1),
      endDate: today,
    },
    {
      label: 'Last 3 days',
      startDate: threeDays,
      endDate: today,
    },
    {
      label: 'Last 7 days',
      startDate: sevenDays,
      endDate: today,
    },
    {
      label: 'Last 30 days',
      startDate: thirtyDays,
      endDate: today,
    },
    {
      label: 'Last 90 days',
      startDate: ninetyDays,
      endDate: today,
    },
    {
      label: 'Last 6 months',
      startDate: sixMonths,
      endDate: today,
    },
    {
      label: 'Last year',
      startDate: year,
      endDate: today,
    },
  ]

  const ranges = definedRanges.filter(
    (range) => isAfter(range.startDate, minDate) && isAfter(range.startDate, sub(today, maxRange))
  )

  if (isAfter(minDate, sub(today, maxRange))) {
    ranges.push({
      label: 'All time',
      startDate: minDate,
      endDate: today,
    })
  }

  return ranges
}

function getValidatedMonths(range: DateRange, minDate: Date, maxDate: Date) {
  const { startDate, endDate } = range
  if (startDate && endDate) {
    const newStart = max([startDate, minDate])
    const newEnd = min([endDate, maxDate])

    return [isSameMonth(newStart, newEnd) ? addMonths(newStart, -1) : newStart, newEnd]
  }
  return [startDate, endDate]
}
