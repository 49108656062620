import { Button, Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import clsx from 'clsx'
import { useToast } from 'hooks'
import {
  useEmailNotificationSettings,
  useEmailNotificationSettingsUpdateMutation,
  useEnableEmailNotifications,
} from 'hooks/api/email_notification_settings'
import { EmailNotificationsSettings, EmailNotificationsUpdateBody, SummaryFrequency } from 'models'
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import ControlledSelect from '../../../components/ControlledSelect/ControlledSelect'
import Loader from '../../../components/Loader/Loader'
import { DAYS_OF_WEEK, TIMES_OF_DAY } from '../../../const'
import { CheckboxOption } from './CheckboxOption/CheckboxOption'
import styles from './SettingsForms.module.scss'

export function EmailNotificationsForm() {
  const { showToast } = useToast()
  const { data: emailNotificationSettings, isLoading: isLoadingEmailNotificationSettings } =
    useEmailNotificationSettings()

  const { mutate: updateEmailNotificationSettings, isLoading: isUpdatingEmailNotificationSettings } =
    useEmailNotificationSettingsUpdateMutation()

  const { mutate: enableEmailNotifications, isLoading: isEnablingEmailNotifications } = useEnableEmailNotifications()

  const handleUpdateEmailNotificationSettings = useCallback(
    (payload: EmailNotificationsUpdateBody) =>
      updateEmailNotificationSettings(
        { data: payload },
        {
          onSuccess: () => {
            showToast({
              message: 'Successfully updated email notification settings.',
              severity: 'success',
            })
          },
        }
      ),
    [showToast, updateEmailNotificationSettings]
  )

  return (
    <EmailNotificationsFormContent
      emailNotificationSettings={emailNotificationSettings}
      isLoading={isLoadingEmailNotificationSettings || isUpdatingEmailNotificationSettings}
      onSubmit={handleUpdateEmailNotificationSettings}
      onEnableEmails={() => enableEmailNotifications({})}
      isEnablingEmailNotifications={isEnablingEmailNotifications}
    />
  )
}

export interface EmailNotificationsFormContentProps {
  emailNotificationSettings?: EmailNotificationsSettings
  isLoading?: boolean
  onSubmit: (data: EmailNotificationsUpdateBody) => void
  onEnableEmails: () => void
  isEnablingEmailNotifications: boolean
}

export function EmailNotificationsFormContent({
  emailNotificationSettings,
  isLoading,
  onSubmit,
  onEnableEmails,
  isEnablingEmailNotifications,
}: EmailNotificationsFormContentProps) {
  const defaultValues = useMemo(
    () => ({
      ...emailNotificationSettings,
      summaryDay: emailNotificationSettings?.summaryDay ?? DAYS_OF_WEEK[0].value,
      summaryHour: emailNotificationSettings?.summaryHour ?? TIMES_OF_DAY[0].value,

      // Not relevant to the BE but used for the radio group.
      frequency: emailNotificationSettings?.summaryDay ? SummaryFrequency.Weekly : SummaryFrequency.Daily,
    }),
    [emailNotificationSettings]
  )

  const formMethods = useForm({
    defaultValues,
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods

  const isSummaryEnabled = watch('summary')
  const frequency = watch('frequency')

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  if (emailNotificationSettings?.isUnsubscribedFromAll) {
    return (
      <EmailNotificationsCard isLoading={isEnablingEmailNotifications}>
        <Typography variant='body2' component='body' className={styles.disabledDescription}>
          Currently all emails are disabled for your email address. Please click the button below to proceed.
        </Typography>
        <Button onClick={onEnableEmails} disabled={isEnablingEmailNotifications} variant={'outlined'}>
          Enable All Email Notifications
        </Button>
      </EmailNotificationsCard>
    )
  }

  return (
    <EmailNotificationsCard isLoading={isLoading}>
      <form
        onSubmit={handleSubmit((data) => {
          const isWeekly = frequency === SummaryFrequency.Weekly

          onSubmit({
            summary: data.summary ?? false,
            summaryHour: data.summaryHour,
            // Setting the day to `null` is the way to switch to daily summaries.
            summaryDay: isWeekly ? data.summaryDay : null,
            subscriptionAlerts: data.subscriptionAlerts ?? false,
          })
        })}
        className={styles.form}
      >
        <FormProvider {...formMethods}>
          <CheckboxOption
            name='summary'
            label='Summary'
            description='Get updates on total API calls/unique visitors.'
            disabled={isLoading}
          />

          <Controller
            name='frequency'
            control={control}
            render={({ field: { value, onChange } }) => (
              <RadioGroup value={value} onChange={onChange} className={styles.radioGroup}>
                <FormControlLabel
                  value='weekly'
                  control={<Radio />}
                  label='Weekly'
                  disabled={isLoading || !isSummaryEnabled}
                />
                <FormControlLabel
                  value='daily'
                  control={<Radio />}
                  label='Daily'
                  disabled={isLoading || !isSummaryEnabled}
                />
              </RadioGroup>
            )}
          />

          {frequency === SummaryFrequency.Weekly && (
            <ControlledSelect
              title='At what day?'
              name='summaryDay'
              items={DAYS_OF_WEEK.map(({ value, name }) => ({ value, displayValue: name }))}
              rules={{ required: 'Summary day required.' }}
              disabled={isLoading || !isSummaryEnabled}
              className={clsx(styles.input, styles.subOption)}
            />
          )}

          <ControlledSelect
            title='At what time?'
            name='summaryHour'
            items={TIMES_OF_DAY.map(({ value, name }) => ({ value, displayValue: name }))}
            rules={{ required: 'Summary time required.' }}
            disabled={isLoading || !isSummaryEnabled}
            className={clsx(styles.input, styles.subOption)}
          />

          <Divider className={styles.divider} />

          <CheckboxOption
            name='subscriptionAlerts'
            label='Application Alerts'
            description='Get notified when your application status changed, application trial is over soon or usage limit was exceeded.'
            disabled={isLoading}
          />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            title='Save Email Notification Settings'
            disabled={!isDirty}
            className={styles.button}
          >
            Save
          </Button>
        </FormProvider>
      </form>
    </EmailNotificationsCard>
  )
}

function EmailNotificationsCard({ children, isLoading }: PropsWithChildren<{ isLoading: boolean | undefined }>) {
  return (
    <Paper className={styles.card}>
      <Typography variant='h2' className={styles.cardTitle}>
        Email Notifications
      </Typography>
      {children}
      {isLoading && <Loader testId='email-notifications-form-loader' />}
    </Paper>
  )
}
