import { UnfoldMore } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { SubscriptionDistribution } from 'models'
import { useState } from 'react'

import { ampli } from '../../../../models/ampli'
import { OPTION_MAPPING } from './const'
import styles from './Distribution.module.scss'

export function DistributionAction({
  selected,
  all,
  unavailable,
  onSelect,
  blockIndex,
}: {
  selected: SubscriptionDistribution
  all: SubscriptionDistribution[]
  unavailable: Set<SubscriptionDistribution>
  onSelect: (selected: SubscriptionDistribution) => void
  blockIndex: number
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    ampli.chartSettingsOpened({ chartType: 'Distribution', metricName: selected })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleItemClick = (item: SubscriptionDistribution) => {
    ampli.chartSettingsChanged({
      chartType: 'Distribution',
      metricName: selected,
      newMetricName: item,
      slotColumn: blockIndex,
      slotRow: 2,
    })
    onSelect(item)
    handleClose()
  }

  return (
    <div className={styles.action}>
      <IconButton
        id='swap-button'
        aria-controls={open ? 'swap-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableTouchRipple
      >
        <UnfoldMore fontSize='16' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'swap-button',
        }}
      >
        {all.map((d) => (
          <MenuItem key={d} onClick={() => handleItemClick(d)} selected={d === selected} disabled={unavailable.has(d)}>
            {OPTION_MAPPING[d]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
