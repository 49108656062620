import { Button, Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './CtaCard.module.scss'

type Props = PropsWithChildren<{
  title: string
  subtitle: ReactNode
  ctaTitle: string
  ctaPath: string
  ctaOnClick?: () => void
}>

export function CtaCard({ title, subtitle, ctaTitle, ctaPath, ctaOnClick }: Props) {
  return (
    <Paper className={clsx(styles.card)}>
      <Typography variant='bodyMMedium'>{title}</Typography>
      <Typography variant='bodyS'>{subtitle}</Typography>
      <Button
        component={RouterLink}
        to={{ pathname: ctaPath }}
        onClick={ctaOnClick}
        className={styles.actionButton}
        variant='outlined'
        color='danger'
      >
        {ctaTitle}
      </Button>
    </Paper>
  )
}
