import {
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { PropsWithChildren, ReactNode } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

export function routeMatches(baseRoute: string, to: string, pathname: string) {
  const toRelative = to.replace(baseRoute, '')
  const pathnameRelative = pathname.replace(baseRoute, '')

  // startsWith check won't work with empty string
  if (toRelative === '') {
    return pathnameRelative === ''
  }

  return pathnameRelative.startsWith(toRelative)
}

type GenericMenuItemContentProps = {
  icon?: ReactNode
  label?: string | ReactNode
  subRoutes?: string[]
}
export type GenericMenuItemOverrideProps = {
  iconProps?: ListItemIconProps
  labelProps?: ListItemTextProps
}
type GenericMenuItemLinkProps =
  | {
      to: string
      href?: never
    }
  | {
      to?: never
      href: string
    }
  | {
      to?: undefined
      href?: undefined
    }
type GenericMenuItemProps = PropsWithChildren<
  GenericMenuItemContentProps & GenericMenuItemLinkProps & GenericMenuItemOverrideProps & MenuItemProps
>
export const GenericMenuItem = ({
  icon,
  iconProps,
  label,
  labelProps,
  children,
  to,
  href,
  subRoutes = to ? [to] : [],
  ...itemProps
}: GenericMenuItemProps) => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const baseOverviewRoute = buildRoute(AppRoute.SubscriptionOverview, { subscriptionId })

  const { pathname } = useLocation()
  const selected = !!subRoutes.find((route) => routeMatches(baseOverviewRoute, route ?? '', pathname))

  const calculatedProps = {
    component: to ? RouterLink : 'a',
    selected,
    ...(to ? { to } : undefined),
    ...(href ? { href, target: '_blank' } : undefined),
  }

  return (
    <MenuItem {...calculatedProps} {...itemProps}>
      {children ?? (
        <>
          {icon && <ListItemIcon {...iconProps}>{icon}</ListItemIcon>}
          {label && (typeof label === 'string' ? <ListItemText {...labelProps} primary={label} /> : label)}
        </>
      )}
    </MenuItem>
  )
}
