import { Button, Grid, Paper, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DOCS_SMART_SIGNALS_OVERVIEW } from 'const'
import { Header, MainContent } from 'features/commonUI'
import { ChangePlanAction } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { Link as RouterLink } from 'react-router-dom'

import SmartSignalsSvg from './smart-signals.svg'
import styles from './SmartSignalIntroduction.module.scss'

export default function SmartSignalsIntroduction() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <>
      <Header title='Smart Signals' />
      <MainContent>
        <Paper className={styles.card}>
          <Grid item xs={12} md={8} className={styles.details}>
            <Typography variant={'h5'} component={'h5'} className={styles.cardTitle}>
              Reveal the true intentions of every visitor
            </Typography>
            <Typography variant='body1' className={styles.description}>
              Make smart, data-driven decisions with Smart Signals to prevent increasingly sophisticated fraud and
              improve positive experiences for trusted users. Upgrade your existing plan to reveal existing Smart
              Signals insights.
            </Typography>
            <div className={styles.buttonsSection}>
              <Button
                component={RouterLink}
                to={buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus99 })}
                variant='contained'
                size='large'
              >
                Upgrade and reveal data
              </Button>
              <Button variant='text' size='large' href={DOCS_SMART_SIGNALS_OVERVIEW} className={styles.infoButton}>
                Learn More
              </Button>
            </div>
          </Grid>
          <Grid xs={12} md={4} className={styles.smartSignalsImageGrid}>
            <SmartSignalsSvg className={styles.smartSignalsImage} />
          </Grid>
        </Paper>
      </MainContent>
    </>
  )
}
