import { Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material'
import { format } from 'date-fns'
import { CalloutBox } from 'features/apiKeys'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatDateShortWithoutYear } from 'helpers/format'
import { getClientTimezone } from 'helpers/locale'
import { useToast } from 'hooks'
import { useUserContext } from 'hooks/api/context'
import { useIdentificationEventsExport } from 'hooks/api/identification_events'
import { DateTime } from 'luxon'
import { ampli } from 'models/ampli'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import styles from './ExportIdentificationEventsModal.module.scss'

export interface ExportIdentificationEventsModalProps {
  isOpen: boolean
  handleClose: () => void
}

export default function ExportIdentificationEventsModal({ isOpen, handleClose }: ExportIdentificationEventsModalProps) {
  const { data: userContext } = useUserContext()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      timeZone: 'UTC',
      dateRangeIndex: 0,
    },
  })
  const { mutate: initiateIdentificationEventExport } = useIdentificationEventsExport()
  const { showToast } = useToast()

  const email = userContext?.email ?? ''

  const onClose = useCallback(() => {
    reset()
    handleClose()
  }, [handleClose, reset])

  const nowDate = useMemo(() => new Date(), [])
  const availableDateRanges = useMemo(
    () => [
      { name: 'Last Day', from: new Date(nowDate.getTime() - 24 * 60 * 60 * 1000), to: nowDate },
      { name: 'Last 7 days', from: new Date(nowDate.getTime() - 7 * 24 * 60 * 60 * 1000), to: nowDate },
      { name: 'Last 30 days', from: new Date(nowDate.getTime() - 30 * 24 * 60 * 60 * 1000), to: nowDate },
    ],
    [nowDate]
  )

  const convertDateWithZone = (date: Date, timeZone: string) => {
    const originalDate = DateTime.fromJSDate(date)
    const formattedDate = originalDate.setZone(timeZone, { keepLocalTime: true }).toISO({ includeOffset: true })

    if (formattedDate) {
      return DateTime.fromISO(formattedDate).toUTC().toString()
    }

    return date.toISOString()
  }

  const onSubmit = useCallback<(data: { dateRangeIndex: number; timeZone: string }) => void>(
    (data) => {
      const { dateRangeIndex, timeZone } = data

      const dateRange = {
        from: convertDateWithZone(availableDateRanges[dateRangeIndex].from, timeZone),
        to: convertDateWithZone(availableDateRanges[dateRangeIndex].to, timeZone),
      }
      const subscriptionId = currentSubscriptionId

      initiateIdentificationEventExport(
        {
          params: { subscriptionId },
          data: { dateRange },
        },
        {
          onSuccess: () => {
            showToast({
              severity: 'success',
              message: (
                <>
                  Success! Be on the lookout for a <strong>download link</strong> sent to <strong>{email}</strong>.
                </>
              ),
            })
            ampli.dataExportRequested({
              exportFormat: 'CSV',
              timeZone: timeZone === 'UTC' ? 'UTC' : 'Local Time',
              dateRange: availableDateRanges[dateRangeIndex].name as 'Last Day' | 'Last 7 days' | 'Last 30 days',
            })
            onClose()
          },
        }
      )
    },
    [currentSubscriptionId, availableDateRanges, initiateIdentificationEventExport, showToast, email, onClose]
  )

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: styles.dialog }}>
      <DialogTitle onClose={onClose} className={styles.dialogTitle}>
        <Typography variant='h1' component='span' className={styles.heading}>
          Export events
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={styles.content}>
          <Grid rowGap={'40px'}>
            <Grid className={styles.biggerMargin}>
              <CalloutBox title='How does this work?' type='info'>
                Select your desired time zone and date range then click “Start Export”. Our servers will crunch the data
                and email you, <b>{email}</b>, a .CSV download link.
                <br />
                <br />
                Only columns available with your current billing plan will be exported, regardless of presence in
                historical events. Also, events captured prior to the introduction of this feature (October 3rd) will be
                excluded from the export.
              </CalloutBox>
            </Grid>

            <Grid className={styles.radioContainer}>
              <Typography variant='body2' className={styles.label}>
                Time Zone
              </Typography>

              <Grid className={styles.radioOptions}>
                <Controller
                  name='timeZone'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} className={styles.radioGroup}>
                      <FormControlLabel
                        value={getClientTimezone()}
                        control={<Radio />}
                        className={styles.formControlLabel}
                        label={
                          <Typography variant='body3' fontSize='14px'>
                            Local Time ({getClientTimezone()})
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='UTC'
                        control={<Radio />}
                        label={
                          <Typography variant='body3' fontSize='14px'>
                            UTC
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>

            <Divider className={styles.biggerMargin} />

            <Grid className={styles.radioContainer}>
              <Typography variant='body2' className={styles.label}>
                Date Range
              </Typography>

              <Grid className={styles.radioOptions}>
                <Controller
                  name='dateRangeIndex'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} className={styles.radioGroup}>
                      {availableDateRanges.map((dateRange, idx) => (
                        <FormControlLabel
                          key={dateRange.name}
                          value={idx}
                          control={<Radio />}
                          className={styles.formControlLabel}
                          label={
                            <Grid className={styles.labelGrid}>
                              <Typography variant='body2' className={styles.optionLabel}>
                                {dateRange.name}
                              </Typography>
                              <Tooltip
                                title={`${format(dateRange.from, 'yyyy-MM-dd hh:mm:ss')} - ${format(
                                  dateRange.to,
                                  'yyyy-MM-dd hh:mm:ss'
                                )}`}
                              >
                                <Typography variant='body2' className={styles.option}>
                                  {formatDateShortWithoutYear(dateRange.from)} -{' '}
                                  {formatDateShortWithoutYear(dateRange.to)}
                                </Typography>
                              </Tooltip>
                            </Grid>
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={styles.dialogActions}>
          <Button color='lightGrey' variant='outlined' onClick={onClose} title='Cancel'>
            Cancel
          </Button>
          <Button variant='contained' type='submit' title='Apply filter'>
            Start Export
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
