import { format } from 'date-fns-tz'
import { BotdCounterType, ClientCounterType, UsageCounterPeriod, UsageCounterType } from 'models'
import { CSSProperties } from 'react'

import { usageCounterSortOrder } from './data'
import { getDateInUserTZ, roundDateToStartOfMonth } from './date'
import { formatDateByGranularity, formatNum, formatTimeShort } from './format'

export interface UsageCounterPoint {
  counterType: ClientCounterType
  value: number
}

export enum ChartScale {
  Linear,
  Symlog,
}

/**
 * Generates evenly spaced values.
 *
 * @param count Number of values to generate.
 * @param min Lowest value on the scale.
 * @param max Highest value on the scale.
 * @param scale The scale to use when generating values. Values for symlog must be non-negative.
 * @return Array with `count` values evenly spaced between `min` and `max` (inclusive).
 */
export function generateTickValues(count: number, min: number, max: number, scale = ChartScale.Linear) {
  switch (scale) {
    case ChartScale.Linear:
      return generateLinearTicks(count, min, max)
    case ChartScale.Symlog:
      return generateSymlogTicks(count, min, max)
  }
}

export function pickDateValues(count: number, items: number[]) {
  if (items.length < count) {
    return items
  }
  if (items.length <= 2) {
    return items
  }
  if (count <= 2) {
    return [items[0], items[items.length - 1]]
  }
  const pickedDates = []

  // Calculate only for count-1 items because we will always take the last item as {count}th item
  const step = Math.floor(items.length / (count - 1))
  for (let i = 0; i < count - 1; i++) {
    const index = i * step
    if (index >= items.length) {
      continue
    }
    pickedDates.push(items[index])
  }

  // Take last item of the array as {count}th item
  pickedDates.push(items[items.length - 1])

  return pickedDates
}

function generateSymlogTicks(count: number, min: number, max: number) {
  const minMagnitude = Math.log10(Math.max(min, 1))
  const maxMagnitude = Math.log10(max)
  const magnitudeRange = maxMagnitude - minMagnitude
  const exponentDelta = magnitudeRange / (count - 1)

  const hasZero = min === 0
  const generatedCount = hasZero ? count - 1 : count
  const generatedTicks = [
    ...Array(generatedCount)
      .fill(undefined)
      .map((_, i) => Math.pow(10, minMagnitude + (i + (hasZero ? 1 : 0)) * exponentDelta)),
  ]

  return hasZero ? [0, ...generatedTicks] : generatedTicks
}

function generateLinearTicks(count: number, min: number, max: number) {
  const range = max - min
  const delta = range / (count - 1)

  if (range < 4) {
    return [min, max]
  }

  return Array(count)
    .fill(undefined)
    .map((_, i) => min + delta * i)
}

export function getTickValue(time: number, granularity: UsageCounterPeriod, timezone?: string) {
  const dateInTz = getDateInUserTZ(time, timezone)

  if (granularity === UsageCounterPeriod.Hour) {
    return formatTimeShort(dateInTz)
  }
  if (granularity === UsageCounterPeriod.Month) {
    return format(roundDateToStartOfMonth(new Date(time)), 'MMM, yyyy')
  }
  return format(dateInTz, 'MMM, dd')
}

export interface CustomTooltipProps {
  timestamp: string
  points: UsageCounterPoint[]
}

export function customTooltipWrapper<T>(
  granularity: UsageCounterPeriod,
  preprocessor: (data: T) => CustomTooltipProps,
  timezone?: string
) {
  return function Tooltip(data: T & { x: number; absX: number }): JSX.Element {
    const { timestamp, points } = preprocessor(data)

    const sortedPoints = sortCounterPoints(points)

    return (
      <div
        style={
          {
            fontSize: 13,
            backgroundColor: 'white',
            padding: 8,
            borderRadius: 2,
            boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)',
          } as CSSProperties
        }
      >
        {formatDateByGranularity(timestamp as string, granularity, timezone)}
        {sortedPoints.map(({ counterType, value }) => (
          <div key={counterType}>
            {formatCounterName(counterType)}: <strong>{formatNum(+value)}</strong>
          </div>
        ))}
      </div>
    )
  }
}

export function customTickWrapper(granularity: UsageCounterPeriod, timezone?: string) {
  return function Tick<T extends { x: number; y: number; value: number }>(data: T): React.ReactNode {
    const transform =
      granularity === UsageCounterPeriod.Month
        ? `translate(${data.x - 10},${data.y + 30}) rotate(-60)`
        : `translate(${data.x - 5},${data.y + 30}) rotate(-60)`

    return (
      <g transform={transform}>
        <text textAnchor='middle' dominantBaseline='middle' style={{ fontSize: '11px' }}>
          {getTickValue(data.value, granularity, timezone)}
        </text>
      </g>
    )
  }
}

export function formatCounterName(counter: ClientCounterType) {
  switch (counter) {
    case UsageCounterType.ApiCalls:
      return 'API Calls'
    case UsageCounterType.UniqueVisitors:
      return 'Unique Visitors'
    case UsageCounterType.RestrictedCalls:
      return 'Restricted Calls'
    case UsageCounterType.ThrottledCalls:
      return 'Throttled Calls'
    case BotdCounterType.BadBots:
      return 'Malicious Bots'
    case BotdCounterType.GoodBots:
      return 'Friendly Bots'
    case BotdCounterType.Humans:
      return 'Humans'
  }
}

function sortCounterPoints(points: UsageCounterPoint[]) {
  return points.sort((a, b) => {
    return usageCounterSortOrder.indexOf(a.counterType) - usageCounterSortOrder.indexOf(b.counterType)
  })
}

/**
 * Rounds up to the nearest short form of the number with one decimal point.
 * This rounds a number up to the nearest 1, 1.000, 1.000.000, ..., 10^3k with one decimal point.
 * Meant to be used when generating chart labels.
 *
 * Check tests for examples.
 *
 * @param num Number to round.
 * @return The number rounded up to the nearest multiple of 10^3k.
 */
export function roundUpToShort(num: number) {
  if (num === 0) {
    return 0
  }

  const magnitude = Math.log10(num)

  // For numbers bigger than 1000, the short form will have one decimal point.
  const magnitudeOffset = num > 1000 ? -1 : 0

  // Closest multiple of three before magnitude.
  const shortMagnitude = Math.floor(magnitude / 3) * 3
  const shortPowerOfTen = Math.pow(10, shortMagnitude + magnitudeOffset)

  return Math.ceil(num / shortPowerOfTen) * shortPowerOfTen
}
