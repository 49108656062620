import { CampaignOutlined, MailOutline } from '@mui/icons-material'
import { MenuItemProps } from '@mui/material'
import { SUPPORT_PAGE_URL } from 'const'
import { ProductFeedback } from 'features/commonUI'
import { ampli } from 'models/ampli'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

export const ContactSupportMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  return (
    <>
      <ProductFeedback
        triggerComponent={GenericMenuItem}
        triggerProps={{
          icon: <CampaignOutlined />,
          label: 'Leave Feedback',
          ...props,
        }}
      />
      <GenericMenuItem
        icon={<MailOutline />}
        label={'Contact Support'}
        href={SUPPORT_PAGE_URL}
        onClick={(...params) => {
          ampli.contactSupportClicked({ source: 'header' })
          props.onClick?.(...params)
        }}
        {...props}
      />
    </>
  )
}
