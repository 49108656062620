import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { AppRoute } from 'appRoutes'
import { useCallback } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import styles from './UpgradeConfirmationDialog.module.scss'

export interface UpgradeConfirmationDialogProps {
  isOpen: boolean
  onClose: () => void
}

export default function UpgradeConfirmationDialog({ isOpen, onClose }: UpgradeConfirmationDialogProps) {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()

  const handleClose = useCallback(() => {
    const previousState = (history?.location?.state || {}) as object
    // dialog is opened by this route state, so we remove it to ensure the
    // modal doesn't reopen on refresh
    history.replace({
      ...history.location,
      state: { ...previousState, isUpgradeConfirmationDialogOpen: undefined },
    })
    onClose()
  }, [history, onClose])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={mdDown}>
      <DialogTitle className={styles.header}>
        <IconButton size='small' onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant='h2' className={styles.title}>
          Your Plan Has Been Upgraded!
        </Typography>
        <Typography variant='body2' className={styles.text}>
          If you need to change your payment information go to the{' '}
          <Link component={RouterLink} to={AppRoute.Billing} underline='none'>
            billing page
          </Link>
          .
        </Typography>
        <Button color='primary' variant='contained' onClick={handleClose} fullWidth>
          Done
        </Button>
      </DialogContent>
    </Dialog>
  )
}
