import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiKey, ApiKeyType, ApiKeyTypeLogMap } from 'models'
import { ampli } from 'models/ampli'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const listCacheKey = 'subscriptions/tokens'

export function useSubscriptionTokens(subscriptionId: string | null | undefined, withPolling?: boolean) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [listCacheKey, subscriptionId] as const,
    queryFn: ({ queryKey: [_, subscriptionId] }) =>
      extractData(withErrorHandling(buildRequest('tokens', { params: { subscriptionId: subscriptionId! } }))),
    enabled: subscriptionId != null,
    refetchInterval: withPolling ? 60 * 1000 : undefined,
  })
}

export function useTokenCreateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('tokenCreate', {
    onSuccess: (payload) => {
      const currentCache = queryClient.getQueryData<ApiKey[]>([listCacheKey, payload.subscriptionId]) ?? []
      const newCache = [...currentCache, payload]

      queryClient.setQueryData<ApiKey[]>([listCacheKey, payload.subscriptionId], newCache)
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useTokenDeleteMutation(type: ApiKeyType) {
  const queryClient = useQueryClient()

  return useRequestMutation('tokenDelete', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      ampli.apiKeyDeleted({ apiKeyType: ApiKeyTypeLogMap[type] })
      queryClient.invalidateQueries([listCacheKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useTokenSettingsUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('tokenUpdate', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([listCacheKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

const encryptionKeysQueryKey = 'listEncryptionKeys'

export function useEncryptionKeys(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [encryptionKeysQueryKey, subscriptionId],
    queryFn: ({ queryKey: [_, subscriptionId] }) =>
      extractData(withErrorHandling(buildRequest('encryptionKeys', { params: { subscriptionId } }))).then(
        (data) => data?.keys
      ),
    enabled: subscriptionId != null,
  })
}

export function useEncryptionKeyDeleteMutation(type: ApiKeyType) {
  const queryClient = useQueryClient()

  return useRequestMutation('encryptionKeyDelete', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      ampli.apiKeyDeleted({ apiKeyType: ApiKeyTypeLogMap[type] })
      queryClient.invalidateQueries([encryptionKeysQueryKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useEncryptionKeyCreateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('encryptionKeyCreate', {
    onSuccess: (payload) => {
      const currentCache = queryClient.getQueryData<ApiKey[]>([encryptionKeysQueryKey, payload.subscriptionId]) ?? []
      queryClient.setQueryData<ApiKey[]>([encryptionKeysQueryKey, payload.subscriptionId], [...currentCache, payload])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useEncryptionKeyActivateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('encryptionKeyActivate', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([encryptionKeysQueryKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useEncryptionKeyUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('encryptionKeyUpdate', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([encryptionKeysQueryKey, subscriptionId])
    },
    errorHandling: {
      forceToast: true,
    },
  })
}
