import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import { StylesProvider } from '@mui/styles'
import { Provider as RollbarProvider } from '@rollbar/react'
import { Elements as StripeProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FPJS_REGION, FPJS_SCRIPT_URL_PATTERN, FPJS_TOKEN, FPJS_USE_TLS, GenericError, STRIPE_PUBLIC_KEY } from 'const'
import { AuthProvider, DialogProvider } from 'hooks'
import { lazy, ReactNode, Suspense } from 'react'
import materialUiTheme from 'styles/material-ui'

import { AmplitudeProvider, rollbarConfig } from './helpers/vendor'
import { ToastProvider, UiPreferencesProvider } from './hooks'
import { AnalyticsProvider } from './hooks/analytics'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type Props = {
  children: ReactNode
}

const stripe = loadStripe(STRIPE_PUBLIC_KEY)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (retryCount, error: GenericError) => {
        // Suboptimal solution, should be revisited later in a separate PRD.
        if (error != null && 'message' in error) {
          switch (error.message) {
            case 'You are not authorized to perform that action':
            case 'You are sending too many requests. Try again later.':
            case 'Invalid Response Encoding':
              return false

            case 'Something went wrong':
              return retryCount < 2
          }
        }

        return retryCount < 3
      },
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  },
})

/**
 * This wrapper is used both for storybook and application
 */
export default function AppProviders({ children }: Props) {
  const showReactQueryDevtools = localStorage.getItem('showDevtools') === 'true'

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RollbarProvider config={rollbarConfig}>
          <FpjsProvider
            loadOptions={{
              scriptUrlPattern: FPJS_SCRIPT_URL_PATTERN,
              apiKey: FPJS_TOKEN,
              region: FPJS_REGION,
              disableTls: !FPJS_USE_TLS,
            }}
          >
            <AnalyticsProvider>
              <AmplitudeProvider>
                <StripeProvider stripe={stripe}>
                  <StylesProvider injectFirst>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={materialUiTheme}>
                        <UiPreferencesProvider>
                          <DialogProvider>
                            <ToastProvider>{children}</ToastProvider>
                          </DialogProvider>
                        </UiPreferencesProvider>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </StylesProvider>
                </StripeProvider>
              </AmplitudeProvider>
            </AnalyticsProvider>
          </FpjsProvider>
        </RollbarProvider>
      </AuthProvider>

      {showReactQueryDevtools ? (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      ) : null}
      {process.env.NODE_ENV !== 'production' ? <ReactQueryDevtools /> : null}
    </QueryClientProvider>
  )
}

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
)
