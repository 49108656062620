import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import clsx from 'clsx'

import { colorMap, PlatformFilterIndicator } from '../PlatformFilterIndicator/PlatformFilterIndicator'
import styles from './PlatformSelector.module.scss'

interface PlatformSelectorProps {
  value: PlatformType[]
  metricsCount: number
  maxMetricsCount: number
  onChange: (type: PlatformType[]) => void
}

type SignalType = {
  key: string
  label: string
}

export type PlatformType = 'ios' | 'android' | 'js'

const signalTypes: SignalType[] = [
  { key: 'js', label: 'Web' },
  { key: 'android', label: 'Android' },
  { key: 'ios', label: 'iOS' },
]

export function PlatformSelector({ value, metricsCount, maxMetricsCount, onChange }: PlatformSelectorProps) {
  const allKeys = signalTypes.map((signal: SignalType) => signal.key)

  const onPlatformChange = (event: SelectChangeEvent<PlatformType[]>) => {
    const selectedValues = event.target.value as PlatformType[]
    if (selectedValues.length === 0) {
      onChange(allKeys as PlatformType[])
    } else {
      onChange(selectedValues)
    }
  }

  return (
    <Select
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          transition: 'border 0.3s ease-out',
        },
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e0e0e0',
          },
          backgroundColor: '#f0f0f0',
        },
      }}
      id='signalType'
      labelId='signalType-label'
      multiple
      value={value}
      onChange={onPlatformChange}
      className={clsx(styles.customSelect)}
      renderValue={(selected) => (
        <div className={styles.signalContainer}>
          <PlatformFilterIndicator platforms={selected.map((value) => value)} hideNonSelected={false} />
          <Typography variant='body1' className={styles.customText}>
            SMART SIGNALS
          </Typography>
          <Typography variant='body1' className={clsx(styles.customText, styles.background)}>
            {metricsCount}/{maxMetricsCount}
          </Typography>
        </div>
      )}
    >
      {signalTypes.map((signal) => (
        <MenuItem key={signal.key} value={signal.key} classes={{ root: styles.menuItem }}>
          <div className={styles.signalContainer}>
            <span className={styles.colorBall} style={{ backgroundColor: colorMap[signal.key] }} />
            {signal.label}
          </div>
        </MenuItem>
      ))}
    </Select>
  )
}
