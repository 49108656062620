import { max, subMonths } from 'date-fns'
import { useCurrentSubscriptionData } from 'features/subscription'
import { currentDateInZonedTime } from 'helpers/date'
import { useCurrentUser } from 'hooks/api/users'
import { PropsWithChildren, useMemo } from 'react'

import { DateRangeContextProvider } from './DateRangeContext'

export function DefaultDateRangeContext({ children }: PropsWithChildren) {
  const { data: currentUser } = useCurrentUser()
  const { subscription } = useCurrentSubscriptionData()

  const today = useMemo(() => currentDateInZonedTime(currentUser?.timezone), [currentUser?.timezone])

  const initialMinDate = useMemo(() => {
    const availableDates = [subMonths(today, 8)]
    if (subscription) {
      availableDates.push(new Date(subscription.createdAt))
    }

    return max(availableDates)
  }, [subscription, today])

  return (
    <DateRangeContextProvider initialMinDate={initialMinDate} initialMaxDate={today} today={today}>
      {children}
    </DateRangeContextProvider>
  )
}
