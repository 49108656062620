import { Button, CircularProgress, Link, Typography } from '@mui/material'
import { useDeploymentStatus } from 'hooks/api/cloudflare'
import { useEffect, useState } from 'react'

import { GenericError, SUPPORT_EMAIL_MAILTO } from '../../../../const'
import { DeploymentStatus } from '../../../../models'
import WizardStep, { WizardStepContent, WizardStepErrorContent } from '../../../Wizard/Step/WizardStep'
import styles from './DeploymentInProgress.module.scss'

export interface DeploymentInProgressProps {
  integrationId: string
  onCompletedDeploy: () => void
  onRestartDeployment: () => void
}

enum ErrorTypes {
  InvalidToken = 'invalidToken',
  ContactSupport = 'contactSupport',
}

export default function DeploymentInProgress({
  integrationId,
  onCompletedDeploy,
  onRestartDeployment,
}: DeploymentInProgressProps) {
  const { data, error: statusError } = useDeploymentStatus(integrationId, true)

  const [errorType, setErrorType] = useState(ErrorTypes.ContactSupport)
  const [error, setError] = useState<GenericError | undefined>(undefined)

  useEffect(() => {
    async function handleStatusUpdate() {
      if (data?.status === DeploymentStatus.Completed) {
        onCompletedDeploy()
        return
      }

      if (data?.status === DeploymentStatus.Error) {
        if (
          data?.error &&
          (data.error.includes('Unable to verify zone') || data.error.includes('Authentication error'))
        ) {
          setError({
            code: 'value_invalid',
            message:
              "API Token doesn't have permissions for creating Worker. Go to Cloudflare Dashboard and edit the API Token (give sufficient permissions) and redeploy.",
          })
          setErrorType(ErrorTypes.InvalidToken)
        } else {
          setError({
            code: 'value_invalid',
            message: 'An unexpected error happened while connecting your account to Cloudflare.',
          })
        }
      }
    }

    handleStatusUpdate()
  }, [data, onCompletedDeploy])

  return (
    <WizardStep
      title='Connecting'
      subtitle='We are connecting your account with Cloudflare, this might take a few minutes.'
      error={statusError ?? error}
    >
      {error ? (
        <WizardStepErrorContent>
          <Typography variant='body1'>
            Need help?{' '}
            <Link href={SUPPORT_EMAIL_MAILTO} underline='hover'>
              Contact support
            </Link>
          </Typography>

          {errorType === ErrorTypes.InvalidToken && (
            <div className={styles.actions}>
              <Button variant='contained' color='primary' onClick={onRestartDeployment} className={styles.button}>
                Try Again
              </Button>
            </div>
          )}
        </WizardStepErrorContent>
      ) : (
        <WizardStepContent>
          <CircularProgress className={styles.loader} />
        </WizardStepContent>
      )}
    </WizardStep>
  )
}
