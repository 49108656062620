import { Link, Typography } from '@mui/material'
import Logo from 'components/Logo/Logo'
import OnboardingNavOptions from 'components/OnboardingNavOptions/OnboardingNavOptions'
import { PRIVACY_URL, TERMS_URL, WEBSITE_URL } from 'const'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import { SUPPORT_PAGE_URL } from '../../../../const'
import { getLocale } from '../../../../helpers/locale'
import styles from './ExternalLayout.module.scss'

export type Props = {
  isLoading?: boolean
  children?: React.ReactNode
  logoHref?: string
  srcPage?: string
}

const getAmpliSource = (srcPage?: string) => {
  switch (srcPage) {
    case 'login':
      return 'login form'
    default:
      return 'layout footer'
  }
}

export function ExternalLayout({ isLoading, children, logoHref, srcPage }: Props) {
  const onClickPrivacyPolicy = useCallback(() => {
    ampli.privacyPolicyClicked({ source: getAmpliSource(srcPage) })
  }, [srcPage])

  const onClickTermsOfService = useCallback(() => {
    ampli.termsOfServiceClicked({ source: getAmpliSource(srcPage) })
  }, [srcPage])

  const onContactSupport = useCallback(() => {
    ampli.contactSupportClicked({
      source: 'onboarding-header',
    })
    return false
  }, [])

  const logo = <Logo fill='#F04405' fillSecondary='#000' short={false} className={styles.logo} />

  const copyrightYear = new Date().toLocaleString(getLocale(), { year: 'numeric' })

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.topNav}>
          {logoHref ? (
            <Link component={RouterLink} to={logoHref} underline='hover'>
              {logo}
            </Link>
          ) : (
            <Link href={WEBSITE_URL} underline='hover'>
              {logo}
            </Link>
          )}
          {srcPage && <OnboardingNavOptions srcPage={srcPage} onContactSupport={onContactSupport} />}
        </div>
      </div>

      {isLoading ? <FullpageLoader /> : <div className={styles.main}>{children}</div>}

      <footer className={styles.footer}>
        <div className={styles.links}>
          <Typography component='span' variant='body2' className={styles.copyright}>
            © {copyrightYear} FingerprintJS, Inc
          </Typography>
          <Link
            href={PRIVACY_URL}
            color='primary'
            underline='hover'
            target='_blank'
            className={styles.link}
            onClick={onClickPrivacyPolicy}
          >
            Privacy Policy
          </Link>
          <Link
            href={TERMS_URL}
            color='primary'
            underline='hover'
            target='_blank'
            className={styles.link}
            onClick={onClickTermsOfService}
          >
            Terms of Service
          </Link>
          <Link
            href={SUPPORT_PAGE_URL}
            color='primary'
            underline='hover'
            target='_blank'
            className={styles.link}
            onClick={onContactSupport}
          >
            Contact us
          </Link>
        </div>
      </footer>
    </div>
  )
}
