import { MenuItemProps } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { useAuth } from 'hooks'
import { ampli } from 'models/ampli'
import { MouseEventHandler, useCallback } from 'react'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

export const SignOutMenuItems = ({ onClick, ...props }: MenuItemProps & GenericMenuItemOverrideProps) => {
  const { removeCredentials } = useAuth()

  const onSignOut: MouseEventHandler<HTMLLIElement> = useCallback(
    (e) => {
      ampli.signOutClicked()
      removeCredentials()
      onClick?.(e)
    },
    [onClick, removeCredentials]
  )

  return <GenericMenuItem label='Sign out' to={AppRoute.Login} onClick={onSignOut} {...props} />
}
