import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, InputLabel, Paper, Tab, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { PaymentMethod } from 'models'
import { ampli } from 'models/ampli'
import { memo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { GenericError } from '../../../const'
import { getErrorParams } from '../../../helpers/data'
import { muiRegister } from '../../../helpers/reactHookForm'
import CardBrands from '../../CardBrands/CardBrands'
import CardDescription from '../../CardDescription/CardDescription'
import CardLogo from '../../CardLogo'
import ControlledSelect from '../../ControlledSelect/ControlledSelect'
import SimpleCardInput from '../SimpleCardInput/SimpleCardInput'
import styles from './StripeForm.module.scss'

enum PaymentMethodTab {
  EXISTING = 'EXISTING',
  NEW = 'NEW',
}

export interface StripeFormProps {
  isLoading?: boolean
  error?: GenericError | null
  paymentMethods: PaymentMethod[]
}

export default function StripeForm({ isLoading, error, paymentMethods }: StripeFormProps) {
  const [tab, setTab] = useState(PaymentMethodTab.NEW)
  const { resetField } = useFormContext()

  const hasExistingPaymentMethods = paymentMethods.length > 0
  const defaultPaymentMethodId = paymentMethods.find(({ isDefault }) => isDefault)?.id

  useEffect(() => {
    if (tab === PaymentMethodTab.NEW) {
      resetField('cardHolderName')
    }
  }, [tab, resetField])

  useEffect(() => {
    setTab(hasExistingPaymentMethods ? PaymentMethodTab.EXISTING : PaymentMethodTab.NEW)
  }, [hasExistingPaymentMethods])

  return (
    <Paper data-testid='stripeForm' className={styles.tabs}>
      <TabContext value={tab}>
        <div>
          <div className={clsx([styles.heading, { [styles.tabHeading]: hasExistingPaymentMethods }])}>
            {hasExistingPaymentMethods && (
              <TabList
                textColor='primary'
                indicatorColor='primary'
                onChange={(_event, newTab: PaymentMethodTab) => {
                  if (newTab === PaymentMethodTab.EXISTING) {
                    ampli.useExistingPaymentSelected()
                  }
                  setTab(newTab)
                }}
              >
                <Tab disableRipple label='Existing' value={PaymentMethodTab.EXISTING} classes={{ root: styles.tab }} />
                <Tab disableRipple label='New' value={PaymentMethodTab.NEW} classes={{ root: styles.tab }} />
              </TabList>
            )}
          </div>

          <TabPanel value={PaymentMethodTab.EXISTING} className={styles.tabPanel}>
            <ExistingPaymentMethodTab
              hasExistingPaymentMethods={hasExistingPaymentMethods}
              error={error}
              defaultPaymentMethodId={defaultPaymentMethodId}
              paymentMethods={paymentMethods}
            />
          </TabPanel>

          <TabPanel
            value={PaymentMethodTab.NEW}
            className={clsx(styles.tabPanel, { [styles.noTabs]: !hasExistingPaymentMethods })}
          >
            <NewPaymentMethodTab hasExistingPaymentMethods={hasExistingPaymentMethods} error={error} />
          </TabPanel>
        </div>
      </TabContext>

      <Button
        disabled={isLoading}
        variant='contained'
        size='large'
        color='primary'
        type='submit'
        title='Submit'
        fullWidth
        className={styles.button}
      >
        Submit
      </Button>
    </Paper>
  )
}

interface TabProps {
  hasExistingPaymentMethods: boolean
  error?: GenericError | null
}

interface ExistingPaymentMethodTabProps extends TabProps {
  defaultPaymentMethodId?: string
  paymentMethods: PaymentMethod[]
}

const ExistingPaymentMethodTab = memo(function ExistingPaymentMethodTab({
  error,
  defaultPaymentMethodId,
  paymentMethods,
}: ExistingPaymentMethodTabProps) {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <ControlledSelect
      title='Payment Method'
      name='paymentMethodId'
      items={paymentMethods.map((paymentMethod) => ({
        value: paymentMethod.id,
        displayValue: (
          <>
            <CardLogo cardBrand={paymentMethod.cardBrand} />
            <CardDescription compact={lgDown} paymentMethod={paymentMethod} className={styles.cardDescription} />
          </>
        ),
      }))}
      defaultValue={defaultPaymentMethodId ?? paymentMethods[0].id}
      rules={{ required: 'Payment method required' }}
      error={error}
    />
  )
})

const NewPaymentMethodTab = memo(function NewPaymentMethodTab({ error }: TabProps) {
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext()
  const cardholderNameError = getErrorParams('cardHolderName', formErrors, error)

  return (
    <>
      <InputLabel htmlFor='cardHolderName'>Card Name</InputLabel>
      <TextField
        fullWidth
        className={clsx(styles.field, {
          [styles.hasErrors]: cardholderNameError.error,
        })}
        id='cardHolderName'
        placeholder='John Doe'
        variant='outlined'
        {...muiRegister(register, 'cardHolderName', { required: 'Name on card required' })}
        {...cardholderNameError}
      />

      <InputLabel htmlFor='cardInput'>Card Info</InputLabel>
      <SimpleCardInput className={styles.field} />
      <div className={styles.cardHelper}>
        <Typography className={styles.errorMessage} variant='body2'>
          {error && (error.param === 'card' || error.param === 'stripePaymentMethod') && error.message}
        </Typography>
      </div>
      <CardBrands />
    </>
  )
})
