import { Equalizer } from '@mui/icons-material'
import { Alert, Box, Button, Link, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { GenericError } from 'const'
import { getChartDataPoints } from 'helpers/data'
import { ChartType, UsageChartData, UsageCounterPeriod, UsageCounterType } from 'models'
import { Link as RouterLink } from 'react-router-dom'

import { UsageChart } from '../UsageChart'
import styles from './SubscriptionOverview.module.scss'
import { SubscriptionOverviewState } from './SubscriptionOverviewState'

export function UsageCharts({
  subscriptionId,
  apiRequestsState,
  usageCountersData,
  granularity,
  timezone,
  usageCountersError,
}: {
  subscriptionId: string
  apiRequestsState: SubscriptionOverviewState
  usageCountersData: UsageChartData | undefined
  usageCountersError?: GenericError | null
  granularity?: UsageCounterPeriod
  timezone: string | undefined
}) {
  if (granularity == null) {
    return null
  }

  switch (apiRequestsState) {
    case SubscriptionOverviewState.HasUsageCounters:
      if (usageCountersData![UsageCounterType.UniqueVisitors]) {
        const { [UsageCounterType.UniqueVisitors]: uniqueVisitorsData, ...callBasedData } = usageCountersData!
        return (
          <>
            <UsageChart
              data={getChartDataPoints({ [UsageCounterType.UniqueVisitors]: uniqueVisitorsData })}
              granularity={granularity}
              chartType={ChartType.UniqueVisitors}
              timezone={timezone}
            />
            <UsageChart
              data={getChartDataPoints(callBasedData)}
              granularity={granularity}
              chartType={ChartType.CallBased}
              timezone={timezone}
            />
          </>
        )
      } else {
        return (
          <UsageChart
            data={getChartDataPoints(usageCountersData!)}
            granularity={granularity}
            chartType={ChartType.CallBased}
            timezone={timezone}
          />
        )
      }

    case SubscriptionOverviewState.NoUsageCountersDuringPeriod:
      return <Alert severity='info'>No calls have been made during this period.</Alert>

    case SubscriptionOverviewState.UsageCountersError:
      return <Alert severity='error'>{usageCountersError!.message}</Alert>

    case SubscriptionOverviewState.Setup:
    default:
      return (
        <div className={clsx(styles.zerostate, styles.wrapper)}>
          <Box className={clsx(styles.zerostate, styles.icon)}>
            <Equalizer htmlColor='#999' />
          </Box>
          <Typography variant='h4' component='h4' className={clsx(styles.zerostate, styles.header)}>
            There&apos;s nothing here
          </Typography>
          <Typography variant='body2' className={clsx(styles.zerostate, styles.secondaryText)}>
            Statistics will appear here once your app is sending requests.
          </Typography>
          <Link component={RouterLink} to={buildRoute(AppRoute.Integrations, { subscriptionId })}>
            <Button color='primary' variant='contained' className={clsx(styles.zerostate, styles.button)}>
              Install Fingerprint Now
            </Button>
          </Link>
        </div>
      )
  }
}
