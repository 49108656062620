import { DeleteOutline, InfoOutlined } from '@mui/icons-material'
import { Alert, IconButton, Paper, TableHead, Typography, useMediaQuery, useTheme } from '@mui/material'
import { SupportEmail } from 'components'
import PageHeader from 'components/PageHeader/PageHeader'
import { DEFAULT_CERTIFICATE_LIMIT, DOCS_SUBDOMAIN_INTEGRATION_URL, PRODUCT_NAME } from 'const'
import { appSettingNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useConfirmationDialog, useDocumentTitle } from 'hooks'
import { SSLCertificate, SSLCertificateStatus } from 'models'
import { ampli } from 'models/ampli'
import { memo, useEffect } from 'react'

import AddEntityButton from '../../../../components/AddEntityButton/AddEntityButton'
import EntityTableBodyEmpty from '../../../../components/Table/CustomStatus/EntityTable'
import {
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableCell,
  TableContainer,
  TableRow,
} from '../../../../components/Table/Table'
import Tag from '../../../../components/Tag/Tag'
import { pluralize } from '../../../../helpers/pluralize'
import { useToast } from '../../../../hooks'
import SubdomainIntegrationInfoDrawer from '../InfoDrawer/SubdomainIntegrationInfoDrawer'
import styles from './CertificatesList.module.scss'

export interface ListProps {
  certificates: SSLCertificate[]
  isLoading?: boolean
  errorMessage?: string
  canCreateNew?: boolean
  certificatesLimit: number
  onCertificateClick: (certId: string) => void
  onCertificateDelete: (certId: string) => void
  onCertificateAddClick: () => void
}

export default memo(function CertificatesList(props: ListProps) {
  useDocumentTitle('Custom Subdomain')

  const {
    isLoading,
    errorMessage,
    certificates = [],
    certificatesLimit,
    onCertificateClick,
    onCertificateAddClick,
    onCertificateDelete,
  } = props
  const { showToast } = useToast()
  const { openDialog } = useConfirmationDialog()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (errorMessage) {
      showToast({ message: errorMessage })
      if (errorMessage === 'Certificate can be created only for active and trialing subscription') {
        ampli.trialRequiredErrorDisplayed()
      }
    }
  }, [errorMessage, showToast])

  const certificateData = {
    name: 'Certificate',
    count: certificates.length,
    limit: certificatesLimit,
    onAddClick: onCertificateAddClick,
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />

      <MainContent>
        <Alert severity='info' icon={<InfoOutlined className={styles.icon} />} className={styles.alert}>
          <Typography variant='body2'>
            Your current application is limited to{' '}
            {pluralize(certificatesLimit ?? DEFAULT_CERTIFICATE_LIMIT, 'SSL certificate')}. Please contact{' '}
            <SupportEmail /> if you need more.
          </Typography>
        </Alert>

        <PageHeader
          title='Custom Subdomain'
          infoDrawer={<SubdomainIntegrationInfoDrawer />}
          actions={<AddEntityButton entityData={certificateData} />}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '70%', maxWidth: 792, minWidth: mdUp ? 600 : undefined }}>
                  Certificate name
                </TableCell>
                <TableCell style={{ width: '10%', maxWidth: 128 }}>Status</TableCell>
                <TableCell style={{ width: '10%', maxWidth: 48, textAlign: 'right' }} align='right'>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody columnCount={3} isLoading={isLoading} error={errorMessage}>
              <TableBodyData>
                {certificates.map(({ id, domainName, status }) => (
                  <TableRow key={id} onClick={() => onCertificateClick(id)} interactive>
                    <TableCell style={{ width: '70%', maxWidth: 792, minWidth: mdUp ? 600 : undefined }}>
                      {domainName}
                    </TableCell>

                    <TableCell style={{ width: '10%', maxWidth: 128 }}>
                      <Status status={status} />
                    </TableCell>

                    <TableCell style={{ width: '10%', maxWidth: 48, textAlign: 'right' }} align='right'>
                      {status !== SSLCertificateStatus.Revoked && (
                        <IconButton
                          aria-label='delete'
                          size='small'
                          onClick={(e) => {
                            e.stopPropagation()
                            openDialog({
                              label: 'Delete certificate?',
                              onConfirm: () => onCertificateDelete(id),
                            })
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBodyData>

              <TableBodyEmpty>
                <EntityTableBodyEmpty
                  entityData={certificateData}
                  title='Subdomain setup'
                  body={
                    <>
                      Using a subdomain significantly improves accuracy and is required for correct identification when
                      using {PRODUCT_NAME}.
                    </>
                  }
                  docsUrl={DOCS_SUBDOMAIN_INTEGRATION_URL}
                  showAddButton
                />
              </TableBodyEmpty>
            </TableBody>
          </Table>
        </TableContainer>
      </MainContent>
    </>
  )
})

const Status = ({ status }: { status: SSLCertificateStatus }) => {
  switch (status) {
    case SSLCertificateStatus.Default:
      return <Tag color='darkGray' label='Init' />
    case SSLCertificateStatus.Validation:
      return <Tag color='yellow' label='Awaiting validation' />
    case SSLCertificateStatus.Issued:
      return <Tag color='blue' label='Issued' />
    case SSLCertificateStatus.Revoked:
      return <Tag color='red' label='Removing' />
    default:
      return <Tag color='gray' label='Invalid' />
  }
}
