import { Add } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'

import { usePermissions } from '../../hooks/permissions'
import { EntityData } from '../../models'

interface AddEntityButtonProps {
  entityData: EntityData
  variant?: 'outlined' | 'contained'
  onlyName?: boolean
  className?: string
}

export default function AddEntityButton({
  entityData,
  variant = 'outlined',
  onlyName,
  className,
}: AddEntityButtonProps) {
  const { isReadOnly } = usePermissions()
  const { name, count, limit, onAddClick } = entityData

  const limitExceeded = count >= limit

  const button = (
    <Button
      size='medium'
      color='primary'
      variant={variant}
      onClick={onAddClick}
      disabled={isReadOnly || limitExceeded}
      startIcon={variant === 'outlined' ? <Add /> : undefined}
      className={className}
    >
      {onlyName ? name : `Add ${name}`}
    </Button>
  )

  return limitExceeded ? (
    <Tooltip title={`The number of ${name.toLowerCase()} is limited to ${limit}`}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  )
}
