import { useCurrentSubscription } from 'features/subscription'
import { useSmartSignal } from 'hooks/api/smart_signals'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

const supportedSignalsPresentationalInfo: Record<string, Pick<SmartSignalMetric, 'platform' | 'tooltip' | 'label'>> = {
  totalGoodBots: {
    label: 'Good bots',
    tooltip: 'Well-known web crawler or other search engine bot.',
    platform: ['js'],
  },
  totalBadBots: {
    label: 'Bad bots',
    tooltip: 'Automated tools that don’t have legitimate uses and assumes fraudulent activity.',
    platform: ['js'],
  },
  totalIncognito: { label: 'Incognito mode', tooltip: 'Detected incognito mode in major browsers.', platform: ['js'] },
  totalTampering: {
    label: 'Browser tampering',
    tooltip:
      'Detected User-Agent spoofing or changes to the output of selected signals that are collected from the browser.',
    platform: ['js'],
  },
  totalTamperingAndroid: {
    label: 'Android tampering',
    tooltip: 'Detected that the Android device is rooted.',
    platform: ['android'],
  },
  totalVpn: {
    label: 'VPN',
    tooltip: 'Detected that the device uses an active VPN connection.',
    platform: ['js', 'android', 'ios'],
  },
  totalIpBlocklist: {
    label: 'IP blocklist',
    tooltip: 'Detected that the IP address is listed in different public and proprietary blocklists.',
    platform: ['js', 'android', 'ios'],
  },
  totalVirtualMachines: {
    label: 'VM detection',
    tooltip: 'Detected the browser is running inside a virtualization software like VirtualBox.',
    platform: ['js'],
  },
  totalPrivacySettings: {
    label: 'Privacy settings',
    tooltip: 'Detected that privacy settings are turned on in privacy-focused browsers like Firefox or Brave.',
    platform: ['js'],
  },
  totalHighActivity: {
    label: 'High-activity device',
    tooltip: 'Detected spikes in traffic connected to a single visitor ID.',
    platform: ['js', 'android', 'ios'],
  },
  totalEmulator: {
    label: 'Android emulator',
    tooltip: 'Detected that the device is an Android emulator software.',
    platform: ['android'],
  },
  totalAppCloners: {
    label: 'Cloned app',
    tooltip: 'Detected that request is coming from a cloned app.',
    platform: ['android'],
  },
  totalFrida: {
    label: 'Frida',
    tooltip: 'Detected that Frida was used to dynamically instrument the app and change its behavior.',
    platform: ['android', 'ios'],
  },
  totalJailbreak: {
    label: 'Jailbreak',
    tooltip:
      'Detected that device was jailbroken and that unauthorized changes were made to its underlying operating system.',
    platform: ['ios'],
  },
}

export type SmartSignalMetric = {
  label: string
  signalName: string
  tooltip: string
  platform: string[]
  value: bigint | null
  previousPeriodValue: bigint | null
}

export function useSmartSignalMetrics(dateRange: { startDate?: DateTime; endDate?: DateTime }) {
  const { currentSubscriptionId } = useCurrentSubscription()

  // we want to be able to add on the backend first, and expose it to
  // the frontend when we have the presentational data set up
  const supportedSignals = useMemo(() => new Set(Object.keys(supportedSignalsPresentationalInfo)), [])

  const previousDateRange = useMemo(() => {
    if (dateRange.startDate == null || dateRange.endDate == null) {
      return { startDate: null, endDate: null }
    }

    const diff = dateRange.endDate.diff(dateRange.startDate)
    return {
      startDate: dateRange.startDate.minus(diff),
      endDate: dateRange.startDate,
    }
  }, [dateRange])

  const { data, isLoading } = useSmartSignal(
    currentSubscriptionId,
    dateRange.startDate?.toISO() ?? undefined,
    dateRange.endDate?.toISO() ?? undefined
  )
  const { data: previousPeriodData, isLoading: isLoadingPrevious } = useSmartSignal(
    currentSubscriptionId,
    previousDateRange.startDate?.toISO() ?? undefined,
    previousDateRange.endDate?.toISO() ?? undefined
  )

  const smartSignalsMetrics: SmartSignalMetric[] = useMemo(() => {
    const currentPeriodSignals = data?.stats?.data
    const previousPeriodSignals = previousPeriodData?.stats?.data

    if (!currentPeriodSignals || !previousPeriodSignals) {
      return []
    }

    const unfilteredSignals = Object.keys(currentPeriodSignals).reduce<SmartSignalMetric[]>((agg, platform) => {
      const signalMetricsForPlatform: SmartSignalMetric[] =
        currentPeriodSignals[platform] &&
        Object.keys(currentPeriodSignals[platform]).map((signalName) => {
          const currentPeriodSignal = currentPeriodSignals?.[platform]?.[signalName] ?? null
          const previousPeriodSignal = previousPeriodSignals?.[platform]?.[signalName] ?? null

          const currentPeriodValue = currentPeriodSignal ? BigInt(currentPeriodSignal) : null
          const previousPeriodValue = previousPeriodSignal ? BigInt(previousPeriodSignal) : null

          if (signalName === 'totalTampering' && platform === 'android') {
            signalName = 'totalTamperingAndroid'
          }

          return {
            ...supportedSignalsPresentationalInfo[signalName],
            signalName: signalName,
            value: currentPeriodValue,
            previousPeriodValue,
          }
        })

      if (signalMetricsForPlatform) {
        agg.push(...signalMetricsForPlatform)
      }
      return agg
    }, [])

    return unfilteredSignals.filter((signal) => supportedSignals.has(signal.signalName))
  }, [data?.stats?.data, previousPeriodData?.stats?.data, supportedSignals])

  return {
    isLoading: isLoading || isLoadingPrevious,
    smartSignalsEnabled: data?.smartSignalsEnabled,
    smartSignalsAvailable: data?.smartSignalsAvailable,
    totalApiCalls: data?.totalApiCalls ? BigInt(data.totalApiCalls) : null,
    smartSignalsMetrics,
  }
}
