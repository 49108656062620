import { ExpandedSubscription, UserContext } from 'models'

export interface UserConsentModel {
  privacyPolicy: boolean
  termsOfService: boolean
}

export interface AuthTokens {
  accessToken: string
  refreshToken: string
}

export interface AuthTokensWithUserContext extends AuthTokens {
  context: UserContext
}

export interface LoginResponse extends AuthTokensWithUserContext {
  subscriptions: ExpandedSubscription[]
}

export interface SSOLoginResponse extends LoginResponse {
  isSignup: boolean
}

export enum OAuthProvider {
  Google = 'GoogleOAuth',
  GitHub = 'GitHubOAuth',
  Microsoft = 'MicrosoftOAuth',
}

type AmplitudeType = 'google sso' | 'github sso' | 'microsoft sso'

export const providerToAmplitudeTypeMap: {
  [key in OAuthProvider]: AmplitudeType
} = {
  [OAuthProvider.Google]: 'google sso',
  [OAuthProvider.GitHub]: 'github sso',
  [OAuthProvider.Microsoft]: 'microsoft sso',
}

export interface SsoStatusResponse {
  sso: {
    isEnabled: boolean
    ssoLink?: string
  }
}

export type SsoAuthModel = { redirectUrl: string; provider?: OAuthProvider } & Partial<UserConsentModel>

export type OAuthProviderLinkMap = Record<OAuthProvider, string>
export type OAuthProviderHandlerMap = Record<OAuthProvider, () => void>
