import { Button, LinearProgress, Skeleton, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { formatNum } from '../../../../helpers/format'
import styles from './PlanUsage.module.scss'

export interface PlanUsageProps {
  entries: Array<{
    key: string
    label: string | ReactNode
    value?: number | string
    hidden?: boolean
    badge?: {
      text: string
      tooltip: string
    }
  }>
  total?: number
  current?: number
  projected?: number
  action?: string
  onAction?: () => void
  actionTo?: string
  isLoading?: boolean
  className?: string
}

export function PlanUsage({
  entries,
  total,
  current,
  projected,
  action,
  onAction,
  actionTo,
  isLoading,
  className,
}: PlanUsageProps) {
  const progressVariant = isLoading ? 'query' : 'determinate'

  const valuePercentage = current && total ? Math.min((current * 100) / total, 100) : 0
  const projectedPercentage = projected && total ? Math.min((projected * 100) / total, 100) : 0

  const isUnlimited = total === Infinity

  return (
    <div className={clsx(styles.planUsage, className)}>
      <dl className={styles.entries}>
        {entries
          .filter(({ hidden }) => !hidden)
          .map(({ key, label, value, badge }) => (
            <span key={key} className={styles.entry}>
              <div className={styles.text}>
                <Typography variant='h2' component='dt'>
                  {isLoading || value === undefined ? <Skeleton /> : value}
                </Typography>
                {badge && (
                  <Tooltip title={badge.tooltip} placement='bottom-start' classes={{ popper: styles.tooltip }}>
                    <Typography
                      variant='body3'
                      className={styles.planBadge}
                      style={{
                        background: '#E9F4FE',
                        color: '#0D3C61',
                      }}
                    >
                      {badge.text}
                    </Typography>
                  </Tooltip>
                )}
              </div>
              <Typography variant='body3' component='dd' className={styles.label}>
                {label}
              </Typography>
            </span>
          ))}
      </dl>

      {action && onAction && (
        <Button
          component={actionTo ? RouterLink : 'button'}
          color={isLoading ? 'inherit' : 'primary'}
          variant='outlined'
          onClick={onAction}
          to={actionTo}
          disabled={isLoading}
          classes={{ root: clsx(styles.button, { [styles.disabled]: isLoading }) }}
          fullWidth
        >
          {action}
        </Button>
      )}

      {!isUnlimited && (
        <>
          <LinearProgress
            variant={progressVariant}
            value={valuePercentage}
            valueBuffer={projectedPercentage}
            classes={{ root: styles.progress, bar: styles.bar }}
          />
          <div className={styles.legend}>
            <Typography variant='caption'>0</Typography>
            <Typography variant='caption'>
              {!isLoading && total ? formatNum(Math.round(total / 2)) : <Skeleton width={16} />}
            </Typography>
            <Typography variant='caption'>
              {!isLoading && total ? formatNum(total) : <Skeleton width={16} />}
            </Typography>
          </div>
        </>
      )}
    </div>
  )
}
