import { Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { WithCopyButton } from '../WithCopyButton/WithCopyButton'
import styles from './KeyValueList.module.scss'

export interface Entry {
  key: string
  value: string | ReactNode
  monospace?: boolean
  faded?: boolean
  blurred?: boolean
  copyText?: string
  onCopy?: () => void
}

export interface KeyValueListProps {
  items: Array<Entry>
  isLoading?: boolean
  className?: string
}

export default function KeyValueList({ items, isLoading, className }: KeyValueListProps) {
  const minWidth = Math.max(...items.map(({ key }) => key.length * 8))

  return (
    <dl className={clsx(styles.list, className)}>
      {items.map(({ key, value, monospace, faded, blurred, copyText, onCopy }) => {
        const displayValue = copyText ? (
          <WithCopyButton text={copyText} onCopy={onCopy}>
            {value}
          </WithCopyButton>
        ) : (
          value
        )

        return (
          <span key={key} className={styles.keyValue}>
            <Typography component='dt' variant='body2' className={styles.key} style={{ minWidth }}>
              {key}
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              className={clsx(
                styles.value,
                { [styles.copyable]: !!copyText },
                { [styles.monospace]: monospace },
                { [styles.faded]: faded },
                { [styles.blurred]: blurred && !isLoading }
              )}
            >
              {isLoading ? <Skeleton width={128} data-testid='key-value-list-skeleton' /> : displayValue}
            </Typography>
          </span>
        )
      })}
    </dl>
  )
}
