import { ValueWithDifference } from './ValueWithDifference'

export function NumericValue({
  value,
  previousValue,
  precise,
}: {
  value?: number
  previousValue?: number
  precise?: boolean
}) {
  if (value == null) {
    return <ValueWithDifference />
  }

  // Do not divide by zero.
  if (previousValue == null || previousValue === 0) {
    return <ValueWithDifference value={value} difference={value} percentage={value === 0 ? 0 : 1} precise={precise} />
  }

  const difference = value - previousValue
  const percentage = (value - previousValue) / previousValue
  const shownPercentage = Number.isFinite(percentage) ? percentage : percentage > 0 ? 1 : -1

  return <ValueWithDifference value={value} difference={difference} percentage={shownPercentage} precise={precise} />
}
