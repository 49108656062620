import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import PageHeader from 'components/PageHeader/PageHeader'
import { appSettingNavItems, Header, MainGrid, TabNavigation } from 'features/commonUI'
import { LatencyChart, OperationsChart, useClientSdkPerformanceQuery } from 'features/health'
import { useCurrentSubscription } from 'features/subscription'
import { useLogPageView } from 'helpers/vendor'
import { useHealth } from 'hooks/api/health'
import { ampli } from 'models/ampli'

import { SdkHealthWidgetAdapter } from '../../components/widgets/SdkHealthWidget/SdkHealthWidget'
import { ThrottledRequestsHealthWidget } from '../../components/widgets/ThrottledRequestsHealthWidget/ThrottledRequestsHealthWidget'
import { useSdkHealthStatusQuery } from '../../hooks/useSdkHealthStatusQuery'
import styles from './HealthPage.module.scss'

export function HealthPage() {
  useLogPageView((route) => ampli.healthPageViewed({ route }))
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: health, isLoading: isLoadingHealth } = useHealth(subscriptionId)
  const { data: sdkHealthStatus } = useSdkHealthStatusQuery(subscriptionId)
  const { data: performance } = useClientSdkPerformanceQuery(subscriptionId)

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainGrid spacing={4}>
        <Grid item xs={12}>
          <PageHeader title='Health' />
        </Grid>
        <Grid item sm={12} container spacing={2} className={styles.main}>
          <Grid item xs={12} className={styles.fullWidth}>
            <Typography variant='caption'>System</Typography>
          </Grid>
          {isLoadingHealth || !health || sdkHealthStatus == null ? (
            <>
              <Grid item>
                <Skeleton
                  className={styles.widget}
                  variant='rectangular'
                  data-testid='health-widget-loading-skeleton-rect'
                />
              </Grid>
              <Grid item>
                <Skeleton className={styles.widget} variant='rectangular' />
              </Grid>
            </>
          ) : (
            <>
              <Grid item data-testid='throttled-requests-widget'>
                <ThrottledRequestsHealthWidget {...health.throttledRequestsHealth} className={styles.widget} />
              </Grid>
              <Grid item data-testid='sdk-health-widget'>
                <SdkHealthWidgetAdapter sdkHealthStatus={sdkHealthStatus} className={styles.widget} />
              </Grid>
            </>
          )}
        </Grid>
        {performance == null || performance.stats.length !== 0 ? (
          <>
            <Grid item xs={12}>
              <Stack gap={2}>
                <Typography variant='caption'>Operations</Typography>
                {performance ? (
                  <OperationsChart performance={performance} className={styles.widget} />
                ) : (
                  <Skeleton className={styles.widget} variant='rectangular' />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={2}>
                <Typography variant='caption'>Latency</Typography>
                {performance ? (
                  <LatencyChart performance={performance} className={styles.widget} />
                ) : (
                  <Skeleton className={styles.widget} variant='rectangular' />
                )}
              </Stack>
            </Grid>
          </>
        ) : null}
      </MainGrid>
    </>
  )
}
