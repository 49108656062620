import { ApiKeyType } from '../../models'
import { apiKeyDisplayNameMap } from './components/KeySection/KeySection'

export const PRIVATE_KEY_TYPES = [
  ApiKeyType.Secret,
  ApiKeyType.BrowserProxy,
  ApiKeyType.Encryption,
  ApiKeyType.Management,
]
export const privateWarning = (type: ApiKeyType) =>
  `Never share ${apiKeyDisplayNameMap[type].toLowerCase()} keys outside of your team.`
export const ownerRestricted = (type: ApiKeyType) =>
  `Only owners can manage ${apiKeyDisplayNameMap[type].toLowerCase()} keys.`
export const keysLimitReached = (maxKeys: number) =>
  `Your application has reached its maximum number of ${maxKeys} keys`
