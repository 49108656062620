import { Box, Skeleton } from '@mui/material'
import {
  DefaultDateRangeContext,
  Header,
  interpretOverviewDateRange,
  MainContent,
  useDateRangeContext,
} from 'features/commonUI'
import { ChartDateRangePicker } from 'features/subscriptionOverview'
import { DateRange } from 'helpers/dateRange'
import { useCurrentUser } from 'hooks/api/users'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { useEffect, useMemo, useState } from 'react'

import Loader from '../../../../components/Loader/Loader'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { DiffTypeSelector } from '../../components/DiffTypeSelector/DiffTypeSelector'
import { PlatformSelector, PlatformType } from '../../components/PlatformSelector/PlatformSelector'
import SmartSignalsIntroduction from '../../components/SmartSignalsIntroduction/SmartSignalsIntroduction'
import { SmartSignalsMetricsContent } from '../../components/SmartSignalsMetricsContent/SmartSignalsMetricsContent'
import { SmartSignalMetric, useSmartSignalMetrics } from '../../hooks/useSmartSignalsMetrics'
import { DiffType } from '../../utils/models'
import styles from './SmartSignalsPage.module.scss'

export function SmartSignalsPage() {
  return (
    <DefaultDateRangeContext>
      <Page />
    </DefaultDateRangeContext>
  )
}

function Page() {
  const { data: currentUser } = useCurrentUser()

  const { dateRange, setDateRange, initialDateRange, initialMinDate, initialMaxDate, today } = useDateRangeContext()
  const { startDate, endDate } = interpretOverviewDateRange(dateRange, currentUser?.timezone)

  const [diffType, setDiffType] = useState<DiffType>('absolute')
  const [signalTypeFilter, setSignalTypeFilter] = useState<PlatformType[]>(['js', 'android', 'ios'])

  const { smartSignalsMetrics, smartSignalsEnabled, totalApiCalls, isLoading } = useSmartSignalMetrics({
    startDate,
    endDate,
  })

  useEffect(() => {
    if (totalApiCalls == null && diffType === 'percent') {
      setDiffType('absolute')
    }
  }, [diffType, totalApiCalls])

  const metricsForSelectedPlatforms = smartSignalsMetrics.filter((metricData) =>
    metricData.platform.some((plat: PlatformType) => signalTypeFilter.includes(plat))
  )

  const totalMetrics = useMemo(() => reduceMetrics(smartSignalsMetrics), [smartSignalsMetrics])
  const displayedMetrics = useMemo(() => reduceMetrics(metricsForSelectedPlatforms), [metricsForSelectedPlatforms])

  const currentSignalsCount = displayedMetrics.length
  const highestSignalsCount = totalMetrics.length

  const allSmartSignalsEnabled = true
  // TODO Once the backend returns correct values, check if there's anything to upsell for
  // useMemo(() => {
  //   const set = new Set(smartSignalsAvailable)
  //   for (const enabled of smartSignalsEnabled ?? []) {
  //     set.delete(enabled)
  //   }

  //   return set.size === 0
  // }, [smartSignalsAvailable, smartSignalsEnabled])

  if (
    !isLoading &&
    smartSignalsEnabled &&
    (smartSignalsEnabled.length === 0 ||
      (smartSignalsEnabled.length === 1 && smartSignalsEnabled[0] === 'identification'))
  ) {
    return <SmartSignalsIntroduction />
  }

  return (
    <>
      <Header title='Smart Signals' />
      <MainContent>
        <Box>
          <Box className={styles.headerGrid}>
            <PageHeader title='Insights' className={styles.header} />
            <Box className={styles.actionDiv}>
              <DiffTypeSelector
                value={diffType}
                onChange={(value) => {
                  if (value) {
                    setDiffType(value)
                  }
                }}
                disabled={totalApiCalls == null}
              />
              <PlatformSelector
                value={signalTypeFilter}
                onChange={setSignalTypeFilter}
                metricsCount={currentSignalsCount}
                maxMetricsCount={highestSignalsCount}
              />
              {today && setDateRange && initialDateRange ? (
                <ChartDateRangePicker
                  onApplyRange={(range: Required<DateRange>) => setDateRange(range)}
                  initialDateRange={initialDateRange}
                  initialMinDate={initialMinDate}
                  initialMaxDate={initialMaxDate}
                  today={today}
                  rangeInterpreter={(dateRange: Required<DateRange>) =>
                    interpretOverviewDateRange(dateRange, currentUser?.timezone)
                  }
                  timezone={currentUser?.timezone}
                />
              ) : (
                <Skeleton width={200} />
              )}
            </Box>
          </Box>
          {isLoading ? <Loader /> : null}
          <SmartSignalsMetricsContent
            displayedMetricData={isLoading ? undefined : displayedMetrics}
            totalApiCalls={totalApiCalls}
            diffType={diffType}
            allSmartSignalsEnabled={allSmartSignalsEnabled}
          />
        </Box>
      </MainContent>
    </>
  )
}
function reduceMetrics(metricsForSelectedPlatforms: SmartSignalMetric[]) {
  const metricsByLabel = groupBy(metricsForSelectedPlatforms, (m) => m.label)
  const reducedMetrics = map(metricsByLabel, (metrics) =>
    metrics.reduce((acc, metric) => {
      if (!(acc.value == null && metric.value == null)) {
        acc.value = (acc.value ?? 0n) + (metric.value ?? 0n)
      }
      return acc
    })
  )
  const filteredMetrics = reducedMetrics.filter((m) => m.value != null)
  const displayedMetrics = filteredMetrics.length > 0 ? filteredMetrics : reducedMetrics
  return displayedMetrics
}
