import { Button, Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { SUPPORT_PAGE_URL } from '../../const'
import { useAuth } from '../../hooks'
import styles from './OnboardingNavOptions.module.scss'

export default function OnboardingNavOptions({
  srcPage,
  onContactSupport,
}: {
  srcPage: string
  onContactSupport: () => void
}) {
  const { removeCredentials } = useAuth()
  const { search } = useLocation()

  let button
  switch (srcPage) {
    case 'login':
      button = (
        <Button
          variant='outlined'
          component={RouterLink}
          to={{ pathname: buildRoute(AppRoute.Signup), search }}
          className={styles.buttonOption}
          data-testid='layoutSignupButton'
        >
          Sign up free
        </Button>
      )
      break
    case 'onboarding':
    case 'signupConfirm':
      button = (
        <Button
          variant='outlined'
          className={styles.buttonOption}
          onClick={() => removeCredentials()}
          data-testid='layoutLogoutButton'
        >
          Log out
        </Button>
      )
      break
    default:
      button = (
        <Button
          variant='outlined'
          component={RouterLink}
          to={{ pathname: AppRoute.Login, search }}
          className={clsx(styles.buttonOption, srcPage === 'outline' ? styles.buttonOptionOutline : null)}
          data-testid='layoutLoginButton'
        >
          Log in
        </Button>
      )
  }

  return (
    <div className={styles.container}>
      <div className={styles.navSupport}>
        <div className={styles.support}>
          Need help?{' '}
          <Link
            href={SUPPORT_PAGE_URL}
            underline='hover'
            target='_blank'
            onClick={onContactSupport}
            style={{ marginRight: '24px' }}
            data-testid='layoutContactSupportLink'
          >
            Contact support
          </Link>
        </div>
        {button}
      </div>
    </div>
  )
}
