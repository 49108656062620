import { GroupOutlined, ReceiptLongOutlined, SettingsOutlined } from '@mui/icons-material'
import { MenuItemProps } from '@mui/material'
import { AppRoute } from 'appRoutes'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

export const SettingsMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  return (
    <>
      <GenericMenuItem icon={<GroupOutlined />} label='Team' to={AppRoute.UserManagement} {...props} />
      <GenericMenuItem
        icon={<ReceiptLongOutlined />}
        label={'Billing'}
        data-testid='header-billing-link'
        to={AppRoute.Billing}
        {...props}
      />
      <GenericMenuItem
        icon={<SettingsOutlined />}
        label='Account Settings'
        to={AppRoute.AccountApplications}
        {...props}
      />
    </>
  )
}
