import { Close, OpenInNew } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'

import { TextFieldWithCounter } from '../../../commonUI'
import styles from './FeedbackDrawerView.module.scss'

export type FeedbackDrawerViewProps = {
  alreadyRated?: boolean
  initialRating?: number
  isOpen: boolean
  onClose: () => void
  onConfirm?: ({
    message,
    rating,
    isCallRequested,
  }: {
    message: string
    rating: number
    isCallRequested: boolean
  }) => void
  successMessageShown: boolean
}

export const FeedbackDrawerView = (props: FeedbackDrawerViewProps) => {
  const [message, setMessage] = useState('')
  const [isCallRequested, setIsCallRequested] = useState(false)
  const [selectedRating, setSelectedRating] = useState<number | undefined>(props.initialRating)

  useEffect(() => {
    setSelectedRating(props.initialRating)
  }, [props.initialRating])

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <>
      <Drawer open={props.isOpen} anchor='bottom' onClose={props.onClose}>
        {props.onClose && (
          <IconButton
            size='small'
            onClick={props.onClose}
            className={styles.closeButton}
            data-testid='drawer-close-button'
          >
            <Close />
          </IconButton>
        )}
        <div className={styles.drawerRoot}>
          {props.alreadyRated ? (
            <>
              <Typography variant='h3' component='h3' className={styles.title}>
                You have already submitted feedback.
              </Typography>
              <Typography variant='bodyS' className={styles.alreadyRatedSub}>
                Appreciate your enthusiasm! But we can only accept{' '}
                <Link
                  style={{ textDecoration: 'none' }}
                  href='https://fingerprint.com/blog/prevent-coupon-promo-abuse-increase-sales'
                  target='_blank'
                  rel='noreferrer'
                >
                  one submission per user
                </Link>{' '}
                for our gift card surveys.
              </Typography>
            </>
          ) : props.successMessageShown ? (
            <>
              <Typography variant='h3' component='h3' className={styles.title} style={{ color: '#4CAF50' }}>
                Thank you! Feedback received.
              </Typography>
              <div className={styles.successMessage}>
                We would greatly appreciate an optional public review on G2. In return, we will gift you a{' '}
                <b>$200 gift card</b> of your choice.
              </div>
              <Button
                color='primary'
                variant='contained'
                size='large'
                endIcon={<OpenInNew style={{ width: '16px' }} />}
                fullWidth={!lgUp}
                href='https://www.g2.com/products/fingerprintjs-fingerprint/reviews?utf8=%E2%9C%93&order=most_recent#reviews'
                target='_blank'
              >
                Review for $200
              </Button>
              <Typography variant='bodyS' style={{ marginTop: '16px', textAlign: 'center' }}>
                Your gift card will be emailed once we verify your review.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h3' component='h3' className={styles.title}>
                How likely are you to recommend Fingerprint to a friend or colleague?
              </Typography>
              <div className={styles.subtitle}>
                By sharing your feedback, we’ll email you a gift card of your choice.
              </div>
              <ButtonGroup variant='outlined' fullWidth>
                {[...Array(11).keys()].map((i) => (
                  <Button
                    key={`fb_button_${i}`}
                    className={styles.ratingButton}
                    onClick={() => setSelectedRating(i)}
                    variant={selectedRating === i ? 'contained' : 'outlined'}
                  >
                    {i}
                  </Button>
                ))}
              </ButtonGroup>
              <div className={styles.buttonsLegend}>
                <Typography variant='bodyS'>Not likely at all...</Typography>
                <Typography variant='bodyS'>Extremely likely!</Typography>
              </div>
              {selectedRating !== undefined && selectedRating < 9 ? (
                <div className={styles.feedback}>
                  How can we improve? (optional)
                  <TextFieldWithCounter
                    defaultValue={message}
                    placeholder='This message goes to our Slack. Message #product-feedback'
                    maxSym={500}
                    onChange={(val) => {
                      setMessage(val)
                    }}
                  />
                  <Stack
                    justifyContent='space-between'
                    width='100%'
                    gap='16px'
                    direction={{ xs: 'column', sm: 'row' }}
                    style={{ marginTop: '24px' }}
                  >
                    <FormControlLabel
                      componentsProps={{ typography: { variant: 'bodyS' } }}
                      label="I'd love to discuss feedback over Zoom with Fingerprint"
                      control={
                        <Checkbox
                          size='small'
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          checked={isCallRequested}
                          onChange={(e) => setIsCallRequested(e.target.checked)}
                        />
                      }
                    />
                    <Button
                      onClick={() => props.onConfirm?.({ message, rating: selectedRating ?? 0, isCallRequested })}
                      color='primary'
                      variant='contained'
                      size='large'
                    >
                      Send feedback
                    </Button>
                  </Stack>
                </div>
              ) : (
                <Stack
                  justifyContent='space-between'
                  width='100%'
                  gap='16px'
                  direction={{ xs: 'column', sm: 'row' }}
                  style={{ marginTop: '24px' }}
                >
                  <div />
                  <Button
                    disabled={selectedRating === undefined}
                    onClick={() => props.onConfirm?.({ message, rating: selectedRating ?? 0, isCallRequested })}
                    color='primary'
                    variant='contained'
                    size='large'
                  >
                    Send feedback
                  </Button>
                </Stack>
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  )
}
