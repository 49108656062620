import { InfoOutlined } from '@mui/icons-material'
import { Skeleton, Tooltip, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './Widget.module.scss'

export function Widget({ title, children, info }: PropsWithChildren<{ title: string; info?: ReactNode }>) {
  return (
    <div className={styles.widget}>
      <div className={styles.titleRow}>
        {title ? <Typography variant='bodyS'>{title}</Typography> : <Skeleton width={112} height={16} />}
        {info ? (
          <Tooltip title={info}>
            <InfoOutlined fontSize='14' color='secondary-text' />
          </Tooltip>
        ) : null}
      </div>
      {children ? <div className={styles.children}>{children}</div> : <Skeleton width={96} height={32} />}
    </div>
  )
}
