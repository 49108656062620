import { Grid, Paper, Typography } from '@mui/material'
import { endOfDay, format, getDate, isSameMonth, isToday, isWithinInterval, startOfDay } from 'date-fns'

import {
  DateRange,
  getDaysInMonth,
  inDateRange,
  isEndOfRange,
  isRangeSameDay,
  isStartOfRange,
  WEEK_DAYS,
} from '../../../../helpers/dateRange'
import { Marker, NavigationAction } from '../../types'
import Day from '../Day/Day'
import Header from '../Header/Header'
import styles from './Month.module.scss'

interface MonthProps {
  date: Date
  marker: Marker
  dateRange: DateRange
  minDate: Date
  maxDate: Date
  canNavBack: boolean
  canNavForward: boolean
  setDate: (date: Date) => void
  helpers: {
    inHoverRange: (day: Date) => boolean
  }
  handlers: {
    onDayClick: (day: Date, marker: Marker) => void
    onDayHover: (day: Date, marker: Marker) => void
    onMonthNavigate: (marker: Marker, action: NavigationAction) => void
  }
}

const chunks = (array: ReadonlyArray<Date>, size: number): Date[][] =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size))

export default function Month({
  helpers,
  handlers,
  date,
  dateRange,
  marker,
  setDate,
  minDate,
  maxDate,
  canNavBack,
  canNavForward,
}: MonthProps) {
  return (
    <Grid
      container
      square
      elevation={0}
      className={styles.root}
      component={Paper}
      data-testid={`date-range-picker-${marker === Marker.FirstMonth ? 'first' : 'second'}-month`}
    >
      <Header
        date={date}
        minDate={minDate}
        maxDate={maxDate}
        setDate={setDate}
        nextDisabled={!canNavForward}
        prevDisabled={!canNavBack}
        onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
        onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
      />

      <Grid item container direction='row' justifyContent='space-between' className={styles.weekDaysContainer}>
        {WEEK_DAYS.map((day) => (
          <Typography color='textSecondary' key={day} variant='body2'>
            {day}
          </Typography>
        ))}
      </Grid>

      <Grid item container direction='column' justifyContent='space-between' className={styles.daysContainer}>
        {chunks(getDaysInMonth(date), 7).map((week, idx) => (
          <Grid key={idx} container direction='row' justifyContent='center'>
            {week.map((day) => {
              const isStart = isStartOfRange(dateRange, day)
              const isEnd = isEndOfRange(dateRange, day)
              const isRangeOneDay = isRangeSameDay(dateRange)
              const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day)
              const isDisabled =
                !isSameMonth(date, day) ||
                !isWithinInterval(day, {
                  start: startOfDay(minDate),
                  end: endOfDay(maxDate),
                })

              return (
                <Day
                  key={format(day, 'MM-dd-yyyy')}
                  filled={isStart || isEnd}
                  outlined={isToday(day)}
                  highlighted={highlighted && !isRangeOneDay}
                  disabled={isDisabled}
                  startOfRange={isStart && !isRangeOneDay}
                  endOfRange={isEnd && !isRangeOneDay}
                  onClick={() => handlers.onDayClick(day, marker)}
                  onHover={() => handlers.onDayHover(day, marker)}
                  value={getDate(day)}
                />
              )
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
