import Loader from 'components/Loader/Loader'
import { APITypesMap, GenericError } from 'const'

import CreateAppForm from '../CreateAppForm/CreateAppForm'

type FormData = Pick<
  APITypesMap['subscriptionStart']['body'],
  | 'name'
  | 'domain'
  | 'regionCode'
  | 'fraudType'
  | 'otherFraudType'
  | 'jobLevel'
  | 'jobFunction'
  | 'privacyPolicy'
  | 'termsOfService'
  | 'isDeveloper'
  | 'expectedIdentifications'
>

export type Props = {
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: FormData) => Promise<void>
}

export default function SubscriptionCreate({ isLoading, onSubmit, error }: Props) {
  if (isLoading) {
    return <Loader />
  }

  return <CreateAppForm onSubmit={onSubmit} error={error} />
}
