import { endOfDay, startOfDay, subDays } from 'date-fns'
import { VisitsFilter } from 'models'

export const today = new Date()
export const since = startOfDay(subDays(today, 7))
export const before = endOfDay(today)

export const defaultValues: VisitsFilter = {
  since: since.valueOf(),
  before: before.valueOf(),
  ipAddress: '',
  linkedId: '',
  origin: '',
  requestId: '',
  url: '',
  visitorId: '',
}
