import { Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './PageHeader.module.scss'

export interface EntityContainerProps {
  title: string
  infoDrawer?: ReactNode
  actions?: ReactNode | ReactNode[]
  className?: string
}

export default function PageHeader({ title, infoDrawer, actions, className }: PropsWithChildren<EntityContainerProps>) {
  return (
    <header className={clsx(styles.header, className)}>
      <span className={styles.title}>
        <Typography variant='h5'>{title}</Typography>
        {infoDrawer}
      </span>

      {actions}
    </header>
  )
}
