import { Skeleton, Typography } from '@mui/material'
import { GenericError } from 'const'
import { calculateGranularity, useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { getTableDataPoints } from 'helpers/data'
import { DateRange } from 'helpers/dateRange'
import { getIdentificationCounterQueryType } from 'helpers/subscription'
import { useSubscription } from 'hooks'
import { useIntegrationStatus } from 'hooks/api/integration_status'
import { useUsageCounters } from 'hooks/api/usage_counters'
import { useCurrentUser } from 'hooks/api/users'
import {
  BillingType,
  ExpandedSubscription,
  IntegrationStep,
  SubscriptionIntegrationStatus,
  SubscriptionStatus as Status,
  UsageChartData,
  UsageCounterDataPoint,
} from 'models'

import { interpretOverviewDateRange } from '../../../commonUI/components/DateRangeContext/interpretOverviewDateRange'
import { DistributionCharts } from '../Distributions/DistributionCharts'
import ChartDateRangePicker from '../UsageChart/ChartDateRangePicker/ChartDateRangePicker'
import UsageTable from '../UsageTable/UsageTable'
import { NumericWidgets } from '../WorkspaceInsights/NumericWidgets'
import styles from './SubscriptionOverview.module.scss'
import { SubscriptionOverviewState } from './SubscriptionOverviewState'
import { UsageCharts } from './UsageCharts'

type Props = {
  subscription?: ExpandedSubscription
  integrationStatus?: SubscriptionIntegrationStatus
  usageCountersData?: UsageChartData
  usageCountersError?: GenericError | null
  timezone?: string
}

export function Insights() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(subscriptionId)
  const { data: currentUser } = useCurrentUser()

  const { dateRange } = useDateRangeContext()
  const { startDate, endDate } = interpretOverviewDateRange(dateRange, currentUser?.timezone)
  const granularity = calculateGranularity(startDate, endDate)

  const { data: usageCountersData, error: usageCountersError } = useUsageCounters({
    subscriptionId,
    from: startDate,
    to: endDate ? endDate.minus({ millisecond: 1 }) : undefined,
    queryType: getIdentificationCounterQueryType(subscription ?? { billingType: BillingType.ApiCalls }),
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: currentUser != null,
  })

  const { data: integrationStatus } = useIntegrationStatus(subscriptionId)

  return (
    <div className={styles.insights}>
      <InsightsHeader timezone={currentUser?.timezone} />
      <NumericWidgets subscriptionId={subscription?.id} />
      <DistributionWidgets />
      <InsightsCharts
        integrationStatus={integrationStatus}
        subscription={subscription}
        timezone={currentUser?.timezone}
        usageCountersData={usageCountersData}
        usageCountersError={usageCountersError}
      />
      <InsightsUsageTable
        integrationStatus={integrationStatus}
        subscription={subscription}
        timezone={currentUser?.timezone}
        usageCountersData={usageCountersData}
        usageCountersError={usageCountersError}
      />
    </div>
  )
}

function InsightsHeader({ timezone }: { timezone?: string }) {
  const { setDateRange, initialDateRange, initialMinDate, initialMaxDate, today } = useDateRangeContext()

  return (
    <div className={styles.insightsHeader}>
      <Typography variant='h3' style={{ margin: 0 }}>
        Insights
      </Typography>

      {today && setDateRange && initialDateRange ? (
        <ChartDateRangePicker
          onApplyRange={(range: Required<DateRange>) => setDateRange(range)}
          initialDateRange={initialDateRange}
          initialMinDate={initialMinDate}
          initialMaxDate={initialMaxDate}
          today={today}
          rangeInterpreter={(dateRange: Required<DateRange>) => interpretOverviewDateRange(dateRange, timezone)}
          timezone={timezone}
        />
      ) : (
        <Skeleton width={200} />
      )}
    </div>
  )
}

function DistributionWidgets() {
  return (
    <div className={styles.distributionWidgets}>
      <DistributionCharts />
    </div>
  )
}

function InsightsCharts({ subscription, timezone, usageCountersData, usageCountersError, integrationStatus }: Props) {
  const { dateRange } = useDateRangeContext()
  const { startDate, endDate } = interpretOverviewDateRange(dateRange)
  const granularity = calculateGranularity(startDate, endDate)
  const overviewState = extractOverviewState(subscription, integrationStatus, usageCountersData, usageCountersError)

  if (subscription == null || (usageCountersData == null && usageCountersError == null) || granularity == null) {
    return <Skeleton width='100%' height={250} data-testid='usage-records-loader' />
  }

  return (
    <UsageCharts
      subscriptionId={subscription.id}
      apiRequestsState={overviewState}
      usageCountersData={usageCountersData}
      usageCountersError={usageCountersError}
      granularity={granularity}
      timezone={timezone}
    />
  )
}

function InsightsUsageTable({
  subscription,
  timezone,
  usageCountersData,
  usageCountersError,
  integrationStatus,
}: Props) {
  const { dateRange } = useDateRangeContext()
  const { startDate, endDate } = interpretOverviewDateRange(dateRange)
  const granularity = calculateGranularity(startDate, endDate)
  const overviewState = extractOverviewState(subscription, integrationStatus, usageCountersData, usageCountersError)

  if (
    subscription != null &&
    overviewState === SubscriptionOverviewState.HasUsageCounters &&
    usageCountersData &&
    granularity
  ) {
    return <UsageTable data={getTableDataPoints(usageCountersData)} granularity={granularity} timezone={timezone} />
  }

  if (subscription != null && usageCountersData != null) {
    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
    </div>
  )
}

function extractOverviewState(
  subscription?: ExpandedSubscription,
  integrationStatus?: SubscriptionIntegrationStatus,
  usageCountersData?: UsageChartData,
  usageCountersError?: GenericError | null
): SubscriptionOverviewState {
  const hasRecentUsageCounters =
    usageCountersData &&
    (Object.values(usageCountersData) as UsageCounterDataPoint[][]).some((points) =>
      points.some((point) => point.value > 0)
    )

  if (subscription?.status !== Status.Canceled && integrationStatus?.currentStep === IntegrationStep.ApiCalls) {
    return SubscriptionOverviewState.Setup
  } else if (hasRecentUsageCounters) {
    return SubscriptionOverviewState.HasUsageCounters
  } else if (usageCountersError) {
    return SubscriptionOverviewState.UsageCountersError
  } else {
    return SubscriptionOverviewState.NoUsageCountersDuringPeriod
  }
}
