import { Bolt, Close, Code, DesktopMac, Home, Settings } from '@mui/icons-material'
import { Divider, Drawer, IconButton, List, Theme, useMediaQuery } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import SubscriptionSelect from 'components/SubscriptionSelect/SubscriptionSelect'
import { DEMO_HUB_URL } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { HAS_SEEN_DEVELOPER_TOOLS, useSubscription, useSubscriptions, useUserProperty } from 'hooks'
import { useUserStats } from 'hooks/api/userStats'
import Botd from 'icons/Botd.svg'
import { ampli } from 'models/ampli'
import { IntegrationStep } from 'models/integrationStatus'
import { CSSProperties } from 'react'

import Logo from '../../../../components/Logo/Logo'
import { ContactSupportMenuItems } from '../Menus/ContactSupportMenuItem'
import { DeveloperMenuItems } from '../Menus/DeveloperMenuItems'
import { GenericMenuItem } from '../Menus/GenericMenuItem'
import { ProfileMenuItems } from '../Menus/ProfileMenuItems'
import { ServiceStatusMenuItems } from '../Menus/ServiceStatusMenuItems'
import { SettingsMenuItems } from '../Menus/SettingsMenuItems'
import { SignOutMenuItems } from '../Menus/SignOutMenuItems'
import { SidebarPlanBanner } from '../SidebarPlanBanner/SidebarPlanBanner'
import { TrialExtendedModal } from '../TrialExtendedModal/TrialExtendedModal'
import FingerprintLogo from './FingerprintLogo.svg'
import styles from './SidebarMain.module.scss'

interface SidebarMainProps {
  mobileOpen?: boolean
  onClose?: () => void
  style?: CSSProperties
}

export function SidebarMain({ mobileOpen, onClose, style }: SidebarMainProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <Drawer
      className={styles.root}
      anchor={isDesktop ? 'left' : 'right'}
      classes={{ paper: styles.drawerContent }}
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={mobileOpen || isDesktop}
      ModalProps={{
        keepMounted: !isDesktop, // Better open performance on mobile.
      }}
      PaperProps={{ style }}
      onClose={onClose}
    >
      <DrawerContent onClose={onClose} />
    </Drawer>
  )
}

interface DrawerContentProps {
  onClose?: () => void
}

function DrawerContent({ onClose }: DrawerContentProps) {
  const { data: subscriptions = [], isLoading } = useSubscriptions()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentSubscription } = useSubscription(subscriptionId)

  const [hasSeenDevsTools, setHasSeenDevsTools] = useUserProperty(HAS_SEEN_DEVELOPER_TOOLS)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const baseOverviewRoute = buildRoute(AppRoute.SubscriptionOverview, { subscriptionId })

  const { data: userStats } = useUserStats(true)

  const showGetStarted =
    userStats?.customer.milestones.hundredApiCalls === false ||
    currentSubscription?.integrationStep === IntegrationStep.ApiCalls

  const menuItemProps = {
    classes: { root: styles.item, selected: styles.selected },
    disableRipple: true,
    iconProps: {
      className: styles.listItemIcon,
    },
    labelProps: {
      classes: { primary: styles.itemText },
    },
  }
  const mobileMenuItemProps = {
    onClick: onClose,
    classes: { root: clsx(styles.item, styles.condensed), selected: styles.selected },
    iconProps: { className: styles.listItemIcon },
    labelProps: {
      classes: { primary: styles.itemText },
    },
  }

  return (
    <>
      <div className={styles.header}>
        <SubscriptionSelect subscriptions={subscriptions} isLoading={isLoading} />

        {!isDesktop && (
          <IconButton
            onClick={onClose}
            edge='start'
            aria-label='Close side panel'
            size='large'
            sx={{ width: '48px', height: '48px', lineHeight: '1', ml: 2 }}
          >
            <Close />
          </IconButton>
        )}
      </div>

      <List component='div' className={styles.links}>
        {showGetStarted && (
          <GenericMenuItem
            icon={<FingerprintLogo />}
            label={'Get Started'}
            to={buildRoute(AppRoute.SubscriptionGetStarted, { subscriptionId })}
            data-testid={'globalSidebarGetStartedLink'}
            {...menuItemProps}
          />
        )}
        <GenericMenuItem
          icon={<Home />}
          label={'Overview'}
          to={baseOverviewRoute}
          data-testid='globalSidebarOverviewLink'
          {...menuItemProps}
        />
        <GenericMenuItem
          icon={<DesktopMac />}
          label={'Identification'}
          to={buildRoute(AppRoute.IdentificationVisits, { subscriptionId })}
          {...menuItemProps}
        />
        <GenericMenuItem
          icon={<Bolt />}
          label={'Smart Signals'}
          to={buildRoute(AppRoute.SmartSignals, { subscriptionId })}
          {...menuItemProps}
        />
        <GenericMenuItem
          icon={<Botd />}
          label={'Bot Detection'}
          to={buildRoute(AppRoute.Botd, { subscriptionId })}
          {...menuItemProps}
        />
        <GenericMenuItem
          icon={<Settings />}
          label={'App Settings'}
          data-testid='globalSidebarAppSettingsLink'
          to={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId })}
          subRoutes={[
            buildRoute(AppRoute.SubscriptionSettings, { subscriptionId }),
            buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }),
            buildRoute(AppRoute.Health, { subscriptionId }),
            buildRoute(AppRoute.CustomSubdomains, { subscriptionId }),
            buildRoute(AppRoute.ApiKeys, { subscriptionId }),
            buildRoute(AppRoute.Webhooks, { subscriptionId }),
            buildRoute(AppRoute.RequestFiltering, { subscriptionId }),
            buildRoute(AppRoute.Integrations, { subscriptionId }),
          ]}
          {...menuItemProps}
        />
        <GenericMenuItem
          icon={<Code />}
          label={
            <>
              <span>Developer Guides</span>
              {!hasSeenDevsTools ? <span className={styles.newChip}>New</span> : null}
            </>
          }
          href={DEMO_HUB_URL}
          onClick={() => {
            ampli.fingerprintHubOpened()
            setHasSeenDevsTools(true)
          }}
          {...menuItemProps}
        />
      </List>

      <div className={styles.filler} />

      <div className={styles.fullWidth}>
        {currentSubscription && (
          <SidebarPlanBanner
            subscription={currentSubscription}
            sx={{ width: 'calc(100% + 24px)', p: '8px', mx: '-12px', my: 0 }}
          />
        )}
      </div>

      {currentSubscription && (
        <TrialExtendedModal
          status={currentSubscription.status}
          type={currentSubscription.type}
          trialEndAt={currentSubscription.trialEndAt}
          extendedTrialStartedAt={currentSubscription.extendedTrialStartedAt}
        />
      )}

      {isDesktop ? null : (
        <div className={styles.actions}>
          <List component='div'>
            <ProfileMenuItems {...mobileMenuItemProps} />
            <Divider />
            <SettingsMenuItems {...mobileMenuItemProps} />
            <Divider />
            <DeveloperMenuItems {...mobileMenuItemProps} />
            <Divider />
            <ContactSupportMenuItems {...mobileMenuItemProps} />
            <Divider />
            <ServiceStatusMenuItems {...mobileMenuItemProps} />
            <Divider />
            <SignOutMenuItems {...mobileMenuItemProps} />
          </List>
        </div>
      )}

      <div className={styles.fullWidth}>
        <Logo className={styles.logo} short={false} fillSecondary='#0D102B' />
      </div>
    </>
  )
}
