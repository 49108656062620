import { ManageAccountsOutlined } from '@mui/icons-material'
import { MenuItemProps } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { useCurrentUser } from 'hooks/api/users'

import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'
import styles from './ProfileMenuItems.module.scss'

export const ProfileMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  const { data: currentUser } = useCurrentUser()

  return (
    <>
      {currentUser?.name != null ? (
        <div className={styles.profileDetails}>
          <strong>{currentUser.name}</strong>
          <br />
          <span>{currentUser.email}</span>
        </div>
      ) : null}
      <GenericMenuItem
        icon={<ManageAccountsOutlined />}
        label='Profile Settings'
        to={AppRoute.ProfileSettings}
        {...props}
      />
    </>
  )
}
