import { Skeleton } from '@mui/material'
import { AppRoute, useRouteParams } from 'appRoutes'
import { MainGrid } from 'features/commonUI'
import { useInvoice } from 'hooks/api/invoices'
import { Redirect } from 'react-router-dom'

import { default as InvoiceView } from '../../components/InvoiceView/InvoiceView'
import styles from './Invoice.module.scss'

export function InvoicePage() {
  const { invoiceId: id, subscriptionId } = useRouteParams<AppRoute.Invoice>()
  const { data: invoice, isLoading, error } = useInvoice(id, subscriptionId)

  return error ? (
    <Redirect to={AppRoute.Billing} />
  ) : (
    <MainGrid>
      {isLoading || !invoice ? (
        <>
          <Skeleton
            className={styles.skeleton}
            width='100%'
            height='200px'
            data-testid='invoice-loading-skeleton-rect'
          />
          <Skeleton className={styles.skeleton} width='100%' height='32px' />
          <Skeleton className={styles.skeleton} width='100%' height='32px' />
          <Skeleton className={styles.skeleton} width='100%' height='32px' />
          <Skeleton className={styles.skeleton} width='100%' height='32px' />
        </>
      ) : (
        <InvoiceView invoice={invoice} />
      )}
    </MainGrid>
  )
}
