import { AccountCircle, HelpOutline, Menu as MenuIcon } from '@mui/icons-material'
import { Button, Divider, IconButton, Menu, MenuItemProps } from '@mui/material'
import { ProductFeedback } from 'features/commonUI'
import { useUiPreferences } from 'hooks'
import { useCurrentUser } from 'hooks/api/users'
import { ampli } from 'models/ampli'
import { ReactNode, useRef, useState } from 'react'

import { ContactSupportMenuItems } from '../Menus/ContactSupportMenuItem'
import { DeveloperMenuItems } from '../Menus/DeveloperMenuItems'
import { GenericMenuItemOverrideProps } from '../Menus/GenericMenuItem'
import { ProfileMenuItems } from '../Menus/ProfileMenuItems'
import { ServiceStatusMenuItems } from '../Menus/ServiceStatusMenuItems'
import { SettingsMenuItems } from '../Menus/SettingsMenuItems'
import { SignOutMenuItems } from '../Menus/SignOutMenuItems'
import styles from './Header.module.scss'

interface HeaderProps {
  title?: string
  info?: ReactNode
}

const MENU_ITEM_PROPS: MenuItemProps & GenericMenuItemOverrideProps = {
  classes: { root: styles.item, selected: styles.selected },
  disableRipple: true,
  iconProps: {
    className: styles.listItemIcon,
  },
  labelProps: {
    classes: { primary: styles.itemText },
  },
}

export function Header({ title, info }: HeaderProps) {
  const { updateUiPreferences } = useUiPreferences()

  return (
    <PureHeader
      onMenuClick={() => updateUiPreferences({ sidebar: { isOpened: true } })}
      title={title}
      info={info}
      extraActions={
        <>
          <ProductFeedback
            triggerComponent={Button}
            triggerProps={{
              variant: 'text',
              color: 'grey',
              children: <>Have feedback?</>,
            }}
          />
        </>
      }
    />
  )
}

export const HelpMenu = () => {
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  return (
    <>
      <IconButton
        title='Help'
        size='medium'
        ref={triggerRef}
        onClick={() => {
          setDropdownOpen(true)
        }}
      >
        <HelpOutline />
      </IconButton>

      <Menu
        anchorEl={triggerRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
      >
        <DeveloperMenuItems onClick={closeDropdown} {...MENU_ITEM_PROPS} />
        <Divider />
        <ContactSupportMenuItems onClick={closeDropdown} {...MENU_ITEM_PROPS} />
        <Divider />
        <ServiceStatusMenuItems onClick={closeDropdown} {...MENU_ITEM_PROPS} />
      </Menu>
    </>
  )
}

export const ProfileMenu = () => {
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  return (
    <>
      <IconButton
        title='Profile'
        className={styles.button}
        size='medium'
        ref={triggerRef}
        onClick={() => {
          if (!dropdownOpen) {
            ampli.userOptionsViewed()
          }
          setDropdownOpen(true)
        }}
      >
        <UserInitialsOrIcon />
      </IconButton>

      <Menu
        anchorEl={triggerRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
      >
        <ProfileMenuItems onClick={closeDropdown} {...MENU_ITEM_PROPS} />
        <Divider />
        <SettingsMenuItems onClick={closeDropdown} {...MENU_ITEM_PROPS} />
        <Divider />
        <SignOutMenuItems {...MENU_ITEM_PROPS} />
      </Menu>
    </>
  )
}

interface PureHeaderProps extends HeaderProps {
  onMenuClick: () => unknown
  extraActions?: ReactNode
}

export function PureHeader({ title, info, onMenuClick, extraActions }: PureHeaderProps) {
  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h1 className={styles.title}>{title}</h1>
          {info}
        </div>

        <IconButton
          className={styles.mobileMenuButton}
          onClick={onMenuClick}
          edge='start'
          aria-label='Open side panel'
          size='large'
          sx={{ width: '48px', height: '48px', lineHeight: '1' }}
        >
          <MenuIcon />
        </IconButton>

        <div className={styles.desktopButtons}>
          {extraActions}
          <HelpMenu />
          <ProfileMenu />
        </div>
      </div>
    </header>
  )
}

function UserInitialsOrIcon() {
  const { data, isLoading } = useCurrentUser()

  if (isLoading) {
    return null
  }

  if (!data?.name) {
    return <AccountCircle />
  }

  const nameParts = data.name.split(' ')
  const firstLetter = nameParts.shift()?.charAt(0)?.toUpperCase()
  const lastLetter = nameParts.pop()?.charAt(0)?.toUpperCase()

  return (
    <>
      {firstLetter ?? ''}
      {lastLetter ?? ''}
    </>
  )
}
