import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GenericError } from 'const'
import { SSLCertificate } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

export function useCertificates(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/certificates', subscriptionId] as const,
    queryFn: ({ queryKey: [_, subscriptionId] }) =>
      extractData(withErrorHandling(buildRequest('certificates', { params: { subscriptionId } }))),
    refetchInterval: 30 * 1000, // automatically refetch every 30 sec
    enabled: subscriptionId != null,
  })
}

export function useCertificate(subscriptionId: string, id: string, withPolling?: boolean, expandSubscription = false) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/certificates', subscriptionId, id, expandSubscription] as const,
    queryFn: ({ queryKey: [_, subscriptionId, id] }) =>
      extractData(
        withErrorHandling(
          buildRequest('certificateGet', {
            params: {
              id,
              subscriptionId,
            },
            expand: expandSubscription ? ['subscription'] : undefined,
          })
        )
      ),
    enabled: subscriptionId != null && id != null,
    refetchInterval: withPolling ? 10 * 1000 : undefined, // automatically refetch every 10 sec
    onError: (err: GenericError) => err, // type infer helper
  })
}

export function useAddCertificateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('certificateCreate', {
    onSuccess: (data) => {
      queryClient.setQueryData<SSLCertificate>(['subscription/certificates', data.subscriptionId, data.id], data)
    },
  })
}

export function useDeleteCertificateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('certificateDelete', {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription/certificates'])
    },
  })
}
